<template>
  <div class="main" darken>
    <header><Timer /></header>

    <h2>{{ quizQuestions[current].question }}</h2>
    <div class="options left">
      <vs-radio
        class="option"
        v-for="(question, i) in quizQuestions[current].options"
        :key="i"
        v-model="picked"
        :val="i"
      >
        {{ question }}
      </vs-radio>
    </div>

    <div class="img-wrap">
      <img crossorigin="anonymous" :src="quizQuestions[current].image" alt="" />
    </div>
    <footer class="footer">
      <div class="con-pagination">
        <vs-pagination
          progress
          v-model="current"
          :length="quizQuestions.length - 1"
        />
          <!-- only-arrows -->
      </div>
    </footer>
  </div>
</template>

<script>
import Timer from "@/components/general/Timer.vue";

export default {
  name: "Quiz",
  props: ["questions"],
  components: { Timer },
  data: function () {
    return {
      picked: null,
      current: 1,
      quizQuestions: [],
    };
  },
  mounted: function () {
    this.generateQuiz();
  },
  methods: {
    generateQuiz: function () {
      const allQuestions = this.questions;
      const iterations = 41;
      console.log(allQuestions.length);
      for (let i = 0; i < iterations; i++) {
        this.quizQuestions.push(
          this.questions[Math.floor(Math.random() * allQuestions.length + 1)]
        );
      }
    },
  },
  computed: {
    quiz: function () {},
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.options {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto 35px;
  .option {
    margin: 5px;
  }
}

.img-wrap {
  width: 100%;
  margin: 0 auto;
  img {
    max-width: 90%;
  }
}
.footer {
  width: 100%;
  height: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  padding: 30px;
  .con-pagination {
    width: 80%;
  }
}
</style>
