<template>
  <div id="app">
    <Main :questions="questions" />
  </div>
</template>

<script>
import Main from "./components/Main.vue";

export default {
  name: "App",
  components: {
    Main,
  },

  data: function () {
    return {
      questions: [
        {
          question:
            "1132. According to the law, how frequently should the brakes of a public vehicle be checked?",
          options: [
            "At least once every six months, in a licensed garage.",
            "At least once every 12 months, in a licensed garage.",
            "At least once a year during the summer, in a licensed garage.",
            "At least once a year during the winter, in a licensed garage.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0760. What risks are caused when you  deviate abruptly between lanes?",
          options: [
            "The deviation might delay other drivers.",
            "You might end up in a lane that you are not allowed to be in.",
            "Risk of hitting a vehicle that you didn’t see before deviating.",
            "It is risky when the deviation takes too long.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0580. What is the meaning of the following traffic sign?",
          options: [
            "Promenade ahead.",
            "Path for cyclists only.",
            "Path for cyclists and pedestrians only.",
            "Crossing for cyclists and pedestrians.",
          ],
          answer: 2,
          image: "img/quiz/3580.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0643. What is the meaning of the following road marking?",
          options: [
            "It marks the line of the road’s edge, where curbstones are absent.",
            "Driving is prohibited between the yellow lines.",
            "Parking is allowed on the left side of the line, on a freeway.",
            "Parking is allowed on the right side of the line, on a freeway.",
          ],
          answer: 0,
          image: "img/quiz/3643.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1043. What is the danger caused by fueling the vehicle while its engine is running?",
          options: [
            "There is no danger in keeping the engine running during vehicle fueling.",
            "Combustion of fuel fumes by a spark.",
            "When the engine is kept running, fuel consumption is increased.",
            "Engine overheating might flare-up the fuel.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "A", "В"],
        },
        {
          question: "1535. What sign forbids entry to pedestrians?",
          options: ["216.", "221.", "815.", "901."],
          answer: 0,
          image: "img/quiz/31535.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0684. What is a driver required to do when approaching the following traffic sign?",
          options: [
            "Slow down before the intersection and proceed in the directions indicated by the Chevrons in the sign only.",
            "Slow down in the place where road works take place and continue driving.",
            "Stop before the sign and turn right or left.",
            "Overtake the sign from left or right and continue driving.",
          ],
          answer: 0,
          image: "img/quiz/3684.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0514. Until where do the following road signs remain valid?",
          options: [
            "Until the nearest traffic light, provided that the light is green.",
            "Until the nearest bus station.",
            "The validity of the sign expires when approaching a densely populated area.",
            "Until the nearest junction or until another “speed limit” or “end of speed limit” sign is placed.",
          ],
          answer: 3,
          image: "img/quiz/3512.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0690. What is the meaning of the following traffic sign?",
          options: [
            "Level crossing 300 meters ahead.",
            "Direction and warning posts at the side of the road.",
            "Guardrail marking.",
            "Railway barrier ahead at a work-site",
          ],
          answer: 1,
          image: "img/quiz/3690.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1078. What is the meaning of the road sign?",
          options: [
            "Early warning to destinations before an Inter urban junction.",
            "Early warning to destinations before a junction in an urban area.",
            "Early warning to destination before leaving an Urban area.",
            "Early warning to destinations before a motor –way (Freeway).",
          ],
          answer: 0,
          image: "img/quiz/31078.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0036. Define “slow moving vehicle”:",
          options: [
            "A motor vehicle having a prescribed maximal speed of 50 k.p.h.",
            "A motor vehicle having a prescribed maximal speed of no less than 50 k.p.h.",
            "A vehicle restricted by law to 40 k.p.h. or less",
            "A motor vehicle having a prescribed maximal speed of 55 k.p.h.",
          ],
          answer: 2,
          image: "",
          types: ["1", "В"],
        },
        {
          question:
            "1336. What should a driver verify (according to the law) before making a U-turn on a straight road section?",
          options: [
            "That the vehicle doesn't carry any hazardous material.",
            "That he starts turning from the farthest right lane.",
            "That the turn is not made before a hill on the road.",
            "That he starts turning from the farthest left lane.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1339. What is “all up weight”?",
          options: [
            "A vehicle’s own weight, plus the weight of the people and freight that the vehicle is permitted to drive or carry, as approved by the Licensing Authority.",
            "It is fixed weight: 3,500 kg for light freight; 12,000 kg for medium freight; 15,000 kg for heavy freight.",
            "A vehicle’s own weight plus the weight of the freight that the vehicle is permitted to carry, as approved by the Licensing Authority, excluding the weight of the driver and passengers.",
            "A vehicle’s own weight minus the weight of the people and freight it is permitted to drive or carry, as approved by the Licensing Authority.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "0851. What specific problems do drivers face at night?",
          options: [
            "Dysfunction of the driver due to outside lighting.",
            "Having many vehicles around him during the night.",
            "Restricted field of vision, being dazzled )blinded) incorrect distance estimations.",
            "Drop in engine power due to the operation of the lighting system.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1782. On a steep descent, when driving on a narrow roadway that doesn’t allow 2 vehicles to pass at the same time:",
          options: [
            "Both vehicles should pull into the “hard shoulders”.",
            "Right of way should be given to commercial vehicles weighing more than 4 tons.",
            "The vehicle driving uphill should stop.",
            "The vehicle driving downhill should give way and stop if necessary.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0092. A driver’s license will not be renewed if it was expired for a period of more than:",
          options: [
            "Nine months, unless the license holder passes the required examinations and tests, as directed by the Licensing Authority",
            "Four months, unless the license holder passes the required examinations and tests, as directed by the Licensing Authority",
            "Six months, unless the license holder passes the required examinations and tests, as directed by the Licensing Authority",
            "One year, unless the license holder passes the required examinations and tests, as directed by the Licensing Authority",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1137. Is a bus driver permitted to talk with the passengers while driving?",
          options: [
            "Yes, but only with security personnel on duty.",
            "Yes, but only about matters relating to the journey and without turning his head away from the road.",
            "A bus driver is strictly forbidden to talk with the passengers.",
            "Yes, but only with the conductor or ticket seller.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question: "0010. What is the legal definition of a “road”",
          options: [
            "Any place that is open for public passage, except for city streets",
            "Asphalt or concrete paved road ways only.",
            "The term “road” has no legal definition",
            "Any rail, street, alley, square, passage, bridge and any other open space in which the public has a right to pass through",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0745. Why is it important to drive on the right side of the road?",
          options: [
            "It allows you to be assisted by the yellow line for driving in a straight line.",
            "It qllows vehicle passengers to easily exit the vehicle.",
            "Driving on the right side of the road is of no significance, except for one-way roadways.",
            "In order to keep a distance from incoming traffic from the opposite direction, and reducing the risk of “head on” collision.",
          ],
          answer: 3,
          image: "img/quiz/3745.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0272. On which part of a road should a cyclist ride?",
          options: [
            "As close as possible to the road’s left edge, facing the incoming traffic.",
            "As close as possible to the right hand side of the road.",
            "Where all other vehicles are driving.",
            "It is absolutely forbidden to ride bicycle on any part of a road.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0328. Which class of license is required for driving a tractor?",
          options: ["Class D.", "Class B.", "Class 1.", "Class C."],
          answer: 2,
          image: "",
          types: ["1"],
        },
        {
          question:
            "0541. Is it permitted to make a U-turn to the left where the following road sign is placed?",
          options: [
            "It is permitted in an intersection with traffic lights, and prohibited in an intersection without traffic lights.",
            "Yes, unless another sign is placed to restrict this.",
            "It is always permitted, even when a “no U-turn” sign is placed.",
            "It is always forbidden.",
          ],
          answer: 1,
          image: "img/quiz/3541.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0997. Why is it necessary to use the handbrake  for parking only?",
          options: [
            "The parking brake cannot be used as an emergency brake.",
            "Because its handle or lever is not convenient to operate.",
            "Because its life span is short and shouldn’t be wasted on regular braking.",
            "Because the parking brake doesn’t work on all the wheels and it doesn’t have much power.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "A", "В"],
        },
        {
          question:
            "0724. You are driving vehicle no. 3. In what order will vehicles enter the following intersection?",
          options: [
            "The vehicle on the left will go first (2), the vehicle on the right will go next (1), and you will go last (3).",
            "You go first (3), the vehicle on the left is next (2), and the vehicle on the right is last (1).",
            "The vehicle on the right (1), you go next (3), and the vehicle on the left is last (2).",
            "You go first (3), the vehicle on the right is next (1), and the vehicle on the left is last (2).",
          ],
          answer: 2,
          image: "img/quiz/3724.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1014. What part of the vehicle reduces whiplash during collision?",
          options: [
            "The safety belts.",
            "The rear bumper.",
            "The head rests of the vehicle’s seats.",
            "The air bags.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1667. Which vehicle can you drive with a Class A2 driving license?",
          options: [
            "Motorcycles with an engine power of over 25 KW (33 horse-powers).",
            "Motorcycles with an engine power of over 39 KW (52 horse-powers).",
            "All motorcycle, without any engine-power restriction.",
            "Motorcycles with an engine power of up to 11 KW (14.6 horse-powers).",
          ],
          answer: 3,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0723. What is the obligation of a driver transporting children, whilst children are disembarking from the school bus?",
          options: [
            "Drive away as soon as the students get off, so that the children will cross the road safely.",
            "Wait and make sure that all the students that have got off and have stepped away from the vehicle.",
            "Ensure that everybody got off through the rear door.",
            "Wait for a police officer to arrive and assist the children to cross the road.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1011. What is the SRS (Supplemental Restraint System) airbag system?",
          options: [
            "It is a system of external airbags that inflate after collision.",
            "It is an airbag system that balances the vehicle during dangerous turns.",
            "It is a system that cools the brakes during emergency braking.",
            "It is a system designed to reduce passenger impact, through the inflation of airbags during collision.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1747. It prohibited to overtake before a pedestrian crossing:",
          options: [
            "Always.",
            "Only on a non-urban road.",
            "Only on a two-way urban road.",
            "Only when a “pedestrian crossing ahead” sign is placed.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1681. A “new driver” under 21 yrs should not carry in a private passenger car:",
          options: [
            "More than four passengers besides himself, unless he is accompanied by an escort.",
            "More than four passengers, and provided that all of them are licensed to drive private vehicles.",
            "More than two passengers besides himself, unless he is accompanied by an escort.",
            "More than one passenger besides himself, unless he is accompanied by an escort.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0962. According to the traffic sign placed on the right, How would you conduct yourself on the road ahead?",
          options: [
            "Pay special attention to vehicles merging from the right.",
            "Carefully merge with the vehicles driving on your left.",
            "Give right of way to vehicles merging from the right.",
            "Give right of way to the vehicles on the right turn.",
          ],
          answer: 0,
          image: "img/quiz/3962.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1454. What does the Carrier Service Law determine?",
          options: [
            "Freight transportation arrangements to and from the port.",
            "The law determines the all up weight of transportation vehicles.",
            "The law determines maximum tariffs for ground transportation.",
            'The law states that transport services by a commercial vehicle with an all up weight exceeding 10,000 kg shall not be provided without a \\"carrier license\\".',
          ],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0144. According to the following picture you are obliged to:",
          options: ["Stop.", "Slow down.", "Accelerate.", "Honk."],
          answer: 1,
          image: "img/quiz/3144.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1322. Which type of motor vehicle is authorized to carry a freight container?",
          options: [
            "A work vehicle that is authorized to transport containers.",
            "A commercial vehicle with an all up weight of more than 15,000 kg, on an urban road only.",
            "A commercial motor vehicle with a minimum all up weight of 16,000 kg.",
            "A commercial vehicle with an all up weight of more than 15,000 kg, on an inter-urban road only.",
          ],
          answer: 2,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0306. Is a policeman authorized to require a vehicle driver to undergo a blood test for the presence of alcohol or intoxicating drugs?",
          options: [
            "Yes. A policeman is authorized to require a blood test if he has reasonable suspicion that the driver is under the influence of alcohol or intoxicating drugs.",
            "No. A policeman is not authorized to require the performance of any test without a court order.",
            "No. A policeman is not authorized to require a driver to perform a blood test for determining alcohol or intoxicating-drug levels..",
            "No. A policeman is not authorized to require a driver to perform any type of test, at any given time or place.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0607. What is the meaning of the following traffic sign?",
          options: [
            "Traffic directions for the lanes of the next intersection.",
            "One-way road ahead.",
            "Public transportation is prohibited to proceed forward.",
            "Vehicles on the right lane are prohibited to deviate to another lane.",
          ],
          answer: 0,
          image: "img/quiz/3607.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0393. Which of the following traffic signs should be placed where parking and stopping at the side of the road are prohibited?",
          options: ["817", "434", "432", "433"],
          answer: 3,
          image: "img/quiz/3393.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0466. What is the meaning of the following traffic sign?",
          options: [
            "Left curve followed by a right curve.",
            "Rough road.",
            "Drive in the center of the road.",
            "Danger of skidding.",
          ],
          answer: 3,
          image: "img/quiz/3466.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1622. According to the law, while driving at night and in good weather conditions – what is the minimal distance that should be covered by the vehicle’s headlights?",
          options: [
            "The range of the headlights is not defined by law, and it is determined at the discretion of the vehicle’s manufacturer.",
            "100 meters for the high beam, and 30 meters for the low(dipped) beam.",
            "120 meters for the high beam, and 50 meters for the low beam.",
            "150 meters for the high beam, and 60 meters for the low beam.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1371. It is obligatory to fit Extremity (width) side lights:",
          options: [
            "On a 210cm-or-more wide vehicle.",
            "Only on connected vehicles or semi-trailers.",
            "On all commercial vehicles.",
            "On vehicles with an all up width of 180cm or less.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question: "1209. Is it permitted to smoke in a bus?",
          options: [
            "Yes – within the limits of urban roads only.",
            "Yes – but only in the back of the bus.",
            "No – and it is even obligatory to place a “no smoking” sign inside the bus.",
            "Yes – but only when the air-conditioning system doesn’t work.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question: "0040. What is a “private passenger car”?",
          options: [
            "A public motor vehicle, designed to carry eight passengers, licensed as a public vehicle",
            "A private motor vehicle, designed to carry up to eight passengers, apart from the driver and stated on the vehicle license private vehicle.",
            "A motor vehicle, designed to carry ten passengers, licensed as a private vehicle",
            "A private motor vehicle, designed to carry at least twelve passengers, licensed as a private vehicle",
          ],
          answer: 1,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0523. Which of the following road signs permits U-turns only?",
          options: ["210", "212", "303", "209"],
          answer: 1,
          image: "img/quiz/3523.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1755. The speed limit of private passenger cars on urban roads (unless indicated otherwise on any traffic sign) is:",
          options: [
            "50 k.p.h.",
            "80 k.p.h.",
            "Up to 40 k.p.h.",
            "50 k.p.h on a one-way road and 60 k.p.h on a two-way road.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1787. When a traffic light in a junction displays a flashing green light:",
          options: [
            "A blinking green light indicates the same as a regular green light; hence it shouldn’t be given any special attention.",
            "It indicates that the green light is about to turn to yellow, in 3 seconds.",
            "It indicates that the traffic light is out of order and you should follow the signs that are placed in the intersection.",
            "It indicates that the light turned green a few moments ago and that you can continue driving.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0800. What is a “blind spot”?",
          options: [
            "The distance a driver should keep from other vehicles while driving in reverse.",
            "Part of the vehicle's circumference in which the driver cannot see or detect road users.",
            "The non-paved area around the vehicle.",
            "Part of the vehicle's circumference which the driver can only see through the mirrors.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "1277. What risks are expected when the bus driver makes a sudden brake?",
          options: [
            "Being hit by a parked vehicle.",
            "Injuring the bus passengers.",
            "Over heating of the brakes.",
            "Skidding and rolling over while driving uphill.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question: "0727. Which is the correct lane for turning left?",
          options: [
            "It doesn’t matter which lane.",
            "Always the right lane.",
            "The left hand lane.",
            "The lane that is more open.",
          ],
          answer: 2,
          image: "img/quiz/3727.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1416. A “permanent crane” is:",
          options: [
            "A new fixture that is designed to elevate the axle while changing a punctured tire.",
            "A fixture in the vehicle that prevent the vehicle from rolling-over during loading or unloading of an interchangeable box.",
            "A fixture that is permanently installed in the vehicle and designed to lift or lower interchangeable crates..",
            "A fixture that is permanently connected to the vehicle and designed to lift one side of a side-loading-platform.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "0763. How should one change lanes?",
          options: [
            "Signal with the winker and make a short diagonal deviation to the intended lane.",
            "After checking and taking account of the position and speed of other vehicles in the intended lane.",
            "Drive as close as possible to the desired lane, and when no vehicle drives behind you – signal with the winker and make a short diagonal deviation to the intended lane.",
            "Deviate quickly and fearlessly in a short diagonal, and verify that you are signaling with the winker.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1474. What is the maximum freight weight a commercial vehicle is permitted to carry?",
          options: [
            "The freight’s weight should not exceed the weight that was determined by the vehicle’s manufacturer.",
            "The freight’s weight should not exceed the permitted weight that is indicated in the vehicle’s registration document.",
            "The freight’s weight would be the weight determined by the vehicle’s manufacturer plus no more than 50%.",
            "The freight’s weight would be in accordance with the vehicle’s tire manufacturer only.",
          ],
          answer: 1,
          image: "",
          types: ["C"],
        },
        {
          question:
            "1771. What is the maximum freight height permitted for a vehicle carrier that has three loading surfaces, one over the other?",
          options: [
            "5.20 meters.",
            "5.00 meters.",
            "4.20 meters.",
            "4.80 meters.",
          ],
          answer: 3,
          image: "",
          types: ["C"],
        },
        {
          question:
            "1005. Why is it important for the vehicle to have intact bumpers (fenders)?",
          options: [
            "To give the vehicle an aesthetic look.",
            "To reduce damage during accidents.",
            "To avoid relatively minor accidents.",
            "To prevent damage during serious accidents.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0340. How do you mark an agricultural machine that has a width of more than 340 cm?",
          options: [
            "A “caution! Overload” sign is hanged on the machine’s rear, and reflective triangles are placed during light time.",
            "The machine is marked with flickering yellow side lamps that are seen to a distance of 150 meters, both day and night.",
            "The machine is marked with a white triangle in front and a white triangle with red edges at the machine’s rear.",
            "A “caution! Wide vehicle” sign is hung on the front, and width warning lights are illuminated during lighting up time.",
          ],
          answer: 3,
          image: "",
          types: ["1"],
        },
        {
          question:
            "1538. How is it required to mark freight that sticks out from the vehicle’s rear side?",
          options: [
            "With a red light-reflecting triangle on the tip of the oversize freight.",
            "With a white light-reflecting triangle on the tip of the oversize freight.",
            "With a white or yellow light on the tip of the oversize freight.",
            "With a red light on the tip of the oversize freight.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "В"],
        },
        {
          question:
            "1375. On which of the following commercial vehicles and work vehicles is it obligatory to have a compression brake (retarder)?",
          options: [
            "On all of them, regardless of weight.",
            "On a vehicle weighing at 8,000 kg or more.",
            "On a vehicle weighing at least 12,000 kg.",
            "On a vehicle weighing weighing 4,500 kg or more.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question:
            "0735. Which factors affect the driver’s decision to shift to higher or lower gear?",
          options: [
            "The speed limit in that road section.",
            "The vehicle’s speed, considering traffic condition, road condition and visibility.",
            "When driving uphill, there is no consideration whether to shift to higher or lower gear.",
            "The only affecting factor is the cumulative driving distance.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1310. What is the all up weight of a trailer with 2 axles or more that is designed for transporting a container of any size?",
          options: [
            "Not less than 29,000 kg.",
            "At least 25,000 kg.",
            "Not more than 28,000 kg.",
            "At least 30,000 kg.",
          ],
          answer: 0,
          image: "",
          types: ["C"],
        },
        {
          question:
            "1567. Is it permitted to drive a heavy vehicle when the air pressure in the braking system is below the required minimum?",
          options: [
            "Yes, but only under permission of a policeman or an officer.",
            "Yes, but only if the vehicle properly brakes.",
            "Yes, if definitely no leak is detected.",
            "No.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question:
            "0547. When the following traffic sign is placed at the intersection:",
          options: [
            "You are only permitted to drive straight ahead from the left lane only.",
            "The road after the intersection is a one-way road.",
            "You are only permitted to drive straight ahead.",
            "Steep ascent ahead.",
          ],
          answer: 2,
          image: "img/quiz/3545.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1511. What are the risks of driving a long commercial vehicle?",
          options: [
            "Inability to turn left safely.",
            "It is not riskier to drive a long vehicle compared to a private passenger car.",
            "The driver’s response time is longer.",
            "Presence of small vehicles within its “blind spots”, and difficulty to make sharp turns.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1060. What is a driver required to do after driving through a puddle and dampening the brakes?",
          options: [
            "He shouldn’t do anything. There is no connection between water and the braking system.",
            "Drive fast to dry the brakes.",
            "Tow the vehicle to the nearest garage.",
            "Drive very slowly until the brakes dry out and resume normal functioning.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0061. Is it obligatory to obey the directions of a policeman who has identified himself with an appropriate certificate?",
          options: [
            "No. you must only obey a traffic policeman who directs traffic in an intersection.",
            "Yes, even if his directions contradict those of a road sign.",
            "Generally not.",
            "Yes, but only concerning traffic directions that are given within the intersection.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1223. Who is responsible for displaying information and fare lists in a taxi?",
          options: [
            "The dispatcher and the driver.",
            "The owner should display the route map, briefing and fare, as instructed by the Licensing Authority.",
            "The driver should display the route map, briefing and fare, as instructed by the Licensing Authority.",
            "The owner and the driver are responsible to display the route map, and fare, as instructed by the Controller of Road Transport.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1527. What is the maximum permitted height - above the road’s surface -for a loaded commercial vehicle with an all up weight of between 3,501 and 8,000 kg?",
          options: [
            "3.50 meters.",
            "4.00 meters.",
            "3.00 meters.",
            "2.50 meters.",
          ],
          answer: 0,
          image: "",
          types: ["C1"],
        },
        {
          question: "0449. What is the meaning of the following traffic sign?",
          options: [
            "Approx. 100 meters to railway crossing.",
            "Approx. 300 meters to railway crossing.",
            "Approx. 30 meters to railway crossing.",
            "Approx. 150 meters to railway crossing.",
          ],
          answer: 1,
          image: "img/quiz/3449.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0902. On a sharp curve, slow down:",
          options: [
            "When you exit the curve.",
            "Straight after entering the curve.",
            "All along the curve.",
            "Before entering the curve.",
          ],
          answer: 3,
          image: "img/quiz/3902.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0085. Where should vehicles drive in a road divided by a continuous white line?",
          options: [
            "On the right side of the line only",
            "On the right side of the line, but it is permitted to drive on the left side of the line for overtaking.",
            "On the left side of the separation line",
            "The greater part of the vehicle should be on the right side of the line",
          ],
          answer: 0,
          image: "img/quiz/3085.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1379. What is a “pedestrian crossing”:",
          options: [
            "A suspended bridge or a tunnel designated for roadway crossing by pedestrians.",
            "Every place on the roadway in which pedestrians usually cross.",
            "A place in which school children cross.",
            "A roadway section that is designated, by marking or by traffic sign, to permit pedestrian to cross the road. .",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0573. When the following road sign is placed:",
          options: [
            "Only buses are permitted to stop.",
            "Passenger loading is permitted on holidays only.",
            "Only taxis are permitted to stop.",
            "Only unloading of workers is permitted.",
          ],
          answer: 2,
          image: "img/quiz/3572.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0576. What is the meaning of the following traffic sign?",
          options: [
            "Entry for bicycles only.",
            "No entry for bicycles.",
            "No entry for bicycles and motorcycles.",
            "No entry for four-wheeled motor vehicles.",
          ],
          answer: 1,
          image: "img/quiz/3576.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1266. Is a public minibus weighing 3300kilos all up weight permitted to park within the limits of the following road sign?",
          options: [
            "Yes, but only on weekdays from 21:00 until 05:30.",
            "No.",
            "Only on weekends.",
            "Yes.",
          ],
          answer: 1,
          image: "img/quiz/3627.jpg",
          types: ["D"],
        },
        {
          question:
            "1685. Upon completion of the two year “new driver” period, a regular driver’s license will not be given to a “new driver” who was convicted of:",
          options: [
            "Driving above the maximum speed limit.",
            "Not stopping next to a “danger” warning sign.",
            "Driving a motor vehicle while having a temporary insurance policy.",
            "Not placing a “new driver” sign on the vehicle’s rear windshield.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "A", "В"],
        },
        {
          question:
            "0305. Can a “blood alcohol test” by means of a breathalyzer device be considered admissible evidence in a court of law?",
          options: [
            "No, only a blood test is admissible evidence in a court of law.",
            "No, only a saliva test is accepted as accurate evidence in a court of law.",
            "Yes.",
            "No, only an alcohol test which is performed in a hospital is admissible evidence.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0933. How are you required to conduct yourself in the following road section?",
          options: [
            "Drive in low gear, steep climb ahead.",
            "Drive in high gear, to prevent engine overheating.",
            "Slow down, drive in low gear and maintain the engine’s temperature.",
            "Slow down and keep to the right of the roadway, due to the limited field of vision.",
          ],
          answer: 3,
          image: "img/quiz/3933.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1670. How can you know that the brake pads are worn out and needs to be replaced?",
          options: [
            "There is a mark on the brake pad that indicates that it is worn out.",
            "Only after being checked at a licensing facility.",
            "According to the cooling rate of the brakes after a strong braking.",
            "You cannot know until the padding completely disappears.",
          ],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1071. Why is a driver required to adjust his sitting position before starting to drive?",
          options: [
            "To prevent unnecessary leg pains that are a result of prolonged sitting in the driver’s seat.",
            "To have good control of the vehicle’s operation, instruments and field of vision.",
            "Only pregnant women are required to adjust their sitting position.",
            "To have easier access to the radio or cellular phone.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "0095. What is a “vehicle non-use notice” (taking a vehicle off the road)?",
          options: [
            "A vehicle non-use notice given to the vehicle owner by a manager of an authorized garage, following the detection of a malfunction in the vehicle",
            "A vehicle non-use notice given to the driver by a policeman, a car assessor or a driving examiner due to a malfunction of the vehicle or damage caused by an accident.",
            "A vehicle non-use notice given to the vehicle owner by a driving examiner, following the detection of a malfunction in the vehicle",
            "A vehicle non-use notice given to the driver by a policeman when the driver commits a traffic offence",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0525. What is the meaning of the following traffic sign?",
          options: [
            "No stopping or parking on the road-side where the sign is placed.",
            "No stopping for vehicles on both sides of the road.",
            "Stopping is permitted but parking is prohibited.",
            "It is allowed to stop and park, except on Saturdays and holidays.",
          ],
          answer: 0,
          image: "img/quiz/3015.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0872. How can a correct grip of the steering wheel help to control the vehicle and to keep it stable?",
          options: [
            "The way you grip the wheel is only important in sharp turns and curves, when the vehicle moves in high speed.",
            "Correct wheel grip is only important in big and heavy vehicles such as trucks and buses.",
            "In a new-model vehicle, the way we hold the steering wheel is important while driving in sharp curves.",
            "A correct grip of the steering wheel allows precise turning of the wheel, thus allowing control at all times.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question: "0199. When is it prohibited to stop or park a vehicle?",
          options: [
            "Besides blue-white painted curbstones?.",
            "When the vehicle is parked in the left lane of a one-way urban road.",
            "When the vehicle obstructs the traffic or blocks a road sign.",
            "When the vehicle is not locked.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1516. Where is the biggest “blind spot” in a commercial vehicle?",
          options: [
            "It depends on the size of the vehicle’s rear view mirror: The larger it is, the bigger is the dead spot in front.",
            "On the vehicle’s left side.",
            "At the vehicle’s rear.",
            "On the vehicle’s front.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1618. What would be the result of sharply braking a motorcycle while turning?",
          options: [
            "The motorcycle will drive faster.",
            "Sliding of the rear wheel.",
            "The front wheel would be lifted in the air.",
            "Sliding of the front wheel.",
          ],
          answer: 1,
          image: "",
          types: ["A"],
        },
        {
          question: "0562. What is the meaning of the following traffic sign?",
          options: [
            "Road for non-motor vehicles only.",
            "Road for motor vehicles only.",
            "Road for commercial vehicles only.",
            "Road for public vehicles only.",
          ],
          answer: 1,
          image: "img/quiz/3562.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1164. Where is a proper place to install plates with the details of the taxi owner and of the driver?",
          options: [
            "On the frame-head between the doors, on the right side only.",
            "On the inner part of the taxi’s left and right door frame-heads.",
            "Both of them should be installed to the right of the dashboard, in front of the glove compartment.",
            "From both sides of the dashboard.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question: "0135. What should a driver of an overtaken vehicle do?",
          options: [
            "He should continue driving without changing his speed and driving lane.",
            "An overtaken vehicle should take no interest in the overtaking vehicle, and should continue driving normally.",
            "Deviate as much as possible to the road’s edge, without changing his speed.",
            "Stop at the side of the road and allow the traffic to pass him.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1582. What driving license class is required for driving a work vehicle that tows a trailer with an all up weight exceeding 4,000 kg?",
          options: ["B.", "C+E.", "A.", "D."],
          answer: 1,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0719. According to the law, how should you (vehicle no. 3) behave at the following intersection?",
          options: [
            "Give right of way to the blue vehicle (1).",
            "Cross carefully. The two other drivers should yield (3).",
            "Give right of way to both vehicles (1,2).",
            "Give right of way to the yellow vehicle (2).",
          ],
          answer: 1,
          image: "img/quiz/3719.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1783. When there is a “no left turn” sign in an intersection:",
          options: [
            "It is permitted to make a U-turn when there is an arrow pointing to the right only.",
            "It is permitted to make a U-turn from any lane.",
            "It is permitted to make a U-turn when no traffic disturbance is caused and when the U-turn is not prohibited by any traffic sign or marking.",
            "It is permitted to make a U-turn for private vehicles only.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0865. What is the correct way to check the road when starting to drive and merging with traffic?",
          options: [
            "Glance in the mirrors and turn our head in the direction to which we intend to drive.",
            "Look as far as possible ahead.",
            "Glance at the rear view mirror and make sure that there are no vehicles behind us.",
            "Plan our merging with vehicle traffic near us.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1410. Is it obligatory to mark oversize freight on a moving vehicle at all times?",
          options: [
            "Yes, but only during light time.",
            "Yes, but it is only obligatory to mark a vehicle with an oversize weight on its front side.",
            "No, It is obligatory to mark oversize freight from the front and from the rear of the vehicle, but only if the freight exceeds the vehicle by 150 cm.",
            "Yes, it is always obligatory to mark oversize freight.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "0219. How will you stand a vehicle on a downhill road?",
          options: [
            "Engage the parking brake, turn the front wheels towards the road’s edge and shift into reverse gear (manual car only).",
            "Turn the front wheels towards the road’s edge and shift into forward gear (In an automatic car – P).",
            "Engage the handbrake and turn the front wheels towards the center of the road.",
            "Turn on the parking lights, turn the front wheels towards the road’s edge and shift into reverse gear.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "0692. You step on the brake pedal and feel that the service brake (foot brake) is not working. What will you do?",
          options: [
            "Try to brake using the hand break.",
            "Turn the stirring wheel quickly to the right to break by changing the direction of the wheels.",
            "Use the horn and turn-on the lights to warn the driver in front of me.",
            "Try to break by stepping on the clutch pedal.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0342. Who is legally responsible for the registration of a change of ownership?",
          options: [
            "The seller only.",
            "The registered vehicle owner and the buyer of the vehicle.",
            "The buyer of the vehicle only.",
            "The owner of the vehicle testing facility.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1278. What sign warns drivers about an approaching tunnel?",
          options: ["633", "127p.", "138", "634"],
          answer: 2,
          image: "img/quiz/31278.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1457. What is a commercial vehicle in accordance with the Carrier Services Law?",
          options: [
            "Any commercial vehicle used for paid transportation only.",
            "Vehicles used for passenger transportation only.",
            "A vehicle with an all up weight of 10,000 kg or more and a vehicle transporting hazardous materials of any weight.",
            "A commercial vehicle, as per its lawful definition, that was given a permit to carry goods and transport passengers.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1588. What is the risk of driving a motorcycle while its kick-stand is down?",
          options: [
            "Damage would be caused to the roadway and other infrastructures.",
            "The motorcycle would be unbalanced.",
            "The kick-stand will hit the roadway and might cause the motorcycle to roll-over.",
            "The kick-stand would wear out and cause the motorcycle to fall.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0256. When is a driver of a motor vehicle required to dip the vehicle’s lights?",
          options: [
            "When driving backwards.",
            "When entering a premises.",
            "While searching for a parking spot.",
            "When the vehicle is standing.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0981. In your opinion, is the truck positioned correctly within the curve?",
          options: [
            "When driving on a sharp curve, it is required to stay in it as shortly as possible; hence his position is correct.",
            "In sharp curves it is necessary to “cut” through the curve, in order to reduce the centrifugal force (the force that acts on the vehicle while it turns).",
            "When there is no other vehicle in the curve it is possible to be positioned anywhere within it.",
            "No, the truck driver is not keeping to the right of the road and his position presents a risk to any vehicle coming from the opposite direction.",
          ],
          answer: 3,
          image: "img/quiz/3981.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1116. The all up weight of a minibus shall not exceed:",
          options: ["5,000 kg.", "3,500 kg.", "6,000 kg.", "4,000 kg."],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1100. What is the most important safety rule while operating a PTO power transfer?",
          options: [
            "The power transfer should only be operated when the engine is already hot.",
            "The power transfer should not be operated while driving, but only when the tractor is standing.",
            "The power transfer should not be operated without the supervision of an additional operator on the ground.",
            "Stay away from the moving parts of the tractor and of the attachment.",
          ],
          answer: 3,
          image: "",
          types: ["1"],
        },
        {
          question:
            "1156. A passenger bus is required, among other things, to have:",
          options: [
            "A wheel pump.",
            "Approved waste basket next to every door.",
            "A drinking dispenser with plastic glasses.",
            "A jack stand.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0208. Within which distance before and after a railway crossing is it allowed to stand or park a vehicle?",
          options: [
            "At least six meters from the crossing.",
            "At least twenty meters from the crossing.",
            "At least four meters from the crossing.",
            "At least twelve meters from the crossing.",
          ],
          answer: 1,
          image: "img/quiz/3208.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0173. How should you conduct yourself at an exit from a premises or from an access road to a house, when you are about to cross the sidewalk (pavement)?",
          options: [
            "Stop always and give right-of-way to the vehicles that are come from the left and approaching the exit.",
            "Slow down and even stop if required, in order to allow pedestrians to cross safely.",
            "Stop always and give right-of-way to the pedestrians that are crossing the road.",
            "The rule “first to come, first to go” applies to an exit from a premises or from an access road to a house. The pedestrians will wait.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1113. Define “Autobus”:",
          options: [
            "A motor vehicle designed to transport at least 8 persons besides the driver and is indicated as an Autobus on its registration documents.",
            "A commercial motor vehicle designed to transport at least 8 persons and is indicated as a bus in its registration documents.",
            "A commercial vehicle designed to transport passengers for hire.",
            "A motor vehicle designed to transport more than 15 persons and is indicated as a bus in its registration documents.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1698. During “light time”, when are you permitted to use the vehicle’s high beam?",
          options: [
            "Only on a freeway.",
            "On a non-urban road, when driving within a row of vehicles.",
            "On an urban road with street lights and on a non-urban road.",
            "On an inter-urban roadway, when allowed by traffic conditions and there is no vehicle ahead of you.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0658. What is the meaning of alternating red-white colored curbstones?",
          options: [
            "It is permitted to stop but prohibited to park along the red-white marked area.",
            "The marking is intended to emphasize separation areas, sidewalks and traffic islands, and it has no restrictive meaning.",
            "Arranged parking space by means of parking tickets.",
            "No stopping besides the curbstones, except for public transport that load or unload passengers.",
          ],
          answer: 3,
          image: "img/quiz/3658.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1095. Is it possible to brake only one wheel in a tractor?",
          options: [
            "Yes, on both axles.",
            "Only on the rear axle.",
            "Not on any axle.",
            "Only on the front axle.",
          ],
          answer: 1,
          image: "",
          types: ["1"],
        },
        {
          question:
            "0187. Under which conditions is it permitted to cross railway tracks?",
          options: [
            "You are only permitted to cross after giving right-of-way to the vehicles that drive away from the crossing, and without causing any disturbance to them.",
            "When a “stop” sign or a “red” traffic light are placed in your direction of driving.",
            "When the barrier starts to lift..",
            "When the railway-crossing is free, with its barrier lifted, and you can be cross it safely.",
          ],
          answer: 3,
          image: "img/quiz/3155.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1751. How are you required to conduct yourself when a traffic light displays a green light but the intersection is blocked by vehicles?",
          options: [
            "It is prohibited to enter an intersection when the exit is blocked.",
            "Cross the intersection as quickly as possible.",
            "Follow the green light and enter the intersection.",
            "Enter the intersection anyway.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1355. What is the fifth wheel on a Semi-trailer?",
          options: [
            "It is a popular expression meaning the steering wheel.",
            "A fixture, shaped like a wheel, designed to lift the driver’s compartment during engine treatment.",
            "A fixture (plate) that is designed to connect between the cab and the trailer.",
            "It is a spare wheel to replace flat \\r\\n(punctured) tires.",
          ],
          answer: 2,
          image: "",
          types: ["C"],
        },
        {
          question:
            "1648. Where on the motorcycle is it recommended to place luggage, so that its impact on the motorcycle’s stability is minimal?",
          options: [
            "Close to the rear end of the motorcycle and as high as possible.",
            "Close to the front end of the motorcycle and as high as possible.",
            "Close to the center of the motorcycle and as low as possible, so that the luggage doesn’t stick out from the front or rear.",
            "Close to the rear end of the motorcycle and as low as possible, so that the luggage doesn’t stick out from the rear.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question: "1338. The “Overall height” of a vehicle is measured:",
          options: [
            "From the floor of the box until the highest point of the truck.",
            "From the road’s surface until the vehicle’s highest point (including all its fixtures, and when it is loaded)..",
            "From the road’s surface until the vehicle’s highest point (including all its fixtures, and when it is not loaded).",
            "From the road’s surface until the roof of the driver's compartment.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "1"],
        },
        {
          question:
            "0277. How should those involved in a road accident, which resulted in property damage only, conduct themselves?",
          options: [
            "Exchange identifying details and wait for the rescue forces without moving any of the involved vehicles.",
            "Clear their vehicles away from the roadway, without delay, and exchange identifying details.",
            "Report to the police and wait for its instructions.",
            "Call the rescue forces and wait at the place of the accident without moving any of the involved vehicles.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1235. Is a bus driver permitted to drive with one of its doors open?",
          options: [
            "It is only permitted when the air-conditioning system malfunctions.",
            "It is only permitted when there are no passengers on the bus.",
            "It is only permitted when approaching a level crossing.",
            "Driving with an open door is forbidden.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1264. Is a taxi permitted to park within the limits of the following road sign?",
          options: [
            "Yes.",
            "Yes, but only on weekdays from 21:00 until 05:30.",
            "No.",
            "Yes, but only on weekends.",
          ],
          answer: 2,
          image: "img/quiz/3627.jpg",
          types: ["D"],
        },
        {
          question:
            "1673. What should you carefully check while purchasing a  used tire for your vehicle?",
          options: [
            "Only that the size of the new tire is identical to that of the old tire.",
            "Only that the shape of the tire’s sole (the tire’s surface) is identical to that of the old tire.",
            "Only that the air pressure of the new tire is identical to that of the old tire.",
            "The tire’s manufacture date, to ensure it is not too old (over 2 years).",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0682. What is the meaning of the black-white marking besides the road?",
          options: [
            "The marking emphasizes the presence of sidewalks and traffic islands next to the road.",
            "Stopping and parking place before a level crossing.",
            "Stopping and parking is absolutely prohibited.",
            "Parking space regulated by the local authority.",
          ],
          answer: 0,
          image: "img/quiz/3682.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0543. Is it permitted to make a right U-turn where the following road sign is placed?",
          options: [
            "No.",
            "Yes, when no other sign is placed to prohibit it.",
            "It is permitted, even when a “no U-turn” sign is placed.",
            "Yes, in daylight only.",
          ],
          answer: 1,
          image: "img/quiz/3539.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1748. It is prohibited to park a vehicle less than a distance of:",
          options: [
            "15 meters after a stop sign.",
            "15 meters after the intersection.",
            "12 meters before a pedestrian crossing.",
            "30 meters after a level crossing.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1325. Which of the following vehicles must carry a fire extinguisher?",
          options: [
            "All motor vehicles, except for private passenger cars, tractors, motorcycles and motorcycle and side-car",
            "All commercial vehicles, public buses and tractors.",
            "All motor vehicles with more than two wheels.",
            "Trucks and public buses only.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "0169. What should we do when driving behind a vehicle that is giving a direction indicator signal?",
          options: [
            "Stay close to the right of the road.",
            "Continue driving without slowing down. It has nothing to do with you.",
            "Continue driving at an accelerated speed, to minimize the conflict period.",
            "We should allow the driver of the vehicle in front to perform the action he signaled for, by slowing down and stop if required.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1550. What is a driver required to do when he feels tired during night-driving?",
          options: [
            "Search for a safe place to rest and take a light nap where he doesn’t disturb traffic.",
            "Listen to the radio, but avoid relaxing music.",
            "Open the window next to him, to allow air flow in.",
            "Eat a heavy meal and avoid drinking.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "1608. How will a dirty front lamp influence night driving?",
          options: [
            "It will affect the field of vision.",
            "The field of vision will grow.",
            "It will cause a dazzeling (blinding).",
            "Dirt will have no affect on the rider ability to recognize problems.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0758. What is a lane?",
          options: [
            "A roadway designated for motor-vehicle traffic only.",
            "Part of a road’s width, designed to accommodate one line of vehicles, not including two-wheelers.",
            "Any marking of two parallel lines on the roadway.",
            "A passage in a private road in which vehicle traffic is prohibited.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0152. What is the maximum permitted speed for driving a private passenger vehicle (if not indicated otherwise by a traffic sign)?",
          options: [
            "In an urban road – up to 50 KPH and in a freeway – up to 70 KPH",
            "In an urban area – up to 50 KPH and on an inter-urban road – up to 80 KPH",
            "In an urban road – according to road conditions, and in a freeway – up to 100 KPH",
            "In an urban road – up to 40 KPH and in a freeway – up to 80 KPH",
          ],
          answer: 1,
          image: "img/quiz/3139.jpg",
          types: ["C1", "В"],
        },
        {
          question: "0078. How should one drive while inside a “play street”?",
          options: [
            "The driver should avoid entering a play street while children are playing",
            "Always in low gear and never driving in reverse.",
            "The driver should honk and alert pedestrians that a vehicle has entered the street",
            "The driver should allow pedestrians and children to carry on with their activities without endangering them",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1740. Is it permitted to push a vehicle, while its engine is shut-off, into a roadway to which vehicle entry is prohibited?",
          options: [
            "Yes, when the engine works on neutral gear.",
            "Yes, but it should be lifted to the sidewalk.",
            "No. pushing It is the same as driving.",
            "Yes, when the engine is not working.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question:
            "0999. What are you required to do if you detect a malfunction in the braking system while driving?",
          options: [
            "Slow down and try to accustom yourself to the situation and complete your drive safely.",
            "Slow down and rely on the ABS System.",
            "Continue driving very carefully and use the parking brake.",
            "Stop driving immediately and ensure that the vehicle is towed to a garage and checked there.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0225. Is it permitted to drive a vehicle while one of the doors is open?",
          options: [
            "No, unless the air-conditioning is out of order.",
            "Yes, when there are no passengers in the vehicle.",
            "Yes, when carrying oversized cargo.",
            "It is absolutely forbidden.",
          ],
          answer: 3,
          image: "img/quiz/3225.jpg",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "0158. When you approach a railway crossing and an approaching train is seen, heard or sounds a warning signal:",
          options: [
            "You should stop before the railway crossing until the train passes.",
            "If the train is far, you should quickly cross the rails.",
            "You should accelerate and cross the rails before the train arrives, in a careful and watchful manner.",
            "You should carefully cross the rails, in any fitting speed.",
          ],
          answer: 0,
          image: "img/quiz/3158.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1439. A traffic sign forbidding vehicles to drive faster than 70 k.p.h is placed on an urban road. Which of the following vehicles is forbidden to drive according to the sign’s speed indication?",
          options: [
            "Commercial vehicles and work vehicles with an all up weight that doesn’t exceed 10,000 kg. Their speed limit is 50 k.p.h.",
            "Commercial vehicles that are used for carrying goods and that are not equipped with safety belts. Their speed limit is 50 k.p.h.",
            "Vehicles that were manufactured before 1969 and that are not equipped with safety belts. Their speed limit is 50 k.p.h.",
            "Commercial vehicles or work vehicles with an all up weight of 12,000 kg. Their speed limit is 50 k.p.h.",
          ],
          answer: 3,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0390. When traffic sign 308 is placed at an entry to a section,of the road which traffic sign should be placed in the opposite side of that section?",
          options: ["504", "145", "307", "622"],
          answer: 2,
          image: "img/quiz/3390.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1710. Is it permitted to drive with a tractor on the sidewalk in order to pass a road obstruction?",
          options: [
            "Yes, it is permitted to drive on the sidewalk very carefully and in first gear.",
            "Yes, it is permitted to drive on the sidewalk if the roadway is in a bad condition.",
            "No, it is prohibited to drive on the sidewalk in order to pass an obstruction.",
            "Yes, it is permitted to bypass the obstacle through the sidewalk.",
          ],
          answer: 2,
          image: "",
          types: ["1"],
        },
        {
          question:
            "0149. When is it lawfully required to perform a vehicle license test?",
          options: [
            "Every two years, for all vehicle types.",
            "Every two years for commercial vehicles and every year for motorcycles.",
            "Every two years for all types of private passenger vehicles, without any restriction on the vehicle’s age.",
            "Every year or any other lawfully defined period.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0054. Where should a “new driver” sign be placed?",
          options: [
            "At the center of the vehicle rear window’s top edge, visible to other drivers from all sides",
            "On the top edge of the vehicle’s left side window, visible to those driving on the left of the vehicle",
            "On the bottom edge of the vehicle’s rear window, visible to those driving behind",
            "On the bottom edge of the vehicle’s right side window, visible to those driving on the right of the vehicle",
          ],
          answer: 2,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0433. What are you required to do according to the following road sign?",
          options: [
            "Drive faster when yellow light is displayed by the sign.",
            "Always stop before the intersection with traffic lights.",
            "Continue driving carefully and obey the traffic light.",
            "Ignore this sign, as it obligates public vehicle drivers only.",
          ],
          answer: 2,
          image: "img/quiz/3432.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0645. How should you continue driving according to the following marking?",
          options: [
            "You are permitted to drive straight (ahead) or left.",
            "You are permitted to drive left only.",
            "You are permitted to turn left from all lanes.",
            "You are permitted to drive right only.",
          ],
          answer: 0,
          image: "img/quiz/3644.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1772. Can lawful roadway marking be considered as a road sign?",
          options: [
            "Always.",
            "Only if it is accompanied by another sign.",
            "Only within the limits of an urban road.",
            "Only if it is colored white.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0734. Where in the vehicle should the fluorescent vest be kept (except for motor-cycles)?",
          options: [
            "In the driver compartment.",
            "Next to the spare (reserve) wheel.",
            "In the vehicle’s trunk.",
            "In the vehicle’s engine.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0958. What limitations occur on the road ahead of you?",
          options: [
            "Driving is on an urban road.",
            "(1) Road works ahead (2) a speed limit and (3) no overtaking.",
            "Limited field of vision.",
            "Congested traffic on the roadway.",
          ],
          answer: 1,
          image: "img/quiz/3958.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0337. What should a driver do when there’s a change in his health condition?",
          options: [
            "Report it to the Licensing Authority.",
            "Report it to the Licensing Office if it is a result of a road accident only.",
            "Report it immediately to the Ministry of Health.",
            "Report about it to the Licensing Office within 20 days.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1690. What shoukd a driver check after driving through deep water on the road?",
          options: [
            "If the brakes work efficiently.",
            "If the lights work.",
            "If the engine works.",
            "No need of any of the cheks.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question:
            "0924. How are you required to conduct yourself according to the occurrence that is depicted in the following picture?",
          options: [
            "Stop 12 meters before the pedestrian crossing and allow the pedestrian to finish crossing safely.",
            "The pedestrian noticed you and therefore he should stop and allow you to cross the intersection safely.",
            "The pedestrian doesn’t notice you. Honk gently to signal him that you wish to continue driving.",
            "Slow down and even stop if needed, and allow the pedestrian to finish crossing safely.",
          ],
          answer: 3,
          image: "img/quiz/3924.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0243. How many vehicles are allowed to be towed simultaneously by a motor vehicle?",
          options: [
            "One, unless otherwise approved by the Licensing Authority.",
            "Two, provided that their overall length does not exceed fifteen meters.",
            "Two, unless otherwise approved by the Licensing Authority.",
            "Only one and there is no possibility to approve more than one.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "1"],
        },
        {
          question:
            "1453. A taxi driver that is obliged to wear eyeglasses or any other optical instrument:",
          options: [
            "Must hold an extra pair of eyeglasses or contact lenses at the taxi station.",
            "Must ensure that he carries on him a pair of eyeglasses or contact lenses while driving during the night.",
            "Must ensure that he carries on him a pair of eyeglasses or contact lenses while driving during the day.",
            "Must carry with him an extra pair of eyeglasses or contact lenses while driving.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question: "0611. Driving on a wet road creates:",
          options: [
            "A danger of skidding and turning-over, as speed increases",
            "A danger of sliding and rolling-over, which can be prevented by driving faster.",
            "A longer response distance.",
            "Vehicle roll-over due to slow speed.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1482. After how many hours of rest is a driver of a commercial vehicle with an all up weight of 10,000 kg or more permitted to start working?",
          options: [
            "After a continuous break of 5 hours.",
            "After a continuous break of 6 hours.",
            "After a continuous rest break of 7 hours.",
            "After a continuous break of 4 hours.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1272. Is a private minibus without passengers permitted to use a designated bus lane?",
          options: [
            "Dotted lines mark the approach of a separation line. The driver should move to the right lane before the beginning of the separation line.",
            "Yes, but only on Saturdays and holidays.",
            "No.",
            "Yes.",
          ],
          answer: 3,
          image: "img/quiz/31269.jpg",
          types: ["D"],
        },
        {
          question:
            "0292. What is the maximum cargo height, from the road’s surface, that is allowed to be transported in a vehicle of an overall permissible weight of 8000kg – 15,000kg?",
          options: [
            "Four meters.",
            "4.80 meters.",
            "There is no height limit.",
            "Three meters.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1120. When a bus driver obeys a stop sign and stops before a level crossing, he is also obliged:",
          options: [
            "To use the horn and turn off the radio.",
            "To open the front door.",
            "To open the rear door to enable passenger escape.",
            "To wait until at least one train passes.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question: "0675. What is the meaning of the following traffic sign?",
          options: [
            "One-way roadway ahead.",
            "It is permitted to drive in this lane.",
            "Driving in this lane is prohibited.",
            "You have right-of-way on the road ahead",
          ],
          answer: 1,
          image: "img/quiz/3675.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0462. Which traffic sign should be placed at an intersection, after passing traffic sign 139 that is displayed in the following picture?",
          options: ["303", "307", "302", "301"],
          answer: 2,
          image: "img/quiz/3462.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0520. What characterizes the roads that follow this road sign?",
          options: [
            "They pass through an urban area, including residential neighborhoods with pedestrian activity.",
            "They pass through a Kibbutz or Moshav only.",
            "They connect between main cities.",
            "You are entering an area with road works.",
          ],
          answer: 0,
          image: "img/quiz/3519.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0204. What is the minimum distance that it is permitted to park near a fire hydrant?",
          options: [
            "Two meters in any direction from the fire hydrant.",
            "Six meters from either side of the fire hydrant.",
            "Twelve meters from either side of the fire hydrant.",
            "Twenty meters from either side of the fire hydrant.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0883. How would a strong side-wind affect the movement of the tall truck?",
          options: [
            "The wind pushes to divert the truck, and the truck driver would be forced to slow down and overcome the lateral force that pushes him to the side.",
            "It would have no effect. A commercial vehicle carrying a heavy load is not affected at all by side winds.",
            "Side winds do not affect heavy vehicles.",
            "It would have no effect. The truck, without cargo, is not affected by the force of side winds.",
          ],
          answer: 0,
          image: "img/quiz/3883.jpg",
          types: ["C1", "C", "D"],
        },
        {
          question:
            "1347. The grade of license required to drive a commercial vehicle  weighing more than 3500 kilogram, attached to a trailer with weight of 4000 kilogram:",
          options: ["C1.", "C+E.", "D.", "B."],
          answer: 1,
          image: "",
          types: ["C"],
        },
        {
          question:
            "1324. Which of the following vehicles requires chains and flags (perimeter lighting)?",
          options: [
            "A vehicle carrying a dust-creating material.",
            "A vehicle carrying hazardous materials.",
            "A trailer and a semi-trailer that don’t have a floor and walls.",
            "A vehicle carrying steel cylinders.",
          ],
          answer: 2,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0987. What might be the consequence of excessive brake use?",
          options: [
            "Overcooling of the brakes and increase of braking efficiency.",
            "Overheating of the brakes and decrease of braking efficiency.",
            "Excessive use of the brakes should cause no concern.",
            "The power of the engine might be decreased and it might even stop working.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1452. Which vehicle is regarded as a “commercial vehicle”?",
          options: [
            "All vehicles besides private vehicles and passenger vehicles.",
            "Any vehicle used to support the livelihood of its owner or driver.",
            "A vehicle used or designed to transport freight for hire, for the purpose of the vehicle owner’s business and trade.",
            "Vehicles used to carry goods for hire only.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "1065. What is a vehicle GPS navigation system?",
          options: [
            "A system providing information about vehicle location and assisting the driver to reach his destination.",
            "An efficiency enhancement sub-system within the steering system.",
            "A system checking the proper function of the vehicle’s computerized systems.",
            "A system measuring the vehicle’s fuel consumption.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question: "0621. What is the meaning of the following traffic sign?",
          options: [
            "Passage for engine wheelchairs only.",
            "Parking for vehicles of handicapped people who have the sticker that is indicated in the sign.",
            "Stopping place for handicap vehicles only.",
            "Parking for all handicap vehicles.",
          ],
          answer: 1,
          image: "img/quiz/3216.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0569. What is the meaning of the following traffic sign?",
          options: [
            "Cab rank; no stopping and parking of other vehicles, except for unloading passengers.",
            "Pick-up stop; no stopping and parking of other vehicles.",
            "No stopping and parking for any vehicle within a distance of 20 meters.",
            "Route service station for special cabs and busses.",
          ],
          answer: 0,
          image: "img/quiz/3215.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0282. What does the law state regarding the placing or leaving of objects on a road?",
          options: [
            "Objects which might disturb or endanger passersby or their vehicles should not be placed or left on a road.",
            "Objects that are left on a road should be cleared away within 12 hours.",
            "It is allowed to leave objects, but the Ministry of Environmental Protection should be immediately informed about every object that is placed on a road.",
            "It is allowed to leave any object on a road, provided that it is marked with a reflector during “light time”.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0327. Under what conditions can a person be denied the right to obtain or renew his driver’s license?",
          options: [
            "If the applicant for a driver’s license is illiterate.",
            "If he served a prison term of one year due to drunk driving prior to the submission of his license application.",
            "If he is a new immigrant from a country which doesn’t have customary traffic rules.",
            "If he is disqualified from driving or if he suffers from health problems which might endanger himself or others..",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0582. What is the meaning of the following traffic sign?",
          options: [
            "No entry for vehicles transporting drink bottles.",
            "No entry for vehicles transporting dangerous materials,..",
            "Vehicles transporting hazardous materials are permitted to enter.",
            "No entry for vehicles transporting marine containers.",
          ],
          answer: 1,
          image: "img/quiz/3582.jpg",
          types: ["C1", "C"],
        },
        {
          question:
            "1633. The main factors affecting a motor cycle riders ability to identify obstacles on the road are:",
          options: [
            "The motorcycle’s mechanical condition.",
            "The motorcycle’s size.",
            "The proper function of the brakes and tires.",
            "The motorcycle’s position on the road and in traffic.",
          ],
          answer: 3,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0894. Is a motorcycle or a motorcycle with sidecar permitted to drive by the side of another motorcycle in the same lane?",
          options: [
            "Yes, on an urban road only.",
            "Yes, but only on a freeway and when they are in the same lane.",
            "Yes, only for the purpose of lawfully overtaking or passing another vehicle.",
            "Absolutely not, even whilst overtaking.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question: "0012. Define “parking”",
          options: [
            "Stopping a vehicle for a defined period of time, in order to unload cargo, intermittently",
            "Stopping the vehicle for more than thirty seconds",
            "Standing a vehicle in order to load it with cargo only, intermittently",
            "Standing a vehicle for an uninterrupted period of time, not for the purpose of immediate loading or unloading of passengers or loading or unloading of goods.",
          ],
          answer: 3,
          image: "img/quiz/3012.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1700. Reverse lights:",
          options: [
            "Should not be installed in any vehicle other than a private passenger car.",
            "Are only turned on when the driver shifts into reverse gear, and only for as long as the reverse gear is activated.",
            "Are turned on by a separate switch, and should be accompanied by a warning buzz.",
            "Are used during “light time” only.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question: "1515. What are the “blind spots”?",
          options: [
            "Elements obstructing the driver’s rear view.",
            "The areas around a vehicle in which its driver cannot notice other road users – neither by looking nor by glancing at the mirrors.",
            "The area in front of the vehicle only in which the driver can spot pedestrians.",
            "The area behind the vehicle only in which the driver cannot notice other vehicles - neither by looking nor by glancing at the mirrors or by any other means.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0827. How would you get the attention of the overtaken vehicle’s driver while overtaking on a freeway (motorway)?",
          options: [
            "Use the horn for as long as you are passing him. It is important that he doesn't ignore you.",
            "You are permitted, if it is necessary, to use the horn briefly before passing, .",
            "During day light, turn on the vehicle’s parking lights.",
            "If required, signal to him with the vehicle’s four direction indicators.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1438. Which of the following commercial vehicles is prohibited from driving faster than 80 k.p.h on an inter-urban road?",
          options: [
            "Commercial vehicles with an all up weight of 10,000 kg or less.",
            "Commercial vehicles with an actual weight that reaches their “all up weight”.",
            "Commercial vehicles with an all up weight of 12,000 kg or more.",
            "Buses with an all up weight of 10,000 kg or more.",
          ],
          answer: 2,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0925. According to the picture, who of those involved should take measures to prevent an accident?",
          options: [
            "The vehicle and the pedestrian are far from each other; hence no measures should be taken.",
            "The pedestrian should stop and allow the vehicle to continue driving.",
            "The driver should slow down and even stop if necessary.",
            "The pedestrian should walk faster and finish crossing.",
          ],
          answer: 2,
          image: "img/quiz/3925.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0881. What is the required conduct of a driver that exits a dirt road and intends to enter a paved road?",
          options: [
            "He should allow traffic from his right to continue driving undisturbed, and then enter the road.",
            "He should enter the paved road: A vehicle exiting a dirt road has priority over vehicles driving on a paved road.",
            "He should give way to all vehicles on the paved road, from right and left, and then enter the road.",
            "He should honk, blink his lights and enter the paved road.",
          ],
          answer: 2,
          image: "img/quiz/3881.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0235. In which vehicles are the driver and passengers required to wear seat belts?",
          options: [
            "In every vehicle that is equipped with seat belts.",
            "In a private vehicle only.",
            "In every vehicle with more than two wheels that drives passengers.",
            "In a vehicle with an overall permissible weight of up to 3,500kg only.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "1655. How does the helmet’s visor affect driving in the dark?",
          options: [
            "It irritates the rider.",
            "Driving a two-wheeler during dark without the helmet’ visor always improves your quality of vision.",
            "A scratched or dirty visor makes it easier to drive in the dark.",
            "A scratched or dirty visor breaks the light rays and significantly lessens the quality of vision",
          ],
          answer: 3,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0422. Are you required to give right-of-way when the following road sign is placed?",
          options: [
            "Yes, when the following road sign is placed in an intersection, right-of-way is given to vehicles that continue driving straight.",
            "No, when the following road sign is placed in an intersection, right-of-way is given to public vehicles only.",
            "No, you should only give right-of-way according to the lawful right-of-way rules or to the traffic signs that are placed in the intersection.",
            "Yes, when the following road sign is placed in an intersection, right-of-way is given to vehicles that come from the right only.",
          ],
          answer: 2,
          image: "img/quiz/3420.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0660. Choose the only correct sentence:",
          options: [
            "The braking distance is always greater than the stopping distance.",
            "The braking distance always equals the response distance.",
            "The response distance is always longer than the braking distance",
            "The stopping distance is always greater than the braking distance.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0484. What is the meaning of the following traffic sign?",
          options: [
            "Beware! Dead spots.",
            "Beware! Speed restriction bumps on the road ahead.",
            "Beware ! Rough road.",
            "Beware ! Obstacles on the road. .",
          ],
          answer: 1,
          image: "img/quiz/3484.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0956. How would you behave on the road ahead?",
          options: [
            "The number of lanes is decreasing; stay on your driving lane.",
            "The number of lanes is decreasing; move to the right lane and keep to your right.",
            "The number of lanes is increasing; choose the middle lane.",
            "The number of lanes is increasing; choose the left lane.",
          ],
          answer: 1,
          image: "img/quiz/3956.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0241. Is it permitted for any person, apart from the driver, to be inside a vehicle while being towed?",
          options: [
            "Passengers who were inside the car when it broke are allowed to stay inside.",
            "No.",
            "Yes, provided that they wear seat belts.",
            "One accompanying person is allowed.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "В"],
        },
        {
          question: "0583. What is the meaning of the following traffic sign?",
          options: [
            "Parking for non-motor vehicles only.",
            "Soldier pick-up station, for passenger loading and unloading only.",
            "No stopping of vehicles, except for the purpose of passenger loading and unloading.",
            "Vehicle parking area.",
          ],
          answer: 3,
          image: "img/quiz/3583.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0803. When can reasonable speed exceed the maximum speed limit?",
          options: [
            "When overtaking a vehicle that is parked at the side of the road.",
            "While overtaking a driving vehicle.",
            "Never. It is against the law.",
            "When overtaking a vehicle that is driving in the maximum permitted speed.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1398. Is it permitted to stop a vehicle on a freeway?",
          options: [
            "Yes, but pnly in order to board passengers.",
            "Yes, but only in order to drop passengers.",
            "Yes, but only at the side of the road, for driver refreshment purposes.",
            "No, except for stopping on the “hard shoulder” when the vehicle malfunctions and it is impossible to continue driving,",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question: "0014. Define “ATV (All Terrain Vehicle)”:",
          options: [
            "A vehicle that has at least four wheels, with the rider sitting astride the vehicle and steering with handlebars.",
            "A vehicle that has at least four wheels and is steered with a wheel.",
            "A vehicle that has at least two wheels, with the rider sitting astride the vehicle and steering with handlebars.",
            "A vehicle that has at least four wheels and is steered with a wheel. The driver sits on it with both legs on one side of the seat.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "1", "В"],
        },
        {
          question:
            "0022. A back seat passenger must wear a seatbelt. Who is held responsible if a back seat passenger doesn’t wear his seat-belt?",
          options: [
            "The driver only",
            "The driver and the passenger",
            "The passenger only",
            "The owner of the vehicle",
          ],
          answer: 1,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1665. A tractor driver is prohibited to carry a passenger, unless:",
          options: [
            "3 months passed from the date he obtained the tractor driving license.",
            "One year passed from the date he obtained the tractor driving license.",
            "6 months passed from the date he obtained the tractor driving license.",
            "He successfully passed a theoretical examination based on the traffic point system.",
          ],
          answer: 0,
          image: "",
          types: ["1"],
        },
        {
          question:
            "1490. The reaction distance is the distance covered by the vehicle:",
          options: [
            "From the moment the driver eases off his step on the accelerator until the vehicle stops.",
            "From the moment the driver notices the danger until he starts to apply the action he decided to take.",
            "From the moment the driver steps on the brakes until the vehicle stops.",
            "From the moment the driver notices the danger until the vehicle makes a complete stop.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0384. What is the difference between the following traffic signs?",
          options: [
            "There is no difference between them.",
            "Traffic sign 306 alerts about a pedestrian crossing ahead, while traffic sign 135 marks the place of the crossing.",
            "Traffic sign 135 is placed in non-urban roads, while traffic sign 306 is placed in freeways.",
            "Traffic sign 135 alerts about a pedestrian crossing ahead, while traffic sign 306 marks the place of the crossing.",
          ],
          answer: 3,
          image: "img/quiz/3384.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0545. What is the meaning of the following traffic sign?",
          options: [
            "Two-way traffic ahead.",
            "Entrance to a one-way street.",
            "Pass the place that is marked with a signpost from its right.",
            "Drive straight ahead only.",
          ],
          answer: 3,
          image: "img/quiz/3545.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1587. Is a motorcyclist permitted to park in a bus station?",
          options: [
            "It is prohibited to park within a distance of 12 meters before the station and 15 meters after it.",
            "Yes, if he parks the motorcycle on the sidewalk.",
            "It is prohibited to park within the limits of a marked bus station.",
            "Yes, if he parks the motorcycle in parallel to the sidewalk.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0674. What are you required to do when the signal in the picture displays a flashing red light?",
          options: [
            "Always stop before the level crossing, and don’t continue driving until the flashing stops.",
            "You may continue driving straight carefully after stopping first.",
            "Proceed – it is permitted to drive straight when the road is clear.",
            "Drive slowly while passing the crossing.",
          ],
          answer: 0,
          image: "img/quiz/3674.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0508. What is the meaning of the following traffic sign?",
          options: [
            "Entry is permitted for all commercial motor vehicles that have an all up weight which exceeds the weight indicated in the sign.",
            "No entry for any vehicle that has an all up weight which exceeds the weight indicated in the sign.",
            "No entry only for commercial motor vehicles that have an all up weight which exceeds the weight indicated in the sign.",
            "No entry only for public vehicles that have a lesser all up weight than what is indicated in the sign.",
          ],
          answer: 1,
          image: "img/quiz/3508.jpg",
          types: ["C1", "C", "D"],
        },
        {
          question: "0034. Define “play street”:",
          options: [
            "A main road joined by side roads.",
            "A road designed for children’s games, pedestrians and vehicles, sign posted at the entrance with the “play street” sign.",
            "A street designated for children’s games, into which only pedestrians and two wheeled vehicles are permitted to enter.",
            "A street in which different types of vehicles may drive side by side",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1638. Which sign permits a vehicle driver or a Tram driver to proceed only to the right?",
          options: ["711.", "715.", "713.", "714."],
          answer: 0,
          image: "img/quiz/31638.jpg",
          types: ["D"],
        },
        {
          question:
            "1641. When a driving license includes certain restrictions:",
          options: [
            "These restrictions concern the motorcycle’s standard and not the manner of riding it.",
            "The driver must obey these restrictions.",
            "The restriction is just a recommendation which doesn’t obligate the driver.",
            "The driver shall decide at his own discretion which restrictions should be taken into consideration.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0326. Who is responsible for the stability and weight distribution of the cargo that is carried on a vehicle?",
          options: [
            "The vehicle’s owner only.",
            "The driver and the vehicle’s owner.",
            "The driver and the forklift operator.",
            "The forklift operator only.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1327. Who is responsible for arranging the freight on the vehicle and ensuring that it is intact?",
          options: [
            "The driver’s employer.",
            "Only the driver.",
            "The person loading the freight.",
            "The registered vehicle owner.",
          ],
          answer: 1,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0271. The license of a “New driver” will not be renewed as a regular driver's license, if he is convicted of:",
          options: [
            "Driving a motor vehicle without valid insurance.",
            "Not placing a warning triangle before a malfunctioning vehicle that stands on the road.",
            "Not stopping in an intersection where a “give right-of–way” signpost is placed.",
            "Driving a motor vehicle under the influence of alcohol or intoxicants.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question:
            "0194. Driving down a long steep hill on a n Inter-urban  road",
          options: [
            "Go to a higher gear to complete the gradient quickly.",
            "Travel in Neutral.",
            "Brake for a few seconds so as not to drive too fast",
            "Sow down, change down to a lower gear according to the gradient",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1367. Which of the following types of vehicles must have a low rear collision protection bumper?",
          options: [
            "Commercial vehicles with a rear extension that covers 60% of the axle distance.",
            "Commercial vehicles, trailers and semi-trailers with an all up weight of 3,500 kg or less.",
            "Connected vehicles, integral commercial vehicles and semi-trailer trucks with an all up weight of more than 15,000 kg.",
            "Commercial vehicles, trailers and semi-trailers, and work vehicles with an all up weight of more than 3,500 kg.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1188. When a public vehicle lets off passengers - is it regarded as stopping?",
          options: [
            "At first it is regarded as stopping and later on as parking.",
            "Yes, it is regarded as stopping.",
            "No, it is not regarded as stopping.",
            "No, it is regarded as parking.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question: "0620. Driver of a “tour vehicle” must be:",
          options: [
            "First degree graduate in Land of Israel Studies and holder of class D or D1 driving license.",
            "A driver approved by the Ministry of Transportation and holder of type D or D1 driving license.",
            "A certified tour guide approved by the Ministry of Environmental Protection and holder of type D or D1 driving license.",
            "A certified tour guide approved by the Ministry of Tourism, and holder of type D or D1 driving license.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0089. What should you do when facing a structure, post or closed area, while driving on a two-way road?",
          options: [
            "You should continue driving according to size of the structure, post or closed area.",
            "You are permitted to pass the place from either its right or left side",
            "You should pass the place from its left side",
            "You should pass on the right side, unless otherwise marked",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0746. What is the advantage of driving on the right side of the road?",
          options: [
            "It helps to escape quickly to the “hard shoulders” in case of danger.",
            "It allows you to stay within the permitted distance of 40cm from the road’s edge.",
            "There is no advantage in driving on the right side of the road, except for one-way roadways.",
            "It reduces the response distance in case of immediate danger.",
          ],
          answer: 0,
          image: "img/quiz/3745.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1108. Where is the permitted to untie tools or a trailer from the tractor?",
          options: [
            "On a cement or asphalt surface only.",
            "Everywhere. The surface condition in not important.",
            "On an inclined plane, to make it easier for the harnessed tool.",
            "On a leveled surface, to prevent accidental movements.",
          ],
          answer: 3,
          image: "",
          types: ["1"],
        },
        {
          question:
            "1577. When is it required to turn-on the motorcycle’s headlights while driving?",
          options: [
            "During daytime only, all the year round, on any type of road.",
            "During the night only, on any type of road.",
            "During light up time only.",
            "Day and night, all the year round, on any type of road.",
          ],
          answer: 3,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1147. Who is obliged to check the proper function of the following bus systems: brakes, steering wheel, horn, windshield wipers, and the opening and closing of the bus doors?",
          options: [
            "The driver, before every ride.",
            "The licensing facility during the annual test.",
            "The security officer, once every half a year.",
            "The check should be authorized by the dispatcher.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0264. If a vehicle is lawfully photographed while committing an offence, who of the following is held responsible for the offense?",
          options: [
            "The driver’s employer.",
            "The driver and the passenger besides him.",
            "The vehicle’s owner, unless he proves that the vehicle was driven by another person and he identifies this person.",
            "It is not important. The main thing is to have the fine paid.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0791. When is it permitted to turn left, from a lane other than the left lane?",
          options: [
            "It is permitted when two or more left turn lanes are marked on the roadway.",
            "It is only permitted from a one-way road to another one-way road.",
            "It is permitted in an intersection with traffic lights only.",
            "It is prohibited to turn.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1349. What is a “commercial vehicle”:",
          options: [
            "Any vehicle that carries passengers or any type of freight.",
            "A vehicle used alternatively for carrying freights and for transporting passengers for hire.",
            "A vehicle used or designed to carry freight for hire or to serve for the purpose of the vehicle owner’s occupation.",
            "Any freight transporting vehicle.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1366. Which of the following commercial vehicles must be equipped with mud protectors?",
          options: [
            "Every vehicle that its rear wheels are not protected by wings, except for tractors and speed restricted vehicles.",
            "Only commercial vehicles with an all up weight of more than 4,000 kg.",
            "Only freight carrying vehicles.",
            "Every vehicle that is used for transportation in bulk.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "1668. What is the speed limit for a Class A motorcycle (unless indicated otherwise by any traffic sign)?",
          options: [
            "80 k.p.h on an urban road and 100 k.p.h on a freeway.",
            "40 k.p.h on an urban road and 90 k.p.h on a non-urban road.",
            "40 k.p.h on an urban road and 70 k.p.h on an inter-urban road.",
            "50 k.p.h on an urban road and 80 k.p.h on an inter-urban road.",
          ],
          answer: 3,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1662. A person above 18 yrs who received a Class A1 or A2 driving license for the first time shall not carry a passenger on a motorcycle, unless:",
          options: [
            "He already holds this driving license for a period of at least 6 months.",
            "He successfully completed a correct driving course.",
            "He already holds this driving license for a period of at least one year.",
            "He already holds this driving license for a period of at least two months.",
          ],
          answer: 3,
          image: "",
          types: ["A"],
        },
        {
          question: "0908. Describe the following situation:",
          options: [
            "Children playing on the roadway. I shall honk to warn them and pass them from their left.",
            "Children playing on the roadway. In order not to disturb them, I shall pass them from their right.",
            "Children playing on the road. I will take every necessary precaution so as not to endanger them.",
            "I noticed children playing. I shall honk so that they clear the roadway and don’t disturb me.",
          ],
          answer: 2,
          image: "img/quiz/3906.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1545. What is the legal definition of a trailer?",
          options: [
            "A motor vehicle that is intended to be towed behind a motor vehicle.",
            "A motorized trailer with a frontal part resting against the support surface of a towing vehicle.",
            "A non-motorized vehicle that is intended to be towed behind a motor vehicle.",
            "The legal definitions of a trailer and a semit-trailer are identical.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0993. What might happen to a vehicle when its wheels get locked?",
          options: [
            "The vehicle will lose its steering ability.",
            "The vehicle will lose its braking ability.",
            "Overheating of the engine.",
            "The steering ability is not influenced.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question:
            "0378. Which warning sign alerts about two-way traffic ahead?",
          options: ["618", "145", "620", "308"],
          answer: 1,
          image: "img/quiz/3378.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0526. The following road sign remains valid:",
          options: [
            "Until the nearest intersection or until a road sign that cancels it or until a “vehicle parking” sign.",
            "Until a speed limit sign.",
            "Until the nearest intersection with traffic lights.",
            "Until a bus station sign.",
          ],
          answer: 0,
          image: "img/quiz/3015.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0403. Which traffic sign that is placed before an intersection requires drivers to give right-of-way?",
          options: ["301", "504", "308", "310"],
          answer: 0,
          image: "img/quiz/3403.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0996. How should a driver verify that the hydraulic brake system is properly functioning?",
          options: [
            "The driver is not required to do anything as regards to the proper function of the braking system.",
            "The braking system does not require any checks and treatments by the driver.",
            "By checking the level of the brake fluid, checking the braking efficiency, and monitoring vibration and noise whilst braking.",
            "B y means of a general check of the braking system once every five years.",
          ],
          answer: 2,
          image: "",
          types: ["A", "1", "В"],
        },
        {
          question:
            "1332. When you apply the brake pedal during an emergency braking:",
          options: [
            "The vehicle stops on the spot.",
            "The vehicle continues to a certain distance until reaching a complete stop.",
            "The vehicle stops on the spot only when you drive at 50 k.p.h or slower.",
            "The vehicle’s braking distance is constant, regardless of your driving speed.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1382. What is the meaning of this sign?",
          options: [
            "Taxi rank. No entry to the street in which the sign is placed.",
            "The end of the Taxi Rank.",
            "The beginning of a public bus station.",
            "Taxi rank. Parking and stopping is forbidden to all other vehicles.",
          ],
          answer: 1,
          image: "img/quiz/3571.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1519. What is the meaning of the sign?",
          options: [
            "End of parking area.",
            "End of area where parking is not permitted.",
            "Beginning of parking area for payment.",
            "Beginning of parking for bicycles only.",
          ],
          answer: 0,
          image: "img/quiz/31519.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1054. What is the driver required to check before starting to drive?",
          options: [
            "That none of the dashboard’s (control panel)warning lights are turned on.",
            "Engine air pressure, cleanliness of windshields and proper function of the air-conditioning system.",
            "The driver is only supposed to drive the vehicle. All checks should be performed in a garage only.",
            "Level of windshield cleaning fluid and proper function of the lighting system only.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0496. What are you required to do according to the following road sign?",
          options: [
            "Avoid driving on the right lane.",
            "Give right of way to all vehicles coming from the opposite direction.",
            "Drive faster to cross this section quickly.",
            "Continue driving in normal speed, you have right of way.",
          ],
          answer: 1,
          image: "img/quiz/3495.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0634. What is the meaning of the following road marking (white broken line)?",
          options: [
            "Never drive on the left side of the line.",
            "Two-way road.",
            "The road’s direction or the line of a driving lane.",
            "Overtaking is permitted at all times.",
          ],
          answer: 2,
          image: "img/quiz/3634.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1599. Why is it important to have a wide field of vision through the helmet?",
          options: [
            "To allow people with poor eyesight to drive motorcycles as well.",
            "To determine safety distances and driving speed and to reduce response times.",
            "To increase motorcycle control in urban roads.",
            "To prevent tiredness on long rides during the day and especially during the night.",
          ],
          answer: 1,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0544. What are you required to do according to the following traffic sign?",
          options: [
            "Drive left and then right.",
            "Turn right after the sign.",
            "Drive right and turn before the sign.",
            "Make a U-turn to the right in the next intersection.",
          ],
          answer: 1,
          image: "img/quiz/3544.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0701. You are driving vehicle no. 3 before an intersection without traffic signs. According to the rules of giving right-of-way, in what order should vehicles that arrived at the same time enter the intersection?",
          options: [
            "You enter first (3), the garbage truck comes second (1), and the private vehicle is last (2).",
            "The garbage truck enters first (1), second is the private vehicle (2), and you go last (3).",
            "You enter first (3), second is the private vehicle (2), and the garbage truck is last (1).",
            "The private vehicle will go in first (2), you go second (3), and the garbage truck is last (1).",
          ],
          answer: 1,
          image: "img/quiz/3701.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0683. What is the meaning of the following traffic sign?",
          options: [
            "A marking of a narrow part of the road or for an obstacle.",
            "The road is widening.",
            "Direction posts: End of the road.",
            "Wide bridge ahead.",
          ],
          answer: 0,
          image: "img/quiz/3683.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0527. What is the meaning of the following traffic sign?",
          options: [
            "End of overtaking restriction.",
            "From here on parking is prohibited.",
            "End of urban area.",
            "Stopping and parking is permitted.",
          ],
          answer: 3,
          image: "img/quiz/3527.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1074. When there is fog, a part from fog lights.",
          options: [
            "preferble to drive slowly according to field of vision.",
            "drive whith high beam.",
            "obligatory to drive whith emergency lights.",
            "drive fast whith main bean.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0165. When and where are you required to turn on the direction indicator?",
          options: [
            "You should signal with the winker when you are 150 meters before the turn.",
            "You should only signal before making right-hand and left-hand turns.",
            "You should only signal when other passersby are near.",
            "In such place and time that other road users can notice the given signal.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0131. Is it permitted to overtake, or to try overtaking, another vehicle within a distance of 100 meters before a railway crossing?",
          options: [
            "Yes, if the overtaken vehicle is a motorcycle .",
            "Yes, provided that a train is neither seen nor heard.",
            "Yes, if the overtaken vehicle is a tractor.",
            "It is absolutely forbidden.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1306. With is the maximum permitted length of a two-axle commercial vehicle?",
          options: [
            "11.30 meters.",
            "11.00 meters.",
            "12.00 meters.",
            "15.30 meters.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1512. Is a motorcyclist permitted to drive at the side of another motorcycle?",
          options: [
            "No, he is only permitted to drive at the side of a private passenger car.",
            "No, he is only permitted to drive at the side of a commercial vehicle transporting passengers.",
            "No, but he is permitted to overtake it.",
            "Absolutely not.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1003. How does the vehicle’s Electronic Stability Program (ESP) System function during dangerous driving conditions?",
          options: [
            "It checks the tires’ air pressure and activates the hand-brake in order to slow down the vehicle’s speed.",
            "It controls the engine’s power only.",
            "It controls the steering wheel.",
            "It activates the brakes on on all weels seperately and controls the engine’s power.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1626. Who is responsible for keeping a safety distance from the front, rear and sides while riding a motorcycle amongst other vehicle?",
          options: [
            "The other drivers around.",
            "The motorcyclist only.",
            "The infrastructure-planning authorities.",
            "The Police and the Traffic Police.",
          ],
          answer: 1,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1202. Is a bus driver permitted to refuse entry of children trolleys into the bus?",
          options: [
            "Yes, in the event that the bus is crowded and there is not enough space.",
            "Yes, when there is no more space in the luggage compartment of the bus.",
            "The driver is always obliged to let children trolleys on the bus.",
            "Yes, but only when the trolley is designed to carry one baby (not when it is a trolley for twins).",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0784. How should a driver turn left from a two-way road (A) to a one-way road (B)?",
          options: [
            "From lanes 1 or 2 to lanes 5, 6 or 7..",
            "From lane 2 to lanes 5, 6 or 7..",
            "From lane 1 to lane 5.",
            "From lane 2 to lane 5.",
          ],
          answer: 3,
          image: "img/quiz/3784.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1564. What are you required to do when during a night drive you are confronted with a vehicle that doesn’t dip its lights and blinds you?",
          options: [
            "Slow down and keep as much as possible to the right.",
            "Turn on the high beam to overcome the blinding effect of the other vehicle.",
            "Drive faster to shorten the duration of being blinded.",
            "Keep driving with a low beam at your current course and speed",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1146. What is the largest banknote a bus driver is obliged  to change for a fare?",
          options: [
            "For every note.",
            "Only for notes lesser than 20 Nis.",
            "From any note given by a passenger to pay for the ride.",
            "Up to ten times the price of the fare.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question: "0453. What is the meaning of the following traffic sign?",
          options: [
            "If you see the train’s lights, stop six meters before the crossing.",
            "A distance of about 170 meters to an approaching level crossing.",
            "A distance of approx. 100 meters on approach to a level crossing.",
            "A distance of about 250 meters to an approaching level crossing.",
          ],
          answer: 2,
          image: "img/quiz/3453.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1542. Driving at dark with the high beam turned on might:",
          options: [
            "Waste electricity.",
            "Blind (dazzle) other drivers.",
            "Spoil the battery.",
            "Impair the driver’s vision.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1603. Apart from legal demand, why should a motorcyclist avoid driving in the center of the lane?",
          options: [
            "Because it annoys car drivers",
            "Because the center of the lane is the dirtiest and most greasy part of a road.",
            "He is not prohibited from driving in the center of a two-way roadway, or in the center of a marked lane within a two way roadway.",
            "Because he should keep to the left and take account of other riders.",
          ],
          answer: 1,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1580. In an automatic gearbox, what is a driver required to do before shifting from the P gear to any other gear?",
          options: [
            "Apply the brakes firmly.",
            "Step firmly on the accelerator.",
            "Step firmly on the clutch pedal.",
            "Step on the brake and the clutch pedals simultaneously. And then shift to the required gear.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0801. What is the best way to reduce the dangers whilst driving in reverse?",
          options: [
            "By taking a proper and continuous all round view and by driving slowly.",
            "By driving fast and finishing the drive backwards as quickly as possible.",
            "By turning on the vehicle’s lights.",
            "By letting all the passengers off the vehicle while driving backwards.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question: "1571. A “sidecar” is:",
          options: [
            "A motorcycle with three wheels that are aligned one after the other.",
            "A vehicle connected to the side of another vehicle with a bar that is not longer than 2.5 meters.",
            "A fixture assembled on a wheel and connected to a motorcycle from the side, for the purpose of carrying a person or freight.",
            "A vehicle driving next to another vehicle at the same speed.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1461. After connecting a trailer to a towing vehicle or to a truck, is it required to check brake response?",
          options: [
            "No, there is no need to check the response of the brakes.",
            "Yes, the connection of a trailer or a semi-trailer requires the performance of a brake response check when starting to drive.",
            "Yes, after connecting to a towing vehicle or to a truck tractor, it is required to check the proper functioning of the brakes at a brake-testing facility.",
            "No, the driver doesn’t have the means to check the response of the brakes.",
          ],
          answer: 1,
          image: "",
          types: ["C"],
        },
        {
          question:
            "1115. What is the maximum number of passengers permitted to ride a minibus?",
          options: [
            "As indicated in the vehicle’s registration documents.",
            "As the number of seatbelts installed in the vehicle.",
            "As the number of seats in the vehicle.",
            "20 passengers besides the driver.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1151. Is it obligatory to install a tachograph on a route service bus?",
          options: [
            "Yes.",
            "No, except on a commercial vehicle.",
            "No.",
            "Yes, but only on a minibus.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1779. In an intersection without traffic lights, are you required to give right of way to vehicles coming from your opposite direction when you are about to turn left?",
          options: [
            "Only when these vehicles are turning right.",
            "Always.",
            "Only when these vehicles are turning left.",
            "Only when theses vehicles continue driving straight.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1342. Define “commercial vehicle”:",
          options: [
            "A motor vehicle used or designed to carry goods.",
            "A motor commercial vehicle designed to transport people or carry goods for hire, provided that it is indicated in its registration document.",
            "A motor vehicle used or designed to carry passengers and goods.",
            "A motor vehicle used or designed to carry goods, and weighing over 8,000 kg only.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0764. When is it permitted to park on the left side of the road?",
          options: [
            "In any urban road.",
            "In any one-way road.",
            "In any road, provided that the deviation to the left is performed carefully.",
            "Only in a one-way roadway which is not part of a road that has a separation area.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0900. Which of the vehicles displayed in the picture is not parked correctly?",
          options: [
            "The vehicle parked while the pedestrian crossing is behind it.",
            "Both of them are not lawfully parked.",
            "The vehicle park before the pedestrian crossing",
            "Both vehicles are lawfully parked.",
          ],
          answer: 2,
          image: "img/quiz/3900.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0200. Is it permitted to park a vehicle on the left side of a two-way street?",
          options: [
            "Yes, provided that it is parked close to the sidewalk.",
            "It is forbidden.",
            "Yes - in a street with at least three lanes.",
            "Yes - in a street that is wider than 8 meters.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1281. In a student transport minibus, student boarding and dropping is performed:",
          options: [
            "From the front right door or from another door at the vehicle’s front.",
            "From the rear door only.",
            "From any door.",
            "When there are 2 doors at the vehicle’s front – from the front right door only.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question: "0876. At what speed are we required to drive in reverse?",
          options: [
            "Heavy vehicles – slow; light vehicles – fast.",
            "During the day – fast; during the night – slow.",
            "When driving downhill – slow, and when driving uphill – fast.",
            "Always slow.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question: "0445. What is the meaning of the following traffic sign?",
          options: [
            "Careful, electric bus ahead.",
            "Careful! Local train ( or tram) crosses in both direction on the road ahead.",
            "Careful, railway crossing.",
            "Passenger train station.",
          ],
          answer: 1,
          image: "img/quiz/3445.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0297. Is a policeman permitted to demand of a vehicle driver to perform a “Breathalyzer test”?",
          options: [
            "No. A policeman is not entitled to require a vehicle driver to perform an “alcohol test”, except under a court order.",
            'Yes, a policeman may ask a driver to perform a \\"Breathalyzer test\\" at any time.',
            "No. A policeman is not entitled to require a vehicle driver to perform any type of tests, except for an alcohol breath test.",
            "Yes, but only if the policeman reasonably suspects that the vehicle driver’s health condition is not well.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1250. Is the Authority permitted to suspend a taxi license?",
          options: [
            "No. The Authority is not permitted to suspend any license.",
            "Yes, if the license holder does not attend after being summoned by the Authority.",
            "No, only a court of law is permitted to suspend a taxi license.",
            "Yes. The Authority is permitted to do so, but only by force of a court order.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0633. What does the following road marking (double yellow broken line) indicate?",
          options: [
            "Bicycle lane.",
            "Separation between the roadway and its “hard shoulder”.",
            "Road works in place.",
            "Public transport lane.",
          ],
          answer: 3,
          image: "img/quiz/3633.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0846. What are the elements that contribute to vehicle skidding?",
          options: [
            "The old age of the vehicle.",
            "Road visibility conditions.",
            "Every element that prevents direct contact between the tire and the roadway, such as: water, oil, mud, gravel, etc.",
            "Any element which affects the driver's alertness.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0226. Is it permitted to open the vehicle’s doors while driving?",
          options: [
            "Yes, provided that you drive slowly.",
            "It is absolutely forbidden.",
            "Yes, when carrying a long cargo.",
            "Yes, when there are no passengers in the vehicle.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question: "1287. How would you ensure economic fuel consumption?",
          options: [
            "By shifting to the appropriate gear and maintaining a moderate driving speed of up to 90 k.p.h.",
            "By driving in low gear and maintaining a driving speed of more than 120 k.p.h, without any aggressive accelerations or decelerations.",
            "By shifting as much as possible to neutral gear while driving downhill.",
            "By driving in low gear and maintaining a driving speed of more than 90 k.p.h.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "A", "В"],
        },
        {
          question:
            "0472. What are you required to do according to the following road sign?",
          options: [
            "Slow down and be ready to brake or turn to avoid the pedestrians in the vicinity.",
            "Give right-of-way to traffic from the opposite direction.",
            "Drive faster and cross the place quickly.",
            "Honk continuously before the road sign.",
          ],
          answer: 0,
          image: "img/quiz/3471.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1216. A “school-children transport vehicle” is:",
          options: [
            "Any public bus or public vehicle on a route service.",
            "Any bus listed in a transportation company.",
            "A private bus or a public vehicle used for transporting school children.",
            "Any vehicle transporting school children.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1150. Is it permitted to put chairs into a bus or a taxi, other than the vehicle’s fixed seats?",
          options: [
            "Yes, but only if the chairs are padded.",
            "Yes, but only on beach rides.",
            "No.",
            "Yes, but only if they are screwed into the vehicle’s body.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1680. How would you conduct yourself when your motorcycle’s low beam malfunctions during a night drive?",
          options: [
            "You are only permitted to continue driving behind a vehicle with properly functioning lights.",
            "You are permitted to continue driving at a slow speed (half of the speed limit).",
            "You are prohibited to continue driving.",
            "You are permitted to continue driving without the low beam, for no longer than 7 days.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0072. Can a person drive a vehicle immediately after taking medications which have effect on his fitness to drive?",
          options: [
            "No, unless 4 hours pass from the time he takes the medication",
            "Yes, if these medications do not affect his level of driving",
            "No.",
            "Yes, when the medication has a lasting harmful effect",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0249. When you are driving behind a “security vehicle”, you should:",
          options: [
            "Drive at a distance of at least 50 meters behind it.",
            "Drive at a distance of at least 100 meters behind it.",
            "Drive at a distance of at least 200 meters behind it.",
            "Drive at a distance of at least 150 meters behind it.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1219. What is the meaning of this traffic sign?",
          options: [
            "Temporary narrow passage ahead.",
            "Tunnel ahead.",
            "You may drive faster, because you reached the end of the tunnel.",
            "Beware, big tunnel ahead.",
          ],
          answer: 1,
          image: "img/quiz/31219.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1558. How should a driver conduct himself if one of his tires punctures while driving?",
          options: [
            "Release his grip of the steering wheel and gradually brake the vehicle.",
            "Hold the steering wheel firmly and gradually brake the vehicle.",
            "Release his grip of the steering wheel and strongly brake the vehicle.",
            "Hold the steering wheel firmly and strongly brake the vehicle.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "1080. What regular vehicle maintenance check-ups should be performed by the driver?",
          options: [
            "Checking the vehicle’s recent mileage.",
            "Fluid and oil check, tire air pressure check, and functional check of the lamps and wipers.",
            "Checking the vehicle’s registrations.",
            "Fluid check, trunk check, and functionality check of the lamps and wipers.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0101. In the following illustration you can see a wide road. Which of the numbers within the illustration marks a lane?",
          options: ["3", "4", "6", "7"],
          answer: 2,
          image: "img/quiz/3082.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0434. What is the meaning of the following traffic sign?",
          options: [
            "Crossroad ahead.",
            "Merging of a roadway having right-of-way with a side road to its right (turning to the side road is allowed).",
            "You are approaching a side road merging from the right (turning right is prohibited). You have right-of-way over traffic coming from the side road.",
            "Give right-of-way to traffic coming from the road that merges from the right.",
          ],
          answer: 2,
          image: "img/quiz/3434.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0830. What is a hybrid vehicle?",
          options: [
            "A vehicle which had two regular engines.",
            "A vehicle which has an electric engine next to its regular engine.",
            "A vehicle which has two electric engines instead of the regular engine.",
            "A vehicle which has a solar engine (powered by the sun's energy) besides its regular engine.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0153. What is the meaning of the traffic sign in the following picture?",
          options: [
            "It is a speed restriction that is intended for private vehicles only.",
            "The vehicle’s driving speed should not be lesser than 40 KPH.",
            "It is a speed restriction that is intended for two-wheelers and trolleys.",
            "The vehicle’s driving speed should not exceed 40 KPH.",
          ],
          answer: 3,
          image: "img/quiz/3153.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1006. How should one drive on a flooded road?",
          options: [
            "Drive fast on high gear.",
            "Drive slowly in low gear.",
            "Drive fast on low gear.",
            "Drive slowly on high gear.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question:
            "0819. What is a driver always required to do when another vehicle “sits on his tail”?",
          options: [
            "Step on the brake pedal abruptly and continuously.",
            "Verify that the distance he keeps from the vehicle driving in front will allow him to brake moderately and avoid the risk of a collision from behind.",
            "You have nothing to do. The one driving behind is always found guilty in case of an accident.",
            "Accelerate immediately and increase the distance from the vehicle behind.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0871. What measure is it important to take before stepping on the brake pedal in order to slow down or to stop?",
          options: [
            "Ease off the accelerator and shift to lower gear.",
            "Check if there is a vehicle behind by glancing at the mirrors.",
            "Step on the clutch pedal, to reduce the engine’s fuel consumption.",
            "Signal and turn the wheel to the “hard shoulder”, to avoid rear-end collision.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0231. How would you seat a child less than three years of age inside a vehicle?",
          options: [
            "Wearing a three-point safety belt.",
            "In a safety seat that fits his height and weight.",
            "Wearing any of the vehicle’s safety belts.",
            "Wearing a waist belt.",
          ],
          answer: 1,
          image: "img/quiz/3046.jpg",
          types: ["C1", "C", "В"],
        },
        {
          question:
            "0813. The vehicle’s braking distance is the distance covered by the vehicle:",
          options: [
            "From the moment of identifying the risk until the vehicle starts to brake.",
            "From the moment the driver identifies the risk ahead until the vehicle makes a complete stop.",
            "From the moment the driver identifies the risk ahead until he starts stepping on the brake pedal.",
            "From the moment the driver steps on the brake pedal until the vehicle comes to a complete stop.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1268. Is a bus weighing 4500kilos permitted to park within the limits of the following road sign?",
          options: [
            "Yes.",
            "Yes, but only on weekdays from 21:00 until 05:30.",
            "No.",
            "Yes, but only on weekends.",
          ],
          answer: 2,
          image: "img/quiz/3627.jpg",
          types: ["D"],
        },
        {
          question:
            "0952. What are the tedious driving conditions described in the following picture?",
          options: [
            "Wet road, poor visibility and heavy traffic.",
            "Due to the weather conditions the traffic sign is not clear.",
            "A camera is installed in the intersection; therefore it is not permitted to cross when the light is red.",
            "The traffic congestion in the intersection does not oblige us to be overly careful.",
          ],
          answer: 0,
          image: "img/quiz/3952.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1033. What are you required to do after changing a wheel?",
          options: [
            "There is no need to do anything special.",
            "Tighten the wheel’s retaining nuts after removing the jack stand, and repeat this procedure after driving 50km.",
            "Make a general check (of tire properness and your kicking ability) by lightly kicking the tire.",
            "Check the temperature of the tire before the next drive.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0731. How can we avoid danger when we start driving and when we merge with traffic?",
          options: [
            "Danger can be prevented by turning on all the distress signals and warn other drivers while merging with traffic.",
            "Danger can be prevented by driving diagonally and at a higher speed while merging with traffic.",
            "By quickly entering the lane in which traffic is flowing.",
            "By giving an appropriate signal with the winker, looking in all directions and waiting until no one is obstructed.before driving.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0978. According to the picture, how should drivers before the traffic light behave?",
          options: [
            "Stop and get ready to start driving.",
            "Start driving.",
            "Continue driving.",
            "Prepare to stop, because red light is about to appear.",
          ],
          answer: 0,
          image: "img/quiz/3978.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0287. Under what conditions is it obligatory to operate a “speed retarder” while driving downhill?",
          options: [
            "When a “warning sliding” sign is placed on the road.",
            "When the slope rate of the decent exceeds 6% and the length of the decent is more than one kilometer.",
            "When the vehicle is equipped with a speed retarder and a sign warning about a dangerous descent is placed on the road.",
            "If a vehicle is equipped with a speed retarder, it should always be operated, also while driving on plane surfaces.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question:
            "1422. What is “perimeter lighting” according to traffic regulations?",
          options: [
            "A yellow lighting system arranged around an oversize freight. The distance between lamps should not exceed 100 cm.",
            "Green lights installed on the sides of long vehicles.",
            "A portable lighting system that has a chain on which flags are installed. The minimum distance between the flags should be 50 cm.",
            "A fixed lighting system on the walls of long vehicles.",
          ],
          answer: 0,
          image: "",
          types: ["C"],
        },
        {
          question:
            "1653. What is the risk of riding a motorcycle in the dark?",
          options: [
            "The low temperatures to which the motorcycle is exposed.",
            "The difficulty to assess the nature of the road and to detect obstacles in time.",
            "The difficulty to see other vehicles that drive behind him only.",
            "The low temperatures to which the rider is exposed.",
          ],
          answer: 1,
          image: "",
          types: ["A"],
        },
        {
          question: "0590. What is the meaning of the following traffic sign?",
          options: [
            "Entrance to a one-way street.",
            "Freeway ahead.",
            "Follow the direction of the arrow. No entry for bicycles.",
            "One-lane roadway ahead.",
          ],
          answer: 0,
          image: "img/quiz/3590.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0946. How are you required to conduct yourself in the following road section?",
          options: [
            "Turn in a bigger radius to maintain reasonable speed.",
            "Turn in a small radius and adjust your speed.",
            "Slow down before entering the curve and accelerate when coming out of it.",
            "Accelerate at the entry to the curve, and slow down within it and when coming out of it.",
          ],
          answer: 2,
          image: "img/quiz/3946.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1238. Define “bus”:",
          options: [
            "A motor vehicle designed to transport passengers on hire on route service lines that are indicated within its registration document only.",
            "A motor vehicle designed to transport eight passengers besides the driver, and has valid registration documents.",
            "A motor vehicle designed to transport at least eight passengers apart from the driver, and is indicated as a bus within its registration document.",
            "Any vehicle permitted to transport more than ten school children.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1274. What is a bus driver required to check before moving the bus?",
          options: [
            "Whether there are road users in his blind spots.",
            "The steering system’s oil level.",
            "The passenger belongings with a metal detector.",
            "Whether he has adequate bus tickets.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0752. When are we not obligated to drive on the right hand side of the road?",
          options: [
            "When overtaking.",
            "When turning right.",
            "Before a roundabout.",
            "When approaching an intersection.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1057. What is “preventive maintenance” of a vehicle?",
          options: [
            "Maintenance that prevents the vehicle from driving properly.",
            "Intermittent and unplanned maintenance, at the driver’s own discretion only.",
            "Regular and planned maintenance to ensure the proper function of the vehicle and its systems.",
            "Maintenance of unexpected vehicle failures.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question:
            "0289. What does the law state as regards to fueling a vehicle which carries more than 11 passengers?",
          options: [
            "It is allowed to fuel the vehicle, provided that a fire extinguisher is located close by.",
            "It is forbidden to fuel the vehicle while passengers are still on board.",
            "It is allowed to fuel the vehicle when it stands next to a fire extinguishing station.",
            "It is allowed by means of self-fueling.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0555. What are you required to do when you come across this traffic sign?",
          options: [
            "Pass the signpost on the left only.",
            "You are permitted to pass the place marked by the signpost on the right or left.",
            "Pass the signpost from the right. Only public transportation drivers are permitted to pass the signpost on the left.",
            "Give right-of-way to crossing traffic in the roundabout.",
          ],
          answer: 1,
          image: "img/quiz/3552.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1104. Which tractor-derived pressure is used to operate attachments and additional equipment?",
          options: [
            "Engine oil pressure.",
            "Condensed air pressure.",
            "Hydraulic oil pressure.",
            "Steam water pressure.",
          ],
          answer: 2,
          image: "",
          types: ["1"],
        },
        {
          question: "0492. What is the meaning of the following traffic signs?",
          options: [
            "No left turn for public vehicles only.",
            "A turn at the nearest left or nearest right turn is not permitted.",
            "No left or U-turn.",
            "No left U-turn only.",
          ],
          answer: 1,
          image: "img/quiz/3492.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1122. Is it permitted to park a bus that is designed to transport a maximum of 16 passengers in a parking place of another vehicle?",
          options: [
            "No.",
            "Yes, but only in the central station that operates the route service.",
            "Yes, if there is no road sign at place directing otherwise.",
            "Yes, but only by using a pubic vehicle parking ticket.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1064. What is the risk of using the GPS navigation system while driving?",
          options: [
            "There is no risk in using the GPS navigation system while driving. It is only beneficial.",
            "Disruption of the engine management systems.",
            "Driver distraction.",
            "Fast draining of the vehicle’s battery.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0293. Is it permitted to have an excessive cargo length from the front or from the rear of a vehicle with an overall permissible weight of more than 15,000kg?",
          options: [
            "It is allowed from the vehicle’s rear, and there is no length limit.",
            "No, unless it is approved by a police officer.",
            "Yes, along the vehicle’s width only, and provided that a “Warning, wide cargo” sign is placed.",
            "Yes, provided that the cargo is one length unit and that the excessive length is up to one third of the loading platform.",
          ],
          answer: 3,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0079. On which part of the street is a private passenger car allowed to drive?",
          options: [
            "During heavy traffic – on the road edge and the separation area",
            "On the roadway and the separation area always.",
            "On a roadway only - wherever a roadway exists",
            "On any part of the road",
          ],
          answer: 2,
          image: "img/quiz/3079.jpg",
          types: ["В"],
        },
        {
          question:
            "1328. Who is responsible for the proper functioning of the “speed governer” that is installed in the vehicle?",
          options: [
            "The owner of the transported freight.",
            "The driver and the vehicle owner.",
            "The owner of the freight office.",
            "The owner that is stated in the vehicle’s registration document only.",
          ],
          answer: 1,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0534. What are you required to do when the following road sign is placed?",
          options: [
            "Drive! You have right-of-way in the intersection ahead.",
            "Give right of way to vehicles crossing from all directions.",
            "Make a complete stop, look to the right and to the left and evaluate the incoming traffic.",
            "You should stop and then continue driving: You have right-of-way in the intersection.",
          ],
          answer: 1,
          image: "img/quiz/3443.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1383. When a driver is approaching a high point or a place in which his field of vision is obstructed, he should:",
          options: [
            "Continue driving in normal speed.",
            "Turn on the four winkers (distress lights) to warn other drivers.",
            "Drive faster and use the road’s “hard shoulders”.",
            "Slow down and drive at a reasonable speed, which corresponds with his field of vision.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1362. Safety shoes are obligatory:",
          options: [
            "In every motor vehicle with an all up weight of more than 5,000 kg, except for buses.",
            "In semi-trailer trucks and connected vehicles only.",
            "In every motor vehicle with an all up weight of more than 4,000 kg, except for buses and integral commercial vehicles.",
            "In every motor vehicle with an all up weight of more than 5,000 kg.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "0132. When you approach a pedestrian crossing:",
          options: [
            "You are allowed to overtake in a one way road.",
            "Do not overtake and do not try to overtake any other vehicle before the crossing.",
            "You are allowed to overtake if there are no pedestrians on the crossing.",
            "You are allowed to overtake before the pedestrian crossing in an urban road only.",
          ],
          answer: 1,
          image: "img/quiz/3132.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1351. The law requires a trailer with an all up weight of more than 4,000 kg to have:",
          options: [
            'An \\"additional license plate\\" to be installed on the trailer\'s rear.',
            "License plates which are twice the size of regular plates.",
            "A trailer in such a weight doesn't require license plates at all.",
            'An \\"additional license plate\\" to be installed on the trailer\'s front.',
          ],
          answer: 0,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0788. You are approaching a road section with traffic sign 308 posted before it. A vehicle approaching from the opposite direction already entered the road section . What should you do?",
          options: [
            "Turn on the lights and enter into the section. You have right of way.",
            "Honk continuously and enter the section.",
            "Wait for it to exit the section and only then drive through.",
            "Enter the section normally and try to pass the opposite vehicle.",
          ],
          answer: 2,
          image: "img/quiz/3579.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1664. A person is prohibited from driving a tractor that is joined to a trailer with an all up weight that exceeds 4,000 kg:",
          options: [
            "Unless One year passed from the date he obtained the tractor driving license.",
            "Unless 6 months has passed from the date he obtained the tractor driving license.",
            "Unless 3 months passed from the date he obtained the tractor driving license.",
            "Unless he successfully completed a correct driving course.",
          ],
          answer: 1,
          image: "",
          types: ["1"],
        },
        {
          question:
            "0750. What is the advantage of driving on the right side of the road?",
          options: [
            "It prevents the vehicle behind you from overtaking you.",
            "You create a safety distance between yourself and oncoming vehicle traffic.",
            "There is no advantage in driving on the right side of the road, except for one-way roads only.",
            "You Stay within the permitted distance of no more than 40cm from the road’s edge.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1027. What is the consequence of tyre pressure that is too high?",
          options: [
            "Uneven tire wear and shortened of tyre life.",
            "It is harder to turn the steering wheel.",
            "Rapid wear and tear of the steering wheel.",
            "Longer tire life span and reduced fuel consumption.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0060. Is it obligatory to obey the directions and signals of a policeman in uniform?",
          options: [
            "Yes, always, while taking extra precautions",
            "No, only to the directions of a traffic policeman",
            "No, only if the policeman is on duty",
            "Yes, only as regards to traffic directions",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1198. Which seats on a route service bus are reserved for handicapped people?",
          options: [
            "It is not obligatory to reserve seats for handicapped people.",
            "The two seats on the second row to the right of the driver.",
            "The seat next to the bus exit.",
            "One or two marked seats next to the bus entrance.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1465. Is  every taxi required to have an ABS anti-lock braking system?",
          options: [
            "No, only buses and private passenger cars must have it.",
            "No, only buses and commercial vehicles must have it.",
            "No, only city cabs must have it.",
            "Yes.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1458. A public minibus driver that is obliged to wear eyeglasses or any other optical instrument:",
          options: [
            "Must hold an extra pair of eyeglasses or contact lenses at the bus station.",
            "Must wear the eyeglasses or the contact lenses while driving – at least during the day.",
            "Must wear the eyeglasses or the contact lenses while driving – at least during the night.",
            "Must hold with him an extra pair of eyeglasses or contact lenses while driving.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1026. What is the consequence of tyre pressure that is too low?",
          options: [
            "Increased driving noise at high speeds.",
            "Increased wear and tear of the gear box.",
            "Rapid wear and tear of the steering wheel.",
            "Accelerated wear and tear of the tyres and reduced vehicle stability.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "A", "В"],
        },
        {
          question:
            "1106. How would you release the hydraulic oil pressure before disconnecting the supply piping from the tractor’s attachment or additional equipment?",
          options: [
            "There are specific drainage taps for this purpose. They should be opened for a few seconds.",
            "Move the operation lever in all directions when the engine is off.",
            "Turn off the engine, wait a few minutes and only then disconnect the piping.",
            "Connect the pressure discharge passage between the supply line and the return line.",
          ],
          answer: 1,
          image: "",
          types: ["1"],
        },
        {
          question: "0058. Should every road user obey the traffic signs?",
          options: [
            "Yes, while on the sidewalk only.",
            "Yes, every road user should obey the road signs.",
            "No, only vehicle drivers should obey the traffic signs",
            "Yes, while on the road way only.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1087. What determines the maximum weight that can be lifted by the tractor’s hydraulic lift arms?",
          options: [
            "The hydraulic oil type and the lifted device.",
            "The maximum air pressure approved by the manufacturer of the tractor’s rear tires.",
            "The manufacturer’s table, which indicates weight restrictions according to the distance of its center of gravity from the tractor.",
            "The mechanical condition of the tractor’s engine, pump and hydraulic system.",
          ],
          answer: 2,
          image: "",
          types: ["1"],
        },
        {
          question:
            "0792. Is a driver permitted to cross an unbroken separation line in order to make a U-turn?",
          options: [
            "Yes, when the roadway has at least two lanes in each driving direction.",
            "Yes, provided that the traffic is not disturbed.",
            "Yes, when the intersection ahead is blocked.",
            "No.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1495. Under what terms is it permitted to transport hazardous materials?",
          options: [
            "The transportation should be approved by the Ministry of Internal Security.",
            "The driver must have an adequate permit, and the vehicle must be new (less than four years).",
            "A vehicle transporting a hazardous material must have a gasoline engine only.",
            "The driver and the vehicle must have adequate permits to transport hazardous materials from the Licensing Authority.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1412. Is a company that was not approved to operate as a haulage contractor, allowed to transport hazardous materials?",
          options: [
            "Yes, when the driver has a Class C+E driver’s license.",
            "Yes, it is permitted to transport hazardous materials, provided that the driver has at least 10 years of driving experience.",
            "Yes, it is permitted to transport hazardous materials in all vehicles – according to the common rules.",
            "No.",
          ],
          answer: 3,
          image: "",
          types: ["C"],
        },
        {
          question: "0990. How will you recognize brakes overheating?",
          options: [
            "Irregular shaking of the vehicle.",
            "The engine will lose its pulling ability.",
            "The engine’s oil pressure indicator will light up.",
            "By a lessening of braking efficiency and a smell of burned material.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question: "1253. Who determines the taxi fare?",
          options: [
            "The Controller of road transport – in a special order.",
            "The Director of the Licensing Authority.",
            "The National Taxi Association.",
            "The Minister of Transport – within the Control of Prices of Commodities and Services .",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1248. What does the law state about carrying wheelchairs on taxis?",
          options: [
            "It is permitted, but the price for carrying a wheelchair is half the fare of a regular ride.",
            "No fee is to be charged for carrying the wheelchair. However, a taxi driver is permitted to refuse carrying more than one wheelchair.",
            "The Controller of Road Transport determines the price and terms for carrying wheelchairs.",
            "The service station determines the terms and price for carrying wheelchairs.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1373. On which of the following trailers and semi-trailers is it obligatory to install an independent brake?",
          options: [
            "Trailers and semi-trailers with an all up weight of 8,000 kg or more.",
            "Trailers and semi-trailers with an all up weight that exceeds 19,000 kg.",
            "It is obligatory to install an independent brake on every trailer and semi-trailer, regardless of weight.",
            "Trailers and semi-trailers with an all up weight that exceeds 3,000 kg.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0998. What should a driver do when one of the vehicle’s two headlights is malfunctioning during the night?",
          options: [
            "He shouldn’t continue driving, and he cannot continue driving before the malfunction is fixed.",
            "He shouldn’t continue driving only if the low beam is malfunctioning.",
            "He shouldn’t continue driving only if the high beam is malfunctioning.",
            "He can continue driving until the nearest garage.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question: "1220. “Student transport” in a bus is:",
          options: [
            "Any transportation of children on a bus, including on a route service.",
            "Student transport to school or to a school's organized activity, other than on a route service.",
            "Any transportation of children or the transportation of students.",
            "The transportation of children under 16 yrs.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1565. What is a driver required to do when the loading of the braking system’s air tanks takes longer than a reasonable time?",
          options: [
            "Drive to a garage to repair the malfunction.",
            "Reduce the engine’s RPM, to make the work of the compressor more efficient.",
            "Check the system’s fuel filter and, if required, clean it.",
            "Wait until the tanks are filled up, and ignore the problem.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question: "0351. How is the term “lighting up time” defined by law?",
          options: [
            "The night time or any other time when visibility is poor.",
            "The night time or any other time in which visibility is normal.",
            "The time period from sunset to sunrise only.",
            "The time period from sunrise to sunset.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0696. Which accessories reduce the severity of  injury in a road accident?",
          options: [
            "Rear wipers and head rests.",
            "Seat-belts, head-rests and air bags.",
            "Fog lights and orthopedic seats.",
            "The brake and clutch pedals.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "0704. You are driving vehicle no. 3. According to the rules of giving right-of-way, in what order should the vehicles enter the intersection?",
          options: [
            "The cyclist (2), the blue vehicle (1), the vehicle you are driving (3).",
            "The vehicle you are driving (3), the motorcyclist (2), the blue vehicle (1).",
            "The vehicle that continues driving straight will go first into the intersection.",
            "The blue vehicle (1), the motorcyclist (2), the vehicle you are driving (3).",
          ],
          answer: 0,
          image: "img/quiz/3704.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0096. Under which conditions is it permitted to carry a passenger, besides the driver, on an ATV  (quad bike)?",
          options: [
            "Unconditionally, unrestrictedly",
            "On a quad only",
            "When one has a permit to do so from the Licensing Authority, indicated on the vehicle’s registration documents",
            "Provided that the driver’s minimum age is 21 years",
          ],
          answer: 2,
          image: "",
          types: ["C1", "1", "В"],
        },
        {
          question: "0134. When are you forbidden to overtake another vehicle?",
          options: [
            "In a two-way interurban road.",
            "When driving in a left-turn lane.",
            "When the vehicle on your right drives slow.",
            "When the other vehicle stops before an intersection or before a pedestrian crossing or before a railway crossing.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0626. A vehicle driver must slow down when he:",
          options: [
            "Overtakes on a two-way road.",
            "Arrives to the beginning of a steep uphill climb.",
            "Overtakes on a one-way road.",
            "Drives down a steep or long descent.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1595. When a rider cries a pillion passanger the law obliges:",
          options: [
            "The passenger feet will be on the foot support at all times.",
            "The pillion rider will hold the hips of the rider all times.",
            "The pillion passnger will be a least 8 years old.",
            "Both the passengers legs will be on the right side of the motorcycle.",
          ],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question:
            '1225. The license of a private minibus states that the minibus may carry 16passengers apart from the driver. What is the maximum amount of passengers a "B" license may  carry?',
          options: [
            "12 passengers, excluding the driver.",
            "14 passengers, excluding the driver.",
            "16 passengers, including the driver.",
            "Eight passengers, excluding the driver.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1374. On which of the following commercial vehicles is it obligatory to install an ABS wheel-locking prevention system?",
          options: [
            "Commercial vehicles with an all up weight of 8,000 kg or less.",
            "Commercial vehicles with an all up weight of 4,000 kg or less.",
            "Commercial vehicles with an all up weight of 12,000 kg or more.",
            "Commercial vehicles with an all up weight of 10,000 kg or less.",
          ],
          answer: 2,
          image: "",
          types: ["C"],
        },
        {
          question:
            "1155. Is it obligatory to turn-on the bus headlights during the day as well?",
          options: [
            "Yes, but only during winter, autumn and spring.",
            "Yes, on city routes, when there are passengers on the vehicle.",
            "No, except on intercity routes.",
            "Yes, every year between November 1st and March 31st.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1035. Is it permitted to change the size of tires on a vehicle?",
          options: [
            "Only if it is approved in advance by the Licensing Authority in the Ministry of Transportation.",
            "Yes, to any size the driver wants, provided that all the vehicle’s wheels are replaced with new ones.",
            "Yes, if the change is approved by the insurance company.",
            "Absolutely not.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question:
            "0937. What is the speed limit for private vehicles on the following road section?",
          options: [
            "80 k.p.h.",
            "50 k.p.h.",
            "90 k.p.h.",
            "Less than 50 k.p.h.",
          ],
          answer: 0,
          image: "img/quiz/3937.jpg",
          types: ["C1", "В"],
        },
        {
          question:
            "0874. What is the correct order of actions while reducing the vehicle’s speed?",
          options: [
            "Slowing down by stepping on the clutch pedal and shifting to higher gear.",
            "Slowing down and shifting to lower gear.",
            "The order of actions is of no importance during vehicle deceleration.",
            "Shifting to lower gear and stepping on the clutch pedal.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1763. Is it obligatory to obey a portable traffic sign held by a member of the road safety guards?",
          options: [
            "Yes, it is a legitimate road sign.",
            "No, because it was not authorized by the Road Safety Authority.",
            "No, because its smaller in size than normal traffic signs",
            "No, it is not a legitimate road sign, but merely a recommendation.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0222. When is it allowed to leave a vehicle standing in a position that is not parallel to the sidewalk (angular parking)?",
          options: [
            "It is absolutely forbidden.",
            "In an urban road only.",
            "When there is an angular marking on the roadway, and according to the marking.",
            "In an urban road, on weekends only.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "1658. What is the proper seating position of a passenger on a motorcycle?",
          options: [
            "Sitting before the driver with his body leaned forward and holding the fuel tank or the handlebar.",
            "Sitting on a regulated seat with his body erect backwards and holding the handle installed at the motorcycle’s rear side.",
            "Sitting behind the driver with his body leaned forward and holding the fuel tank.",
            "Sitting astride a regulated seat, with his feet placed on the feet supports and holding a handle or the rider..",
          ],
          answer: 3,
          image: "",
          types: ["A"],
        },
        {
          question: "0564. What is the meaning of the following traffic sign?",
          options: [
            "It is forbidden to exceed the speed indicated in the sign (in k.p.h).",
            "Road for motor vehicles that are permitted and capable of driving at least the speed indicated in the sign (in k.p.h).",
            "Speed limit of 55 k.p.h.",
            "It is forbidden to drive slower than the speed indicated in the sign (in k.p.h).",
          ],
          answer: 1,
          image: "img/quiz/3564.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0657. What is the meaning of alternating blue colored curbstones?",
          options: [
            "To indicate and emphasize sidewalks and built traffic islands.",
            "Parking space for vehicles according to conditions laid down by the local authority . usually for payment.",
            "No parking or stopping besides the curbstones, except for taxis on an urban road.",
            "Arranged parking space for public vehicles only.",
          ],
          answer: 1,
          image: "img/quiz/3657.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1331. On which part of the semi trailer is a 6.10 meter long freight container loaded?",
          options: [
            "On the front part.",
            "On the rear part.- even when twist locks are absent.",
            "This type of container should not be carried on a semi-trailer at all.",
            "On the rear part – and only when appropriate twist locks are used.",
          ],
          answer: 3,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0754. When are we not obligated to drive in the right lane?",
          options: [
            "Whilst overtaking another vehicle.",
            "When another vehicle overtakes us from the left on a two-lane roadway.",
            "When we pass another vehicle from its right side.",
            "When another vehicle in front of us signals about its intention to start driving on the roadway’s left side.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0907. How would you handle the following situation?",
          options: [
            "Slow down and overtake the cyclist, whilst maintaining proper lateral distance from him and observing his movements.",
            "Drive behind the cyclist until after the intersection and then honk and pass him quickly.",
            "Honk to the cyclist until he clears away from your driving lane, and pass him as quickly as possible.",
            "Drive faster and overtake the cyclist before entering the intersection in which overtaking is prohibited.",
          ],
          answer: 0,
          image: "img/quiz/3907.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1228. Is any taxi driver permitted to make sightseeing / touring rides?",
          options: [
            "Yes, provided that he is listed in a travel agency.",
            "Yes, any driver that is listed in a recognized taxi station may do so.",
            "Yes, provided that he has a permit from the Ministry of Tourism.",
            "Yes, every taxi driver is permitted to do so.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0767. What is a driver obliged to do when an emergency vehicle on duty appears behind him?",
          options: [
            "Slow down and deviate to the right if the road has only one lane.",
            "Stop on the right side of the road, away from the intersection, and allow the emergency vehicle to pass.",
            "Slow down and allow the emergency vehicle to pass.",
            "No action is required when an emergency vehicle approaches from behind.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0369. Before you is traffic sign 303. Which traffic sign is sometimes placed before it?",
          options: ["114", "122", "121", "145"],
          answer: 2,
          image: "img/quiz/3369.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0189. What should you do when approaching a pedestrian crossing that is being crossed by pedestrians?",
          options: [
            "Stop and give right-of-way to the pedestrians that cross the road from left to right only.",
            "Slow down, honk and warn the pedestrians that you intend to continue driving.",
            "Speed up and cross in front or behind them, without endangering them.",
            "Always slow down and even stop if required, in order to allow the pedestrians to cross the roadway safely.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0756. While driving on a road with two lanes in your direction of driving:",
          options: [
            "You are always permitted to drive on the left lane only.",
            "You are obliged to drive in the right lane, unless you are overtaking or turning left.",
            "You are permitted to drive on the right or left lane, according to the type of the vehicle.",
            "You may drive on either lane, as long as you don’t cause any risk to traffic.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0967. A certain restriction is shown in the following picture. What is the reason for the restriction?",
          options: [
            "Overtaking is restricted due to limited field of vision.",
            "Overtaking is restricted due to speed limit.",
            "Overtaking is restricted for commercial vehicles only, due to traffic congestion.",
            "The restriction is within the speed limit.",
          ],
          answer: 0,
          image: "img/quiz/3967.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1661. What’s the correct manner for braking whilst riding a motorcycle?",
          options: [
            "The front and rear brakes should be operated together in order to slow-down and stop.",
            "The rear brake should be operated and the engine used for slowing down.",
            "Only the rear brake should be operated intermittently. The front brake should not be used.",
            "The front and rear brakes should be operated intermittently.",
          ],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0906. How would you conduct yourself in the following situation?",
          options: [
            "Honk and bypass the children as if you are overtaking a parked vehicle.",
            "Slightly swerve to the right and bypass the children quickly.",
            "Glance at the mirrors, slow down and stop if required..",
            "Don’t disturb the children, you are driving in a play street.",
          ],
          answer: 2,
          image: "img/quiz/3906.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1400. While driving during “lighting up  time” in a vehicle with an overall width of 220 cm, is it obligatory to turn-on the “Extremity“ side lights?",
          options: [
            "Yes.",
            "No, the side lights are to be used for warning when the vehicle malfunctions and stands on the road.",
            "Yes, but only from November 1st until March 31st of the following year",
            "No, the side lights should only be turned-on while the vehicle is standing for any reason.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question:
            "1411. How should oversize freight be marked from the vehicle’s front or rear sides?",
          options: [
            "It is not obligatory to mark oversize freight from the vehicle’s front, and the oversize freight from the rear shall be marked with a glittering red triangle.",
            "Oversize freight from the vehicle’s front or rear that exceeds by more than 150cm should be marked with 2 glittering red triangles from each side.",
            "The freight shall be marked from its front with a red triangle, and from its rear with a white triangle.",
            "The freight shall be marked from its front with white light reflecting triangle, and from its rear with a white triangle with red light-reflecting margins.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "1702. An efficient Braking System with ABS:",
          options: [
            "How braking cancels the ABS.",
            "Hard braking will cut out the engine.",
            "Hard braking causes stopping on the spot.",
            "Hard braking causes vibrations and noise.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0381. What should you do after seeing the following traffic sign?",
          options: [
            "You should give right of way to incoming traffic.",
            "Note that the road system ahead becomes a two-way traffic system, and keep to the right of the road.",
            "You are not allowed to drive backwards in the road section ahead.",
            "You have right-of-you over incoming traffic.",
          ],
          answer: 1,
          image: "img/quiz/3381.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0266. The period that a “new driver” is obliged to be escorted in a private passenger vehicle is?",
          options: [
            "Two years from the date in which the license was initially received.",
            "Three months from the date in which the license was initially received.",
            "Two months from the date in which the license was initially received.",
            "There is no time limitation as regards to a new-driver escorting period.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1110. What is the function of the tractor’s middle lift arm (“the third point”)?",
          options: [
            "To prevent vertical freedom of movement of the arms and of the tool.",
            "To secure the harnessed tool with special security chains.",
            "To prevent sideways freedom of movement of the arms and to prevent the tractor turning.",
            "To lift the tool a few times to maximum height in order to ensure that the tool is properly harnessed.",
          ],
          answer: 2,
          image: "",
          types: ["1"],
        },
        {
          question:
            "0276. Which identifying details must be provided by a vehicle driver involved in a road accident with casualties?",
          options: [
            "His name. ID card number and telephone number,",
            "If the vehicle’s number plates are intact and readable, there’s no need to provide any details.",
            "His name, address, driver’s license number, vehicle’s registered number and name of vehicle’s owner.",
            "Driver’s license number and vehicle’s registered number only.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1437. Which type of vehicle is it prohibited to stand or be left standing on a road?",
          options: [
            "A trailer that was disconnected from a Semi-trailer or a trailer that was disconnected from a towing vehicle.",
            "A work vehicle.",
            "A vehicle not equipped with a lighting system.",
            "A rescue and towing vehicle.",
          ],
          answer: 0,
          image: "",
          types: ["C"],
        },
        {
          question:
            "1024. What is the required air pressure of the vehicle’s spare tire?",
          options: [
            "The average air pressure of all the vehicle’s tires.",
            "The pressure stated by the manufacturer\\t",
            "The air pressure of the spare tire is of no importance.",
            "A pressure equaling the lowest pressure in the vehicle’s tires.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "В"],
        },
        {
          question: "0228. Is it permitted to get on or off a moving vehicle?",
          options: [
            "It is absolutely forbidden.",
            "No, except if it doesn’t cause any risk.",
            "Yes, as part of a sports activity, and only for adults passengers.",
            "Yes, with the approval of a police officer.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0860. What are we required to see in the inner mirror of the vehicle?",
          options: [
            "The surrounding that is seen from the rear windshield and, as much as possible, from both its sides.",
            "The passengers in the back seats.",
            "The view from the right and left sides of the vehicle.",
            "The rear view and the “blind spots” within it.",
          ],
          answer: 0,
          image: "",
          types: ["D", "В"],
        },
        {
          question:
            "0880. What should you do when your driving lane is blocked by the double-parked silver vehicle (marked with a circle)?",
          options: [
            "Slow down, allow the vehicle coming from the opposite direction to pass undisturbed, and then overtake the parked vehicle.",
            "Signal with your headlight to the vehicle coming from the opposite direction and swerve quickly to the opposite lane, before it arrives",
            "Stop behind the silver vehicle and wait for a policeman or an inspector.",
            "Overtake the silver vehicle through the gap between it and the vehicle coming from the opposite direction.",
          ],
          answer: 0,
          image: "img/quiz/3880.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1128. What is the maximum passenger capacity allowance in a desert vehicle?",
          options: [
            "As indicated in the vehicle’s registrations.",
            "At least eight passengers.",
            "As indicated in the insurance policy.",
            "As indicated in the driver’s license.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question: "0011. What is the legal definition of “lighting up time”",
          options: [
            "The time period of natural lighting conditions, when there is no need to have the vehicle lights turned on",
            "The night time or any other time of the day in which visibility conditions are poor due to weather conditions.",
            "The time period in which the vehicle’s lights must be turned on while driving in a freeway",
            "The night time period, between sunset and sunrise",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0593. What is the meaning of the following traffic sign?",
          options: [
            "Pedestrian crossing about 150 meters ahead.",
            "Suspended pedestrian bridge.",
            "Path for pedestrians only.",
            "give right of way to pedestrians crossing on the marked area.",
          ],
          answer: 3,
          image: "img/quiz/3593.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1732. A 17yr. old Israeli born citizen applies for a driving license.Which physician is certified to perform a medical examination for his application?",
          options: [
            "A physician who has been treating the license applicant for at least five years.",
            "A physician who has been treating the license applicant for at least two years.",
            "A physician who has been treating the license applicant for at least seven years.",
            "A physician who has been treating the applicant for at least three years.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0050. When may a driver be in possession of only photocopies of his original vehicle documents?",
          options: [
            "Only a vehicle transport company is allowed to hold a photocopy of the vehicle registration",
            "It is only permitted when the vehicle is owned by a corporation, and the photocopied documents’ validity is authorized by the safety officer’s signature and stamp on them.",
            "When the vehicle is owned by a private person, and the photocopy is certified by the vehicle owner’s signature",
            "Never. The driver must always be in possession of the original documents",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0515. What is the meaning of the following traffic sign?",
          options: [
            "The sign indicates the speed limit ahead, and it applies to all vehicles.",
            "End of special speed limit.",
            "Special speed limit for restricted vehicles only.",
            "It is prohibited to drive slower (in k.p.h) than indicated in the sign.",
          ],
          answer: 1,
          image: "img/quiz/3385.jpg",
          types: ["C1", "C", "D"],
        },
        {
          question:
            "0812. The driver’s reaction distance is the distance covered by the vehicle:",
          options: [
            "From the moment the driver steps on the brake pedal until the vehicle makes a complete stop.",
            "From the moment the driver identifies the danger ahead until the moment he begins to react to the danger.",
            "From the initial reaction until the end of the vehicle’s braking.",
            "From the moment the driver identifies the risk ahead until the vehicle makes a complete stop.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1224. Where is a route service bus driver supposed to stop?",
          options: [
            "In any place requested by one of the passengers.",
            'In any place which is close to the passenger\'s requested destination, but only on the \\"hard shoulder\\".',
            "In the stations that were approved by the Controller of Road Transport within the route service license.",
            "In every station along the road, provided that it is a station of the route service provider.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1163. Which of the following items should be placed on a school transport vehicle?",
          options: [
            "Only flashing lamps and signs; and a permit of the Licensing Authority should be indicated within the vehicle’s registration documents.",
            "Waste belts and appropriate signs in the front and in the back only. Nothing else.",
            "Only a “children transportation” sign in the front or in the back.",
            "Only flashing lamps; and a permit of the Licensing Authority should be indicated within the vehicle’s registration documents.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0142. When you arrive with your vehicle to the place that is illustrated in the following picture, you must:",
          options: ["Slow down.", "Honk.", "Accelerate.", "Stop."],
          answer: 0,
          image: "img/quiz/3132.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1578. What are the vehicle’s control systems?",
          options: [
            "Brakes, steering and accelerator.",
            "Braking, steering and sound.",
            "Steering, acceleration and air-conditioning.",
            "Braking, steering and air-conditioning.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question:
            "0947. How are you required to conduct yourself in the following road section?",
          options: [
            "Accelerate at the entry to the curve, and slow down within it and when coming out of it.",
            "Slow down and keep to the right of the road, due to the limited field of vision.",
            "Turn in a small radius and adjust your speed.",
            "Turn in a bigger radius to maintain reasonable speed.",
          ],
          answer: 1,
          image: "img/quiz/3947.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1529. How would you drive on a wet road?",
          options: [
            "Accelerate until the end of the wet section – in order to enhance safety.",
            "Step on the clutch pedal intermittently.",
            "Slow down and drive at a reasonable speed, according to the road conditions.",
            "The same as on a dry road. There is no difference.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1613. A  passenger is not permitted on a motorcycle unless:",
          options: [
            "A permit is given by the motorcycle’s manufacturer.",
            "it is listed on the vehicle’s license .",
            "He is listed in the insurance policy.",
            "A permit is given by the vehicle’s importer.",
          ],
          answer: 1,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1050. What is the correct order of actions while changing a wheel?",
          options: [
            "Release two nuts, lift the vehicle with the jack stand and then release the nuts completely.",
            "Loosen the retaining/screw nuts, lift the vehicle with the jack stand, and then completely release all the nuts.",
            "Lift the vehicle with the jack stand and then release the nuts completely.",
            "Release the nuts completely and lift the vehicle with the jack stand.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1797. From the answers before you, where especially should a driver slow down?",
          options: [
            "Whilst driving on an inter-urban road with a built centre divider.",
            "Whilst driving on an inter-urban road without a built centre divider.",
            "At the entrance to and whilst driving through a tunnel.",
            "Whilst driving on an inter-urban road.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1234. What is a taxi cab roof-sign?",
          options: [
            "A box in which the taxi meter is placed, and which must be lighted while the taxi is driving.",
            "A device that registers the details of the bus ride on a disc.",
            "A box used for storing a first aid bag.",
            "A device installed on the roof of a taxi cab, which can be lighted, and is used for identification purposes.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1196. Is it obligatory for a route service bus driver to allow any person into the bus?",
          options: [
            "No. If a passenger refuses to identify himself, the driver may refuse taking him.",
            "Yes, but only during the day.",
            "No, but he should provide an explanation for each refusal.",
            "Yes, unless other statutory restrictions apply.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0691. When traffic signs or road marking have an orange color:",
          options: [
            "They mark that you are in a road works site.",
            "These are traffic signs or markings on hazardous road sections for which no specific traffic sign was determined.",
            "These are temporary traffic signs on a non-urban road only, which are valid for a maximum period of three months.",
            "They are painted with special colors, designed to emphasize the signs during special weather conditions.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0852. What would you do when driving at night and you are dazzled )blinded) by a vehicle approaching from your opposite direction?",
          options: [
            "Slow down, keep to the right and look at the yellow line or at the right edge of the road.",
            "Honk strongly to alert the other driver that he is blinding you.",
            "Stop on the right of the road and turn on the parking lights until the blinding vehicle passes.",
            "Turn on the high beam to improve your field of vision.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0465. What are you required to do according to the following road sign?",
          options: [
            "Use the brakes only.",
            "Slow down and shift to low gear. Refrain from prolonged brake use.",
            "Drive faster in the ascent by shifting to high gear.",
            "Drive faster and shift to high gear.",
          ],
          answer: 1,
          image: "img/quiz/3464.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0915. How would you avoid hitting the pedestrian in the picture?",
          options: [
            "Warn him by honking loudly and continuously.",
            "In the given situation there is no way to avoid hitting the pedestrian.",
            "Brake strongly and honk early to drive the pedestrian away to the sidewalk.",
            "Drive slowly and identify the pedestrian as early as possible on the road.",
          ],
          answer: 3,
          image: "img/quiz/3915.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0667. What is the meaning of the light appearing in the following traffic light (non-flashing yellow)?",
          options: [
            "Continue driving, because the light is about to change to green.",
            "Stop before the intersection, unless you cannot stop safely.",
            "Continue driving, because the traffic light is about to turn green.",
            "You are permitted to drive straight, right and left.",
          ],
          answer: 1,
          image: "img/quiz/3667.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1388. Explain the term “gross axle weight”:",
          options: [
            "The measured pressure at the tips of the chassis connected springs.",
            "The own weight of each vehicle axle, including the wheels installed on it.",
            "The overall weight of all the wheels installed on one axle against the road.",
            "The own weight of the rear axle that includes the differential system.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question:
            "0714. You are driving vehicle no. 2. What should you do in the following situation?",
          options: [
            "Enter the intersection together with the red vehicle.",
            "Give right of way to the red vehicle (1).",
            "Continue driving (2), since you are on the main road",
            "Continue driving normally - The red vehicle (1) is turning left and therefore it has to yield.",
          ],
          answer: 1,
          image: "img/quiz/3714.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0274.  How should a driver involved in traffic accident with casualties conduct himself?",
          options: [
            "If he is not culpable of the accident and the other driver involved is later held responsible for it, then he shall clear his vehicle away from the roadway.",
            "He shall clear his vehicle away from the roadway to prevent traffic disturbance and call the rescue forces.",
            "He shall clear away from the road and inform the police about the accident.",
            "He shall not move his vehicle, unless instructed to do so by a policeman or if the vehicle had to be used for medical evacuation purposes.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0448. What are you required to do according to the following road sign?",
          options: [
            "Prepare for a possible braking, deviation or stopping due to falling rocks.",
            "Dangerous descent, shift to low gear.",
            "Stop and take another (alternative) road.",
            "Drive faster to avoid falling rocks.",
          ],
          answer: 0,
          image: "img/quiz/3448.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0394. After which of the following road signs is it prohibited to overtake (for a distance of about 100 meters)?",
          options: ["132", "307", "130", "135"],
          answer: 0,
          image: "img/quiz/3394.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0160. Is it permitted to drive through a railway crossing when the barrier is moving downwards or upwards?",
          options: [
            "It is permitted to drive through, as long as you drive carefully.",
            "It is allowed for as long as the barrier doesn’t reach its bottom position and locks.",
            "It is allowed when the barrier starts to rise.",
            "No.It is prohibited for as long as the barrier is in movement to any direction or when it is horizontally positioned.",
          ],
          answer: 3,
          image: "img/quiz/3160.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1016. Does the vehicle’s engine have an effect on safe driving?",
          options: [
            "No, the engine has no effect on safe driving.",
            "Yes, but the engine’s affect on safety is reflected only when a vehicle starts driving and accelerates.",
            "Yes, especially for escaping emergency situations.",
            "No, the engine’s effect is only reflected while driving in reverse.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "В"],
        },
        {
          question: "0598. What is the meaning of the following traffic sign?",
          options: [
            "Refreshment station.",
            "First aid station.",
            "Private ambulance station.",
            "Ambulance parking only.",
          ],
          answer: 1,
          image: "img/quiz/3598.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1736. Who is responsible for a vehicle parked in a prohibited area?",
          options: [
            "The vehicle’s owner as listed in its registration document, regardless of who was driving the vehicle at the time the offense was committed.",
            "The vehicle’s owner as listed in its registration document, unless he can prove the identity of any other driver who committed the offense.",
            "Complaint would only be filed when it is made clear who was driving the vehicle at the time the offense was committed.",
            "The rental agency if the vehicle was rented from it.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0147. Which vehicles are permitted to enter a freeway?",
          options: [
            "Only private motor vehicles.",
            "Every motor and non-motor vehicle.",
            "A motor vehicle which is permitted and capable of driving at the speed that is indicated on the road sign.",
            "Every motor vehicle, except for heavy trucks which might disturb traffic due to their low speed.",
          ],
          answer: 2,
          image: "img/quiz/3567.jpg",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question:
            "0970. Driving behind the heavy vehicle in the given road conditions, obligate you  to:",
          options: [
            "The road conditions do not require any special conduct.",
            "Drive closely behind the heavy vehicle ahead.",
            "Keeping distance is not necessary because the speed is anyway slow.",
            "Keep your distance, drive slowly and patiently.",
          ],
          answer: 3,
          image: "img/quiz/3970.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1556. What is the difference between the two signs?",
          options: [
            "Both signs belong to the group of obligatory signs and warn the driver of animals on the road.",
            "Sign 146 warns of wild animals in the area. Sign 411 forbids the entry of animals harnessed or un-harnessed.",
            "Both signs belong to the information group and warn the driver of animals on the road.",
            "Sign 146 forbids the entry of animals harnessed or un-harnessed. 411 warns of wild animals on the road.",
          ],
          answer: 1,
          image: "img/quiz/31556.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0614. What is “pupil transport” by bus?",
          options: [
            "Bus transportation of children or of students.",
            "Bus transportation of children below 16 years of age..",
            "Any transportation of a group of children in a bus, including service route.",
            "Transporting of children to school or on an organized activity in an authorised vehicle.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1039. What are the risks of driving a vehicle with a leaking engine exhaust system?",
          options: [
            "Engine over-heating.",
            "The penetration of burnt gases to the passenger compartment, which might cause loss of consciousness.",
            "It might harm nearby vehicles.",
            "There is no risk in driving a vehicle with a leaking emission system.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1627. What is the most important measure a motorcyclist can take while driving besides or nearby other vehicles?",
          options: [
            "Adjust his speed to the speed of the vehicles around him – even if it exceeds speed limit.",
            "Pay strict attention to the movements of other vehicles in good time.",
            "Reduce his response space and distance.",
            "Ride with legs close to the ground to improve stability.",
          ],
          answer: 1,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0250. Who is held responsible for a vehicle causing excessive noise?",
          options: [
            "The vehicle’s driver.",
            "The vehicle’s manufacturer.",
            "The vehicle's owner.",
            "The garage that treats the vehicle.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0415. What are you required to do according to the following road sign?",
          options: [
            "Give right-of-way in a narrow road.",
            "Continue driving normally. You have right-of-way over opposing traffic.",
            "Give a left signal and carefully cross to the left lane while glancing in the mirrors.",
            "Slow down, keep to the right, and stay alert for oncoming traffic.",
          ],
          answer: 3,
          image: "img/quiz/3415.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0917. How would you conduct yourself according to the picture:",
          options: [
            "Drive faster and move to the left lane.",
            "The pedestrian is not using a pedestrian crossing. Signal to him in whichever way to clear the roadway.",
            "Honk loudly to the pedestrian so that he clears the roadway quickly.",
            "Slow down and even stop if necessary to allow the pedestrian to cross the road.",
          ],
          answer: 3,
          image: "img/quiz/3917.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0811. What are the components of the stopping distance?",
          options: [
            "The sum of the braking distance and the gap from the vehicle in front.",
            "The average of the driver’s braking distance and reaction distance.",
            "The sum of the reaction time and of the braking distance.",
            "The sum of the decision distance and the execution distance of a reasonable driver.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0782. What risk is a driver facing while making a right turn?",
          options: [
            "There is no risk because all road users are aware of the law and know that it is prohibited to be on the right side of a vehicle that makes a right turn.",
            "There is no problem because pedestrians and two-wheeler drivers should not enter into a gap created on the right of a vehicle that makes a right turn.",
            "There is no risk during the turn, because the law permits two-wheelers and other vehicles to turn at the same time.",
            "Two-wheelers might enter the gap to his right during the turn.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0761. What is required in order to change lanes without causing any disturbance or risk?",
          options: [
            "The driver should only verify that he gives a signal with the winker while changing lanes.",
            "The driver should give a timely signal from a sufficient distance, perform a peripheral check in all directions, and glance at the mirrors, before starting to change lanes.",
            "The driver should communicate with other drivers and give a signal 3km before changing lanes.",
            "The driver should give a signal with the winker and with the headlights while changing lanes.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1354. On a semi-trailer, what is the required distance, , between the driver’s cab and the front wall of the trailer?",
          options: [
            "A distance allowing free movement of the braking system’s air pipes, and not more than 50cm.",
            "The distance depends on the length of the cab and the position of the fifth wheel and should not be less than 400 cm.",
            "A distance in which both can move in all directions without touching each other, and that allows free movement of the connectors during maximal turn.",
            "The distance between the trailer and the cab should not be more than 150 cm.",
          ],
          answer: 2,
          image: "",
          types: ["C"],
        },
        {
          question: "0581. What is the meaning of the following traffic sign?",
          options: [
            "The road is open for cyclists and pedestrians.",
            "Crossing for cyclists and pedestrians.",
            "Separate paths for cyclists and pedestrians only.",
            "Path for cyclists only. No entry for pedestrians.",
          ],
          answer: 2,
          image: "img/quiz/3581.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0494. What is the meaning of the following traffic sign?",
          options: [
            "No left turn.",
            "No U-turn to the left during night time only.",
            'A \\"u\\" turn at the nearest left turn is forbidden.',
            "No U-turn to the left and no left turn.",
          ],
          answer: 2,
          image: "img/quiz/3494.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1341. What is a “desert vehicle”:",
          options: [
            "A front-wheel drive commercial vehicle, which is indicated as a desert vehicle within the driving license.",
            "A public vehicle with front –wheel and rear-wheel drive and a standard chassis, which is indicated as a desert vehicle within its registration document.",
            "Any private vehicle with front-wheel and rear-wheel drive which is indicated as a desert vehicle within its registration document.",
            "A commercial vehicle which is indicated as a desert vehicle within its registration document.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1706. When is a blue warning light displayed on the dashboard?",
          options: [
            "All answers are correct.",
            "When the high beam is turned on.",
            "When the low beam is turned on.",
            "When the parking lights are turned on.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1725. A “Private bus” is defined as:",
          options: [
            "A bus that is not operated on a fixed route.",
            "A bus that is not a public service vehicle.",
            "A privately owned bus.",
            "A bus for touring and traveling purposes only,",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1040. What should you pay attention to whilst parking in a closed parking lgarage?",
          options: [
            "The engine should continue running for at least 20 minutes, until it cools down.",
            "The engine should not be left running for a long time, since the parking garaget is poorly ventilated.",
            "The engine should not be left running for more than half an hour continuously.",
            "Nothing. There are no restrictions on parking inside a closed parking lot.",
          ],
          answer: 1,
          image: "",
          types: ["A", "В"],
        },
        {
          question:
            "1780. On a narrow two-way road with an unbroken separation line dividing the two lanes, how would you conduct yourself when a horse drawn wagon drives before you and the opposite traffic lane is open?",
          options: [
            "Continue driving slowly behind the wagon.",
            "Try to overtake the wagon from its right side and drive on the “hard shoulders”.",
            "Overtake the wagon in accordance with all traffic rules, but only if the road ahead is open.",
            "Overtake the wagon to prevent traffic delay.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1372. Is it obligatory to install parking lights on a vehicle that also has Extremity (width) side lights?",
          options: [
            "Yes, the side lights have a different color and cannot replace the parking lights.",
            "Yes, parking lights should be installed on all vehicles, regardless of side lights.",
            "No.",
            "Yes, it is obligatory - in case the side lights malfunction.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question:
            "0176. Which of the roads in the following illustration is a one-way road?",
          options: [
            "Street A and street C.",
            "Street B and street C.",
            "Only street C.",
            "Street A.",
          ],
          answer: 3,
          image: "img/quiz/3176.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1387. Which of the following signs means: Barrier before a level crossing?",
          options: ["404", "403", "113", "511"],
          answer: 0,
          image: "img/quiz/31387.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1419. On which vehicles is it permitted to carry a freight container longer than 610 cm?",
          options: [
            "Trailers or semi-trailers with an all up weight of up to 14,000 kg.",
            "Commercial vehicles with an all up weight that exceeds 10,000 kg.",
            "On a semi-trailer only and according to the provisions of the vehicle’s registration document.",
            "Only vehicles with an all up weight exceeding 19,000 kg.",
          ],
          answer: 2,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0221. What is the correct manner for leaving a vehicle standing in a roadway?",
          options: [
            "There is no restriction on how to leave a vehicle standing in a roadway.",
            "Parallel to the road’s edge or according to the road’s marking.",
            "Perpendicularly to the road’s edge.",
            "In an angle of no more than thirty degrees from the kerb.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "0755. When are we not obligated to drive a private passenger car in the right lane?",
          options: [
            "When another vehicle overtakes us from the left.",
            "When you pass another vehicle from its right side.",
            "When the right lane is designated for another type of road users (e.g. public transportation lane).",
            "When another vehicle that is driving on the left side of the road signals about its intention to start drive.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0106. Under which conditions is a vehicle allowed to deviate from its line of driving?",
          options: [
            "Provided that no risk or disturbance is caused to any passerby.",
            "It is allowed to deviate only in a roadway which is free of traffic.",
            "In slow speed and by deviating sharply.",
            "It is absolutely prohibited to deviate from the driving lane.",
          ],
          answer: 0,
          image: "img/quiz/3106.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1624. Why is it required to keep a distance from our front, rear and sides?",
          options: [
            "To limit our field of vision and our ability to use the “hard shoulder”.",
            "To avoid smoke and dirt from other vehicles.",
            "To allow other two-wheelers to safely merge with traffic.",
            "To ensure response time and space to avoid threats from other vehicles.",
          ],
          answer: 3,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1593. The license class that is required for driving a motorcycle with an engine power of more than 33 horse-powers (25 KW) is:",
          options: ["A1.", "A2.", "A.", "B."],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0255. When is a driver of a motor vehicle required to dip the vehicle’s lights?",
          options: [
            "When driving behind another vehicle and his high beam hits the rear part of the vehicle in front.",
            "When the vehicle’s “fog light” is also turned-on.",
            "Only while driving inside a tunnel.",
            "When driving behind another vehicle and his “fog light” and “high beam” are turned-on at the same time.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1389. Can unprescribed medications have a negative effect on driving?",
          options: [
            "Yes, but only in the summer.",
            "Yes, but only in the winter.",
            "Yes.",
            "No.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1370. Which of the following vehicles must be equipped with a reverse-drive buzzer?",
          options: [
            "All commercial vehicles must be equipped with this buzzer.",
            "Vehicles with an all up weight of 12,000 kg or less.",
            "Vehicles with an all up weight of more than 4,000 kg.",
            "Commercial vehicles and work vehicles with an all up weight of more than 15,000 kg, tractors and buses.",
          ],
          answer: 3,
          image: "",
          types: ["C", "D", "1"],
        },
        {
          question:
            "0122. How should vehicle drivers conduct themselves while driving on a road with a narrow and steep hill..",
          options: [
            "The driver of the descending vehicle shall, if required, stop his vehicle and make way for the ascending vehicle",
            "The driver of the ascending vehicle shall, if required, stop his vehicle and allow the descending vehicle to continue driving.",
            "First one to arrive is first to enter into the narrow section.",
            "It is sufficient that they both deviate to the right, onto the roadway’s edge, to prevent collision.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1555. In order to avoid hitting a vehicle that makes a sudden stop in front of you:",
          options: [
            "Keep your foot on the brake pedal so that you can also stop if needed.",
            "Try to overtake it so it will be behind you.",
            "Always keep sufficient distance from the vehicle in front .",
            "Stop immediately upon noticing the brake lights of the vehicle in front, regardless of your distance from him.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1073. What is the meaning of the road-sign?",
          options: [
            "Number of a main road.",
            "Number of a Motorway (Freeway).",
            "Distance to the nearest Junction.",
            "Distance to the nearest road.",
          ],
          answer: 0,
          image: "img/quiz/31073.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0730. What are the advantages in looking far ahead?",
          options: [
            "It allows the driver to engage in other activities while driving straight.",
            "It allows you to plan your drive according to the road’s condition and to avoid being surprised by other road users.",
            "There are no advantages in looking far ahead. The important thing is what’s happening close to the vehicle.",
            "It has no advantages. Only looking to the sides assists the driver.",
          ],
          answer: 1,
          image: "img/quiz/3730.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1719. What might be the cause of loss of control of a vehicle?",
          options: [
            "Driving too fast and not wearing seatbelts.",
            "Driving too fast, lack of vigilance and being unfamiliar with the vehicle.",
            "Driving too fast and having non-functional head rests.",
            "Driving at a reasonable speed and being unfamiliar with the vehicle’s mechanisms only.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0697. You are driving in vehicle no. 3. To which of the following vehicles are you required to give right-of-way?",
          options: [
            "To the blue vehicle (1).",
            "To the red vehicle (2).",
            "To the red vehicle (2) first and then to the blue vehicle (1).",
            "To neither of them: You have right-of-way over all the rest (3).",
          ],
          answer: 0,
          image: "img/quiz/3697.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0923. Is it permitted to overtake according to the situation described in the picture?",
          options: [
            "Yes, provided that we take all the required precautions to avoid hitting children that might run into the roadway.",
            "Yes, provided that we make warning honks during overtaking, before and while we pass the bus.",
            "Overtaking is absolutely prohibited.",
            "It is prohibited to overtake a bus which stops to load children.",
          ],
          answer: 0,
          image: "img/quiz/3922.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1683. Where inside a vehicle should the reflective traffic vest be placed?",
          options: [
            "In the trunk.",
            "The law doesn’t refer to the vest’s holding place.",
            "In the driver’s compartment, except for motorcycles.",
            "Only on the driver’s seat rest.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1008. Why is it prohibited to install stiff and protruding accessories inside the passenger compartment?",
          options: [
            "Because these accessories might injure the passengers and the driver during an accident.",
            "To prevent any chance of damaging the vehicle’s tires.",
            "To avoid undermining the passengers’ field of vision while driving backwards.",
            "Because they disturb the vehicle’s reception of radio transmissions,",
          ],
          answer: 0,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0844. While braking and slowing down, what force of nature is applied on the vehicle?",
          options: [
            "No force of nature affects the vehicle while it is braking or slowing down.",
            "The force of inertia, i.e. the force which causes the vehicle to continue moving.",
            "The centrifugal force, i.e. the force applied on the vehicle when it is turning.",
            "The lift force, i.e. the force that pulls the vehicle upwards.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0458. How should you conduct yourself, from the place of the following sign until after the railway tracks?",
          options: [
            "Stop after the signpost and look left and right.",
            "Slow down and cross the railway tracks at a constant speed. Don’t stop whilst on the tracks.",
            "Give right-of-way to traffic coming from your opposite direction.",
            "Cross the railway tracks quickly.",
          ],
          answer: 1,
          image: "img/quiz/3456.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0834. Where would you stand your vehicle after being required to pull onto the “hard shoulder”?",
          options: [
            "Across the canal that is adjacent to the road’s edge.",
            "No more than 40cm from the road’s edge.",
            "With its two left wheels on the road.",
            "As far as possible from the edge of the road.",
          ],
          answer: 3,
          image: "img/quiz/3832.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1099. What RPM (Rounds per Minute) level should be determined to operate the tractor’s PTO (“Power Take Off”) power transfer?",
          options: [
            "Always according to the recommendation of the tractor’s manufacturer only.",
            "The RPM is determined automatically with the operation of the PTO.",
            "Any RPM level in which the equipment can be operated.",
            "Standard RPM, unless otherwise indicated on the operated equipment.",
          ],
          answer: 3,
          image: "",
          types: ["1"],
        },
        {
          question:
            "0196. Is it permitted to load or unload passengers on a freeway (motorway)?",
          options: [
            "Only in day light.",
            "Only for a public vehicle.",
            "Yes, provided that the road edges are wide.",
            "It is absolutely forbidden.",
          ],
          answer: 3,
          image: "img/quiz/3147.jpg",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question: "1254. Who determines the fare of a special taxi ride?",
          options: [
            "The Minister of Transport.",
            "The Minister of Finance – in a special order.",
            "The Minister of Industry, Trade and Labor.",
            "The National Taxi Association.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1724. What is the speed limit of a bus, other than a minibus on, a Freeway?",
          options: ["70 k.p.h.", "90 k.p.h.", "100 k.p.h.", "80 k.p.h."],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question: "1769. How is a pedestrian crossing marked?",
          options: ["306, 811", "135, 136", "901, 707", "413, 226"],
          answer: 0,
          image: "img/quiz/31769.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0166. How do you give a signal for braking?",
          options: [
            "By turning on the four direction indicators.",
            "By putting out the right hand through the left window.",
            "By the brake lights.",
            "By putting out the right hand through the right window.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0599. What is the meaning of the following traffic sign?",
          options: [
            "First aid station.",
            "Old age home.",
            "Five star hotel",
            "Hospital.",
          ],
          answer: 3,
          image: "img/quiz/3599.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0625. What is the meaning of the following traffic sign?",
          options: [
            "Bar or coffee shop.",
            "Camping spot.",
            "Public washrooms.",
            "Glass casting factory.",
          ],
          answer: 0,
          image: "img/quiz/3625.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1303. When is a driver of a vehicle weighing more than 6,000 kg required to take a half hour brake?",
          options: [
            "After 4.5 hours of non-stop driving.",
            "After 6 hours of non-stop driving.",
            "After a maximum of 4 hours of non-stop driving.",
            "After 5 hours of non-stop driving.",
          ],
          answer: 2,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0281. Within which distance from a tunnel is it prohibited to overtake?",
          options: [
            "Fifty meters before the entry to the tunnel and up to fifty meters after the exit from the tunnel.",
            "There is no overtaking restriction around a tunnel.",
            "100 meters before the entry to the tunnel and up to 100 meters after the exit from the tunnel.",
            "From the entry until the exit of the tunnel.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0262. What is the correct way to stand or leave an over-210-centimeters-wide motor vehicle during “light time”?",
          options: [
            "The four indicator lights and the portable lamp should be turned on and a warning triangle should be placed.",
            "The vehicle’s side and width projection light should be turned on, unless the road’s lighting is sufficient for observing the vehicle.",
            "The vehicle’s side light should be turned on, and the portable lamp should be flickering.",
            "There is no provision of law for this type of vehicle, but only for over-280-centimeters-wide vehicles.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question:
            "0146. Is it permitted to perform an emergency brake for any reason other than to prevent a road accident?",
          options: [
            "Yes, in a dirt road only, at a distance of 200 meters from buildings.",
            "Yes, after signaling a distress sign to the vehicle behind you.",
            "Yes, for brake-testing, provided that it does not disturb or endanger any other road user.",
            "Yes, in a paved road only, at a distance of 200 meters from buildings.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0193. Is it permitted, in an urban road, to stop, stand or park a vehicle on the roadway or on the hard shoulder?",
          options: [
            "It is prohibited in any case to park on the hard shoulder.",
            "Yes, provided that the vehicle remains under supervision.",
            "Yes, when it is not prohibited by law or by any road sign, and when traffic is not disturbed.",
            "Yes, always.",
          ],
          answer: 2,
          image: "img/quiz/3896.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0873. In a vehicle with a manual (regular) gearbox, it is important to adjust the gears:",
          options: [
            "In old vehicles only.",
            "In order to protect the tires.",
            "In long descents.",
            "In order to determine the driving speed.without strain on the engine.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1767. Which vehicles are permitted to pass other vehicles on their right side?",
          options: [
            "Vehicles driving on a bus lane.",
            "It is only permitted when the roadway’s width exceeds 12 meters.",
            "Only slow vehicles on an urban road.",
            "Only motorcycles, when the roadway’s width exceeds 6 meters.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0444. What is a reasonable speed on a freeway(Motorway)?",
          options: [
            "A speed that takes into account traffic conditions, and that doesn’t exceed the permitted speed limit on that road.",
            "In any case, not less that the allowed speed limit.",
            "A speed that gives you maximum drive range, provided that it doesn’t exceed the speed limit.",
            "At the driver’s discretion, provided that it doesn’t exceed the allowed speed limit.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0491. What is the meaning of the following traffic sign?",
          options: [
            "Information sign: Entry of non-motor vehicles is prohibited in both directions.",
            "No entry for all vehicles (one way street Oncoming traffic).",
            "Warning sign: Entry of motor vehicles is prohibited in both directions.",
            "Two-way street ahead.",
          ],
          answer: 1,
          image: "img/quiz/3491.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0275. How should a driver of a vehicle that was involved in a road accident with casualties conduct himself?",
          options: [
            "Drive to the police station and provide his Identifying details.",
            "Inform the police about the accident within 72 hours.",
            "Exchange identifying details with the rest of the people involved in the accident, and then there is no need to inform the police about the accident.",
            "Provide casualties with first-aid to the best of his ability and training, call the rescue forces and wait in place until they arrive.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1204. What sign denotes the number of kilometers of the road?",
          options: ["629p.", "611", "612", "630"],
          answer: 2,
          image: "img/quiz/31204.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0362. Which of the following traffic signs will be placed or marked following traffic sign 144?",
          options: ["513", "806", "101", "821"],
          answer: 3,
          image: "img/quiz/3362.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1800. The maximum height for a vehicle with a double platform, for transporting cars is?",
          options: ["5.20 meters", "4.40 meters", "4.80 meters", "4.50 meters"],
          answer: 1,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0783. When are private passenger cars permitted to turn right from a lane other than the right lane?",
          options: [
            "Turning is permitted for vehicles driving at a higher speed than that of the vehicles on the right lane.",
            "It is permitted when road traffic is sparse.",
            "Never. The law permits only heavy and long vehicles to turn right from a lane other than the right one.",
            "In a road where more than one lane is marked for turning right.",
          ],
          answer: 3,
          image: "",
          types: ["В"],
        },
        {
          question: "1598. When visibility conditions are impaired:",
          options: [
            "Stay close to the vehicle in front, to be assisted by its lights.",
            "Keep to the right of the road and drive faster until visibility conditions improve.",
            "Drive slower and maintain a wider gap from the vehicle in front.",
            "Maintain normal speed, but remain constantly alert in case an emergency brake is required.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0837. What reason justifies an unexpected pull from the roadway into the “hard shoulder”?",
          options: [
            "Sudden appearance of an undesirable element in the driving lane.",
            "When another vehicle stops in the opposite lane without giving an appropriate signal.",
            "Ringing of the vehicle's cellular phone.",
            "Technical malfunction in the vehicle’s air conditioning system.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0768. When are you obliged to signal before moving out of your lane for the purpose of overtaking?",
          options: [
            "You are not obliged to signal before deviating, if there are no other vehicles near you.",
            "You should signal at a reasonable distance before you begin to move out.",
            "You should signal at least five meters before deviating from your lane.",
            "You should first change lanes and then signal.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0025. What is “all up weight”?",
          options: [
            "A vehicle’s own weight, as approved by the Licensing Authority",
            "A vehicle’s own weight, plus the weight of the people and cargo that the vehicle is allowed to drive or carry, as approved by the Licensing Authority",
            "A vehicle’s own volume plus the weight of the people and cargo it is allowed to drive or carry, as approved by the Licensing Authority",
            "The volume of the vehicle and of the cargo it is allowed to drive or carry, as approved by the Licensing Authority",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1012. What factor might undermine seat belt functioning and efficiency   whilst driving?",
          options: [
            "Exaggerated backward tilting of the seat’.",
            "Driving on a rough road.",
            "Driving while wearing few layers of clothes which are too hot.",
            "Driver tiredness and sleepiness.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1215. Are explosives or any other inflammables allowed to be transported in a bus?",
          options: [
            "These elements can be consciously entered into the bus, provided that they comply with a safety standard.",
            "Only gasoline containers that comply with a safety standard are permitted inside the bus.",
            "Yes, but only at the expense of the people sitting in the back seats of the bus.",
            "No.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question: "0443. What is required by the following road sign?",
          options: [
            "Stop and be given right-of-way by traffic in the road that crosses.",
            "The drivers in the road that crosses should give right-of-way.",
            "Give right-of-way to vehicles coming from your right only.",
            "Give right-of-way to traffic crossing on the road ahead.",
          ],
          answer: 3,
          image: "img/quiz/3443.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0516. When you approach a pedestrian crossing that is marked on the road:",
          options: [
            "Overtaking is prohibited.",
            "Careful overtaking is permitted in one-way road ways.",
            "Overtaking is prohibited in urban roads. In inter-urban roads it is permitted.",
            "Overtaking is permitted.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0302. “Beer is not considered as an alcoholic drink”:",
          options: [
            "Correct. Beer is regarded as an alcoholic drink, but it doesn’t influence driving at all.",
            "Incorrect. The influence of beer is similar to that of any other alcoholic drink.",
            "Correct. Beer is not regarded as an alcoholic drink.",
            "Correct, but the influence of beer is different than that of other alcoholic drinks.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0798. Who is held responsible for safety in a vehicle that drives in reverse?",
          options: [
            "The owner of the vehicle even if he is not the current driver.",
            "The driver.",
            "The person guiding the driver while he drives backwards.",
            "Everybody near a vehicle that drives backwards.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0548. What is the meaning of the following symbol?",
          options: [
            "Paid parking lot for buses only.",
            "Bus-repair parking lot.",
            "Night parking lot for buses only.",
            '\\"Park and go\\".',
          ],
          answer: 3,
          image: "img/quiz/3548.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1260. Is a taxi without passengers permitted to enter a road on which the following road sign is placed?",
          options: [
            "No, because it doesn’t carry the number of passengers required by the sign.",
            "Yes, on an urban road providing no obstruction iis caused.",
            "No, because a taxi without passengers does not serve as a taxi.",
            "Yes, because all vehicles are permitted to drive on that road.",
          ],
          answer: 1,
          image: "img/quiz/3531.jpg",
          types: ["D"],
        },
        {
          question:
            "1185. What are the rules concerning the transportation of children on a route service taxi?",
          options: [
            "Two children accompanied by an adult ride for the price of one.",
            "Not more than two children under 5 years who sit on the knees of full-price paying adults are allowed to ride for free.",
            "Every two children will pay 25% of tariff 2.",
            "A child under 5 years accompanied by an adult will pay 25% of tariff 1.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1520. It is obligatory to hold two safety shoes in commercial vehicles with an all up weight that exceeds:",
          options: ["4,000 kg.", "2,500 kg.", "3,000 kg.", "5,000 kg."],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0699. You are driving vehicle no. 3. To which of the following vehicles would you give right-of-way?",
          options: [
            "To none of them. You have right-of-way on the straight road (3).",
            "To the motorcyclist (1) and to the cyclist (2).",
            "To the cyclist only (2).",
            "To the motorcyclist only (1).",
          ],
          answer: 1,
          image: "img/quiz/3699.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1320. Which type of driver must rest at least 7 consecutive hours before commencing his work day?",
          options: [
            "Driver of a public service vehicle and of a commercial vehicle that has an all up weight of more than 6,000 kg and that is permitted to carry at least 11 passengers.",
            "Drivers carrying hazardous materials.",
            "Every holder of a Class 1 driving license.",
            "Every holder of a Class B driving license.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1044. What should the driver verify before starting-up the engine?",
          options: [
            "That the hand (parking) brake is released.",
            "That the hand (parking) brake is on.",
            "There is nothing the driver must do before starting-up the engine.",
            "That the vehicle has a sufficient amount of windshield cleaning fluid in the appropriate compartment.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "В"],
        },
        {
          question: "1399. What is a “path”?",
          options: [
            "Part of a road, designated for pedestrians only.",
            "Road - paved or otherwise - designated for two-wheelers only.",
            "Part of a road, designated for bicycles only.",
            "part of a road that is not a roadway and designated for specific users.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0897. How is a driver supposed to deal with a situation in which pedestrians are crossing his driving lane on a crossing whilst he is making a right turn?",
          options: [
            "He should continue driving, because vehicles have priority of passage in intersections and therefore pedestrians must wait.",
            "Slow down and even stop, and allow the pedestrians to cross at the pedestrian crossing.",
            "Honk to warn the pedestrians and continue driving into the intersection.",
            "Continue driving into the intersection, regardless of the pedestrians.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1496. How should a driver conduct himself after hitting a parked vehicle in the absence of its owners?",
          options: [
            "Leave a note on the vehicle he hit with his personal details, the details of his vehicle and his insurance policy, and report to the police within 24 hours.",
            "Wait until a representative of his insurance company arrives and report to the police within 48 hours.",
            "Inform about the accident to the police station within 7 days.",
            "Wait at the place until the owner of the vehicle arrives.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1001. What is the function of the ABS System?",
          options: [
            "To shorten the life span of the tires.",
            "To facilitate softer and milder braking on a wet roadway.",
            "To maintain the vehicle’s steering and maneuvering abilities during emergency braking.",
            "To facilitate softer and milder braking on a dry roadway.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1049. What might be causing the steering wheel to be pulled, in a non voluntary manner, to either side whilst driving?",
          options: [
            "A malfunction in the braking system only.",
            "A malfunction in the vehicle’s engine.",
            "Low air pressure in one of the back wheels.",
            "Low air pressure in one of the front wheels.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0888. What is the driver of the silver vehicle required to do according to the following picture?",
          options: [
            "Honk loudly and proceed in the right lane, because the driver of the vehicle that exits from the parking space acts against the law.",
            "Slow down and allow the vehicle that exits from the parking space to merge in traffic.",
            "Drive faster and move to the left lane.",
            "Honk and continue driving without any change in position and speed.",
          ],
          answer: 1,
          image: "img/quiz/3888.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0041. When a vehicle is disqualified from use on the road:",
          options: [
            "It is forbidden to be on the road without authorization from the licensing authority.",
            "It is forbidden to get back on the road without authorization of the insurance company",
            "It is allowed to stay on the road for a period of up to seven days after being taken off the road.",
            "It is allowed to stay on the road unlimitedly, as long as it doesn’t pose any traffic safety risk",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0370. Before you is traffic sign 126. Which traffic sign should be placed in the road with which you are about to merge?",
          options: ["301", "123", "115", "310"],
          answer: 1,
          image: "img/quiz/3370.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0959. the law demands that a pillion passenger on a motorcycle will:",
          options: [
            "place both feet on a support either side of a motorcycle",
            "the passenger will be over 6 yrs. old",
            "the passenger will be over 10yrs. old",
            "the passenger will be over 12 yrs. old.",
          ],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0912. According to the following road signs, what are we expected to come across following the next left turn?",
          options: [
            "A pedestrian crossing.",
            "An intersection with traffic lights.",
            "An entrance to an urban road.",
            "A level crossing.",
          ],
          answer: 3,
          image: "img/quiz/3912.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1676. What is the function of a vehicle’s retarder?",
          options: [
            "Serve as an alternative to the service brakes (foot brake), in order to stop the vehicle when it is under imminent danger during an emergency.",
            "Assist the parking brake (hand brake) while parking on a steep descent.",
            "Balance the engine’s function and regulate the motor rotations,",
            "To reduce the vehicle speed, without using the vehicle’s braking system, e.g. during a long descent.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question:
            "0214. Is it permitted to stop a vehicle alongside a pedestrian guardrail?",
          options: [
            "No, unless there is a signpost indicating otherwise.",
            "It is permitted within the area of a high school only.",
            "It is allowed in an urban road.",
            "It is permitted within the area of an elementary school only.",
          ],
          answer: 0,
          image: "img/quiz/3214.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0108. At the junction marked and signposted as in the diagram, what is the correct way to turn right from road B to C?",
          options: [
            "From lane 7 to 6.",
            "From lane 8 to 6.",
            "From lane 7 to 6 or5.",
            "From lane 7 or 8 to 6 or 5.",
          ],
          answer: 0,
          image: "img/quiz/3108.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1491. What is the correct sentence?",
          options: [
            "The braking distance is always longer than the reaction distance.",
            "The reaction distance is always longer than the braking distance.",
            "The braking distance is always longer that the stopping distance.",
            "The stopping distance is always longer than the braking distance.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0497. What is the meaning of the following traffic sign?",
          options: [
            "No overtaking or passing of a restricted vehicle only.",
            "No overtaking or passing of a motor vehicle with more than 2 wheels.",
            "No overtaking or bypassing of a public vehicle only.",
            "No overtaking or passing of a non-motor vehicle with more than six wheels.",
          ],
          answer: 1,
          image: "img/quiz/3497.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0427. Where is the following road sign placed?",
          options: [
            "In the intersection.",
            "After the intersection.",
            "A reasonable before the intersection.",
            "No importance as to where the sign is placed",
          ],
          answer: 2,
          image: "img/quiz/3426.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1346. Which class of license  is required for driving a commercial motor vehicle with an all up weight of more than 12,000 kg?",
          options: ["D.", "C1.", "B.", "C."],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1404. You are driving a private vehicle with an all up weight of 1520kilos. What is the maximum trailer weight you are permitted to tow?",
          options: [
            "1,500 kg.",
            "450 kg.",
            "750 kg.",
            "2,200 kg, provided that it doesn’t exceed the own weight of the towing vehicle.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1464. What factors affect the stability of a freight-carrying commercial vehicle while driving in a curve?",
          options: [
            "The weight of the vehicle and of the engine.",
            "The radius and length of the curve.",
            "The vehicle’s speed, the radius of the curve and the height of the vehicle’s center of gravity.",
            "Only the vehicle’s speed and weight.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0787. According to the law what is the maximum permitted length of the bus?",
          options: ["18.75 met.", "15.50 met.", "13.75 met.", "14.10 met"],
          answer: 0,
          image: "img/quiz/3787.jpg",
          types: ["D"],
        },
        {
          question:
            "0056. Is it permitted to make changes in the vehicle or driver’s documentation?",
          options: [
            "It is permitted only to highlight writings that were erased or made unclear over time",
            "It is permitted only to add or change an address, in the main post office branch",
            "It is permitted only to add the zip code of a residence address, in the main post office branch",
            "No. Only the Licensing Authority is allowed to change details in these documents.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0048. Define “motor vehicle”:",
          options: [
            "A vehicle propelled by a diesel engine only",
            "A vehicle propelled by a gasoline engine only.",
            "A vehicle, propelled by any power or mechanical means.",
            "A vehicle with at least two wheels.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0404. Which of the following traffic signs requires drivers to stop and give right-of-way to other approaching vehicles from either direction?",
          options: ["309", "308", "302", "303"],
          answer: 2,
          image: "img/quiz/3404.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0269. In case of a driver incurring 36 demerit points or more:",
          options: [
            "The Licensing Authority is not authorized to revoke his license based on the point system.",
            "Only a court of law is allowed and authorized to revoke his license.",
            "The Licensing Authority shall revoke his driver’s license for a period of three months and he will be required to undergo a theory exam..",
            "The Licensing Authority may recommend a court of law to permanently revoke his license",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0376. Which of the following road signs indicates a pedestrian crossing?",
          options: ["220", "226", "136", "306"],
          answer: 3,
          image: "img/quiz/3376.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0044. After parking your car besides the pavement, on the right side of an urban road:",
          options: [
            "You should verify that the passenger sitting in the left back seat gets out very carefully from the car to the road.",
            "You should verify that the maximal distance between the car and the pavement is one meter.",
            "Before getting out of the car, you should verify that this will not disturb any road user.",
            "If the street is not lit, you should turn on the parking lights.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1288. You may reduce your vehicle’s fuel consumption by:",
          options: [
            "Driving calmly within the speed limit and making sure that the tire pressure is correct.",
            "Driving calmly within speed limit, using the lowest gear.",
            "Driving as slow as possible and reducing the vehicle’s excess weight.",
            "Driving as fast as possible and reducing the vehicle’s excess weight.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0974. The traffic light in your direction of driving is green. To what are you required to pay attention when approaching it?",
          options: [
            "Ignore the handicap vehicle parked on the right.",
            "Pay attention only to the light shown in the traffic light.",
            "Approach and cross the intersection quickly.",
            "The sudden appearance of pedestrians emerging from between the parked vehicles.",
          ],
          answer: 3,
          image: "img/quiz/3974.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0901. How can you be safe while driving on a congested road?",
          options: [
            "By sticking close to the vehicle in front.",
            "By keeping distance from your front only.",
            "When driving on a congested road, no special attention should be given to the issue of safety.",
            "Make sure to make a peripheral check, keep a safe distance in all directions and drive at a reasonable speed.",
          ],
          answer: 3,
          image: "img/quiz/3901.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0786. What do the following traffic signs have in common?",
          options: [
            "They are posted in freeways only.",
            "They are posted in work sites only.",
            "They are posted in inter-urban roads only.",
            "They are posted in urban roads only.",
          ],
          answer: 1,
          image: "img/quiz/3786.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0666. What is the meaning of the light appearing in the following traffic light?",
          options: [
            "Continue driving only when the light starts blinking.",
            "Wait and don’t cross until yellow light appears.",
            "Start driving when yellow light appears.",
            "You may keep driving if the intersection is clear and safe.",
          ],
          answer: 3,
          image: "img/quiz/3666.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0700. There are no traffic signs placed in the following intersection, According to the rules of giving right-of-way, in what order should vehicles that arrived at the same time enter the intersection?",
          options: [
            "You enter first (3), then the red vehicle (2), and the green vehicle will enter last (1).",
            "First enters the green vehicle (1), the red vehicle next (2) and you go last (3).",
            "The green vehicle (1) first, then you (3), and the red vehicle will enter last (2).",
            "The order is simple - first to arrive is last to drive.",
          ],
          answer: 2,
          image: "img/quiz/3700.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0375. Which of the following road signs should be placed after road sign 135?",
          options: ["136", "306", "226", "220"],
          answer: 1,
          image: "img/quiz/3375.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0348. Is it obligatory for vehicles to have a properly functioning speedometer?",
          options: [
            "No. It is not obligatory to have it functioning properly. It is enough if you drive according to speed limit.",
            "Not always, only when a Techograph is installed in the vehicle.",
            "No, it depends on the driver’s own discretion.",
            "Yes, always.",
          ],
          answer: 3,
          image: "img/quiz/3139.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1450. Do the obligations and prohibitions which are imposed on commercial vehicles apply also to trucks?",
          options: [
            "Yes.",
            "No. The obligations and prohibitions which are imposed on commercial vehicles also apply to integral commercial vehicles only.",
            "Yes, except for the obligation to wear a safety belt.",
            "No.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "1187. What is a zotobus?",
          options: [
            "A taxi that weighs 2,200 – 3,500 kg and is permitted to carry up to 10 passengers.",
            "A big taxi weighing 6,000 kg.",
            "A private bus on a route service.",
            "A small public bus weighing up to 2,000 kg.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1200. Which of the following sentences is correct as regards to the transportation of passengers carrying loaded weapons?",
          options: [
            "It is only permitted for soldiers wearing uniform.",
            "It is absolutely forbidden to enter loaded weapons into buses.",
            "It is prohibited to drive passengers carrying loaded weapons, except for personnel on official duty.",
            "It is only permitted for policemen on duty.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1182. What is the fare for carrying a wheelchair in a taxi?",
          options: [
            "It should be carried free of charge.",
            "Half of tariff 1.",
            "Half of the route service fare.",
            "It should be free of charge if the handicapped person is not on the taxi.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question: "0107. What is the correct manner of making a right-turn?",
          options: [
            "From the right lane to the right lane of the road you enter While driving in a reasonable speed and without causing any disturbance or danger",
            "In a wide arc, from the road's right to any lane of the road you enter",
            "From any lane to the far right lane of the road you enter",
            "On any type of road - after giving an appropriate signal.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1385. What is the all up weight of a vehicle that is required to have a braking system check at least once every half a year?",
          options: [
            "16,000 kg or more.",
            "8,000 kg or more.",
            "10,000 kg or more.",
            "12,000 kg or more.",
          ],
          answer: 0,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0452. What are you required to do according to the following road sign?",
          options: [
            "If you see the train’s lights, stop 20 meters before the crossing.",
            "Slow down and prepare to stop before the crossing, if necessary.",
            "Drive faster and cross the railway tracks.",
            "Stop at a distance of 12 meters from the railway tracks to check if a train is approaching.",
          ],
          answer: 1,
          image: "img/quiz/3451.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0885. According to the following indications, how many vehicles are standing in front of you?",
          options: [
            "There is no way to know how many vehicles are in front based on the picture.",
            "According to the picture, only the silver vehicle is driving in front of us.",
            "There are two vehicles in front of you. There is the silver vehicle and another shadow in front of it.",
            "The indications in the picture do not hint the number of vehicles in front.",
          ],
          answer: 2,
          image: "img/quiz/3885.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0312. “Drinking alcohol disrupts the driver’s judgment and as a result he takes more risks”: Correct or incorrect?",
          options: [
            "Correct.",
            "It is rarely correct.",
            "It is sometimes true.",
            "Incorrect. Alcohol improves the ability to drive.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0032. Define “Junction”:",
          options: [
            "A meeting point of two roads or more.",
            "The area formed by the junction of two or more roads at different levels.",
            "The area formed by the junction of a railroad and a roadway",
            "The junction of two urban roadways, provided that they are both asphalt-paved.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0742. How would you shift to a lower gear while driving uphill in an automatic transmission vehicle, without moving the gear handle?",
          options: [
            "By taking the foot off the brake pedal without giving gas, in order to get the right speed.",
            "By a sharp and quick pressure (“kick down”) on the accelerator, or by applying a designated electric switch.",
            "By stepping firmly on the brake pedal.",
            "By gradually stepping on the accelerator, until getting the desired result.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "0766. What is the order of actions to be taken in order to stop or park?",
          options: [
            "Check if it is permitted; make a peripheral check and slow down carefully.",
            "The order of actions before parking is of no significance.",
            "Slow down, deviate, stop and signal.",
            "The order of actions doesn’t matter. The important thing is to do it carefully.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0706. Do you (vehicle no. 3) have to give right-of-way to the cyclist (2) in the roundabout?",
          options: [
            "No, because the cyclist always yields to other vehicles.",
            "No, it depends on my good will.",
            "Yes, always",
            "No, the rules of right-of-way in a roundabout do not apply to cyclists.",
          ],
          answer: 2,
          image: "img/quiz/3706.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1192. Are passengers on the taxi’s back seat exempt from wearing a seat belt on urban roads?",
          options: [
            "Yes, but only while driving on dirt roads.",
            "Yes, but only on urban roads with a separation area.",
            "No.",
            "Yes.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question: "0409. What is the meaning of the following traffic sign?",
          options: [
            "U-Turn is permitted.",
            "Sharp bend to the right.",
            "Drive left only.",
            "Winding road.",
          ],
          answer: 1,
          image: "img/quiz/3409.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1165. Is it obligatory for taxis to have air-condition installed?",
          options: [
            "Yes.",
            "No.",
            "Only on a taxi which is not operated as a share-taxi.",
            "Only on a share-taxi van.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1317. A vehicle registration document shall not be issued for a bus or renewed for a bus:",
          options: [
            "More than 10 years after its manufacture date.",
            "More than 15 years after its manufacture date.",
            "More than 8 years after its manufacture date.",
            "More than 20 years after its manufacture date.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0648. What are you required to do when approaching the following marking on the road?",
          options: [
            "Stop before the stop-line, when it is obligatory to stop.",
            "You should always stop during lighting-up time.",
            "Always stop before the stop-line.",
            "Stop only when the line is marked before a level crossing.",
          ],
          answer: 0,
          image: "img/quiz/3647.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1072. What vehicle features should be adjusted in order to ensure a comfortable and safe sitting position?",
          options: [
            "The side mirrors and the radio stations.",
            "The seat’s position, the angle of the back rest, and the steering wheel position.",
            "The head rests and the position of the pedals.",
            "The seat’s position and the air flow direction from the vents.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question: "1742. It is permitted to make a U-turn:",
          options: [
            "Only in intersections with traffic lights.",
            "Only in intersections without traffic lights.",
            "From all lanes, unless prohibited by a road sign.",
            "When there is a white unbroken line.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1589. A motorcyclist should not carry on the motorcycle a package, bundle or object:",
          options: [
            "That would prevent him from having full control of the motorcycle.",
            "That is not wrapped as required.",
            "That sticks out beyond the motorcycle’s rear.",
            "That would prevent him from sitting comfortably on the motorcycle.",
          ],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0802. What is considered to be a reasonable driving speed?",
          options: [
            "Any driving speed of a reasonable driver.",
            "Any speed that is determined at the discretion of a reasonable driver.",
            "Statutory speed, which takes account of the road condition, the surroundings and the traffic, and in which the driver stays in full control of the vehicle.",
            "Any speed in which the driver stays in full control of the vehicle.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1744. What is an Electronic Stability Program (ESP) System?",
          options: [
            "A system that prevents the possibility of a braking system malfunction.",
            "A system that prevents the possibility of a steering system malfunction.",
            "A system that prevents loss of control while skidding and keeps the vehicle on course.",
            "A system that prevents the vehicle’s wheels from locking during an emergency braking, thus maintaining the vehicle’s steering.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0138. What is the minimal gap that you should legally maintain from the vehicle in front of you?",
          options: [
            "The distance traveled in at least three second of driving (the 21…22…23 count system)",
            "At least twenty two meters.",
            "The distance traveled in two seconds of driving.",
            "The distance traveled in one second of driving.",
          ],
          answer: 3,
          image: "img/quiz/3138.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0082. In the following illustration you can see a wide road. Which of the numbers within the illustration marks a road way?",
          options: ["1", "3", "2", "5"],
          answer: 1,
          image: "img/quiz/3082.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0595. What is the meaning of the following traffic sign?",
          options: [
            "You are on a road that has right-of-way.",
            "Police “speed trap” ahead.",
            "You are on a roadway that has no right-of-way.",
            "Public transportation roadway.",
          ],
          answer: 0,
          image: "img/quiz/3595.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0942. How are you required to conduct yourself according to the situation depicted in the following picture?",
          options: [
            "Drive faster and quickly pass the danger on your right.",
            "Move to the left lane while driving faster and paying attention to the conduct of the cyclist.",
            "Slow down and pay attention to any change in the conduct of other road users.",
            "Continue driving without doing anything special and give warning signals with the horn.",
          ],
          answer: 2,
          image: "img/quiz/3942.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1194. What measure should be taken before the start of every bus ride?",
          options: [
            "Shutting off the engine and signaling with the left winker.",
            "Signaling in the direction of the road.",
            "Starting up the bus and signaling with the right winker.",
            "Turning off the radio to allow maximum concentration.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0910. How would you conduct yourself according to the situation depicted in the following picture?",
          options: [
            "Drive faster and honk to keep the children away from the roadway so you don’t hit them.",
            "A pedestrian crossing is not marked on the roadway and no “play street” sign is posted. Continue driving normally.",
            "Continue driving. The children shall act responsibly and avoid crossing the road when noticing an approaching vehicle.",
            "Glance at the mirrors, slow down, follow the children movement on both sides of the roadway and – if required – stop to avoid endangering them.",
          ],
          answer: 3,
          image: "img/quiz/3910.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0161. When are you obliged to stop before a railway crossing?",
          options: [
            "When a “railway crossing” sign is placed.",
            "When a “right of way for crossing traffic” sign is placed.",
            "You don’t have to stop. Stop at your own discretion when a train is seen and is approaching.",
            "When the train is seen, heard or sounds a warning signal.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1186. What is tariff 2?",
          options: [
            "Taxi ride tariff from 21:00 until 05:29, excluding Sabbath and holidays.",
            "Tariff for both week days and holidays.",
            "Tariff on Sabbath and mid-holiday periods, from 07:00hrs until 21:00hrs.",
            "Taxi ride tariff from 21:01hrs. until 05:29hrs and during the Sabbath and Holydays",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question: "0432. What is the meaning of the following traffic sign?",
          options: [
            "Maintain your current speed.",
            "Drive faster in order to clear the intersection.",
            "Portable traffic sign ahead.",
            "Caution, traffic light ahead.",
          ],
          answer: 3,
          image: "img/quiz/3432.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1675. When the oil pressure check lamp lights-up while driving, you should:",
          options: [
            "Continue driving, provided that there is enough oil in the oil sump.",
            "Continue driving in low gear until the garage (provided that it doesn’t take more than half an hour)",
            "Stop the vehicle immediately and shut-off the engine.",
            "Continue driving if the engine’s thermometer indicates normal engine temperature.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "1687. What is the statutory speed limit (in k.p.h) of a private minibus on a freeway (unless indicated otherwise by another road sign)?",
          options: ["80", "110", "90", "100"],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            '1279. According to the law, the semi trailer (which is part of a "articulated vehicle"):',
          options: [
            "Must have two strips on its rear side.",
            "Must have stickers and strips on its rear side.",
            'Must have two reflecting strips on which \\"Keep Distance\\" is written,',
            "Must have warning stickers on its rear side,",
          ],
          answer: 3,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0346. A vehicle examiner or a policeman carrying a traffic examiner certificate who finds technical flaws in a vehicle, may:",
          options: [
            "Summon the vehicle owner for an inquiry at the Israel Garage Association.",
            "Notify the driver that the vehicle is taken off the road and take away the vehicle’s registration documents.",
            "He is not permitted to do anything. An examiner or a policeman is not authorized to examine a vehicle on the road.",
            "Send the vehicle to a Licensing Facility to undergo an additional semiannual test.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1536. How is a driver required to conduct himself when a vehicle stops before him on approaching a pedestrian crossing?",
          options: [
            "He is prohibited from overtaking the vehicle that stopped before the crossing.",
            "He is permitted to overtake if the pedestrian crossing has a traffic light.",
            "He is permitted to overtake the vehicle that stopped before the crossing.",
            "He is prohibited to overtake the other vehicle on an urban road only.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0597. What is the meaning of the following traffic sign?",
          options: [
            "You are on a roadway that has right-of-way.",
            "No vehicle parking.",
            "Slow down and prepare for stopping.",
            "End of road that has right-of-way.",
          ],
          answer: 3,
          image: "img/quiz/3597.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1314. What is the maximum freight height (when the freight is in one unit) permitted on a commercial vehicle with an all up weight of more than 15,000kg (without a permit from a police officer)?",
          options: [
            "3.50 meters.",
            "4.80 meters.",
            "4.20 meters.",
            "3.80 meters.",
          ],
          answer: 1,
          image: "",
          types: ["C"],
        },
        {
          question: "0488. What is the meaning of the following traffic sign?",
          options: [
            "Bicycle traffic crossing ahead.",
            "Careful! Two-wheeled motor vehicle.",
            "Give right-of-way to bicyclists.",
            "Bicycle path.",
          ],
          answer: 0,
          image: "img/quiz/3419.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1451. What is a “Tachograph”?",
          options: [
            "A device installed by the vehicle’s owner under in accordance with the importer’s instructions, and traces the number of rounds of the wheels.",
            "A device in a vehicle which has a disc that traces and records time, distance, driving speed and driver work hours.",
            "A device that is installed in a vehicle in accordance with the importer’s instructions and that traces the vehicle’s speed in order to save on fuel.",
            "A device that is installed in a vehicle and that registers vehicle-related technical data and allows the detection of technical malfunctions while driving.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0156. What must a driver do when approaching a railway crossing barrier which is marked by a traffic light?",
          options: [
            "Turn off the vehicle’s radio only.",
            "Act normally. The driver must drive carefully only when he sees or hears an approaching train.",
            "Act normally. When a railway crossing is protected by a barrier, the driver is allowed to be less alert.",
            "He must always slow down.",
          ],
          answer: 3,
          image: "img/quiz/3155.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0718. How should you behave at the following intersection (the red vehicle is not an emergency vehicle)?",
          options: [
            "Enter the intersection. You have right of way in the intersection (3)",
            "Give right of way to the private vehicle (1), and to the truck (2).",
            "Give right of way to the private vehicle (1).",
            "Allow the big vehicle to drive (2). Big vehicles always get right of way.",
          ],
          answer: 2,
          image: "img/quiz/3718.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1799. What’s the type of the vehicle in the picture and what is its statutory all up length?",
          options: [
            "A veicle conected to a trailer with an all up weight of 16.50 meters.",
            "A veicle conected to a trailer with an all up weight of 17.60 meters.",
            "An articulated vehicle with an all up weight of 16.50 meters.",
            "An articulated vehicle with an all up weight of 17.60 meters.",
          ],
          answer: 2,
          image: "img/quiz/31799.jpg",
          types: ["C"],
        },
        {
          question:
            "0128. When are you permitted to pass another vehicle on its right side?",
          options: [
            "When a vehicle is driving slower than you on the left lane in front of you.",
            "When you are driving in a right-turn-only lane.",
            "Only when you are driving a motorcycle.",
            "Only when you are driving in a left-turn-only lane.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0685. What is the meaning of the following traffic sign?",
          options: [
            "Crossroad ahead.",
            "T-junction to the left.",
            "Driving is permitted to the right only.",
            "No through road (cul-de-sac) on the right.",
          ],
          answer: 1,
          image: "img/quiz/3685.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0487. What are you required to do according to the following road sign?",
          options: [
            "Honk continuously to warn the tractor drivers.",
            "Give right-of-way to an agricultural vehicle on a freeway.",
            "Turn-on the lights and stay on the right of the road.",
            "Slow down as you may encounter a heavy and slow agricultural vehicle.",
          ],
          answer: 3,
          image: "img/quiz/3486.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0482. What is the meaning of the following traffic sign?",
          options: [
            "The notice above or below the road sign is only intended for vehicles turning in the direction of the arrow.",
            "Right turn is permitted for restricted vehicles only.",
            "Right turn is to a one-way street.",
            "No right turn.",
          ],
          answer: 0,
          image: "img/quiz/3482.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1537. It is obligatory to assign an escorting vehicle to commercial vehicles carrying oversize freight with a width that exceeds:",
          options: [
            "3 meters.",
            "20 cm from each side of the vehicle’s freight trunk.",
            "2.5 meters.",
            "3.40 meters.",
          ],
          answer: 3,
          image: "",
          types: ["C"],
        },
        {
          question: "0139. What  is a reasonable vehicle speed?",
          options: [
            "On paved roads – 80KpH; on dirt roads – 40KpH.",
            "The maximum speed the vehicle can reach under the existing circumstances.",
            "A reasonable speed which, considering all the road conditions, and road signs allows the driver to control the vehicle",
            "Any speed, as long as the driver fully controls the vehicle.",
          ],
          answer: 2,
          image: "img/quiz/3139.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1562. When the hand (parking) brake light on the dashboard is turned on while driving:",
          options: [
            "It means that the handbrake is operated and should be released, because it activates the brakes.",
            "It means that the handbrake is not operated and should be activated.",
            "It means that the handbrake is ok. The light indicator is malfunctioning and should be replaced.",
            "Everything is ok. It is a check light that should be on for as long as the handbrake is properly functioning.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1471. On an inter-urban road without a centre dividing area, what is the speed limit for a commercial vehicle with an all up weight that doesn’t exceed 3.5 tons (if not indicated otherwise by any road sign)?",
          options: ["60 k.p.h.", "90 k.p.h.", "70 k.p.h.", "80 k.p.h."],
          answer: 3,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0848. What is the imminent danger when a vehicle skids whilst driving?",
          options: [
            "Loss of steering ability and loss of control over the vehicle.",
            "That the clutch and the driving wheels stop to function.",
            "Heating and wear of the vehicle’s brakes.",
            "Heating and wear of the vehicle’s tires.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1178. How frequently should a bus be disinfected?",
          options: [
            "Once every two years.",
            "It must be disinfected shortly before its annual test.",
            "Following its annual test.",
            "Once a year.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0186. How would you conduct yourself when approaching an intersection with a yellow flashing traffic light?",
          options: [
            "Slow down and even stop if necessary - according to the surrounding traffic.",
            "In any case – stop before the pedestrian crossing.",
            "Speed up before the light turns red.",
            "Slow down and give right-of-way to vehicles coming from your left only.",
          ],
          answer: 0,
          image: "img/quiz/3186.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1304. What is the maximum permitted length of a rigid vehicle?",
          options: [
            "25.00 meters.",
            "20.00 meters.",
            "15.65 meters.",
            "18.75 meters.",
          ],
          answer: 3,
          image: "",
          types: ["C"],
        },
        {
          question:
            "1061. Why should we avoid running the vehicle’s engine in a closed placed?",
          options: [
            "Because the emitted burnt gases (exhaust)are toxic.",
            "To avoid engine over-heating due to poor ventilation.",
            "There is no need to avoid it, because it is not important whether or not the engine is running in a closed or open place.",
            "Because the engine cannot be operated in a closed place.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "1739. When the vehicle in front of you signals about its intention to turn left:",
          options: [
            "It is prohibited to pass the vehicle from its rights side.",
            "You may overtake it from the left side only.",
            "It is prohibited to pass it from either side.",
            "It is permitted to pass the vehicle on its right side.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0694. What is the meaning of this road sign?",
          options: [
            "Parking in the section following the traffic sign is prohibited for all commercial vehicles.",
            "Parking is prohibited for commercial vehicles with an all up weight of more than 8,000 kg.",
            "Parking in the section following the traffic sign is prohibited for commercial vehicles with an all up weight of more than 4,000 kg.",
            "Parking in the area following the traffic sign is prohibited for commercial vehicles with an all up weight of more than 10,000 kg.",
          ],
          answer: 3,
          image: "img/quiz/3694.jpg",
          types: ["C1", "C"],
        },
        {
          question:
            "1313. What is the general freight height (from the ground) which requires a permit from a police officer?",
          options: [
            "Less than 4.20 meters.",
            "More than 3.80 meters.",
            "More than 4.80 meters.",
            "More than 4.00 meters.",
          ],
          answer: 2,
          image: "",
          types: ["C"],
        },
        {
          question: "0570. Where the following traffic sign is placed:",
          options: [
            "All vehicles are permitted to park and wait for passengers.",
            "It is permitted to load a maximum of six passengers in each cab.",
            "It is forbidden to unload passengers within the boundaries of this sign.",
            "All vehicles are permitted to stop for the purpose of passenger unloading.",
          ],
          answer: 3,
          image: "img/quiz/3215.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1489. The reaction distance depends on:",
          options: [
            "The vehicle’s speed only.",
            "The driver’s response only.",
            "The driver’s reaction time and the vehicle’s speed.",
            "The proper function of the vehicle's brakes.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1241. Which of the following vehicles must carry a rescue kit?",
          options: [
            "Busses on touring rides only.",
            "Touring vehicles and busses on inter-urban route service lines only",
            "Taxis and desert vehicles only.",
            "Every touring vehicle and every bus must carry a rescue kit.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1067. The auxiliary (parking/hand) brake may also be used:",
          options: [
            "To reduce wear and tear of the braking system.",
            "As an emergency brake .",
            "To prevent the vehicle from moving only.",
            "To assist drivers while driving in reverse.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "1", "В"],
        },
        {
          question:
            "1663. How would we pass a road obstacle while riding a motorcycle?",
          options: [
            "Accelerate and quickly cross the obstacle.",
            "Brake by shifting to neutral gear until we finish crossing the obstacle.",
            "Slow down before the obstacle.",
            "Avoid braking and continue driving normally.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1097. Is it permitted to drive a tractor with brakes that are permanently separated?",
          options: [
            "No. It might cause overheating and brake loss.",
            "Yes, but is will cause uneven wear between the right and left brakes.",
            "No. It might cause the tractor to roll-over while braking.",
            "Yes, provided that the driver’s leg is positioned exactly between the two brake pedals.",
          ],
          answer: 2,
          image: "",
          types: ["1"],
        },
        {
          question:
            "0315. ”When you drink alcohol, it will take you more time to recover from dazzling(blinding)”. Correct or incorrect?",
          options: [
            "Incorrect.",
            "It is correct for young people only.",
            "It is correct for people wearing eyeglasses only.",
            "Correct.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1129. What class of license is required for driving buses or commercial vehicles with an all up weight of more than 3,500 kg?",
          options: ["A2.", "D.", "B.", "A1."],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0651. The following arrow is marked on the road, inside your lane. What does the arrow mean?",
          options: [
            "Overtaking from the left is permitted.",
            "From this lane you will have to turn left at the next intersection.",
            "Deviate now to the left lane.",
            "You should deviate to the lane on your left.",
          ],
          answer: 1,
          image: "img/quiz/3651.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0702. You are driving vehicle no. 3 before an intersection without traffic signs. According to the rules of giving right of way, who should enter the intersection first?",
          options: [
            "The vehicle you are driving (3).",
            "The motorcyclist (2).",
            "The law offers no solution to the given situation.",
            "The cyclist (1).",
          ],
          answer: 3,
          image: "img/quiz/3702.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1721. The auxiliary (parking/hand) brake is used by the driver:",
          options: [
            "To reinforce the braking force of the service (foot) brake.",
            "Only new drivers use the auxiliary brake.",
            "To assist the driver when he begins driving on a steep uphill climb only.",
            "To secure his vehicle from any movement.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "1682. Which vehicles are required to be equipped with a reflective traffic vest?",
          options: [
            "All motor vehicles except for motorcycles.",
            "All motor vehicles except for tractors.",
            "All motor vehicles.",
            "All motor and non-motor vehicles.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1493. When visibility on the road is poor, the driver should:",
          options: [
            "Slow down, adjust his speed to the visibility conditions and turn-on his dipped beam.",
            "On a two-way road – slow down; and on a one-way road – continue driving normally.",
            "Accelerate and turn-on the rear lights, to prevent collision with the vehicle driving behind.",
            "Turn-on the high beam to extend the field of vision and warn the incoming traffic from your opposite direction.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0500. What is the meaning of the following traffic sign?",
          options: [
            "End of urban road.",
            "End of overtaking restriction.",
            "End of freeway.",
            "End of road-section in which trucks are allowed to drive on the road’s “hard shoulder”.",
          ],
          answer: 1,
          image: "img/quiz/3386.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1672. What is the type of the vehicle in the picture and what is its statutory all up length?",
          options: [
            'It is an \\"Articulated Vehicle\\" (Semi-trailer) vehicle with an all up weight of 17.60 meters.',
            'It is an \\"Articulated Vehicle\\" (Cabin and trailer) vehicle with an all up weight of 16.50 meters.',
            "It is a truck and trailer vehicle with an all up weight of 17.60 meters.",
            "It is a truck and trailer with an all up weight of 16.50 meters.",
          ],
          answer: 0,
          image: "img/quiz/31672.jpg",
          types: ["C"],
        },
        {
          question: "0350. What is a one-way street?",
          options: [
            "A street in which traffic movement is permitted in one direction only.",
            "A road with only one lane.",
            "A roadway in which one direction of traffic is open for all vehicles while the opposite direction is limited to public vehicles only.",
            "A dead-end road.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0006. A police officer may revoke a driver’s license for a period of thirty days:",
          options: [
            "When the driver endangers the life of a person on a pedestrian crossing",
            "An arrest warrant was issued against the vehicle’s owner",
            "When the driver committed an offence of holding a driver’s license that has been expired for more than ten days",
            "When the passenger on the driver’s side did not wear a seatbelt",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1211. Vehicles of handicapped people (paraplegic handicap tag holders) are permitted to use a bus lane:",
          options: [
            "Only in order to get to the garage.",
            "Even when the handicapped person is not in the vehicle.",
            "Also when an escort is driving the vehicle for the handicapped person.",
            "Only when the handicapped person is the driver.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1612. What effect does a curve or a turn have on the motorcycle’s ability to brake?",
          options: [
            "It has no effect on the motorcycle’s ability to brake.",
            "It requires the driver to slow down before the curve or the turn and to brake very gently.",
            "The driver’s response time is doubled during turns or curves.",
            "During a curve or a turn only the rear brake can be used and therefore braking is impaired.",
          ],
          answer: 1,
          image: "",
          types: ["A"],
        },
        {
          question: "0073. Is it permitted to drive an un-roadworthy vehicle?",
          options: [
            "Yes, if the malfunction doesn’t involve the vehicle’s brake system",
            "It is forbidden to drive an un-roadworthy vehicle that cannot be fully controlled",
            "Yes, if the malfunction involves the vehicle’s lighting system only",
            "Yes, if the malfunction involves the vehicle’s steering system only",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1460. What is the risk in shifting gears while driving a heavy vehicle down a steep descent?",
          options: [
            "There is no risk in shifting gears, and gears are not so important, as long as one of them is operated.",
            "Shifting gears while driving down a steep descent is not dangerous.",
            "There is no danger. By shifting from low gear to high gear while driving on a steep descent, the vehicle can drive faster.",
            "There is a risk that the gear would not shift and the vehicle would stay in neutral gear.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1596. What is special about the operation of the motorcycle’s brakes?",
          options: [
            "The rear and front brakes can be operated separately.",
            "Brakes operation is safer in a motorcycle because it is done with the hands only.",
            "In a motorcycle you cannot brake every wheel separately.",
            "The motorcycle can brake at any speed on the spot.",
          ],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question: "1226. Who is authorized to repair or set a taxi meter?",
          options: [
            "A person authorized by the Authority as a certified service provider.",
            "Only the manufacturer of the meter is authorized to repair it.",
            "A certified electrician.",
            "Only the vehicle’s manufacturer.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0308. When a driver’s blood alcohol concentration exceeds lawful levels, will he be regarded as a drunk driver?",
          options: [
            "No, the term “drunk” was not defined by the law.",
            "No, only someone behaving as a drunkard shall be regarded as a drunk driver.",
            "Yes, he will be regarded as a drunk driver even if he has not committed any other violation.",
            "No, a person who is not affected by a small quantity of alcohol shall not be regarded as a drunk driver, regardless of the amount of alcohol.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0136. When is a driver required to drive on an asphalt paved hard shoulder?",
          options: [
            "When driving a commercial motor vehicle with an overall permissible weight of more than 10,000 Kg.",
            "When driving a motor vehicle that is forced to drive at a slow speed due to the cargo it is carrying or to the slope of the road.",
            "When driving a bus with an overall permissible weight of more than 10,000 Kg.",
            "Never. The law prohibits driving on the road’s edges.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "1"],
        },
        {
          question:
            "0828. You are driving in a two-lane one-way street and try to overtake the vehicle driving in front of you. The main risk in deviating to the left is:",
          options: [
            "The crossing of an unbroken separation line.",
            "An incoming vehicle driving in your opposite direction might appear on the lane to your left.",
            "Disturbing a vehicle driving in the left lane.",
            "Disturbing a vehicle driving behind you on your lane.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0922. How is a driver required to conduct himself when intending to overtake a school transport bus that stopped to pick-up and let down children?",
          options: [
            "Overtake the bus quickly, according to the customary overtaking stages.",
            "Stop behind the bus and then overtake it.",
            "Slow down and be prepared to stop.",
            "Honk continuously and overtake.",
          ],
          answer: 2,
          image: "img/quiz/3922.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1028. Where can you find information about the vehicle’s required tyre pressure?",
          options: [
            "Only in a licensed garage.",
            "In the manufacturer’s manual.",
            "In the vehicle’s insurance company.",
            "In the vehicle’s registration document.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "A", "В"],
        },
        {
          question:
            "1764. A driver exiting a gas station must give right of way:",
          options: [
            "To vehicles approaching from his left only.",
            "To vehicles driving fast.",
            "To all approaching vehicles on the roadway, from all directions.",
            "To vehicles approaching from his right only.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0483. What is the meaning of the following traffic sign?",
          options: [
            "Steep escent for the next 2,500 meters.",
            "Steep escent starts in 2,500 meters.",
            "Steep and winding descent for the next 2,500 meters.",
            "Steep descent starts in 2,500 meters.",
          ],
          answer: 2,
          image: "img/quiz/3483.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1316. When should you operate a flashing yellow lamp above the driver’s compartment?",
          options: [
            "When you are driving a vehicle carrying freight that is wider than the vehicle.",
            "When you are driving a connected vehicle.",
            "When you are driving a vehicle with a permitted weight of more than 15,000 kg.",
            "When the length of the freight or of the vehicle exceeds 20.00 meters.",
          ],
          answer: 3,
          image: "",
          types: ["C"],
        },
        {
          question:
            "1757. On a single lane two-way road, when the lanes are divided by an unbroken separation line:",
          options: [
            "Stopping and parking are unrestricted, unless any appropriate road sign is placed.",
            "Parking and stopping are prohibited, even on the “hard shoulder”.",
            "Stopping and parking are permitted in parallel to the sidewalk only.",
            "Stopping is permitted for buses only.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0245. Which vehicle is allowed to tow an agricultural trailer which is exempt from registration and licensing duties?",
          options: [
            "Only a utility vehicle.",
            "A tractor.",
            "A commercial vehicle with an overall permissible weight of more than 12,000 kg.",
            "Only an all-terrain vehicle.",
          ],
          answer: 1,
          image: "",
          types: ["1"],
        },
        {
          question:
            "0304. A vehicle which emits oil or smoke above normal levels:",
          options: [
            "May be driven at a low speed of less than 40 Kmh.",
            "Is prohibited from carrying passengers.",
            "Is prohibited to be driven..",
            "Is prohibited from carrying cargo.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0537. What is the meaning of the following traffic sign?",
          options: [
            "Stop and give right-of-way to traffic on the road that crosses ahead.",
            "Stop for a moment and then continue driving.",
            "Give right-of-way to traffic on the road that crosses ahead while road-works take place.",
            "Stop! (Hand held road sign).Stop as long as the sign is held up.",
          ],
          answer: 3,
          image: "img/quiz/3537.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            '0268. A “new driver” aged less than 21 years (or has not completed two years from the date of receiving a "new driver" license) cannot transport in a vehicle:',
          options: [
            "More than three passengers, besides himself, unless an escort sits beside him.",
            "More than one person, besides himself.",
            "More than four passengers, besides himself, unless an escort sits beside him.",
            "More than two passengers, besides himself, unless an escort sits beside him in the front seat.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1561. When a continuous separation line is marked to your left, accompanied by a broken line to its left:",
          options: [
            "You are permitted to cross the lines. However, vehicle traffic from the opposite direction is not permitted to cross them.",
            "You are permitted to cross the lines. However, vehicle traffic from the opposite direction has right of way.",
            "You are permitted to cross the lines because the driver has right of way.",
            "You are forbidden to cross the lines. However, a vehicle from the opposite direction is permitted to cross them when the road is clear.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1096. When is it required to brake only one wheel in a tractor (brake separation)?",
          options: [
            "When the tractor driver wants to make a maneuver in a particularly large radius between structures or crops.",
            "When one wheel slides against the ground and the tractor cannot proceed.",
            "When the tractor driver wants to make a sharp turn on the spot while driving at a high speed.",
            "When the tractor driver wants to make a sharp turn on the spot while driving at a very slow speed.",
          ],
          answer: 3,
          image: "",
          types: ["1"],
        },
        {
          question:
            "1337. When is it obligatory to operate a flashing yellow lamp above the driver’s compartment of a truck?",
          options: [
            "When the freight’s width is more than 3 meters.",
            "When the freight’s width is three meters or less.",
            "When carrying disposable equipment.",
            "When the freight’s width is more than 2.80 meters.",
          ],
          answer: 0,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0379. According to the law, what is the validity period of demerit points incurred by a driver?",
          options: [
            "When the driver has up to 20 demerit points: Two years from the date of the offence.",
            "When the driver has less than 12 demerit points: Six years from the date of the offence.",
            "There is no determined period of time. The point-accumulation period is not mentioned in the regulations.",
            "It is unlimited. According to the new “point system”, accumulated demerit points are not erased.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0652. What does it mean when a yellow arrow pointing straight ahead is marked on the road?",
          options: [
            "All vehicles are permitted to drive straight, but right-of-way should be given to buses.",
            "Every vehicle carrying at least six passengers is permitted to continue straight forward.",
            "Only public vehicles are permitted to continue driving straight ahead at the coming intersection.",
            "Private vehicles are permitted to carefully drive straight.",
          ],
          answer: 2,
          image: "img/quiz/3652.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1714. Who is responsible for a vehicle emitting smoke above statutory levels?",
          options: [
            "The vehicle’s treating garage.",
            "The driver and the vehicle’s manufacturer.",
            "The vehicle’s driver.",
            "The owner of the gas station that sold the fuel.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0688. Wheels that are installed on the same axle must be:",
          options: [
            "Of the same manufacture date always.",
            "Of the same size and in compliance with the manufacturer’s manual.",
            "Of the same color.",
            "Of the same manufacturer.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1240. How will a bus driver conduct himself when passengers start fighting?",
          options: [
            "Drive the bus to the nearest police station or turn to a policeman along the way.",
            "Stop the bus and clear away from it.",
            "Ignore it and continue driving.",
            "Drive back to the station of origin.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question: "0556. What is the meaning of the following traffic sign?",
          options: [
            "Road for motor vehicles only.",
            "You have right of way in the road that crosses.",
            "No through road (cul-de-sac) to your right.",
            "Special entrance to schools or kindergartens.",
          ],
          answer: 2,
          image: "img/quiz/3556.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1047. What danger might be caused due to low air pressure in one of the front wheels?",
          options: [
            "The steering wheel might be pulled to the direction of the wheel with the higher air pressure.",
            "There is no danger, as long as the pressure level is at least half of the required pressure.",
            "There is no risk. The air pressure in the front wheels is of no importance.",
            "The steering wheel might be pulled to the direction of the wheel with the lower air pressure.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "В"],
        },
        {
          question: "1032. What is the permitted tyre size for a vehicle?",
          options: [
            "As determined by the tire manufacturer.",
            "Any size of tire, as long as all the vehicle tires are of the same size.",
            "As approved by a licensed garage.",
            "As determined by the manufacturer and noted in the vehicle’s registration document.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "A", "В"],
        },
        {
          question: "1415. What is an “interchangeable crate?",
          options: [
            "A box or fixed loading platform designed for freight loading and unloading.",
            "crate, box or any other means for carrying goods, designed to be loaded on or off the vehicle with a permanent crane",
            "A wooden box designed to transport citrus fruit in bulk, which can be replaced by other boxes.",
            "A transport fixture for fluids that can be taken off the vehicle’s chassis and be left with or without freight.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1063. When is it required to operate the parking (hand) brake whilst parking?",
          options: [
            "Only when the vehicle is parked on a steep descent.",
            "Always.",
            "Only when the vehicle is parked uphill.",
            "Only on a wet road.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "1", "В"],
        },
        {
          question:
            "1463. What warning measures are installed in the driver’s compartment of a vehicle with a pneumatic braking system (air pressure)?",
          options: [
            "Warning lamp, warning buzzer, and air pressure gauge.",
            "Red warning lamp in the middle of the dashboard.",
            "Warning lamp and emergency braking handle.",
            "Warning lamp and independent brake.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question:
            "0201. Is it permitted to stand or park a vehicle on the sidewalk (pavement)?",
          options: [
            "No, except in a regulated place marked with a sign..",
            "It is absolutely forbidden.",
            "Yes, it is permitted on an interlocking-stone-surface sidewalk.",
            "Yes, it is permitted on a sidewalk with a minimum width of 3 meters.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "A", "В"],
        },
        {
          question:
            "0110. The following illustration shows an intersection with traffic signs. What is the correct manner of making a turn from street A to street C?",
          options: [
            "From lane 2 to lane 3.",
            "From lane 2 or from lane 1 to lane 3.",
            "From lane 2 to lane 3 or to lane 4.",
            "From lane 2 or from lane 1 to lane 3 or to lane 4.",
          ],
          answer: 0,
          image: "img/quiz/3110.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0467. What are you required to do according to the following road sign?",
          options: [
            "Drive fast in the right lane.",
            "Slow down gently and refrain from sudden braking or sharp turns.",
            "Shift into high gear.",
            "Drive in the right lane and overtake quickly.",
          ],
          answer: 1,
          image: "img/quiz/3466.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1720. Is it permitted to drive under the influence of sedatives?",
          options: [
            "Absolutely not, unless it is indicated otherwise in the medication’s prescription.",
            "Yes, sedatives have no affect on driving.",
            "It is permitted to take sedation pills half an hour before driving.",
            "Only if the driver tends to be influenced by a certain medication.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0171. When is it permitted to use the horn for giving a warning signal in an urban road?",
          options: [
            "It is only allowed to use an air horn.",
            "It is always allowed to use the horn, provided that the signal is given in a continuous and uniform manner.",
            "It is only allowed to use the horn in order to avoid imminent danger that cannot be prevented in any other way.",
            "It is always allowed to use the horn in an urban road.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1601. What is the problem in detecting an obstacle before a motorcycle at a very late stage?",
          options: [
            "The time left for responding and braking is too short.",
            "The obstacle’s weight can not be precisely evaluated.",
            "There is no problem in late detection of an obstacle.",
            "There is not enough time to change gears.",
          ],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1549. Who should verify that a bus is equipped with a complete set of first aid equipment?",
          options: [
            "The owner of the bus.",
            "The bus driver is obliged to verify that the equipment is present, but only when the bus comes out of the garage for the first time after being repaired.",
            "The bus driver must verify that this equipment is always on the bus.",
            "The manager of the company’s garage has to verify that this equipment is present on the bus at all times.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1013. What is the significance of the head rests on the vehicle’s seats during an accident from behind?",
          options: [
            "They can prevent neck and back pains during long drives.",
            "They reduce forward and backward head jolting (whiplash).",
            "They can reduce and prevent passenger knee injuries during frontal collision.",
            "They can reduce and prevent passenger leg injuries during vehicle roll-over.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0748. What is the advantage of driving on the right side of the road?",
          options: [
            "You are not preventing other vehicles from overtaking",
            "There is no advantage in keeping to the right side while driving.",
            "It is easier to see the incoming traffic from your opposite direction.",
            "It allows an increased response time while overtaking.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0733. What is the order of actions the driver needs to take before slowing down?",
          options: [
            "Step firmly on the brake pedal, to inform the driver behind you began to slow down.",
            "Give a hand signal to the driver behind, glance at the mirror to see his response and slow down.",
            "Turn your head backwards, step on the brake pedal to slow down and finally take a quick look at the mirrors.",
            "Glance at the mirrors, ease off the accelerator pedal and use the brakes or gears.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1239. Is a taxi driver permitted to smoke while driving?",
          options: [
            "No.",
            "Yes, provided that the air-conditioner is turned off and the windows are open.",
            "Yes, but only if all passengers consent.",
            "Yes - on special rides only.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1697. Why are you required to be more careful while driving after dark?",
          options: [
            "The duty of care only applies under conditions of fog and rain.",
            "The duty of care applies only in case of blinding.",
            "Because visibility (seeing and being seen) is limited.",
            "You are not required to be extra cautious during the dark.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1059. What will be the result of moderate driving, without performing heavy engine accelerations?",
          options: [
            "Savings in the vehicle’s electricity consumption.",
            "Engine fuel consumption savings.",
            "Driving becomes longer than necessary.",
            "Engine oil consumption savings.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question:
            "0929. The following picture contains several road elements. How should a driver respond when approaching these elements?",
          options: [
            "Slow down and keep to the right.",
            "He should make a warning honk, continue driving in normal speed, glance at the mirrors and then drive faster to quickly cross the curve.",
            "Approaching a curve in high speed is dangerous on a rough road only.",
            "In the absence of a road sign warning about an incoming curve, there is no need to slow down. He should continue driving at normal speed.",
          ],
          answer: 0,
          image: "img/quiz/3929.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1679. How would you keep your vehicle balanced when approaching a curve in the roadway?",
          options: [
            "You should slow down before entering the curve, and accelerate when coming out of it.",
            "You should shift into high gear.",
            "You should shift into neutral gear.",
            "You should drive faster before entering the curve.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0300. What does the law state as regards to a vehicle driver refusing to undergo a Breathalyzer test at the request of a policeman?",
          options: [
            "He shall not be allowed to continue holding a driver’s license without performing an alcohol test.",
            "Actual revocation of his driver’s license for a period of at least five years.",
            "Actual revocation of his driver’s license for a period of at least two years and a permanent suspended revocation.",
            "He shall be considered a drunk driver.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0736. What is the first thing a driver should verify before driving an unfamiliar vehicle?",
          options: [
            "The identity of the vehicle’s owner and that the vehicle is not stolen.",
            "That he is proficient in operating and using the controls of the vehicle.",
            "That there are work tools, proper spare (reserve) tire, warning triangle and air compressor.",
            "That the vehicle had gone through proper servicing on time.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1243. What type of vehicle is obliged to have its inside lights turned-on during “light time”?",
          options: [
            "Taxis.",
            "None: Inside lights in vehicles should not be turned-on. The light reflects in the front windshield and disturbs the driver.",
            "Any commercial vehicle transporting passengers for hire.",
            "Buses.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1766. It is prohibited to stop, park or stand a vehicle on or before a pedestrian crossing, within a distance of:",
          options: [
            "20 meters from the crossing.",
            "15 meters from the crossing.",
            "12 meters from the crossing.",
            "24 meters from the crossing.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0191. What would you do if your driving lane is obstructed?",
          options: [
            "Quickly cross the obstruction from either its right or left.",
            "Drive slowly and disregard the road signs that are posted in the place.",
            "Cross the obstruction from the right only, and vehicles approaching from your front are supposed to give you right-of-way.",
            "Obey the signals that are given by a policeman, a signaler or a road sign.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1086. How is a driver of a tractor equipped with a shuvelloader required to conduct while parking?",
          options: [
            "The loader’s position is not important.",
            "Leave the loader at the height of the steering wheel in order to balance the tractor.",
            "Lower the loader to the ground.",
            "Leave the loader elevated to avoid bumping with it.",
          ],
          answer: 2,
          image: "",
          types: ["1"],
        },
        {
          question:
            "1695. What should you do to reduce the effect of dazzle (blinding) during the dark?",
          options: [
            "Pull down the sunshade.",
            "Keep the windshield clean.",
            "Blind back the driver approaching from the opposite direction.",
            "Wear dark sunglasses.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1480. When is a vehicle affected by centrifugal force?",
          options: [
            "While driving in a straight line, even when the road is horizontal.",
            "While driving around curves and turns.",
            "While driving straight in steep uphill climbs.",
            "While driving straight in steep descents.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question: "0647. What is the meaning of the following road marking?",
          options: [
            "Parking line (parking space for vehicles or animals).",
            "Waiting line (in a place where there is no traffic sign).",
            "Vehicle parking-space ahead.",
            "Stop line, a place for stopping a vehicle when it is necessary to do so..",
          ],
          answer: 3,
          image: "img/quiz/3647.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1430. How would we mark a commercial vehicle with an all up weight of more than 3,500 kg that transports at least 11 passengers?",
          options: [
            "This commercial vehicle doesn’t require any special signing.",
            "By placing a “passengers” sign in the front only.",
            "By placing a “passengers” sign in the front and back.",
            "With a “careful, children” sign.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1634. The main factors affecting a motorcyclist’s ability to try and evade objects that fall from a vehicle in front are:",
          options: [
            "The motorcycle’s mechanical condition.",
            "Excessive air pressure in the motorcycle’s tires – double the required pressure,",
            "The proper function of the brakes and tires.",
            "Keeping distance and maintaining an effective field of vision at all times.",
          ],
          answer: 3,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1534. What should a vehicle driver do in case of a strong side wind?",
          options: [
            "Slow down and adjust the driving speed to allow him to control the vehicle – .",
            "He should avoid driving on roads with side winds, or he should otherwise stop on the “hard shoulders” until the wind fades.",
            "Turn on the fog lights and continue driving normally.",
            "Drive faster to avoid the side wind.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question:
            "0307. Can a driver refuse to undergo a Breathalyzer test after being requested to do so by a policeman?",
          options: [
            "Yes, he is allowed to refuse, because the policeman represents the health care system and not the law.",
            "A driver is forbidden to refuse a policeman’s request to undergo a “breath test” or to give a blood/urine/saliva sample for testing.",
            "Yes, he is allowed to refuse, because the test contradicts the Basic Law: Human Dignity and Liberty.",
            "Yes, he is allowed to refuse, because of the Protection of Privacy Law.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0411. What is the meaning of the following traffic sign?",
          options: [
            "Winding road ahead.",
            "Narrow and winding road ahead.",
            "Bend to the right and then bend to the left.",
            "Hairpin curve ahead.",
          ],
          answer: 2,
          image: "img/quiz/3411.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1045. What is the function of the vehicle’s auxiliary (parking/hand) brake?",
          options: [
            "To secure the vehicle from moving or going downhill..",
            "To assist the driver during a “hill start” only.",
            "Its use prevents wear of the service brakes (foot brake)?",
            "To enhance the braking force of the vehicle’s brakes.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1046. When is it required to check the vehicle’s air polluting emissions?",
          options: [
            "Vehicles cause no pollution; hence there is no need to perform a check.",
            "Once a month, in every garage specializing in car electronics only.",
            "Once every half a year, at the treating garage.",
            "In the vehicle’s annual test at a licensing facility.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "1", "В"],
        },
        {
          question:
            "1801. What sign signifies a charging point foe electric vehicles?",
          options: ["ס-130", "ס-31", "ס-131", "ס-55"],
          answer: 0,
          image: "img/quiz/31801.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1591. The license class that is required for riding a 125cc or smaller motorcycle with an engine power of up to 14.6 horse-powers (11 KW) is:",
          options: ["A2.", "B.", "C1.", "D."],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1119. Which of the following types of vehicles is prohibited to cross a railroad?",
          options: [
            "A restricted vehicle with a maximum speed of 40 k.p.h.",
            "A taxi carrying children on a special road trip ride, if there is no asphalt or cement road at a distance of 20 meters before the tracks.",
            "All vehicles carrying, or designed to carry, at least 11 passengers besides the driver, if there is no asphalt or cement road at a distance of 20 meters before the tracks.",
            "A commercial vehicle transporting, or designed to transport, hazardous materials, and vehicles in a length of 18 meters or more.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0612.  A vehicle that is approved for transporting children is:",
          options: [
            "A private bus or a public vehicle that has been licensed by the Ministry of transport .",
            "Any motor vehicle that is driving children.",
            "Any bus listed in a transportation company.",
            "Any public bus on service route.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0270. The license of a “New driver” will not be renewed as a regular driver's license, if he is convicted of:",
          options: [
            "Not stopping where a “give right-of–way” signpost is placed.",
            "Illegal overtaking",
            "Not placing a “new driver” sign on the vehicle’s rear window.",
            "Driving a motor vehicle without valid insurance.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question:
            "0229. Is it permitted to seat more than one passenger alongside of the driver?",
          options: [
            "It is absolutely forbidden.",
            "No, unless it is stated in the vehicle registration.",
            "Yes, if two seatbelts are installed, in addition to the driver’s seatbelt.",
            "Yes, if the front seat is an integrated seat.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "В"],
        },
        {
          question:
            "0364. The license of a “new driver” shall not be renewed as a regular driver's license if he is convicted of the following offence:",
          options: [
            "Not obeying a red light or not giving right of way.",
            "Driving a motor vehicle without valid insurance.",
            "Not stopping by the stop line besides a traffic light.",
            "Not placing a “new driver” sign on the vehicle’s rear window.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0992. What is the function of the ABS system?",
          options: [
            "To prevent tire wear.",
            "To prevent excessive use of the vehicle’s brakes.",
            "To serve as an additional brake in case of danger.",
            "To enable control and steering of the vehicle during braking.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question: "0046. What is a “safety seat”:",
          options: [
            "A seat meeting specified standards installed on two-wheelers and designed for transporting children.",
            "A seat, meeting specified standards, secured to the vehicle with a safety belt and used for transporting children with independent belts.",
            "Any seat that is adjusted for driving children and elderly people and meets the standard which was set by the Ministry of Environmental Protection",
            "A seat which is installed inside a vehicle during its manufacture-stage and is adjusted for driving children with a maximum age of 14 years",
          ],
          answer: 1,
          image: "img/quiz/3046.jpg",
          types: ["C1", "В"],
        },
        {
          question:
            "0705. You are driving vehicle no. 3. According to the rules of giving right of way, in what order should the vehicles enter the intersection which you approach (the red vehicle is not an emergency vehicle)?",
          options: [
            "The red vehicle (2) the vehicle I am driving (3), the cyclist (1).",
            "The cyclist (1), the red vehicle (2), the vehicle I am driving (3).",
            "The vehicle I am driving (3), the red vehicle (2), the cyclist (1).",
            "The vehicle I am driving (3) the cyclist (1), the red vehicle (2).",
          ],
          answer: 2,
          image: "img/quiz/3705.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1413. In which vehicle is it permitted to transport loose ash and bulk cement?",
          options: [
            "Only in a vehicle specially designed for this work",
            "In every commercial vehicle.",
            "In every open vehicle for the transport of bulk powders.",
            "In a special vehicle equipped with a blower.",
          ],
          answer: 0,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0795. Which of the following sentences is correct: The law prohibit drivers from making a left U-turn:",
          options: [
            "When a U-turn prohibiting sign is placed.",
            "Where the vehicle cannot be observed by other drivers approaching from any direction.",
            "All the answers are correct.",
            "When he approaches a steep peak or a curve in the road.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0185. How would you conduct yourself under the situation that is depicted in the following picture?",
          options: [
            "You should not obey the traffic light signals. Follow the road signs only.",
            "Stop before the junction and do not enter into it.",
            "Stop before the junction and then drive through uninterruptedly.",
            "Give right-of-way to the traffic on the crossing road.",
          ],
          answer: 1,
          image: "img/quiz/3185.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0372. Which traffic sign marks the final warning before a level crossing?",
          options: ["128", "133", "131", "132"],
          answer: 1,
          image: "img/quiz/3372.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0596. What are you required to do after passing the following traffic sign, which is placed to your left?",
          options: [
            "Stop before level crossing.",
            "Give right of way to traffic on the crossing road.",
            "Drive normally. You are on a roadway that has right-of-way.",
            "It is the end of the road section that has right of way.",
          ],
          answer: 2,
          image: "img/quiz/3595.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1581. Under what condition is it permitted to drive a motorcycle?",
          options: [
            "Wearing a standard helmet that is secured to the rider’s head and will not fall-off whist driving.",
            "The motorcyclist should have a rescue kit in case of an accident.",
            "The motorcyclist should ensure that the fuel tank is full and secured before beginning to drive.",
            "The rider must wear a helmet, even if it is not secured to the rider’s head.",
          ],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1088. What would happen if the weight exerted on the tractor’s rear hydraulic arms is too high or far away from the tractor?",
          options: [
            "The front wheels would lift in the air.",
            "The pressure and load would cause the tractor’s rear wheels to explode.",
            "The heavy load would damage the hydraulic system.",
            "The tractor chassis might be seriously damaged.",
          ],
          answer: 0,
          image: "",
          types: ["1"],
        },
        {
          question:
            "1230. What is the maximum permitted length of a dual axle bus?",
          options: [
            "There is no length restriction, but the length of its rear extension should not exceed 3.5 meters.",
            "13 meters; and for an articulated bus – 18 meters.",
            "15.5 meters; and for an articulated bus – 18.75 meters.",
            "13.5 meters.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1429. According to the law, is there any significance to the manner in which the freight is arranged on the vehicle?",
          options: [
            "The load on the axles is not significant as long as the overall weight is within permitted levels.",
            "Only the load on the rear axles should not exceed the permitted load according to traffic regulations.",
            "Yes,it should be ensured that the freight is arranged and anchored to the vehicle so it doesn’t fall off.",
            "The load on the axles may exceed the load that was determined by the vehicle’s manufacturer or by regulations by up to 20%.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1756. Following a “level crossing” sign that is placed about a 100 meters before the crossing, how would you conduct yourself when a slow vehicle is driving before you?",
          options: [
            "You are permitted to overtake it.",
            "You are permitted to overtake it only when the road ahead is clear.",
            "You are prohibited from overtaking until after the level crossing.",
            "You are permitted to overtake it only when its speed doesn’t exceed 35 k.p.h.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0655. The rear fog lamp:",
          options: [
            "It is obligatory to install fog lights in buses only.",
            "Is turned-on automatically when it is foggy.",
            "the rear fog lights can only be turned on if the front lights are on.",
            "Is turned-on only while driving in reverse.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question: "0259. When is it permitted to use floodlights?",
          options: [
            "Only when the vehicle stops, provided that they are not directed at another vehicle or along the length of the road.",
            "For security purposes only and during “light time”.",
            "Only by a security vehicle during fence patrol.",
            "It is forbidden to use floodlights on the road, except for fire fighting vehicles.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "0949. What in the conduct of the blue vehicle is improper?",
          options: [
            "The driver of the blue vehicle is not driving in the right-hand lane.",
            "Although it is cloudy the driver has not turned on his lights.",
            "the driver of the blue vehicle has not turned on his left indicator.",
            "The driver in the blue vehicle is not keeping a sfe braking distance",
          ],
          answer: 0,
          image: "img/quiz/3949.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0486. What is the meaning of the following traffic sign?",
          options: [
            "No entry for tractors.",
            "Give right of way to tractors driving in this road section,",
            "Beware! Work vehicles and tractors crossing.",
            "Stop! Only agricultural vehicles are permitted to go through.",
          ],
          answer: 2,
          image: "img/quiz/3486.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0216. Is it permitted to stop any type of vehicle where the following signpost is placed?",
          options: [
            "Yes, during day time only.",
            "Yes, when there are many available parking spaces.",
            "It is forbidden, except for a vehicle with a tag, as stated on the sign.",
            "Any vehicle having a regular handicapped pass is allowed to park there when the spot is available.",
          ],
          answer: 2,
          image: "img/quiz/3216.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0459. What is the meaning of the following traffic sign?",
          options: [
            "You are approaching a train station.",
            "Level-crossing with train traffic in both directions.",
            "Careful! railway barrier.",
            "Level crossing, more than one track.",
          ],
          answer: 3,
          image: "img/quiz/3459.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1297. How should a driver conduct himself when another vehicle suddenly emerges in his driving lane from the opposite direction, heading towards him?",
          options: [
            "Swerve to the left and drive on the opposite lane, which is currently free.",
            "Honk and signal the other driver to get back to his lane.",
            "Slow down, pull as far right as possible and even pull onto the “hard shoulder” if necessary.",
            "Turn on his high beam and signal the other driver to get back to his lane.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0904. The vehicle coming from your opposite direction (1) started to turn to his left. How would you respond?",
          options: [
            "Turn right to avoid collision.",
            "Maintain your current speed and take right of way.",
            "Honk to warn the driver and maintain your driving speed.",
            "Slow down or stop and allow the white vehicle in the intersection to complete the turn..",
          ],
          answer: 3,
          image: "img/quiz/3904.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1345. Which class of license  is required for driving a commercial motor vehicle with an all up weight of less than 12,000 kg?",
          options: ["B.", "A1.", "D.", "C1."],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "0218. What should a driver do after parking his vehicle?",
          options: [
            "Turn off the engine, place a warning triangle and lock the vehicle’s doors.",
            "Engage the parking brake,Turn off the engine, and remove the key from the ignition switch. .",
            "Remove the key from the ignition switch, place a warning triangle and pull the handbrake.",
            "Place the parking tag on the left side of the windshield, in a place that is clearly visible to the municipal inspector.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "0963. On which type of road is a private passenger car permitted to drive at the speed of 110 k.p.?",
          options: [
            "A freeway(motorway)",
            "A non-urban road.",
            "An urban road with a built dividing area.",
            "A non-urban road with a built dividing area.",
          ],
          answer: 0,
          image: "img/quiz/3963.jpg",
          types: ["C1", "В"],
        },
        {
          question:
            "0114. The following illustration shows an intersection with traffic signs. What is the correct manner of making a turn from street A to street C?",
          options: [
            "From lane 1 to lane 3.",
            "From lane 2 to lane 3.",
            "From lane 2 to lane 5.",
            "From lanes 1 or 2 to lane 3 or to lane 4.",
          ],
          answer: 0,
          image: "img/quiz/3114.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0857. Can a short refreshment break whilst driving lessen the driver’s tiredness?",
          options: [
            "Yes, after a break of a few minutes you can drive continuously for many hours.",
            "Yes, a short break would drive the tiredness away completely.",
            "No, a short break is an ineffective way to fight tiredness.",
            "Yes, for a short time.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1506. Where would you load heavy freight on a truck’s freight platform?",
          options: [
            "The positioning of the heavy freight is non-significant.",
            "From the rear axle to the direction of the driver’s compartment.",
            "In the front part of the freight trunk.",
            "Behind the rear axle.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "1774. When is it permitted to turn on the fog lights?",
          options: [
            "Only in the dark.",
            "Only in a condition of pouring rain, fog or snow.",
            "Only during the winter.",
            "Whenever the driver deems it fit.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0779. What is the first action taken by drivers before crossing an intersection?",
          options: [
            "Checking and identifying the lanes after crossing the intersection, mainly in our direction of driving.",
            "Looking beyond the intersection to identify the entry lanes to the opposite directions and the traffic in them",
            "Making sure that the intersection is clear and that it can be crossed safely.",
            "Entering the intersection.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0893. A driver approaches a pedestrian crossing marked on the road.Although ther are no pedestrians on the crossing. The driver should,",
          options: [
            "Slow down to ensure that there no pedestrians in the vicinity.",
            "Pass with caution but no need to slow down.",
            "Accelerate before pedestrians arrive",
            "Sound the horn and accelerate to clear the crossing",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0536. What does it mean when a “stop” sign is placed on the left side of the roadway only?",
          options: [
            "That it only applies to those turning left or making a u-turn. To the left",
            "Stop and give right-of-way to incoming traffic from your opposite direction only.",
            "Stop and give right of way to traffic coming from your left only.",
            "Stop and give right of way to traffic coming from your right only.",
          ],
          answer: 0,
          image: "img/quiz/3184.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0064. Is it obligatory to obey the directions and signals of a military policeman in uniform?",
          options: [
            "No, only for the purpose of stopping a vehicle",
            "Yes, equally to a civil policeman",
            "No, only as regards to the presentation of a driver’s license",
            "Usually not",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1477. Which of these traffic signs permits bicycle entry?",
          options: ["219", "227", "412", "414"],
          answer: 1,
          image: "img/quiz/31477.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1620. How would you, while driving a motorcycle, evade an obstacle – such as an object that is laid on the road – that suddenly appears in front of you?",
          options: [
            "Brake before the obstacle and while on it. This way you ensure maximum braking time.",
            "Substantially accelerate the motorcycle to maintain balance.",
            "Brake before the obstacle and then ease-off the brakes just before it and evade it.",
            "Drive faster and pass the obstacle without any delay.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1140. Which of the following items must be placed in a passenger bus on city route?",
          options: [
            "A first aid bag and a stretcher.",
            "First aid kit",
            "A first aid bag and a rescue kit.",
            "A stretcher and a drinking dispenser.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question: "0413. What is the meaning of the following traffic sign?",
          options: [
            "Dangerous descent ahead.",
            "Right curve followed by left curve ahead.",
            "Winding road.",
            "Instruction sign – hairpin curve.",
          ],
          answer: 2,
          image: "img/quiz/3413.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0258. Is a vehicle driver allowed to exceed the speed limit while overtaking?",
          options: [
            "Yes, in a non-urban road only.",
            "No.",
            "Yes, in a freeway only.",
            "Yes, when overtaking a motorcycle.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0672. What are you required to do when the following traffic light is placed (and is flashing)?",
          options: [
            "Stop and do not continue driving until the blinking yellow light turns off.",
            "Proceed carefully and allow pedestrians to cross the road safely.",
            "Ignore it. It is intended for pedestrians only.",
            "Always stop before a pedestrian crossing, even if no one is crossing.",
          ],
          answer: 1,
          image: "img/quiz/3672.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1775. Who has the authority to revoke a driving license?",
          options: [
            "A court of law, the Israel Police, the Road Safety Medical Institute.",
            "A court of law and the Israel Police only.",
            "The Licensing Authority, a court of law, the Israel Police.",
            "The Licensing Authority and a court of law only.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1275. What should a bus driver check after the passengers boarded the bus and before starting to drive?",
          options: [
            "That all the passengers paid the fare in full.",
            "That all the passengers are seated.",
            "That the fare is paid in full during the ride.",
            "That the bus doors are closed.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0979. Are the vehicles within the sharp curve positioned correctly?",
          options: [
            "Yes, all vehicles are keeping to the right as required in this sort of situation.",
            "Both vehicles are not keeping eye contact between them as required.",
            "The wide side-distance enables a third vehicle to enter between them.",
            "You should enter a sharp curve when you are close to the middle of the roadway.",
          ],
          answer: 0,
          image: "img/quiz/3979.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0329. Which class of license is required to drive a motor vehicle with an overall permissible weight of up to 3,500kg?",
          options: ["1", "A.", "B.", "A1."],
          answer: 2,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1546. What is the duty of a driver after sensing that some of the freight he is carrying had fallen-onto the roadway",
          options: [
            "Inform about it to the police. The municipality or the Public Works Department is responsible to clear it away.",
            "Inform the insurance company.",
            "Inform the police and the insurance company.",
            "Stop immediately, and make sure that the falling freight is cleared away immediately.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "В"],
        },
        {
          question:
            "0796. According to the following picture, is it permitted to make a U-turn?",
          options: [
            "Yes, when the turn can be completed in one continuous movement.",
            "Yes, from the left side of the road.",
            "No.",
            "Yes, provided that the turn is made carefully and without causing any traffic disturbance.",
          ],
          answer: 2,
          image: "img/quiz/3796.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0070. Can an owner of a valid driver’s license for a particular group of vehicle drive any vehicle of the same group?",
          options: [
            "Yes, provided that the driver can operate and use it proficiently",
            "Yes, holder of a valid driver’s license can drive any vehicle, unconditionally",
            "Yes, provided that he holds a police certificate, testifying that he can operate the vehicle proficiently",
            "Yes, provided that he had not been found guilty of any traffic offence",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0234. According to the traffic sign that is placed at the bus station, parking at the bus station is permitted on Saturdays and holidays:",
          options: [
            "For private vehicles only.",
            "For motorcycles only.",
            "For slow vehicles only.",
            "For commercial vehicles only.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question:
            "0957. Who is obliged to obey the “stop” sign in the following picture?",
          options: [
            "A driver wanting to make a left U-turn.",
            "A driver wanting to turn left only.",
            "A driver wanting to continue driving straight.",
            "Any driver approaching an intersection.",
          ],
          answer: 1,
          image: "img/quiz/3957.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0969. The white vehicle’s driver is merging into traffic. how would you behave?",
          options: [
            "Honk to the driver of the parked vehicle.",
            "Slow down",
            "Drive faster.",
            "Signal and move to the left lane.",
          ],
          answer: 1,
          image: "img/quiz/3968.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0552. What is the meaning of the following traffic sign?",
          options: [
            "You may pass the marked place from either its right or left.",
            "Pass the place that is marked with a signpost from its right.",
            "No right or left turn.",
            "It is prohibited to drive forward.",
          ],
          answer: 0,
          image: "img/quiz/3552.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1407. Is a policeman permitted to instruct the driver of a freight carrying vehicle to bring the vehicle to a weighing station?",
          options: [
            "Yes, but only if the driver doesn’t have a bill of lading.",
            "Yes, a policeman is permitted to instruct the driver of a freight carrying vehicle to bring the vehicle for weighing at the closest possible place.",
            "Yes, provided that the weighing station is less than 3km away.",
            "Yes, provided that the instructions are of a traffic policeman.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0743. In order to shift gears from parking (P) to Drive (R or D) in an automatic transmission vehicle, you should:",
          options: [
            "Step on the brake pedal, press on the selector lock button, and apply the gear selector to Drive.",
            "Step on the brake pedal and the gear will be shifted automatically.",
            "Press on the immobilizer (operating button) and the gear will be shifted automatically.",
            "Step on the clutch and shift the gear handle.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "0772. In order to overtake safely, the overtaking driver should:",
          options: [
            "Start overtaking only when the road is clear in a sufficient distance, and verify while overtaking that the road is still clear.",
            "Overtake only when a sign permitting overtaking is placed.",
            "Overtake in a speed that exceeds speed limit, in order to clear the road way.",
            "Always overtake at a slow speed and with patience.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1423. How would we mark a vehicle that escorts a vehicle carrying oversize freight?",
          options: [
            "With a “careful, oversize freight” sign on the front of the escorting vehicle.",
            "The escorting vehicle will turn-on its four winkers while driving, and place a “careful oversize freight” on its roof.",
            "With “caution oversize freight” signs on the escorting vehicle’s front and on the back of the freight-carrying vehicle, and by mounting a flashing yellow light above the driver’s compartment.",
            "With a flickering light and a “careful, oversize freight” sign on the front of the escorting vehicle.",
          ],
          answer: 2,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0980. In your opinion, is the white vehicle within the curve positioned correctly?",
          options: [
            "No, the vehicle is close to the middle of the roadway and it endangers vehicles coming from the opposite direction.",
            "In sharp curves it is necessary to “cut” through the curve, in order to reduce the centrifugal force (the force that acts on the vehicle while it turns).",
            "When there is no other vehicle in the curve it is possible to be positioned anywhere within it.",
            "When driving on a sharp curve, it is required to stay in it as shortly as possible; hence his position is correct.",
          ],
          answer: 0,
          image: "img/quiz/3980.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0550. What is the meaning of the following traffic sign?",
          options: [
            "In the intersection ahead only left U-turn is permitted.",
            "Give right-of-way to traffic crossing your path within the roundabout and pass the roundabout from its right side.",
            "You have right-of-way in the roundabout, and pass the roundabout from its right.",
            "Pass the place that is marked in the signpost from its left only.",
          ],
          answer: 1,
          image: "img/quiz/3550.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1569. Which sign warns of the presence of wild animals ahead?",
          options: ["146.", "411.", "414.", "635."],
          answer: 0,
          image: "img/quiz/31569.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0029. Define an “axle” of a vehicle:",
          options: [
            "The vehicle’s back beam to which the vehicle’s spare wheel is connected.",
            "A shaft which transmits the rotary motion from the gearbox to the engine.",
            "Shaft or system of shafts running the width of the vehicle attached to the wheels.",
            "The vehicle’s front part, designed to absorb the impact of low-speed collisions",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question: '1475. What is the legal definition of a "-trailer"?',
          options: [
            "A motorized trailer with a frontal part that rests on a towing vehicle.",
            "A motor vehicle that is designed to be towed by another vehicle.",
            "A trailer that has its front part resting on the support surface behind a cab.",
            "A trailer that has its rear part supported by a cab.",
          ],
          answer: 2,
          image: "",
          types: ["C"],
        },
        {
          question: "0606. The shape of warning signs are:",
          options: [
            "Usually triangular with its top pointing upwards.",
            "Almost always round.",
            "Square or rectangle.",
            "Usually triangular with its top pointing downwards.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1432. At what minimal distance from a residential building or a public structure is it permitted to park a vehicle transporting hazardous materials?",
          options: [
            "400 meters.",
            "300 meters when the vehicle is loaded; 50 meters when the vehicle is empty.",
            "At least 50 meters.",
            "200 meters.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0075. Is it permitted to take one hand off the wheel or handlebar while driving?",
          options: [
            "Yes, only for tuning the vehicle’s radio or operating the lighter",
            "No, except for changing gears and operating the direction indicator, or in order to assure the vehicle's proper function.",
            "Not for any reason",
            "Yes, for using a cellular phone",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1157. What should be displayed on the taxi’s meter when the taxi is available to carry passengers?",
          options: [
            "Its light should be turned on and it should read “Available”.",
            "Its light should be extinguished and it should read “Occupied”.",
            "Its light should be turned on and it should read “Occupied”.",
            "Its light should be extinguished and it should read “Available”.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0425. Is it determined by the following road sign which rules for giving right-of-way should be applied?",
          options: [
            "Yes, when the following road sign is placed in an intersection, right-of-way is given to vehicles that continue driving straight only.",
            "Yes, when the following road sign is placed in an intersection, right-of-way is given to public vehicles only.",
            "No, right-of-way rules are determined according to the traffic load.",
            "No, right-of-way is determined according to lawful right-of-way rules or according to the traffic signs that are placed in the junction.",
          ],
          answer: 3,
          image: "img/quiz/3423.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0744. When driving on a narrow downhill road, you should give right of way to vehicles driving uphill in the following instances:",
          options: [
            "Only on an urban road.",
            "Only if a dangerous descent sign is placed.",
            "When the road section is too narrow to allow both vehicles to pass each other at the same time.",
            "Only if the weight of the vehicle driving uphill is more than four tons.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1735. When approaching a junction with no traffic signs and you wish to turn right, whilst another vehicle approaches the junction from your opposite direction, you should:",
          options: [
            "Continue driving carefully.",
            "Stop and give right of way to the approaching vehicle.",
            "Slow down and, if needed, stop and give right of way.",
            "In any case, stop.",
          ],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1267. Is a private minibus weighing 2700kiols  permitted to park within the limits of the following road sign?",
          options: [
            "Only on weekends.",
            "Yes, but only on weekdays from 21:00 until 05:30.",
            "No.",
            "Yes.",
          ],
          answer: 2,
          image: "img/quiz/3627.jpg",
          types: ["D"],
        },
        {
          question: "0030. Who is a “road user”?",
          options: [
            "A person who uses the road for standing or running only",
            "A person who uses the road for riding a motor vehicle only",
            "A person who uses the road for bicycle riding only",
            "A person who uses the road for driving, traveling, riding, walking, standing or any other purpose",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0539. What is the meaning of the following traffic sign?",
          options: [
            "Drive lright Turn after the sign.",
            "Winding road.",
            "Left turn followed by a right turn.",
            "Drive to the right Turn before the sign.",
          ],
          answer: 3,
          image: "img/quiz/3539.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1749. What is the certified “authority” for issuing bus operation permits?",
          options: [
            "The district licensing authority.",
            "The Israel Police Commissioner.",
            "The Director of the Vehicle Department in the Ministry of Transportation.",
            "The Inspector of Transport.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0123. From which side are we permitted to overtake another vehicle?",
          options: [
            "It is permitted from either its right or left side.",
            "From its right side, provided that we maintain reasonable speed.",
            "Only from its right side.",
            "Only from its left side.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1642. Is a motorcyclist permitted to drive on a marked pedestrian path?",
          options: [
            "No..",
            "Yes, if the path leads to a vehicle parking lot.",
            "No, unless the roadway next to the path is blocked by vehicles.",
            "Yes, if there are no \\r\\nPedestrians on the path.",
          ],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0155. What must a driver do when approaching a railway crossing?",
          options: [
            "Continue driving normally. You don’t have to slow down.",
            "Stop, open the vehicle’s windows and listen",
            "Stop at a distance of at least one meter before the railway.",
            "Slow down to a speed which will allow him to stop before the crossing.if necessary.",
          ],
          answer: 3,
          image: "img/quiz/3155.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1703. Which driver  related ability is impaired during fog?",
          options: ["Vehicle acceleration.", "Braking.", "Steering.", "Vision"],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0770. How can a driver handle the many tasks he needs to perform when approaching an intersection?",
          options: [
            "Warn other road users that he is approaching the intersection (by honking and signaling).",
            "Ignore other road users; especially those smaller than him.",
            "Drive faster in order to cross the intersection more quickly.",
            "Slow down before the intersection, look around, and avoid engaging in any activity other than driving.",
          ],
          answer: 3,
          image: "img/quiz/3770.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0669. What is the meaning of the green arrow in the traffic light?",
          options: [
            "Keep driving straight or right only.",
            "You may continue driving in the direction of the arrow only.",
            "Only public vehicles are permitted to turn in the direction of the arrow.",
            "Cross the intersection and clear it quickly, in every direction you want to go.",
          ],
          answer: 1,
          image: "img/quiz/3669.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0948. What is the problem in the following picture?",
          options: [
            "The bus cannot enter the bay and will disturb traffic when stopping.",
            "The presence of the florist in the station makes the passengers’ stay in it more pleasant and it causes no problem.",
            "The presence of the florist causes the bus to linger too much time in the station.",
            "The flowers placed at the station block the view of the passengers waiting in it.",
          ],
          answer: 0,
          image: "img/quiz/3948.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0332. When does the law require a driver to “reduce the speed of a vehicle”?",
          options: [
            "When the driver approaches speed limit.",
            "Before completing an overtaking maneuver in a two-way road.",
            "Reduction of speed is required when there is imminent danger to life or property.",
            "When the driver is tired.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0260. When is it permitted to turn on the “fog lights”?",
          options: [
            "Only while driving freeways.",
            "Only when it is raining.",
            "Only while driving in reverse.",
            "In case of fog, heavy rain and snow only.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "0211. When is it permitted to stop a vehicle on a bridge or inside a tunnel?",
          options: [
            "It is permitted when the roadway has two lanes or more.",
            "It is permitted during “light time” only.",
            "During day time only.",
            "It is absolutely forbidden.",
          ],
          answer: 3,
          image: "img/quiz/3211.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1487. Is a driver permitted to drive over the speed limit while overtaking?",
          options: [
            "Yes, when it is required in order to shorten the overtaking time.",
            "No.",
            "Yes, but only on a non-urban road.",
            "Yes, but only on a multi-lane road and for a short time.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question:
            "0778. Which of the following sentences is correct, as regards to the behavior of pedestrian near an intersection:",
          options: [
            "When pedestrians are using a pedestrian crossing, they are not obliged to take account of drivers approaching the intersection.",
            "Pedestrians are at no risk near an intersection.",
            "An intersection is the most dangerous place on the roadway, as drivers take no sufficient note of pedestrians but only of traffic lights.",
            "The safest place for pedestrians to cross is the pedestrian crossing next to the intersection, because drivers are obliged to slow down in the area of the intersection.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question:
            '0091. the holder 0f a "B" driving license  is entitled to drive',
          options: [
            "any licensed invalid carriage with an all up weight of 4000klg",
            "a licensed invalid carriage with an all-up weight of 2500klg",
            "any vehicle with an all-up weight of 5000klg",
            "any invalid carriage with no weight restriction.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "В"],
        },
        {
          question: "1160. Who is exempt from paying taxi fare?",
          options: [
            "Every three children pay one ticket.",
            "One child under 5 years that is accompanied by an adult.",
            "Every child under 5 years.",
            "No one. The exemption is only relevant to bus rides.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0777. Which of the following vehicles are permitted to drive on the road’s “hard shoulders”?",
          options: [
            "Only taxis, in order to bypass vehicles that stand in an intersection.",
            "No one is allowed, in to use the “hard shoulders”.",
            "Non-motor vehicles, tractors and speed-restricted vehicles.",
            "Only private vehicles and motorcycles are permitted to drive on the “hard shoulder”, and only in order to give right-of-way to buses.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0395. Which of the following road signs prohibits vehicles to make a left-turn into a gas station?",
          options: ["431", "428", "205", "429"],
          answer: 3,
          image: "img/quiz/3395.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0407. What is the meaning of the following traffic sign?",
          options: [
            "Mountain road.",
            "Road-works ahead.",
            "Uneven road surface",
            "Speed bumps ahead.",
          ],
          answer: 2,
          image: "img/quiz/3407.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1621. What maintenance work is required on a motorcycle?",
          options: [
            "Motorcycle periodic treatment is only required before its annual test.",
            "In a new motorcycle there is no need to perform regular treatments.",
            "A periodic treatment according to the instructions of the garage manager. He knows the motorcycle best.",
            "A periodic service according to the manufacturer’s manual.",
          ],
          answer: 3,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1023. What is the implication of driving a vehicle with a broken headlamp lens?",
          options: [
            "The light beam is not properly focused and other drivers are blinded by it.",
            "Over-cooling of the lamp bulb.",
            "Accelerated emptying of the battery.",
            "Self-blinding of the driver.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question:
            "0940. What conduct can be expected from the vehicles in the following picture?",
          options: [
            "To slow down at the entry to the sharp curve and keep driving on the far right of the roadway.",
            "Only the dark vehicle is obliged to slow down before entering the curve.",
            "The white vehicle shall stop on the right side of the road and give right of way to the dark vehicle.",
            "The dark vehicle shall stop on the right side of the road and allow the white vehicle to proceed.",
          ],
          answer: 0,
          image: "img/quiz/3940.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0839. What are the characteristics of a mountain road?",
          options: [
            "Many pedestrians and animals.",
            "Wide and unmarked roadways.",
            "Mountain roads are never paved.",
            "Narrow and winding roadway, sharp curves, bends and sudden slopes.",
          ],
          answer: 3,
          image: "img/quiz/3839.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0016. Define “one way street”",
          options: [
            "Any path in which vehicle traffic is allowed in one direction only",
            "Any roadway in which vehicle traffic is allowed in one direction only",
            "Any roadway in which only motor vehicle traffic is allowed in one direction only",
            "Any roadway in which vehicle traffic is allowed in both directions",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1369. The law requires to install a third license plates in these types of vehicles:",
          options: [
            "Every commercial vehicle with an all up weight of up to 3,500 kg.",
            "Every commercial vehicle with an all up weight of up to 7,500 kg.",
            "Trucks with reclining trailers.",
            "Every commercial vehicle and truck tractors.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "0762. What is the correct way for changing lanes?",
          options: [
            "Lane change should be done in two stages: First deviating and then signaling with the hand.",
            "Lane change should be done in two stages: First deviating and then communicating with the other drivers.",
            "You should pass to the other lane through the shortest diagonal distance and as quickly as possible.",
            "First perform a check and glance at the mirrors, then check the lane you want to pass to, verify that you can pass to it and then deviate moderately (diagonally) to the other lane.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1136. Which norms of conduct are required from bus drivers and conductors?",
          options: [
            "They must behave politely and courteously.",
            "They should assist babies and old people and ensure they have a place to sit.",
            "They should speak quietly.",
            "They should behave according to the accepted social rules of courtesy in their place of residence.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question: "0418. What is the meaning of the following traffic sign?",
          options: [
            "Tunnel entrance.",
            "Barrier ahead.",
            "Vehicle height restriction. No entry for a vehicle that exceeds, with its cargo, the height that is indicated in the sign.",
            "Narrow and low roadway.",
          ],
          answer: 2,
          image: "img/quiz/3418.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1205. One of the equipment requirements for a School bus   is:",
          options: [
            "The installation of flickering lights that must flicker during the entire course of a school-children transportation ride.",
            "The installation of flickering lights, operated whenever the emergency lights are on.",
            "The installation of special flashing lights in the front and back, operated when the doors open.",
            "The installation of flickering lights, operated only when the bus stops.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0668. What are you required to do when a traffic light displays a flashing yellow light?",
          options: [
            "Ignore the traffic light and keep driving. Everything is ok.",
            "Proceed carefully and drive according to the traffic signs placed at the intersection.",
            "Stop inside the intersection and wait for the green light.",
            "Stop at the customary stopping place and wait for the green light.",
          ],
          answer: 1,
          image: "img/quiz/3186.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1691. Wearing a seat belt as required:",
          options: [
            "Keeps the driver in an upright sitting position;",
            "Reduces the extent of injury during an accident.",
            "Keeps the driver alert.",
            "Reduces the driver’s movement in his seat and prevents accidents.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1158. Is it permitted to solicit a passenger to get on a taxi at the Ben Gurion Airport?",
          options: [
            "Only during daytime.",
            "Only at night, by calling.",
            "No.",
            "Only by signaling.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0314. “Drinking a large quantity of alcohol disrupts the driver’s peripheral vision and field of vision”: Correct or incorrect?",
          options: [
            "Incorrect.",
            "Correct.",
            "Correct, but it depends on the type of vehicle driven.",
            "It is correct for young drivers only.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0849. How can we moderate a situation in which our vehicle skids on the road?",
          options: [
            "Through correct speed ,steering and a correct and early use of the brakes.",
            "By strongly turning the wheel left and right, to stabilize the vehicle.",
            "By holding the steering wheel firmly with both hands.",
            "By stepping firmly and continuously on the brake pedal.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0401. The purpose of information road signs is to:",
          options: [
            "Instruct vehicle drivers what to do.",
            "Provide information about places and directions.",
            "Determine prohibitions.",
            "Alert passersby about road hazards ahead.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0143. In which cases are you obliged to slow down?",
          options: [
            "You are only obliged to slow down in case of busy traffic.",
            "When encountering or overtaking an animal.",
            "There is no obligation to slow down. There is an obligation to drive carefully.",
            "You are obliged to slow down in an urban road only.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1394. What is the responsibility of the controller of the vehicle as regards to the work and rest hours of the vehicle’s driver?",
          options: [
            "The controller of the vehicle must not permit the vehicle’s driver to exceed statutory work hours.",
            "The controller of the vehicle is not responsible for the work and rest hours of the vehicle’s driver. The driver is solely responsible for it.",
            "The controller of the vehicle may, under the approval of the safety officer, instruct the driver to exceed statutory driving hours.",
            "The controller of the vehicle is responsible, yet the safety officer of a vehicle fleet may adjust the work and rest hours to meet the requirements, regardless of the law.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question:
            "1144. How many glass breaking hammers must be placed on a bus?",
          options: [
            "Four hammers.",
            "One hammer must be placed on the frame-head to the left of each door.",
            "It varies according to the size of the bus.",
            "At least two.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0322. How many passengers are permitted to travel in a commercial vehicle with an overall permissible weight of more than 3,500kg?",
          options: [
            "According to the specifications of the driver’s license.",
            "Only one passenger, sitting besides the driver.",
            "According to the number of seats in the driver’s compartment only.",
            "Up to 6 passengers, not including those sitting besides the driver.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0818. What characterizes a state of driving in which proper distance from the vehicle in front is kept?",
          options: [
            "Driving that requires constant distance adjustments, according to the road’s conditions, and which is exhausting for the driver.",
            "Less stressed and relaxed driving, which doesn’t cause tiredness, and prevents risks.",
            "Keeping distance has no effect on the character of driving.",
            "A state of driving which encourages other drivers to enter into the distance which is kept in front.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1244. Is a bus driver permitted to smoke while driving?",
          options: [
            "No, except on touring rides only.",
            "No, except on a touring ride, when the passengers are foreign citizens and with their consent.",
            "No.",
            "Yes. The driver is separated from the rest of the passengers and therefore is permitted to smoke any time.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0814. Keeping a safe distance from the vehicle in front of us is required:",
          options: [
            "Only when the driver is very tired and his responses are not quick enough.",
            "In order to drive in the correct lane and at a proper speed.",
            "In high speeds only and during stormy weather conditions.",
            "In order to prevent a rear-end collision when the vehicle in front stops or slows down.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1184. Is it obligatory to remove the meter from a taxi if its owner doesn’t have a valid taxi operation permit?",
          options: [
            "Yes.",
            "No.",
            "Yes, if three months passed since the scheduled date of the annual test and the test has not been made yet.",
            "Yes, within 72 hours.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0517. According to the law, what is the maximum allowed length of the bus in the following picture?",
          options: ["13.5 meters.", "18.5 meters.", "11 meters.", "12 meters."],
          answer: 0,
          image: "img/quiz/3517.jpg",
          types: ["D"],
        },
        {
          question:
            "0239. Is it permitted to drive passengers for hire in a private passenger car?",
          options: [
            "Only during a public transportation strike.",
            "Yes, when the passengers pay a fare that is equal to that of a bus ride.",
            "No.",
            "Only in inter-urban rides.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "В"],
        },
        {
          question:
            "0098. Under which conditions is the driver of a utility vehicle (mule) allowed to carry a passenger with him?",
          options: [
            "It is allowed, without any condition",
            "It is allowed, provided that the driver’s minimum age is 21 years",
            "It is allowed on a farm utility vehicle (mule) only",
            "When he has a permit to do so from the Licensing Authority",
          ],
          answer: 3,
          image: "",
          types: ["C1", "1", "В"],
        },
        {
          question:
            "1233. In what types of buses is it obligatory to have a speed retarder?",
          options: [
            "Every bus in which a techograph was installed.",
            "Buses with a minimum all up weight of 10,000 kg.",
            "Every bus in which a retarder was installed during its manufacturing process.",
            "Every bus that is permitted to carry more than 20 passengers.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question: "1036. What is the meaning of thr following Road sign.",
          options: [
            "End of an inter-urban area",
            "End of an urban area.",
            "End of parking restrictions for commercial vehicles",
            "End of parking restrictions on the road or pavement (side-walk)",
          ],
          answer: 1,
          image: "img/quiz/31036.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1532. How is the required distance for overtaking determined?",
          options: [
            "It only depends on the driving speed of the overtaken vehicle and not of the overtaking one.",
            "It depends on the driving speeds of the overtaking and the overtaken vehicles",
            "It only depends on the driving speed of the overtaking vehicle and not of the overtaken one.",
            "It corresponds to the speed limit in that road section.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1359. Which of the following commercial vehicles must have a towing permit for towing a trailer?",
          options: [
            "A vehicle with an all up weight of more than 8,000 kg.",
            "A vehicle with an all up weight of more than 3,500 kg.",
            "A vehicle with an all up weight of more than 6,000 kg.",
            "A vehicle with an all up weight of more than 10,000 kg.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "0338. When is it obligatory to place a warning triangle?",
          options: [
            "Only when a malfunctioning vehicle is left unsupervised.",
            "Only when a malfunctioning vehicle is left standing for more than 24 hours.",
            "Whenever it is necessary to abandon a vehicle on the road.",
            "Only when a malfunctioning vehicle is left standing on an urban road.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0918. How are you required to conduct yourself according to the event that is depicted in the following picture?",
          options: [
            "Drive faster and move to the left lane.",
            "Stop next to the pedestrian and explain to him that pedestrians should use the pedestrian crossing behind him.",
            "Stop before the pedestrian and signal to him in whatever way that there is a pedestrian crossing behind him.",
            "Slow down, show extreme caution, and don’t do anything that might endanger or scare the pedestrian.",
          ],
          answer: 3,
          image: "img/quiz/3917.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1271. Is a taxi with passengers permitted to use a designated bus lane?",
          options: [
            "Dotted lines mark the approach of a separation line. The driver should move to the right lane before the beginning of the separation line.",
            "Yes, but only during traffic rush hour, from 06:00 until 09:00.",
            "No.",
            "Yes.",
          ],
          answer: 3,
          image: "img/quiz/31269.jpg",
          types: ["D"],
        },
        {
          question:
            "1291. The ability to control the vehicle in order to prevent accidents:",
          options: [
            "Improves the faster you drive.",
            "Is higher the slower you drive.",
            "Depends on the size of the tires.",
            "Is not dependent on speed at all.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0532. The following road sign remains valid until:",
          options: [
            "The nearest intersection or the end of the tracks.",
            "The nearest bus station.",
            "The nearest intersection with traffic lights or the end of the tracks.",
            "The beginning of the freeway that is indicated in the sign.",
          ],
          answer: 0,
          image: "img/quiz/3531.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0495. What is the meaning of the following traffic sign?",
          options: [
            "You have right-of-way on the narrow road.",
            "Give right-of-way in a narrow road to traffic from the opposite direction.",
            "Traffic restrictions on the right of the road.",
            "Two-way traffic ahead.",
          ],
          answer: 1,
          image: "img/quiz/3495.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1052. What is the function of the vehicle’s dashboard( Control panel)",
          options: [
            "To control and receive information from the vehicle’s different systems.",
            "The dashboard regulates the engine systems, and prevents tire wear.",
            "To serve as an additional security measure during frontal collisions.",
            "To control the sound system in the driver’s compartment only.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "1", "В"],
        },
        {
          question:
            "0861. What are we required to see in the outer side mirrors of the vehicle?",
          options: [
            "The tip of the vehicle’s side body and the road at the sides of the vehicle.",
            "Any moving vehicle to the right of our vehicle.",
            "Any vehicle to the left of our vehicle, including pedestrians.",
            "The back wheel and the area in front of it.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "1004. What system helps to stabilize the vehicle when it is skidding or in danger of rolling over?",
          options: [
            "The Electronic Stability Program (ESP) System",
            "The engine’s fuel consumption control system.",
            "The signaling system.",
            "The speed control system.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "В"],
        },
        {
          question: "1794. The all up weight of a minibus:",
          options: [
            "The law doesn’t restrict the all up weight of a minibus.",
            "Should not exceed 5,000 kg.",
            "Should not exceed 4,000 kg.",
            "Should not exceed 6,000 kg.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0809. What is the relation between the vehicles speed and the vehicle’s stopping distance?",
          options: [
            "The higher the vehicle’s speed is, the shorter is its stopping distance.",
            "There is no relation between them. The stopping distance is constant and does not depend on the vehicle’s speed.",
            "The slower the vehicle’s speed is, the longer is the braking distance.",
            "The higher the vehicle’s speed is, the longer is its stopping distance.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1105. What should be ensured before disengaging the hydraulic oil pipe connectors from the tractor’s attachment or from the tractor’s additional equipment?",
          options: [
            "That there is no oil pressure in them.",
            "To check for leaking.",
            "That they are properly connected.",
            "That the connectors are in proper condition.",
          ],
          answer: 0,
          image: "",
          types: ["1"],
        },
        {
          question:
            "1017. By driving correctly and moderately, and maintaining proper tire air pressure:",
          options: [
            "The tires’ life span is prolonged.",
            "The engine’s fuel consumption is increased.",
            "The vehicle can drive faster.",
            "The engine’s oil consumption is increased.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1609. What are the main factors affecting the motorcyclist’s ability to detect obstacles on the road?",
          options: [
            "Traffic conditions around the motorcycle.",
            "The proper functioning of the brakes and the tires.",
            "The motorcycle’s mechanical condition.",
            "The motorcyclist’s riding skill and sitting position.",
          ],
          answer: 3,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0311. “Alcohol slows down the driver’s ability to respond”: Correct or incorrect?",
          options: [
            "Correct.",
            "Incorrect. There is no connection between the drinking alcohol and the driver’s ability to respond.",
            "Incorrect. Alcohol improves the ability to concentrate.",
            "Incorrect. Alcohol improves your mood.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0468. What is the meaning of the following traffic sign?",
          options: [
            "Caution! Pedestrian path.",
            "Pedestrian crossing ahead.",
            "Zebra crossing for children only.",
            "Caution! Children nearby.",
          ],
          answer: 1,
          image: "img/quiz/3468.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0721. In the following picture:",
          options: [
            "You always have right of way (2).",
            "You should give right of way to the red vehicle (1) in the roundabout.",
            "The driver of the red vehicle should give you right-of-way.",
            "You have right-of-way in any case, because you come from the right.",
          ],
          answer: 1,
          image: "img/quiz/3721.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1522. How is it required to mark freight that sticks out from the vehicle’s front side?",
          options: [
            "With a white-reflecting triangle on the tip of the oversize freight.",
            "With a red light-reflecting triangle on the tip of the oversize freight.",
            "With a light and a red flag on the tip of the oversize freight.",
            "With a light and a white or yellow flag on the tip of the oversize freight.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0049. Which documents should a driver be in possession of whilst driving?",
          options: [
            "Valid driving license, vehicle registration, compulsory insurance certificate and any other lawfully issued certificate",
            "Driving license and vehicle registration only",
            "Identity card, driving license, comprehensive insurance certificate and a certification of a towing arrangement for the vehicle",
            "Identity card, driving license and a certificate testifying about the vehicle’s proper functioning signed by a manager of a garage",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1421. Is it permitted to transport a freight container on a vehicle that is not equipped with twist locks?",
          options: [
            "Yes, but only with a permit from the Licensing Authority.",
            "No.",
            "Yes, it is permitted to transport freight containers in every vehicle equipped with cables to safely attach the container to the vehicle.",
            "Yes, containers can be transported in every vehicle that has a high front wall.",
          ],
          answer: 1,
          image: "",
          types: ["C"],
        },
        {
          question: "0557. When the roadway is divided by a broken line?",
          options: [
            "You are permitted to overtake restricted vehicles only.",
            "You are permitted to overtake when the road is clear.",
            "No overtaking until where the dotted line turns to an unbroken line.",
            "No overtaking until where an “end of overtaking restriction” sign is placed.",
          ],
          answer: 1,
          image: "img/quiz/3557.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1093. For what reason are weights installed on the tractor’s front?",
          options: [
            "To prevent the front part from jumping during high-speed off-road driving.",
            "To reduce some of the stress on the tractor’s rear axle while lifting loads.",
            "To improve driving quality and comfort on dirt roads.",
            "To prevent its front part from elevating while lifting loads from its rear side.",
          ],
          answer: 3,
          image: "",
          types: ["1"],
        },
        {
          question:
            "1436. It is prohibited to stand or park a vehicle on a road, if the vehicle’s width is more than:",
          options: [
            "4.80 meters.",
            "2.25 meters.",
            "2.10 meters.",
            "2.50 meters.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0400. Which of the following traffic signs allows vehicles to park on the sidewalk?",
          options: ["435", "627", "432", "626"],
          answer: 1,
          image: "img/quiz/3400.jpg",
          types: ["C1", "В"],
        },
        {
          question:
            "0889. How should the driver of the silver vehicle respond, when the driver of the dark vehicle wants to park his vehicle in reverse between two vehicles next to the sidewalk?",
          options: [
            "Enter the parking by himself and prevent the driver of the dark vehicle from parking.",
            "Slow down or even stop and allow the driver of the dark vehicle to finish parking.",
            "Continue driving normally, as if he did not identify the intentions of the other driver.",
            "Drive closer to the dark vehicle, honk, and overtake it quickly.",
          ],
          answer: 1,
          image: "img/quiz/3889.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1029. When the tire air pressure is too low, it might cause:",
          options: [
            "Quick wear and tear of the braking system.",
            "Easy wheel manipulation.",
            "Tire overheating.",
            "Low fuel consumption.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "A", "В"],
        },
        {
          question: "0613. Children should get on and off a student bus:",
          options: [
            "From the front right door only.",
            "From the rear door only.",
            "Far away from the sidewalk always.",
            "From the front right door and from the rear door.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question: "0850. How can we prevent our vehicle from skidding?",
          options: [
            "By holding the steering wheel firmly with both hands, and by driving at a similar speed to that of other vehicles on the road.",
            "By driving reasonably and using the brakes in a gentle manner.",
            "By stepping firmly and continuously on the brake pedal.",
            "By turning the steering wheel right, to stabilize the vehicle.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0927. When is it allowed to use the horn?",
          options: [
            "Only to prevent imminent danger that cannot be prevented in any other way.",
            "Only in an urban road, to prevent danger.",
            "In every type of road, only for public vehicles, to prevent danger.",
            "Only in a non-urban road, to prevent danger.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0063. Is it obligatory to obey the directions of a Public Works Department employee in uniform?",
          options: [
            "No, unless he directs you to stop on the road’s “hard shoulders” only.",
            "Yes, but on urban roads only.",
            "Yes, wherever road pavement or maintenance works are conducted.",
            "No, it is only obligatory to obey a policeman in uniform,",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0154. What is the maximum allowed speed in a “play street”?",
          options: ["10 KPH.", "20 KPH.", "40 KPH.", "30 KPH."],
          answer: 3,
          image: "img/quiz/3154.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1162. Is a taxi driver required to help a passenger to load or unload his luggage on or off the taxi?",
          options: [
            "Yes, if needed.",
            "Only on rides from Ben Gurion Airport.",
            "Absolutely not.",
            "Only if the passenger pays for the loading and unloading service.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0361. Which of the following traffic signs alerts that another road is about to merge with the roadway in which you are driving?",
          options: ["124", "145", "620", "725"],
          answer: 0,
          image: "img/quiz/3361.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0230. The use of which safety restraints is obligatory when driving children less than eight years of age?",
          options: [
            "A three-point safety belt only.",
            "A safety seat or a booster.",
            "A safety belt only.",
            "A waist belt only.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "В"],
        },
        {
          question:
            "0319. “Drinking alcohol has a negative effect on the driver’s ability to react”: Correct or incorrect?",
          options: [
            "Incorrect. Alcohol has a positive effect.",
            "Incorrect. Alcohol improves your mood and your ability to drive.",
            "Correct. Alcohol is a suppressive drug.",
            "Incorrect. The driver feels that he cannot drive when he’s drunk.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0412. What are you required to do according to the following road sign?",
          options: [
            "Drive in the middle of the road and turn-on the winker before each turn.",
            "Drive faster in order to pass the place.",
            "Slow down and move to the road’s left in the left curve.",
            "Slow down, adjust your speed to the road’s conditions and keep to the right of the road.",
          ],
          answer: 3,
          image: "img/quiz/3411.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1524. Which of the following commercial vehicles must be equipped with an                    auxiliary brake?",
          options: [
            "Commercial vehicles with an all up weight exceeding 4,000 kg.",
            "Commercial vehicles with an all up weight exceeding 6,000 kg.",
            "Commercial vehicles with an all up weight exceeding 5,000 kg.",
            "Diesel-engine vehicles with an all up weight exceeding 8,000 kg.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0637. What are you required to do when the following marking appears on the roadway?",
          options: [
            "You are prohibited to turn right on the roadway.",
            "You are permitted to overtake very carefully on the left of the line.",
            "No stopping in this road section, except for the “hard shoulders”.",
            "Drive on the right side of the line, without crossing it.",
          ],
          answer: 3,
          image: "img/quiz/3636.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0875. What limitations does a driver experience while driving in reverse?",
          options: [
            "Limited field of vision and uncomfortable vehicle operation.",
            "An experienced driver has no limitations while driving in reverse.",
            "It is hard to control the braking system while driving fast and needing to operate many systems.",
            "Driving in reverse takes more time.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question: "0592. What is the meaning of the following traffic sign?",
          options: [
            "Driving in the direction marked with a yellow arrow is permitted for public transportation only.",
            "You have right of way on the narrow road.",
            "Give right-of-way to opposite motor traffic on a narrow and steep road.",
            "Drive only forward. Turning is prohibited.",
          ],
          answer: 0,
          image: "img/quiz/3560.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0538. What is the meaning of the following traffic sign?",
          options: [
            "Proceed carefully (hand held sign).",
            "Drive fast.",
            "Drive without stopping. Stopping is prohibited.",
            "Stop and wait for the instructions of the traffic flagger.",
          ],
          answer: 0,
          image: "img/quiz/3538.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1364. During “lighting up  time”, when weather conditions are good – from which distance is the yellow flashing light of the portable lamp supposed to be seen?",
          options: ["150 meters.", "30 meters.", "100 meters.", "75 meters."],
          answer: 0,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question: "1377. Explain the term “independent brake”:",
          options: [
            "A device which causes the braking system to operate each wheel independently.",
            "It is a device operated by the driver during continuous and steep descents.",
            "A device within the braking system that brakes each wheel independently when the vehicle is slipping.",
            "A spring loaded device that operates the braking system independently, and that starts operating when the air pressure in the braking system drops below its required level.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question: "1455. Who is required to have a carrier license?",
          options: [
            "Any provider of transportation services on a commercial vehicle weighing 8,000 kg or less.",
            "Anyone owning more than five passenger transportation vehicles.",
            "Any commercial vehicle.",
            "Any provider of transport services on a commercial vehicle with an all up weight of 10,000 kg or more.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0331. Who is permitted to drive a “security vehicle” or a “rescue vehicle”?",
          options: [
            "Any person who received a permit from the Israel Fire and Rescue Commissioner.",
            "Any person who received a permit to do so from the Licensing Authority.",
            "Any person who received a permit from the Ministry of Health.",
            "Any person who received a license from the Ministry of Defense.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "В"],
        },
        {
          question: "1298. Non adjusted headlights:",
          options: [
            "Do not affect driving in the dark.",
            "Cause self-blinding when driving in the dark.",
            "Do not iluminate the road as required by law.",
            "Increase the driver’s alertness – especially during the night.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question:
            "1175. Is a taxi owner or driver permitted to change the taxi’s ride fare?",
          options: [
            "Yes, after due notice of 26 days in advance.",
            "Yes, up to 15% above the determined tariff.",
            "Yes, 24 hours before the new tariff enters into force.",
            "No.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0774. What is the main risk of approaching an intersection on a wet road?",
          options: [
            "When it rains, there are more pedestrians.",
            "On a wet road, the road markings are concealed.",
            "The vehicle might skid into the area of the intersection due to the wet road.",
            "Vehicles face no risk on a wet road.",
          ],
          answer: 2,
          image: "img/quiz/3774.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0205. At what distance from a pedestrian crossing is it prohibited to stand a vehicle?",
          options: [
            "Within twelve meters before the pedestrian crossing and twelve meters after the pedestrian crossing.",
            "Within twenty meters before and after the pedestrian crossing.",
            "On the pedestrian crossing and within twelve meters after it.",
            "On the pedestrian crossing and within twelve meters before it.",
          ],
          answer: 3,
          image: "img/quiz/3205.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1753. It is prohibited to stop or park a vehicle before and after a level crossing, within a distance of:",
          options: [
            "At least 30 meters.",
            "At least 50 meters.",
            "At least 25 meters.",
            "At least 20 meters.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1138. What does the law state regarding the sale of tickets or change-giving by the driver of a public vehicle?",
          options: [
            "It is permitted to sell tickets or give change while the bus or taxi is moving.",
            "It is forbidden to sell tickets or give change while the bus or taxi is moving.",
            "It is permitted to sell tickets or give change, but only if the driver has an automatic ticket sale machine.",
            "It is permitted to give change to passengers while the vehicle is moving.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1717. Whilst driving, it is permitted to take one hand off the wheel for the following purpose only:",
          options: [
            "Receiving a mobile phone call.",
            "Change gears.",
            "Reading or sending an SMS Message.",
            "Adjusting the passenger seat besides the driver.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1263. Is it permitted for a vehicle other than a taxi to load passengers within the limits of the following road sign?",
          options: [
            "No. The sign marks a place in which only taxi-passenger loading and unloading is permitted.",
            "Yes. The sign marks a place in which passenger loading and unloading is permitted for taxis and rouse service buses.",
            "Yes. The sign marks a place in which only taxis are permitted to unload passengers.",
            "No. The sign marks a place in which all other vehicles are permitted to load and unload passengers.",
          ],
          answer: 0,
          image: "img/quiz/3572.jpg",
          types: ["D"],
        },
        {
          question:
            "0972. In your opinion, why was a continuous line marked on the roadway?",
          options: [
            "Because of the limited field of vision ahead.",
            "Because of the tree lines on both sides of the roadway.",
            "Because there is a bus-stop bay on the right side of the roadway.",
            "Because the roadway is narrow.",
          ],
          answer: 0,
          image: "img/quiz/3972.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1526. The length of a commercial vehicles shall not exceed:",
          options: ["12.5 meters.", "10 meters.", "12 meters.", "11 meters."],
          answer: 2,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "0610. What is the meaning of the following traffic sign?",
          options: [
            "No overtaking for buses",
            "No overtaking of private vehicles.",
            "No overtaking for commercial vehicles.",
            "End of road section with an overtaking restriction.",
          ],
          answer: 3,
          image: "img/quiz/3386.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1431. What is the maximum number of passengers permitted on a commercial vehicle with an all up weight exceeding 3,500 kg, which has no standard chassis for passenger transportation?",
          options: [
            "Eleven passengers including the driver.",
            "Six passengers, including the passengers that sit next to the driver.",
            "Six passengers, besides the passengers that sit next to the driver.",
            "Eight passengers, besides the passengers that sit next to the driver.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "0617. What is the meaning of the following traffic sign?",
          options: [
            "A path for bicycles only.",
            "Driver pay attention, bicycles passing.",
            "Designated lane for motorcycles and bicycles only.",
            "No entry for bicycles.",
          ],
          answer: 0,
          image: "img/quiz/3577.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0710. How would you (vehicle no. 3) conduct yourself in the following intersection?",
          options: [
            "You (3) have right of way over the other vehicles.",
            "The first one to arrive at the intersection enters it.",
            "Give right of way to the motorcyclist (1)",
            "Give right of way to the red vehicle (2).",
          ],
          answer: 2,
          image: "img/quiz/3710.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1688. Upon completion of the two year “new driver” period, a regular driver’s license will not be given to a “new driver” who was convicted  of:",
          options: [
            "Not placing a “new driver” sign on the vehicle’s rear windshield.",
            "Driving a motor vehicle without valid insurance.",
            "Driving without an escort.",
            "Not stopping next to a “falling rocks” sign.",
          ],
          answer: 2,
          image: "img/quiz/31688.jpg",
          types: ["C1", "A", "1", "В"],
        },
        {
          question:
            "1802. What sign  signifies a station for changing a battery of an electric vehicle?",
          options: ["ס-131", "ס-31", "ס-130", "פ-151"],
          answer: 0,
          image: "img/quiz/31802.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1077. What safety measures should be taken before lifting the vehicle with a jack stand?",
          options: [
            "You should prevent vehicle movement (by putting on the hand brake and placing blocks under the wheels) and loosen wheel retaining/screw nuts.",
            "You should verify that the vehicle stands on a straight and horizontal surface.",
            "You should shift the gear handle to neutral and release the parking brake.",
            "You should first lift the vehicle from another corner, to verify that the jack stand is functioning properly.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0554. You are driving a commercial vehicle and come across this traffic sign:",
          options: [
            "Pass only from the right. Only public transportation is allowed to pass from the left as well.",
            "Pass only from the left. Public transportation is allowed to pass from the left as well.",
            "Drive only straight (forward).",
            "Pass from the right or from the left; whatever is comfortable for you.",
          ],
          answer: 0,
          image: "img/quiz/3513.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1433. On a roadway with curbstones, how should we park uphill a commercial vehicle with an all up weight of more than 5,000kg?",
          options: [
            "Turn the wheels towards the center of the road, shift to reverse gear and place safety shoes under the rear wheels.",
            "Turn the wheels towards the curbstones and place safety shoes under the rear wheels.",
            "Properly brake the wheels, place safety shoes under the wheels and leave the vehicle in neutral gear.",
            "Turn the front wheels towards the center of the road, shift to forward drive gear and place safety shoes behind the rear wheels.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "1"],
        },
        {
          question:
            "1427. What is the maximum permitted freight height during bulk transportation?",
          options: [
            "The freight should be one meter lower than the height of the wall, to prevent freight dispersion over the road.",
            "The freight’s height is not important as long as it is covered according to regulations.",
            "The freight or any part of it should not be higher than the sides of the vehicle or ladders that were legally installed.",
            "The freight’s height should not be more than 480 cm, and it should be covered.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0333. What is the minimum age requirement for obtaining a Class B driver’s license?",
          options: [
            "17.5 years.",
            "17 years.",
            "16 years, subject to parents’ approval.",
            "16 years.",
          ],
          answer: 1,
          image: "",
          types: ["В"],
        },
        {
          question:
            "0678. What is the meaning of this traffic light when it is placed above your driving lane?",
          options: [
            "Vehicles coming from the opposite direction are prohibited to proceed.",
            "Driving in this lane is prohibited.",
            "4-way intersection ahead.",
            "It is a lane for public transportation only.",
          ],
          answer: 1,
          image: "img/quiz/3677.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1127. Is it permitted to park a bus that is authorized to transport at least 20 passengers within the limits of a sign that allows parking on the sidewalk?",
          options: [
            "Yes, provided that there are passengers on the bus.",
            "Yes, provided that there are no passengers on the bus.",
            "No.",
            "Yes.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1042. How can we reduce air pollution caused by the vehicle’s gas emissions?",
          options: [
            "By washing the vehicle’s fuel tank once a month.",
            "By keeping the engine and its surroundings clean.",
            "It is impossible to reduce air pollution. It is a constant factor in the vehicle.",
            "By regular maintenance of the vehicle’s engine and other systems.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question:
            "0178. How should you conduct yourself when exiting premises, a gas station or an access road to a house?",
          options: [
            "You should give right-of-way to vehicles coming from the left only.",
            "The “right-of-way” rules in such an exit are identical to those that apply to an intersection.",
            "You should give right-of-way to vehicles coming from the right only.",
            "Give right of way to any vehicles approaching the road you are entering and pedestrians on the pavement.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0586. What is the meaning of the following traffic sign?",
          options: [
            "You have right-of-way in the narrow road.",
            "Give right-of-way on the narrow road to vehicles coming from the opposite side.",
            "No turning at the end of the road section.",
            "No through road (cul-de-sac) ahead.",
          ],
          answer: 3,
          image: "img/quiz/3586.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0202. Is it permitted to park a vehicle within an intersection?",
          options: [
            "It is permitted to park two-wheelers only.",
            "Yes, in an intersection of two lanes or more.",
            "No, unless parking is permitted by a traffic sign.",
            "It is permitted for no more than 5 minutes.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0920. How can you avoid collision according to the indications in the following picture?",
          options: [
            "Honk loudly and continuously.",
            "Keep a proper distance from parked vehicles.",
            "Stop and don’t continue driving.",
            "Flash the high beam.",
          ],
          answer: 1,
          image: "img/quiz/3919.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1523. When a driver approaches a narrow bridge, he should:",
          options: [
            "Cross the bridge as quickly as possible, to allow vehicles coming from the opposite direction to pass it without delays.",
            "Slow down while approaching and crossing the bridge.",
            "Stop before the bridge for safety reasons and then cross it quickly.",
            "Drive faster only while approaching the bridge, and drive slowly on the bridge – in order to enhance safety.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1242. How long after a change is made in the size of certain parts in the drive system, should the taxi meter be calibrated?",
          options: [
            "Within forty eight hours.",
            "Twenty four hours only.",
            "Not more than half a year before the date of the periodic adjustment of the meter.",
            "One week from the date in which the repair was finished.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question: "0631. What is the meaning of the following traffic sign?",
          options: [
            "Begining of traffic restriction zone.",
            "The minimum permitted speed is 30 k.p.h.",
            "Beginning of 30 k.p.h zone.",
            "The maximum permitted speed is 30 k.p.h.",
          ],
          answer: 0,
          image: "img/quiz/3631.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0246. How would you conduct yourself when approaching an intersection with a yellow flashing traffic light?",
          options: [
            "Slow down and give right-of-way to vehicles approaching from the opposite direction.",
            "Stop until the traffic light turns green.",
            "Slow down and follow the “right-of-way” rules and the traffic signs that are placed in the intersection.",
            "You have right-of-way towards the pedestrians on the zebra crossing, and therefore you should proceed normally to the intersection.",
          ],
          answer: 2,
          image: "img/quiz/3186.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0015. When a no stopping sign (433) is placed on the right side of a two-way highway:",
          options: [
            "Stopping is prohibited on both sides of the vehicle.",
            "Stopping is prohibited on the right side of the roadway and on the hard shoulder.",
            "Stopping is prohibited on the roadway only.",
            "Stopping is prohibited on the hard shoulder only.",
          ],
          answer: 1,
          image: "img/quiz/3015.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1644. Is it obligatory to install a mirror on a motorcycle?",
          options: [
            "No, except for motorcycles and a side-car.",
            "Yes, it is lawfully required on all types of motorcycles.",
            "No, the driver can decide at his own discretion whether or not to install a mirror.",
            "Yes, but only on motorcycles with a sidecar.",
          ],
          answer: 1,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0903. Which of the following sentences is correct as regards to other drivers?",
          options: [
            "You should assume that other drivers would always give you right of way.",
            "You shouldn’t assume that other drivers will always give you right of way.",
            "Truck drivers are professional drivers; hence they are less of a danger.",
            "Drivers who turn-on the winker, always turn to the direction they signal to.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0240. Is it permitted to transport cargo in a private vehicle?",
          options: [
            "Yes, inside the vehicle, in the trunk(boot) or on the roof-rack..",
            "It is absolutely forbidden.",
            "Yes, provided that the weight of the cargo is no more than 1,000 kg.",
            "Yes, only on the rooftop cargo carrier.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0479. What should you do if a vehicle suddenly approaches you while you are being overtaken by another vehicle?",
          options: [
            "Make a complete abrupt stop in the right lane.",
            "Deviate as far as possible to the right and do not increase your speed.",
            "Honk to warn the overtaking driver.",
            "Drive faster to exit the danger area.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1716. An additional passenger is not permitted on a utility tractor unless:",
          options: [
            "It is stated in the vehicle’s registration document.",
            "It is listed in the driving license.",
            "A permit is given by the vehicle’s importer.",
            "It is listed in the insurance policy.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "1", "В"],
        },
        {
          question:
            "0359. Which of the following traffic signs restricts vehicle entry, regardless of freight height?",
          options: ["417", "145", "150", "416"],
          answer: 3,
          image: "img/quiz/3359.jpg",
          types: ["C1", "C", "1"],
        },
        {
          question: "1699. A panoramic mirror:",
          options: [
            "Causes the objects that reflect in it to look smaller and farther.",
            "Is very expensive and therefore used only in buses.",
            "Increases the size of reflecting objects.",
            "Precisely reflects the objects mirrored in it (without increasing or decreasing their size).",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1101. What is the meaning of the PTO power transfer “standard operating speed’?",
          options: [
            "It is the RPM of the power transfer exit and of its drive shaft.",
            "The RPM in which the gearbox exit shaft rotates.",
            "The RPM of the oil pump that moves the power transfer.",
            "The RPM of the engine itself (the “fly wheel” exit).",
          ],
          answer: 0,
          image: "",
          types: ["1"],
        },
        {
          question: "1456. For what period is a carrier license given?",
          options: [
            "One year.",
            "Five years.",
            "The length of time depends on the type of freight.",
            "Until the vehicle registration document is renewed.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "0471. What is the meaning of the following traffic sign?",
          options: [
            "Zebra crossing for children only.",
            "Pedestrians nearby.",
            "Pedestrian path.",
            "Children nearby.",
          ],
          answer: 1,
          image: "img/quiz/3471.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1308. What is the meaning of “permitted load” on a commercial vehicle?",
          options: [
            "The vehicle’s weight plus the weight of the freight.",
            "The vehicle’s own weight.",
            "The maximum weight in Kg that is permitted to be loaded on the vehicle.",
            "The vehicle’s all up weight.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0283. For how long is it permitted to leave a broken-down vehicle on a road or in a public place?",
          options: [
            "It should be cleared away as soon as possible, no later than 48 hours.",
            "Until the rescue vehicle arrives, provided that it doesn’t exceed a period of one week.",
            "It should be moved away as soon as possible, no later than 24 hours.",
            "For the period in which it is being repaired, provided that it doesn’t exceed 72 hours.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0896. In the following picture, the response of the drivers to the vehicle that comes out of parking is unclear. How is the truck driver required to respond to this situation?",
          options: [
            "Slow down and stop if necessary, to verify that the drivers in front of him conduct themselves safely.",
            "Stop his vehicle and allow the other vehicle to continue driving.",
            "Continue driving and ignore the other exiting vehicle, because the truck has right of passage.",
            "Continue driving while honking continuously to alert about an approaching truck.",
          ],
          answer: 0,
          image: "img/quiz/3896.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1176. Is a bus driver permitted to stop at a place other than a station in order to disembark the Inspector?",
          options: [
            "Yes, in designated bus parking places.",
            "He is only permitted to stop at a station that is indicated within the route service license.",
            "Yes, where stopping is permitted for all other types of vehicles.",
            "No, he should only stop at a station.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1557. Is a policeman permitted to stop a vehicle which is not lawfully loaded or marked?",
          options: [
            "No, a policeman is not permitted to prohibit the vehicle from driving.",
            "Yes, but only on an urban road.",
            "No, a traffic policeman may only reprimand the vehicle owner, warn him and write him a ticket.",
            "Yes, a policeman may prohibit the vehicle from driving until the freight is lawfully arranged.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0278. How should a vehicle driver conduct himself after hitting another vehicle or property in the absence of its owner?",
          options: [
            "Report about the incident to the nearest police station and wait for the arrival of a police investigator.",
            "Leave his identifying details in a clearly visible place and report to the nearest police station within 24 hours.",
            "Leave the place quickly and report about the incident’s details at a later stage.",
            "Leave his vehicle at the site of the accident without moving it and wait for the arrival of a police examiner.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0584. The following road sign remains valid:",
          options: [
            "Until the nearest intersection or until a “no parking” zone.",
            "Until a “no entry” sign.",
            "Until an “end of urban road” sign.",
            "Until the nearest interchange.",
          ],
          answer: 0,
          image: "img/quiz/3583.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0023. What is a “pedestrian crossing”?",
          options: [
            "A part of the pavement that is marked for pedestrian crossing",
            "A part of the road designated and marked for pedestrians to cross.",
            "A part of the road edge that is marked for pedestrian crossing",
            "A part of the road that is marked for pedestrian crossing, including the pavement",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1484. While overtaking or passing by another vehicle:",
          options: [
            "It is permitted to pass the speed limit for a moment, if necessary.",
            "You should drive precisely at the same speed as the overtaken vehicle.",
            "It is prohibited to drive over the speed limit.",
            "You should drive 10 k.p.h slower than the overtaken vehicle.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question:
            "1575. On an urban road, the speed limit for a 125cc or smaller motorcycle, with an engine power that doesn’t exceed 14.6 horse-powers (11 KW), unless indicated otherwise by any traffic sign, is:",
          options: ["30 k.p.h.", "50 k.p.h.", "60 k.p.h.", "40 k.p.h."],
          answer: 1,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0324. When is it obligatory to operate the vehicle speed retarders?",
          options: [
            "When driving in a road section before which a “hairpin curve” sign is placed.",
            "When driving in a road section before which a “danger of sliding” sign is placed.",
            "When driving in a road section where a “dangerous descent” sign is placed.",
            "When driving in a road section before which a “sharp curve” sign is placed.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question:
            "0242. According to the “point system”, when a driver incurs 12 to 22 points:",
          options: [
            "He shall be summoned to attend a corrective driving course and to undergo an exam.",
            "He shall be required to undergo a medical check-up and a theory examination.",
            "His driver’s license shall be revoked for a period of thirty days.",
            "He shall be required to undergo a theory examination and his driver’s license shall be revoked.",
          ],
          answer: 0,
          image: "img/quiz/3242.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0729. How should you conduct yourself on the road ahead?",
          options: [
            "Continue driving in your lane.",
            "Use the fastest lane.",
            "Stop until the arrow changes to green.",
            "Pass to the adjacent right lane.",
          ],
          answer: 3,
          image: "img/quiz/3729.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0236. When is the driver of a private vehicle exempt from wearing a seat belt?",
          options: [
            "While moving forward during parking maneuvering.",
            "While driving in reverse.",
            "While exiting a roofed parking.",
            "While exiting a premises.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "1674. a “new driver” that is convicted of excessive speed.",
          options: [
            "The Licensing Authority must renew his license as a regular driver, without the “new driver” restriction.",
            "The Licensing Authority will renew his license like that of any other driver, provided that he was not convicted of more than one offense during his “new driver” period.",
            "The Licensing Authority will renew his license like that of any other driver, and it is not permitted to set any condition for license renewal.",
            'The Licensing Authority may require him to undergo a driving test, and if he passes the test, he will be once again regarded as a \\"New driver\\" for a further period of two years.',
          ],
          answer: 3,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1276. What sign does not forbid parking a vehicle that weighs over 10,000kg at night?",
          options: ["433", "436", "432", "435"],
          answer: 1,
          image: "img/quiz/31276.jpg",
          types: ["C1", "C"],
        },
        {
          question:
            "1604. How would you drive your motorcycle when you are forced to cross an oil slick on the road?",
          options: [
            "Slow down gently using the front brake during the entire time of crossing.",
            "Slow down gently using the rear brake during the entire time of crossing.",
            "Keep driving straight ahead without braking or accelerating and squeeze the clutch operating lever.",
            "Slow down sharply with an even distribution between the rear and front brakes, until you pass the oil slick.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0227. Are passengers permitted to get in or out of the off side of a vehicle?",
          options: [
            "Yes, if it doesn’t disturb the passengers.",
            "It is absolutely forbidden.",
            "No, except for the driver and except for the passenger sitting next to him when the vehicle is parked on the left of the road.",
            "Yes, if it doesn’t put the passengers and the driver at risk.",
          ],
          answer: 2,
          image: "img/quiz/3227.jpg",
          types: ["C1", "C", "В"],
        },
        {
          question:
            "0317. “Drinking alcohol impairs the driver’s ability to estimate distances and speeds”: Correct or incorrect?",
          options: [
            "It is only correct for drivers wearing eyeglasses.",
            "It is sometimes true.",
            "Drinking alcohol impairs the judgment of young drivers only.",
            "Correct.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0124. When is it permitted to overtake a vehicle on its right side?",
          options: [
            "When the other vehicle drives in the right lane and signals left.",
            "When the other vehicle indicates and positions itself to turn left.",
            "When there is an open lane to the right of the other vehicle.",
            "When the other vehicle is in the central lane and signals right.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0421. What are you required to do when the following road sign is placed?",
          options: [
            "Slow down and follow the accepted right-of-way rules and the traffic signs that are placed at the intersection.",
            "Cross the intersection quickly, while paying attention to vehicles approaching the intersection from your right.",
            "Slow down and give right-of-way to vehicles coming from your right only.",
            "Always stop before the intersection and cross it when it is free of traffic only.",
          ],
          answer: 0,
          image: "img/quiz/3420.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0708. What are you (vehicle no. 3) required to do when approaching the following intersection (the red vehicle is not an emergency vehicle)?",
          options: [
            "You have right of way because you are crossing in a straight line (3).",
            "Enter the intersection before the garbage truck (2), as it must yield (you [3] are coming from its right).",
            "Give right of way to the tractor coming from the right only (1).",
            "Give right of way to the crossing tractor (1) and truck (2).",
          ],
          answer: 3,
          image: "img/quiz/3708.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0475. What is the meaning of the following traffic sign?",
          options: [
            "Give right-of-way to all vehicles.",
            "Be alert, speed camera ahead.",
            "A hazardous place for which a specific road sign was not designated.",
            "Careful! dangerous descent.",
          ],
          answer: 2,
          image: "img/quiz/3475.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0680. What is the meaning of the following traffic sign?",
          options: [
            "Brief stopping is permitted but parking is prohibited.",
            "A barrier before a level crossing.",
            "Parking is allowed with parking tickets.",
            "No Stopping and parking, except for buses.",
          ],
          answer: 1,
          image: "img/quiz/3680.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1199. Who is responsible for the entry and exit of bus passengers?",
          options: [
            "The vehicle owner.",
            "The route service owner.",
            "The dispatcher.",
            "The bus driver.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1469. How would you connect the truck cabin to the semi-trailer?",
          options: [
            "By driving backwards at an angle of less than 15 degrees.",
            "By pushing the semi-trailer forward until the truck cabin.",
            "By driving backwards when both of them are aligned in a straight line.",
            "By driving backwards while the cabin is in a diagonal angle or any other comfortable angle..",
          ],
          answer: 2,
          image: "",
          types: ["C"],
        },
        {
          question: "0420. What is the meaning of the following traffic sign?",
          options: [
            "T-Intersection ahead.",
            "Railway crossing.",
            "Cross road.",
            "Junction in a freeway.",
          ],
          answer: 2,
          image: "img/quiz/3420.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0298. What is a “breathalyzer test”?",
          options: [
            "A test of exhaled air by means of a device that provides information about a person’s blood alcohol level.",
            "A stress test measuring the air quantity that enters the driver’s lungs while driving.",
            "A test in which a person exhales into a device that gives information about his lung volume.",
            "A test performed for new-driver applicants by a family doctor, at the requirement of the Licensing Authority.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0665. What is the meaning of the light appearing in the following traffic light?",
          options: [
            "Stop – don't continue driving for as long as the red light is on.",
            "You may continue driving after verifying that the intersection is clear.",
            "Continue driving: Green light will appear shortly.",
            "Drive faster before the intersection with the traffic lights, in order to cross it before the light changes.",
          ],
          answer: 0,
          image: "img/quiz/3608.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1746. The “hard shoulders” are not designed for vehicle traffic, except for:",
          options: [
            "Hand propelled vehicles, tractors and slow moving vehicles, which delay the traffic behind them.",
            "Private vehicles and motorcycles, in order to give right of way to buses.",
            "All vehicles are prohibited to drive on the “hard shoulders”.",
            "Taxis, in order to overtake other vehicles.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0639. You are driving in the direction of the arrow. What is the meaning of the road marking to your left?",
          options: [
            "One-way road ahead.",
            "Beginning of a rough road ahead.",
            "A continuous separation line will appear shortly.",
            "Separation area on a non-urban road.",
          ],
          answer: 2,
          image: "img/quiz/3639.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1713. How is a driver required to conduct himself when the braking system’s check lamp lights-up on the dashboard?",
          options: [
            "Drive slowly until the garage – not faster than 10 KPH..",
            "Ignore it and continue driving normally. The light in the check lamp doesn’t serve as proof for any malfunction.",
            "Wait for the vehicle’s periodic maintenance treatment, and only then check this problem.",
            "Stop and discontinue driving.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "0181. How would you conduct yourself when approaching an intersection that is preceded by a “stop” sign?",
          options: [
            "Stop and give right-of-way to any other vehicle that is entering or approaching the intersection from a different roadway.",
            "Give right-of-way to any other vehicle that is entering or approaching the intersection from a different roadway.",
            "You are only obliged to stop when vehicle traffic on the crossing road is approaching from your right.",
            "You are only obliged to stop when a policeman instructs you to do so.",
          ],
          answer: 0,
          image: "img/quiz/3181.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0321. What is the overall permitted length of a commercial vehicle with an overall permissible weight of more than 12,000kg?",
          options: ["Ten meters.", "12 meters.", "11.70 meters.", "11 meters."],
          answer: 1,
          image: "",
          types: ["C"],
        },
        {
          question: "1376. Explain the term “service brakes”:",
          options: [
            "A braking system that acts on all the wheels of a vehicle and allows slowing the vehicle down or stopping it in all road conditions.",
            "An auxiliary (parking/hand) brake system, designed to prevent accidents, which only acts on the wheels of the rear axle.",
            "A braking system that only slows the vehicle down during very steep descents, but is not used for stopping.",
            "A braking system that is working at times of danger only and brakes the vehicle until making a complete stop.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "1", "В"],
        },
        {
          question:
            "1483. Why is it required to drive in low gear on a steep descent?",
          options: [
            "To save fuel and to reduce engine wear.",
            "To use the engines compression in order to maintain a slow and constant driving speed without overusing the brakes.",
            "To prevent tire wear.",
            "To prevent overheating of the vehicle’s engine.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1335. What is the maximum freight height (from the road’s surface) that may be carried by a commercial vehicle with an all up weight of up to 8,000kg?",
          options: [
            "3.50 meters.",
            "4.00 meters.",
            "2.50 meters.",
            "3.00 meters.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0088. What should a tractor driver do when he obstructs the traffic behind him on a road?",
          options: [
            "Drive on the right lane of the road,only",
            "Drive on the right side of the road and even on the hard shoulder if it is required and safe",
            "He is permitted to drive on the left lane or on the right hard shoulder only.",
            "Always drive on the hard shoulder.",
          ],
          answer: 1,
          image: "img/quiz/3085.jpg",
          types: ["1"],
        },
        {
          question:
            "1508. What are the dangers of driving continuously for many hours?",
          options: [
            "Heating and accelerated wear of the vehicle’s tires.",
            "Overheating of the vehicle’s engine.",
            "It violates the insurance law.",
            "Tiredness and risk of falling asleep at the wheel",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0703. You are driving vehicle no. 3. According to the rules of giving right-of-way, in what order should the vehicles enter the intersection?",
          options: [
            "The vehicle you are driving goes in first (3), and the red private vehicle will follow (1).",
            "The cyclist will go in first (2), and the vehicle you are driving will follow (3).",
            "The red private vehicle will go in first (1), and the cyclist will follow (2).",
            "The cyclist enters first (2), and the red private vehicle will follow (1).",
          ],
          answer: 3,
          image: "img/quiz/3703.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0355. Which of the following traffic signs requires a vehicle to stop?",
          options: ["102", "302", "124", "301"],
          answer: 1,
          image: "img/quiz/3355.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0895. According to the following picture, what caused the poor field of vision?",
          options: [
            "A disruption in the field of vision can only be a result of blinding by another vehicle driving in the opposite direction.",
            "The disruption is caused because the distance between the two vehicles is too small.",
            "The fog caused the poor field of vision.",
            "The disruption shown in the picture is inevitable, regardless of its cause.",
          ],
          answer: 2,
          image: "img/quiz/3895.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0841. How can we minimize the risk of brake loss while driving on a mountain road?",
          options: [
            "It is advisable to use the brakes without utilizing the engine’s braking power.",
            "Drive in anappropriate \\r\\n(low) gear and avoid excessive use of the brakes.",
            "Don’t use the brakes much and stay on high gear.",
            "Do not change gears along the mountain road.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0823. It is prohibited to start overtaking without verifying",
          options: [
            "Is it permitted? Will I manage to complete it safely, without any doubt?.",
            "Does the road have a “hard shoulder”.",
            "Does the insurance cover the damages in case of an accident?",
            "What is the penalty for prohibited overtaking?",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0008. When may a police officer revoke a driver’s license for a period of ninety days?",
          options: [
            "When the driver is involved in a car accident in which only property damage was caused",
            "When the driver disobeys a stop sign",
            "When a driver involved in an accident is reasonably suspected of causing the death of a person.",
            "When the driver fails to give right of way.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0163. When are you required to turn on the direction indicators and signal?",
          options: [
            "When you approach a railway crossing or a pedestrian crossing.",
            "Only before a sharp curve.",
            "Only when you make left hand turns in intersections.",
            "When you are about to start driving or to turn your vehicle, or to deviate from your lane.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0572. What is the meaning of the following traffic sign?",
          options: [
            "Taxi stand in airports.",
            "Designated waiting place for a taxi service route.",
            "Designated waiting place for taxis.",
            "Place for loading and unloading of taxi passengers only. Waiting for passengers is prohibited.",
          ],
          answer: 3,
          image: "img/quiz/3572.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1576. Is a motorcycle permitted to tow other vehicles?",
          options: [
            "Yes, but only during the dark and without endangering anyone",
            "Yes.",
            "No, a motor vehicle with 3 wheels or less is prohibited to tow.",
            "Yes, when the towed vehicle is a two-wheeler.",
          ],
          answer: 2,
          image: "",
          types: ["A", "В"],
        },
        {
          question:
            "1171. Is a taxi driver permitted to drop passengers next to a sign or marking that prohibits stopping or parking?",
          options: [
            "He is permitted to stop only if instructed to do so by a policeman.",
            "Yes, on an urban road only.",
            "Only share-taxi drivers are permitted to do so.",
            "Yes, on all types of roads.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question: "0799. What is the main risk of driving in reverse?",
          options: [
            "Causing damage to the vehicle’s tires, when approaching the sidewalk or sharp objects.",
            "The presence of road users in the driver’s “blind spots” and the chance of hitting them.",
            "Lack of control over a slow and steady driving speed due to gear restrictions.",
            "Causing damage to the steering system, because the wheel is used oppositely to its normal use while driving forward.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "1778. During the vehicle’s annual test, the licensing facility shall register:",
          options: [
            "The number of kilometers traveled by the vehicle, as indicated on its odometer.",
            "The number of traffic offenses perpetrated within the vehicle.",
            "The number of seatbelts in the vehicle.",
            "The number of head-rests in the vehicle.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0090. What should you do when facing a structure, post or closed area, while driving on a one-way roadway?",
          options: [
            "On an urban road - from either its right or left side; on a highway – always from its right side.",
            "You should pass the place from its left side",
            "You should pass the place from its right side only",
            "You are permitted to pass the place from either its right or left side, unless otherwise marked",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1019. What are you required to check when one of the vehicle’s lamps is not working?",
          options: [
            "The bulb and the fuse connected to it.",
            "The intactness of the lamp’s lens.",
            "The functionality of the lights handle.",
            "The battery’s voltage.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "1", "В"],
        },
        {
          question: "0810. What is a vehicle’s “stopping distance”?",
          options: [
            "The distance covered by the vehicle as of the moment in which the driver reacts to the risk until he steps on the brake pedal.",
            "The required distance for stopping at a certain speed.",
            "The distance covered by the vehicle from the beginning of braking until the vehicle stops.",
            "The distance covered by the vehicle from the time that the driver identifies the risk ahead of him until the vehicle stops.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0237. The following penalties may be imposed by a court of law on a driver convicted of committing a traffic offence:",
          options: [
            "Revoking a driver’s license, prison term, fine and other lawful penalties.",
            "Fine or only suspended prison term.",
            "Revoking a driver’s license or only suspended prison term.",
            "Fine, active prison term or only suspended prison term.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1167. Taxi meter tariff 1 is:",
          options: [
            "Day tariff from 06:30 until 21:00.",
            "Day tariff from 07:30 until 20:00.",
            "Day tariff from 05:30 until 22:00.",
            "Day tariff from 05:30 until 21:00.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1761. How would you conduct yourself when a “stop” sign is placed on the right side of a two-lane one-way roadway?",
          options: [
            "Stop on either of the two lanes.",
            "Stop if you intend to turn left only.",
            "Stop if you are on the right lane only.",
            "Stop if you are on the left lane only.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0402. Which of the following traffic signs allows parking in a position that is not parallel to the sidewalk?",
          options: ["815", "512", "819", "513"],
          answer: 2,
          image: "img/quiz/3402.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1177. Is it permitted to carry animals on a bus?",
          options: [
            "Yes, but only on a seat.",
            "Yes, unrestrictedly.",
            "Yes, when their size enables them to be carried on the passenger’s knees or held in his hands.",
            "No, except for blind people’s guide-dogs.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0838. What is the risk in braking while being forced to pull onto the “hard shoulder”?",
          options: [
            "The Vehicle may spin as a result of braking on different types of road surfaces that have different grades of friction.",
            "Loss of steering ability as a result of excessive air pressure in the tires.",
            "Loss of steering ability as a result of excessive brake use.",
            "Vehicle spinning, because its front wheels become locked.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1518. What effective solution for tiredness is available to the driver?",
          options: [
            "Turning on the fog lights.",
            "Stopping and resting.",
            "Adjusting the speed to his level of tiredness (driving slower the more tired he is).",
            "Turning on the wipers and solving a cryptic crossword.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0042. Define “path”:",
          options: [
            "Part of the width of the road designated for pedestrians only.",
            "A part of the road, designed for motorcycle traffic only.",
            "Lane or part of a lane that is not a road and designated for specific users.",
            "A part of the road, which was marked by the “Israel’s Paths” Company.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0930. What are you required to ensure, among other things, before lifting the car in order to change a wheel?",
          options: [
            "That the handbrake is released until the wheel is taken off and a new wheel is installed.",
            "That the handbrake is released throughout the change of the wheels.",
            "That the car will not move.",
            "There is no connection between the hand (parking) brake and the need to change a wheel.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question: "1677. When is a driver obliged to signal?",
          options: [
            "When he approaches a level crossing with a barrier.",
            "When he intends to change his driving lane.",
            "When he arrives to a long uphill drive.",
            "When he arrives to a steep descent.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0608. What is signified when a traffic light displays red and yellow lights simultaneously?",
          options: [
            "Cross the intersection quickly.",
            "Prepare to drive when the green light appears.",
            "When turning right give right-of-way to pedestrians.",
            "The traffic light will shortly display red light only.",
          ],
          answer: 1,
          image: "img/quiz/3608.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1193. Is a taxi driver exempt from wearing a seat belt while driving?",
          options: [
            "Yes, but only on urban roads, also when there are no passengers in the taxi.",
            "Yes, but only on interurban roads.",
            "Yes, but only on urban roads, when there are passengers in the taxi.",
            "Yes, but only if it is specifically indicated by a traffic sign.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1089. Why is it required to install a safety frame in a tractor?",
          options: [
            "To protect operator from high falling objects.",
            "To protect operator during a collision accident.",
            "To protect the operator./driver in the event that the tractor rolls over.",
            "To protect operator while working between trees and branches.",
          ],
          answer: 2,
          image: "",
          types: ["1"],
        },
        {
          question:
            "1098. What is the function of the tractor’s PTO (“Power Take Off”) power transfer?",
          options: [
            "To operate the oil pump of the hydraulic system.",
            "To operate the front axle in tractors with four wheel drive.",
            "To tow special equipment that requires smart and delicate support.",
            "To move attachments and additional equipment operated by the tractor.",
          ],
          answer: 3,
          image: "",
          types: ["1"],
        },
        {
          question: "0461. What is the meaning of the following traffic sign?",
          options: [
            "“Stop” sign ahead.",
            "“Right-of-way” sign ahead.",
            "Give right-of-way in the roundabout before you.",
            "A distance of about 150 meters to an approaching roundabout.",
          ],
          answer: 0,
          image: "img/quiz/3461.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0423. What is the meaning of the following traffic sign?",
          options: [
            "Making a right turn is forbidden.",
            "Information road sign – T- junction to the right and then T-junction to the left",
            "T-junction to the right.",
            "In the intersection drive right only.",
          ],
          answer: 2,
          image: "img/quiz/3423.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1619. How should you conduct yourself when detecting an oily and slippery area on the roadway while making a turn?",
          options: [
            "You should always try to straighten the motorcycle as much as possible, even if you swerve from your lane as a result.",
            "Brake sharply with both brakes and avoid brake-lock.",
            "Accelerate to improve the road grip of the tires.",
            "You should brake sharply even after entering the slippery area. At such a situation you have nothing to lose.",
          ],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1623. How would we create an efficient safety space in our front, rear and to the side whilst riding in traffic??",
          options: [
            "By positioning ourselves in the middle of the road and driving fast.",
            "By choosing the proper driving lane and speed in relation to the traffic condition around us.",
            "By signaling other vehicles to keep away.",
            "By keeping to the right of the road or of the lane and driving fast.",
          ],
          answer: 1,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0771. How does a multitude of traffic signs before an intersection affect the driver’s response?",
          options: [
            "The driver is forced to slow down in order to identify the signs and obey their demands.",
            "A multitude of traffic signs always confuses the driver.",
            "A multitude of traffic signs will cause the driver to think faster in the intersection.",
            "A multitude of traffic signs should not affect the driver’s conduct when approaching an intersection.",
          ],
          answer: 0,
          image: "img/quiz/3771.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0882. According to the following picture, is it permitted to deviate to the opposite lane?",
          options: [
            "Yes, the driver already looked at the mirror and nobody came behind him.",
            "Yes, you can deviate to the opposite lane after glancing at the mirror, because no one drives behind.",
            "Yes, if the winker is turned on, there is no need to glance at the mirror.",
            "No, due to the double continuous separation line on the roadway.",
          ],
          answer: 3,
          image: "img/quiz/3882.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0431. What are you required to do according to the following road sign?",
          options: [
            "Enter the roundabout carefully since you have right-of-way.",
            "Pay attention to traffic coming from your right within the roundabout.",
            "Stop before entering the roundabout and cross it quickly.",
            "Slow down before the roundabout.",
          ],
          answer: 3,
          image: "img/quiz/3430.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0037. Which of the following is an emergency vehicle?",
          options: [
            "A Red Magen David Ambulance, an Israel Police vehicle, IDF vehicle, Fire-fighting vehicle, integrated policing vehicle or any other vehicle approved by the Authority, when it sounds its emergency signals and operates flashing lights and sounds an alarm signal.",
            "Any ambulance, IDF vehicle, fire-fighting vehicle or other commercial vehicle that was authorized to function as an emergency vehicle by the Licensing Authority",
            "Every vehicle of the Israel Police or the IDF and vehicles that are used for regular security missions in borderline settlements",
            "A vehicle of the Israel Police or the IDF and a fire-fighting vehicle only",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0636. What is the meaning of the following road marking (a continuous separation line)?",
          options: [
            "Separation area on a one-way roadway.",
            "Separation between motor and non-motor vehicles.",
            "Driving on the left of the line is prohibited.",
            "Separation between a public transportation lane and other lanes.",
          ],
          answer: 2,
          image: "img/quiz/3636.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1448. In a roadway having at least two lanes in the same direction of driving, which of the following vehicles must enter an intersection from the right of the two lanes?",
          options: [
            "Commercial vehicles with an all-up weight of 10,000 kg or less.",
            "Commercial vehicles with an all-up weight of more than 4,000 kg.",
            "Motor commercial vehicles with an all-up weight of 6,000 kg or more.",
            "Commercial vehicles and work vehicles with an all-up weight of more than 10,000 kg.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "0670. What is the meaning of the following traffic sign?",
          options: [
            "Road works - drive in the direction of the appropriate arrow.",
            "Lane is closed. No driving in the lane under the traffic sign.",
            "Merge with the traffic in the adjacent lane, from either right or left.",
            "A hairpin turn, either left or right.",
          ],
          answer: 2,
          image: "img/quiz/3670.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0510. What is the meaning of the following traffic sign?",
          options: [
            "No entry for freight-carrying vehicles, the height of which (vehicle and freight), exceeds the height that is indicated in the sign.",
            "No entry for vehicles with a freight width exceeding the width that is indicated in the sign.",
            "No entry for vehicles the height of which is less than the height that is indicated in the sign.",
            "Entry is only permitted for non-motor vehicles with a minimum height of three meters.",
          ],
          answer: 0,
          image: "img/quiz/3418.jpg",
          types: ["C1", "C", "D"],
        },
        {
          question: "0052. The lawful “point system for traffic offenses” is:",
          options: [
            "A system for issuing demerit points to drivers who are convicted of committing traffic offences once every five years.",
            "A system for issuing demerit points to drivers who were convicted of committing offences against the traffic regulations.",
            "A system through which courts are notified about vehicle improper functioning.",
            "A system for registering convictions in criminal offences.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1541. How many years after its manufature will a license of a towing vehicle not be renewed?",
          options: ["19 years.", "15 years.", "11 years.", "23 years."],
          answer: 0,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0916. Which measures would you take in the following situation?",
          options: [
            "Slow down and stop if necessary.",
            "Slow down and honk, to make the pedestrian stop.",
            "There is no need to do anything special, because the pedestrian is far away from you.",
            "Drive faster and move to the left lane.",
          ],
          answer: 0,
          image: "img/quiz/3915.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0097. Is it obligatory to wear a helmet while driving an ATV?",
          options: [
            "It is obligatory to wear a helmet when the ATV doesn’t have a safety frame",
            "No, it is not obligatory to wear a helmet",
            "Yes, the driver and all passengers must wear a helmet while driving",
            "It is obligatory to wear a helmet on a roadway only",
          ],
          answer: 2,
          image: "",
          types: ["C1", "1", "В"],
        },
        {
          question:
            "1384. As of November 1st each year, every commercial vehicle with an all up weight exceeding 10,000 kg should carry:",
          options: [
            "A certification that the lights system functions properly.",
            "A certificate that it had passed a winter check or an annual test that was conducted on August or afterwards.",
            "A certification that the steering system functions properly.",
            "A spedometer calibration certification.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "0285. Is it permitted to drive downhill on neutral gear?",
          options: [
            "It is allowed to shift to neutral gear while driving a public vehicle, a bus or a taxi downhill in a non-urban road only.",
            "It is absolutely forbidden. the gears must always be selected and operated .",
            "It is allowed to shift to neutral gear in any non-urban road which is moderately sloped, in order to save fuel.",
            "It is possible to drive in neutral gear in a moderate slope, where there is no risk of brake overheating, in order to save fuel.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0168. What should you do when the electric signaling system is out of order?",
          options: [
            "Signal with your left hand, as stated by law.",
            "You may continue driving until the closest garage without giving signals - even where signaling is obligatory.",
            "You may continue driving during “light time”. At any other time you may not.",
            "Stop driving.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0224. Are we permitted to ride or drive a vehicle when part of our body is sticking out of the vehicle?",
          options: [
            "It is forbidden, except on a motorbike.",
            "It is only permitted for adult passengers.",
            "Yes, when driving on dirt roads.",
            "Yes, in a collector’s vehicle only.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "1574. The speed limit for an commercial tractor (mule) on an inter-urban road is:",
          options: [
            "According to the manufacturer’s manual.",
            "According to road conditions and traffic signs.",
            "50 k.p.h.",
            "40 k.p.h.",
          ],
          answer: 3,
          image: "",
          types: ["1", "В"],
        },
        {
          question:
            "0005. Is a policeman allowed to detain without a warrant a driver who committed an offence in front of him?",
          options: [
            "Not under any condition",
            "Yes, but only under a court certificate",
            "Yes, if the driver doesn’t give his name and address or doesn’t present his license as required",
            "Yes, only if the driver committed an offence that actually endangered road users",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1418. What is a “freight container”?",
          options: [
            "Shipment tank or box used for transporting goods, equipped with a twist anchor bolt and of a maximum width of 250 cm.",
            "Double walled metal tank used for transportation of fluids and equipped with a vehicle attachment twist lock.",
            "Any fixture with a maximum width of 300 cm, that is separated from the vehicle, used for goods transportation and allows leaving the loading fixture anywhere.",
            "Closed square metal box used for goods transportation and equipped with a vehicle attachment twist lock.",
          ],
          answer: 0,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0053. In what condition should the driver and vehicle’s document be kept?",
          options: [
            "The law doesn’t provide any explicit reference to this issue",
            "It is enough if they are stainless and have a clear photo.",
            "They should be kept at home, clean and readable at all times.",
            "They should always be kept inside the car, clean and readable",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0781. What is the most important principle when turning right in an intersection?",
          options: [
            "To start and to complete the turn close to the right edge of the road.",
            "To stay away from the road’s right edge.",
            "To turn as far as possible from the right sidewalk.",
            "To enter and exit the intersection in a wide arc to the center of the roadway.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0017. What is “night time”?",
          options: [
            "The period of time in which it is required to turn on the vehicle’s parking lights",
            "A period of heavy fog only",
            "The period of time that starts 15 minutes following sunset and ends 15 minutes before sunrise",
            "The period of time between sunset and the following sunrise.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1340. Define “own weight”:",
          options: [
            "The vehicle’s weight including all accessories plus fuel, water and oil, excluding driver and freight.",
            "The vehicle’s weight with freight and with the driver, including the weight of the all accessories plus fuel.",
            "The vehicle’s volume including all accessories plus fuel, water and oil, excluding driver and freight.",
            "The vehicle’s weight including all accessories plus fuel, water and oil, including driver and freight.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "1"],
        },
        {
          question:
            "0267. Who is required to have an escort as a ”new driver” while driving a private passenger car?",
          options: [
            "Anybody receiving for the first time a driver’s license for private passenger cars, and is younger than 30 years of age.",
            "Anybody receiving for the first time a driver’s license for private passenger cars, and is younger than 21 years of age.",
            "Anybody receiving for the first time a driver’s license for private passenger cars or a C1 class license for light trucks.",
            "Anybody receiving for the first time a driver’s license for private passenger cars, and is younger than 24 years of age.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0712. Which of the following vehicles may enter the intersection first?",
          options: [
            "The cyclist (2).",
            "The closest vehicle to the intersection.",
            "The yellow bus (1)",
            "The vehicle turning left (3).",
          ],
          answer: 0,
          image: "img/quiz/3712.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1632. Is it permitted to ride a motorcycle in which the silencer was removed from the exhaust?",
          options: [
            "Yes, but only on a non-urban road.",
            "Absolutely not.",
            "Yes, but only on a freeway.",
            "Yes,",
          ],
          answer: 1,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0944. How are you required to conduct yourself according to the following picture?",
          options: [
            "Continue driving normally, while paying attention to the other vehicles in your direction of driving.",
            "Slow down and allow vehicles to quickly bypass you from the left in order to merge into the left lane.",
            "Drive on the right lane and prepare to move to the left lane as soon as the number of lanes increases.",
            "Move to the left lane, because the right lane is about to terminate.",
          ],
          answer: 0,
          image: "img/quiz/3944.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0406. Which traffic sign should appear after traffic sign 701?",
          options: ["703", "705", "122", "702"],
          answer: 3,
          image: "img/quiz/3405.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0391. Which traffic sign is likely to appear before traffic sign 305?",
          options: ["304", "302", "402", "438"],
          answer: 0,
          image: "img/quiz/3391.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1738. When approaching a vehicle that unloads children, you should:",
          options: [
            "Slow down and drive cautiously at a low speed.",
            "Honk and overtake the vehicle from its left side.",
            "Pull into the “hard shoulders” and pass the vehicle from its right side.",
            "Overtake it quickly to reduce danger.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1378. What is a “speed retarder”?",
          options: [
            "An electric or hydraulic mechanism, independent of the vehicle’s braking system, which decelerates the vehicle when operated.",
            "An electronic system which automatically operates the brakes while driving on a steep decent, thus preventing brake overheating and brake loss.",
            "A system installed in the vehicle that assists in emergency braking and stops the vehicle on the spot.",
            "An electric mechanism installed in the braking system that shuts-off the engine and helps the vehicle to maintain constant speed on all roads.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question:
            "1282. In a student transport vehicle, it is prohibited to drive students:",
          options: [
            "While they are standing.",
            "While sitting.",
            "Without an accompanying guide.",
            "Unless they are all sitting and facing the driver.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0641. What is the marked vehicle required to do when the following marking appears on the road?",
          options: [
            "The driver should not cross the separation line, but he is permitted to make a left U-turn.",
            "The driver of the marked vehicle should drive on the right of the lines, and he is prohibited from crossing the continuous separation line.",
            "The marked vehicle is permitted to cross the unbroken separation line.",
            "The marked vehicle should drive on the left side of the unbroken line.",
          ],
          answer: 1,
          image: "img/quiz/3641.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1708. The one of the functions of the grooves in the tyre tread",
          options: [
            "To facilitate water drainage between the road and the tire and to prevent engine wear.",
            "To facilitate water drainage between the road and the tire and to maintain tire air pressure,",
            "To facilitate water drainage between the road and the tire and to prevent tire wear.",
            "Is too allow water drainage from the tire on wet roads and greater friction and contact with the road..",
          ],
          answer: 3,
          image: "img/quiz/31501.jpg",
          types: ["C1", "A", "1", "В"],
        },
        {
          question:
            "1684.  If a vehicle stops on a road without a pavement when is there an obligation to wear a reflective traffic vest?",
          options: [
            "If one of the passengers wore a vest and stepped out of the vehicle before the driver, then the driver is not required to wear a vest.",
            "When the driver or a passenger alights from a vehicle on an inter-urban road.",
            "If the vehicle has two vests, two persons are permitted to step out of the vehicle wearing vests.",
            "The driver must wear a reflective vest upon stepping out of the vehicle.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1743. If the roadway is marked with an unbroken separation line accompanied by a dotted line to its left:",
          options: [
            "An unbroken line with a dotted line to its left mark a separation area.",
            "It is forbidden to cross these lines.",
            "A dotted line may only appear on the right side of a white separation line.",
            "It is permitted to cross the unbroken line for overtaking only.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1640. What risk do riders of two-wheelers face while passing between two rows of moving cars before an intersection?",
          options: [
            "Being hit by a car that suddenly swerves from its lane to the passage in which the motorcycle is proceeding.",
            "There is no risk, as there is always enough space for two-wheelers to pass comfortably and safely.",
            "The rider might scare drivers who are short-tempered anyway.",
            "The risk is that at the beginning of the row, next to the intersection, there is no more space left because there are many two-wheelers standing there.",
          ],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question: "0477. A round shaped traffic-sign is:",
          options: [
            "An instruction sign.",
            "A direction sign.",
            "A warning sign.",
            "An information sign.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0921. What characterizes the following road?",
          options: [
            "It is a play street. You should adjust your speed appropriately, up to a maximum of 40 k.p.h.",
            "The absence of pedestrians on the roadway; hence there is no risk of being surprised by them. All is in order.",
            "It is an urban road with parked vehicles, and pedestrians might emerge from between the parked vehicles.",
            "The roadway looks very wide and no surprises are expected.",
          ],
          answer: 2,
          image: "img/quiz/3921.jpg",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question:
            "1650. An additional passenger is not permitted on an A.T.V, unless:",
          options: [
            "It is indicated in the insurance policy.",
            "It is indicated in the driver’s license.",
            "A permit is given by the vehicle’s importer.",
            "It is indicated in the vehicle’s registration document.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "1", "В"],
        },
        {
          question:
            "0213. Is it permitted to stop a vehicle opposite to a bus stop?",
          options: [
            "It is absolutely forbidden.",
            "Yes, only during day light and for the purpose of loading passengers.",
            "Yes, provided that the roadway’s width is at least twenty meters.",
            "Yes, provided that the roadway’s width is at least twelve meters.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1048. Which of the vehicle’s systems might cause an uneven wear of the front tires?",
          options: [
            "The air conditioning system.",
            "The steering system.",
            "The braking system.",
            "The electricity system.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0363. What do the two following traffic signs have in common?",
          options: [
            "They both belong to the information-signs group and alert about the road’s condition.",
            "They both advise the driver to slow down and be more alert.",
            "Traffic sign 101 is always placed before traffic sign 144.",
            "Traffic sign 144 is always placed before traffic sign 101.",
          ],
          answer: 1,
          image: "img/quiz/3363.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0157. Where should you stop your vehicle when you are obliged to stop before a railway crossing?",
          options: [
            "In the place with the best field of view, without disturbing train traffic.",
            "After the stop line, eight meters before the proximate rail.",
            "Before the stop line. If there is no stop line – before the barrier.. If there is no barrier – at least four meters before the nearest rail.",
            "Before the barrier. If there is no barrier – six meters before the nearest rail.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1076. A panoramic mirror:",
          options: [
            "Is hard to adjust.",
            "Does not correctly reflect the distance of the objects seen in it.",
            "Is very flexible.",
            "Is very expensive.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0383. Which of the following traffic signs shall be placed in addition to traffic sign 216?",
          options: ["418", "218", "424", "407"],
          answer: 1,
          image: "img/quiz/3383.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0232. How would you seat a child more than three years and less than eight years of age inside a vehicle?",
          options: [
            "In a safety seat or in a booster that fits his height and weight.",
            "Wearing any of the vehicle’s safety belts.",
            "Only in the vehicle’s back seat.",
            "Wearing a three-point safety belt.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "В"],
        },
        {
          question:
            "0457. What are you required to do according to the following road sign?",
          options: [
            "Prepare to stop before the intersection.",
            "Always stop before the railway tracks.",
            "Slow down and even stop before the crossing.",
            "When the barrier’s lights are flickering, drive faster and cross the railway tracks.",
          ],
          answer: 2,
          image: "img/quiz/3456.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0386. What is the meaning of the following traffic sign?",
          options: [
            "End of overtaking-restricted section.",
            "Private passenger vehicles should not overtake trucks.",
            "Buses should not be overtaken.",
            "Trucks should not overtake private passenger vehicles.",
          ],
          answer: 0,
          image: "img/quiz/3386.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1090. How should you do in the event that the tractor rolls over?",
          options: [
            "Jump off the tractor as quickly as possible when it starts to roll-over.",
            "Call for help and try to stabilize the tractor during roll-over.",
            "Hit the brakes and try to reduce the roll-over impact.",
            "Hold the steering wheel tightly and stay as close as possible to it.",
          ],
          answer: 3,
          image: "",
          types: ["1"],
        },
        {
          question:
            "0693. Where would you stop when the traffic light in the intersection ahead displays a red light?",
          options: [
            "After the pedestrian crossing, and if there isn’t one - wherever you have the best field of view.",
            "Before the stop line, and if there is a pedestrian crossing – stop before the crossing.",
            "Always wherever you have the best field of view.",
            "After the stop line, and if there is no stop line - before the intersection line.",
          ],
          answer: 1,
          image: "img/quiz/3190.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1000. How should you use the gearbox while driving on a continuous descent?",
          options: [
            "The gearbox should be in high gear.",
            "The gearbox selector should be in a low gear.",
            "The gear used while driving on a continuous descent is of no importance, as long as the brakes function properly.",
            "The gearbox handle should be in neutral gear.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1296. Under which conditions is it advised to turn on the lights during the day?",
          options: [
            "When the sun is behind the driver.",
            "When the sun is in front of the driver.",
            "When the sun is to the left of the direction of driving.",
            "When the sun is to the right of the direction of driving.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1252. Do the regulation  applying to bus passengers apply also to taxi passengers?",
          options: [
            "Yes, but only during share-taxi rides.",
            "Yes.",
            "No.",
            "Yes, but only during non-urban rides.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question: "1445. Is a tractor permitted to enter a freeway?",
          options: [
            "Yes, but only during daylight.",
            "No.",
            "Yes, unless it tows a trailer.",
            "Yes.",
          ],
          answer: 1,
          image: "",
          types: ["1"],
        },
        {
          question: "1403. Parking at a bus stop",
          options: [
            "Is permitted on Sabbath and holidays for commercial vehicles only, in accordance with the instructions on the sign that is posted in the bus station.",
            "Is permitted on Sabbath and holidays for all types of vehicles.",
            "Is permitted on Sabbath and holidays for private vehicles only, in accordance with the instructions on the sign that is posted in the bus station.",
            "Is permitted on Sabbath and holidays for motorcycles only, in accordance with the instructions on the sign that is posted in the bus station.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question: "1559. When a driver is blinded by the sun, he should:",
          options: [
            "Look as low as possible to the roadway, to avoid blinding.",
            "Overtake the vehicle in front because it is better to drive on an open roadway.",
            "Stay close to the vehicle in front, so it can block the sun for him.",
            "Slow down in accordance with his range of vision and use the sunshade.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question: "0793. What is a “U-turn”?",
          options: [
            "Any turn not in a right angle is called a U-turn.",
            "A turn in which the driver turns back to the direction he came from (turns and drives to the opposite direction)",
            "Only a turn in which the driver drives in a roundabout and returns to the direction he came from.",
            "A turn in which the driver makes a 360 degrees round.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0878. The silver vehicle (1) exits from parking while driving in reverse. What would be your reaction?",
          options: [
            "Honk. Blink your headlights and continue driving.",
            "Swerve to the opposite lane and continue driving normally.",
            "Slow down and prepare to stop (2).",
            "Block the silver vehicle’s driving lane and allow the red vehicle to get out.",
          ],
          answer: 2,
          image: "img/quiz/3878.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0197. Is it permitted to stop, stand or park a vehicle on a freeway (motorway)?",
          options: [
            "Yes, provided that the road edge is wide enough.",
            "No, unless otherwise specifically sign-posted.",
            "No, except for two-wheelers.",
            "No, except for a public vehicle.",
          ],
          answer: 1,
          image: "img/quiz/3147.jpg",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question: "0027. When a driver gives “right of way”, it means that:",
          options: [
            "He merges with traffic without causing an accident",
            "He allows other drivers to continue driving without causing them to change their speed or to stop",
            "He barely manages to push into the traffic flow",
            "He enters the traffic flow in the same speed as the other vehicles.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1507. What road sign permits entry to pedetrians?",
          options: ["226", "413", "All the signs.", "414"],
          answer: 0,
          image: "img/quiz/31507.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1213. What is a bus driver obliged to do once he reaches his final destination?",
          options: [
            "Once the destination stop is announced he is permitted to leave the bus immediately, .without waiting.",
            "He should stay on the bus until the last passenger comes off.",
            "He may leave the bus, provided that he leaves the air-conditioning on.",
            "He should leave the bus straight after shutting off the engine.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0687. When parking a vehicle, is it necessary to operate the parking brake (handbrake)?",
          options: [
            "Only when the vehicle is parked in a steep descent.",
            "Only when the vehicle is parked uphill.",
            "Always.",
            "Only on a wet road.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "1734. How would you drive a motorcycle on a rough non-urban road?",
          options: [
            "Drive slower because there is a risk of losing control.",
            "Drive faster to minimize your stay-time on the rough section.",
            "Continue driving at the same speed but avoid overtaking.",
            "If the motorcycle’s tires are new you can continue driving safely at the same speed.",
          ],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question: "1791. When is there a danger of vehicle skidding?",
          options: [
            "During fog only.",
            "On a narrow road, during a steep uphill climb.",
            "On a dry and hot road.",
            "During the first rain or when there is mud or oil on the road.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0357. How should one pass a structure or a post that obstructs a two-way road?",
          options: [
            "Pass it on the right, unless otherwise indicated by a signpost.",
            "Pass it from the left only.",
            "Pass it according to the road’s condition.",
            "You may pass the obstruction from either side.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0183. Where would you stop according to the following picture:",
          options: [
            "At least 10 meters before the junction.",
            "Before the signpost.",
            "Where you can see the traffic crossing your path.",
            "In any case – before the pedestrian crossing.",
          ],
          answer: 2,
          image: "img/quiz/3183.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1294. A policeman may stop a vehicle and have it checked:",
          options: [
            "After the vehicle involuntarily swerved from its lane.",
            "Due to the level of pollution emitted from the vehicle’s engine.",
            "If he wants to esure that the vehicle’s oil consumption corresponds to the vehicle manufacturer’s manual.",
            "If he wants to ensure that the vehicle’s fuel consumption corresponds to the vehicle manufacturer’s manual.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0960. According to the traffic signs appearing in the picture, the road that turns to the right is:",
          options: [
            "A freeway.(motorway)",
            "An urban road.",
            "A dead-end road.",
            "A road that is neither a freeway nor an urban road",
          ],
          answer: 0,
          image: "img/quiz/3960.jpg",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question: "1731. Which road sign would follow road sign 710?",
          options: ["708", "716", "709", "711"],
          answer: 1,
          image: "img/quiz/31731.jpg",
          types: ["D"],
        },
        {
          question: "0568. What is the meaning of the following traffic sign?",
          options: [
            "Freeway without interchanges.",
            "Entrance to a freeway.",
            "Diagonal merging with a freeway.",
            "End of freeway (Motorway)",
          ],
          answer: 3,
          image: "img/quiz/3568.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1711. What is the meaning of the sign?",
          options: [
            "Portable flashing caution sign situated before a road works.",
            "Portable flashing sign upon leaving an inter-urban road to enter a motorway (freeway).",
            "Portable flashing sign for advertising to drivers.",
            "Portable flashing sign before entering an airport.",
          ],
          answer: 0,
          image: "img/quiz/31711.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0341. Under what conditions will the Licensing Authority approve change of ownership of a vehicle?",
          options: [
            "After the seller pays and presents a certification from the Pledge Registrar.",
            "After the Authority is satisfied that all fees and taxes are paid, that all foreclosures and liens are removed, and that there are no further restrictions.",
            "After the buyer of the vehicle pays the lawful fee including property tax for the parking spot.",
            "Only after the Pledge Registrar certifies that the vehicle is free of any foreclosure or encumbrance.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1414. How should cargo be transported in a vehicle that has no separate driver’s compartment?",
          options: [
            "It is prohibited to transport freight in a vehicle that doesn’t have a separate driver’s compartment.",
            "The vehicle must have a partition that separates and protects the passengers from being hit by the cargo.",
            "It is only permitted to transport freights on platforms.",
            "It is only permitted to transport freight in bulk.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "0644. What is the meaning of the following road marking?",
          options: [
            "It is forbidden to turn left from the left lane.",
            "You have right-of-way on the narrow road over opposite traffic.",
            "Only driving forward is permitted.",
            "Left lane reverts to turning left only.",
          ],
          answer: 3,
          image: "img/quiz/3644.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0807. What is the meaning of the term “driving at a reasonable speed”?",
          options: [
            "Driving at a speed that allows the driver to perform other actions, which are not essential to driving.",
            "Driving in a similar speed to that of the other drivers around you.",
            "Taking account of road and traffic conditions, in a manner that the driver maintains absolute control of the vehicle and can stop safely at all times.",
            "When the driving speed is determined at the driver’s own discretion, and provided that it doesn’t exceed the statutory speed limit.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1502. A driver of a vehicle carrying oversize freight that cannot be overtaken by other drivers is obliged to:",
          options: [
            "Continue driving at normal speed, on the right side of the road. He is prohibited to pull down to the “hard shoulders”.",
            "Slow down, if possible, cross the road’s edge and even stop on the “hard shoulder”.",
            "Drive faster, so he doesn’t disturb the vehicles behind him.",
            "Stop his vehicle and signal to the other vehicles to pass him by.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1579. On a road designated for vehicle traffic, how is it required to stand a motorcycle with a sidecar during “light time” when the road is not lit?",
          options: [
            "With the head light or parking light on.",
            "With the brake light on.",
            "With the rear light and parking lights on.",
            "With only the parking light on.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1002. What is the function of the vehicle’s Electronic Stability Program (ESP) System?",
          options: [
            "To control the engine’s fuel consumption.",
            "To prevent the wheels from locking.",
            "To reduce the danger of skidding.",
            "To reduce the emission of burnt gases from the engine.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1270. Is a public minibus without passengers permitted to use a designated bus lane?",
          options: [
            "Yes.",
            "Yes, but only on Saturdays and holidays.",
            "No.",
            "Dotted lines mark the approach of a separation line. The driver should move to the left lane before the beginning of the separation line.",
          ],
          answer: 0,
          image: "img/quiz/31269.jpg",
          types: ["D"],
        },
        {
          question:
            "0109. What should you check before beginning to make a right-turn?",
          options: [
            "That the left mirror is adjusted correctly.",
            "That there is no vehicle driving on my right.",
            "That no vehicle is parked on the left side of the road.",
            "That there is no vehicle driving on my left.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0450. What are you required to do according to the following road sign?",
          options: [
            "Slow down and prepare for a possible stop if necessary, before the railway tracks.",
            "Stop 2 meters before the railway tracks and check if a train is approaching.",
            "Drive faster and quickly cross the railway tracks.",
            "If you see the train’s lights, stop 30 meters before the railway tracks.",
          ],
          answer: 0,
          image: "img/quiz/3449.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1232. As far as the law prohibiting smoking in public places is concerned, is a bus considered to be a public place?",
          options: [
            "No.",
            "Yes, but only when carrying more than 10 passengers.",
            "Yes.",
            "Only when carrying more than 15 passengers.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1256. Which body authorizes and performs taxi license change of ownership?",
          options: [
            "The Licensing Authority.",
            "The Controller of Road Transport.",
            "The Public Transportation and Railway Authority.",
            "There is no need for a license-conveyance permit, as it contradicts the freedom of occupation choice.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1058. What is considered as a proper vehicle maintenance frequency?",
          options: [
            "As required, and according to the pace in which malfunctions are detected in the vehicle.",
            "At the discretion of the vehicle’s owner only.",
            "The maintenance frequency recommended by the vehicle’s manufacturer.",
            "As recommended by the insurance company.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question:
            "0265. When pedestrians are forced to walk on the road way, on which side of it should they walk?",
          options: [
            "Next to the right “hard shoulder”.",
            "Next to the left sidewalk.",
            "Next to the road’s left edge, when they are facing the incoming traffic.",
            "Next to the road’s right edge.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0209. Is it permitted to stop a vehicle in a one-lane two-way roadway that is divided by a continuous white line?",
          options: [
            "Yes, during “light time” only.",
            "Yes, when the lane’s width exceeds 2.5 meters.",
            "Yes, in an urban road only.",
            "It is absolutely forbidden.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0806. What is the effect of driving at  a high speed during an accident?",
          options: [
            "The driver’s response time is significantly shortened, and safety is enhanced.",
            "High speed does not influence the outcome of the accident.",
            "It causes greater force of collision and a more severe impact on humans and damage to property.",
            "The driver’s response time is lengthened, and safety is thus enhanced.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0738. Before starting to drive, what action needs to be taken by the driver before moving the automatic transmission selector?",
          options: [
            "Release the clutch pedal, to connect between the engine and the gear box.",
            "Step on the accelerator, to start driving immediately.",
            "Release the parking brake, to release the gearbox lock.",
            "Apply the foot-brake, to prevent the vehicle from moving.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "D", "В"],
        },
        {
          question:
            "1790. Is there a connection between the driver’s judgment and the use of drugs and alcohol?",
          options: [
            "Alcohol undermines the driver’s judgment, unlike drugs.",
            "They both have no impact on the driver’s judgment.",
            "Drugs undermine the driver’s judgment, unlike alcohol.",
            "They both impair the driver’s judgment.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1789. When are you required to check engine oil level?",
          options: [
            "After starting-up the engine in the morning.",
            "Before starting-up the engine and on a straight surface.",
            "At the end of a drive and on a straight surface.",
            "After starting-up the engine and on a straight surface.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "В"],
        },
        {
          question: "0439. What is the meaning of the following traffic sign?",
          options: [
            "Careful, danger of sliding.",
            "Traffic congestion ahead (illuminated sign).",
            "Careful, men at work.",
            "Caution! Keep distance.",
          ],
          answer: 1,
          image: "img/quiz/3439.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1401. Is it permitted to drive a vehicle when one of the wheels of its trailer or semi-trailer is not in contact with the road (due to the way in which it was connected or loaded to the vehicle)?",
          options: [
            "Yes, when loading heavy freight on the rear extension.",
            "Yes, subject to the approval of a police officer and with proper escort.",
            "Yes, if the cab is very high and the semi-trailer’s front wheels are lifted.",
            "No.",
          ],
          answer: 3,
          image: "",
          types: ["C"],
        },
        {
          question: "0686. What is the meaning of the following traffic sign?",
          options: [
            "Road works ahead.",
            "Careful! Speed humps ahead.",
            "Pedestrians ahead.",
            "Careful! Rough road ahead.",
          ],
          answer: 0,
          image: "img/quiz/3416.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1262. What does the following road sign signify?",
          options: [
            "A place in which taxi-passenger loading and unloading is prohibited.",
            "A place in which taxi-passenger loading and unloading is permitted, without waiting.",
            "A place in which the loading and unloading of public vehicle passengers is permitted, without waiting.",
            "A place for loading and unloading, as well as for waiting, for taxi passengers.",
          ],
          answer: 1,
          image: "img/quiz/3572.jpg",
          types: ["D"],
        },
        {
          question: "0441. What is the meaning of the following traffic sign?",
          options: [
            "Railway crossing with more than one track.",
            "Railway crossing ahead.",
            "Warning sign before a train station.",
            "Warning sign, 500 meters before a railway crossing.",
          ],
          answer: 1,
          image: "img/quiz/3441.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0757. Is it permitted to park, in an urban area, vehicles transporting hazardous materials with an all up weight of more than 3,500 kg?",
          options: [
            "Yes, only when the tanks are empty.",
            "Yes, provided that nobody smokes next to it.",
            "Yes, but only at a distance of more than 400 meters from public or residential buildings.",
            "It is absolutely forbidden to park this type of vehicle on an urban road.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "В"],
        },
        {
          question:
            "0977. How would you conduct yourself on the following road section?",
          options: [
            "Stop before the container in case pedestrians are crossing from its other side.",
            "Drive faster to avoid a possibility of pedestrians crossing after the container.",
            "Slow down and honk a few times before passing the line of the container.",
            "The close garbage container obstructs your field of vision; hence you have to slow down.",
          ],
          answer: 3,
          image: "img/quiz/3977.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0522. Traffic sign 424 remains valid until the following sign is posted:",
          options: ["301", "220", "432", "425"],
          answer: 3,
          image: "img/quiz/3522.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1397. Is it obligatory to use an appropriate lower gear on every steep descent?",
          options: [
            "No.",
            "Only on steep descents of at least one kilometer.",
            "Yes.",
            "Only when a “descent ahead” sign is placed at the beginning of the descent.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0891. It is allowed to make a U-turn:",
          options: [
            "Only when there is an unbroken white line.",
            "From every lane, unless it is prohibited by a specific road sign.",
            "Only in an intersection with traffic lights, where a left turn is permitted.",
            "Only in an intersection without traffic lights where a left turn is permitted.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0531. What is the meaning of the following traffic sign?",
          options: [
            "4-wheeled vehicles only are permitted to use the roadway or the lane.",
            "Designated lane for trams, buses, taxis and vehicles that transport at least the number of passengers that is indicated in the sign.",
            "All vehicles are permitted to use the roadway or the lane, except for trams and electric buses.",
            "A lane prohibited for public transportation.",
          ],
          answer: 1,
          image: "img/quiz/3531.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0623. What is the meaning of the following traffic signs?",
          options: [
            "Give right-of-way on a narrow road.",
            "The number of lanes on the road is decreasing.",
            "Careful! One-way traffic ahead.",
            "Entrance to a single lane one-way road.",
          ],
          answer: 1,
          image: "img/quiz/3623.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1393. According to the driving work hours’ regulation, can freight-handling be accounted as driving?",
          options: [
            "No. Other than breaks and waiting periods, all other vehicle related activities are not accounted as driving.",
            "Yes. Freight handling, arranging, loading and unloading is lawfully accounted as driving hours.",
            "Yes, but not more than one hour a day.",
            "No. Only actual driving, and not any other vehicle related action, is accounted as driving.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0847. What are the reasons for a vehicle skidding on a road?",
          options: [
            "Late and sudden braking and the presence of elements that prevent traction on the road’s surface.",
            "Roads with a high coefficient of friction.",
            "Driver tiredness.",
            "Tires that are not worn out cause vehicle skidding.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1091. Why are tractor wheels being filled with water?",
          options: [
            "To improve driving quality and comfort on dirt roads.",
            "To prevent tire overheating during particularly hard works.",
            "To increase its weight and thus improve the wheels’ traction on the road.",
            "It is a convenient way to carry water for different purposes while working.",
          ],
          answer: 2,
          image: "",
          types: ["1"],
        },
        {
          question:
            "1081. The level of which types of fluids is a driver required to check in his vehicle?",
          options: [
            "Clutch oil and engine oil.",
            "Steering wheel oil and clutch oil.",
            "Brake fluid and air-condition gas.",
            "Engine oil, coolant, brake fluid and windshield cleaning fluid.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "В"],
        },
        {
          question: "1145. Is it required to have a stretcher in every bus?",
          options: [
            "No, except in minibuses transporting up to 20 passengers.",
            "No, except in buses driving on excursion or sightseeing tours.",
            "No, except in buses driving on city rides.",
            "No, except in buses driving on intercity rides..",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0038. Is it permitted to drive passengers for hire in a private passenger car?",
          options: [
            "Yes, if a contract is signed between the driver and the passengers",
            "Yes, if the vehicle is equipped with a standard chassis, approved by the Licensing Authority",
            "No",
            "No, except with a permission of the Tax Authorities",
          ],
          answer: 2,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1435. What is the duty of a driver of a vehicle with an all up weight of more than 3,500 kg, while standing his vehicle on a inter-urban road during lighting-up time?",
          options: [
            "He must always place a warning triangle, and also place a portable light or turn on the four winkers (distress lights).",
            "He must turn on the four winkers and the side lights.",
            "He must place a warning triangle and turn on the right winker.",
            "He must turn on the four winkers and place a portable light.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question: "0428. What is the meaning of the following traffic sign?",
          options: [
            "T- junction to the right and then T-junction to the left",
            "Warning sign before a sharp right turn followed by a sharp left turn.",
            "Left T-Intersection followed by right T-Intersection ahead.",
            "Give right-of-way to vehicles from your right and then from your left.",
          ],
          answer: 0,
          image: "img/quiz/3428.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0845. While turning, what natural force pushes the vehicle against the direction of the turn?",
          options: [
            "Gravity.",
            "Centrifugal force.",
            "The force of inertia.",
            "None of the natural forces affect the vehicle while turning.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1796. Is it permitted to drive a passenger on a motorcycle while both his legs are on one side of the motorcycle?",
          options: [
            "Yes, but only while driving on a non-urban roadway.",
            "No. It is only permitted to carry a passenger that sits astride the motorcycle.",
            "Yes, but only while driving on a freeway.",
            "Yes, but only while driving on an urban roadway.",
          ],
          answer: 1,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0210. Is it permissible to stop a vehicle next to another vehicle that is parking at the side of the road?",
          options: [
            "Yes, in an urban road only.",
            "It is absolutely forbidden.",
            "Yes, during “light time” only.",
            "Yes, when the lane’s width exceeds 2.5 meters.",
          ],
          answer: 1,
          image: "img/quiz/3210.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0130. When is it forbidden to overtake, or to try overtaking, another vehicle?",
          options: [
            "In a distance of 300 meters before a railway crossing.",
            "When visibility is poor or when the field of vision is obstructed or limited.",
            "During night time.",
            "When driving uphill.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0551. What are you required to do according to the following traffic sign?",
          options: [
            "Give right-of-way to traffic crossing your path on the roundabout,",
            "Drive right or left. A yellow arrow shows the direction of driving for public transportation only.",
            "When you are driving in the roundabout, give right-of way to vehicles that approach the roundabout from its different ‘arms’.",
            "Pass the place that is marked with a signpost from its left only.",
          ],
          answer: 0,
          image: "img/quiz/3550.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0630. What is the meaning of the following traffic sign?",
          options: [
            "Play street. No parking.",
            "End of play street.",
            "An area in which children are allowed to play when accompanied by an adult only.",
            "Speed limit beyond this point is 30 k.p.h.",
          ],
          answer: 1,
          image: "img/quiz/3630.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1441. What is a driver of a vehicle carrying oversize freight required to do when due to road conditions he cannot be overtaken by other drivers?",
          options: [
            "Drive until the nearest parking bay or bus station bay and stop to allow other vehicles to overtake him.",
            "Turn on the four distress winkers, and continue driving on the right of the road way.",
            "Swerve his vehicle as far to the right of the roadway as possible, but avoid pulling into the “hard shoulder” because the vehicle’s weight might cause the “shoulder” to collapse.",
            "Slow down and, if necessary, pull into the “hard shoulder” and even stop, to allow the vehicles behind him to overtake safely.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "1762. When a vehicle stops before a level crossing:",
          options: [
            "It is permitted to overtake it, unless there is an unbroken separation line,",
            "It is permitted to overtake it, unless the roadway’s width is less than 6 meters.",
            "It is forbidden to overtake it.",
            "It is only permitted to overtake it in case of a level crossing with a barrier.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1656. What should you check when purchasing a new tire for your vehicle?",
          options: [
            "Only that the air pressure of the new tire is identical to that of the old tire.",
            "Only that the size of the new tire is identical to that of the old tire.",
            "Only that the shape of the tire’s sole (the tire’s surface) is identical to that of the old tire.",
            "The tire’s manufacture date, to ensure it is not too old (over 6 years).",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1218. Is it permitted to let off passengers on a freeway?",
          options: [
            "Yes.",
            "Yes, but only during lighting up time.",
            'Yes, but only on the \\"hard shoulders\\" and during day time.',
            "No.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "0642. What are you required to do when the following road marking appears on your left?",
          options: [
            "You are prohibited from crossing the unbroken separation line.",
            "Slow down and move to the left of the road.",
            "You should always drive on the right side of the lines.",
            "You may cross the lines when the broken line is on the right of the continuous line.",
          ],
          answer: 3,
          image: "img/quiz/3642.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1183. It is prohibited to drive too close to the vehicle in front of you:",
          options: [
            "During the dark only.",
            "Only when the weight of the vehicle in front is more than 4 tons.",
            "On a steep descent and only after a “dangerous descent” sign.",
            "Because it might brake suddenly.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0148. What is the maximum permitted speed for driving an all-terrain vehicle?",
          options: [
            "30 KPH.",
            "50 KPH in any type of road.",
            "60 KPH in a freeway and 40 KPH in any other type of road.",
            "40 KPH in any type of road.",
          ],
          answer: 3,
          image: "img/quiz/3139.jpg",
          types: ["C1", "1", "В"],
        },
        {
          question:
            "1125. When is it obligatory to operate the vehicle’s different types of decelerators or retarders ?",
          options: [
            "When driving on a road section before which a “sharp curve” sign is placed.",
            "When driving on a road section before which a “danger of slipping” sign is placed.",
            "When driving on a road section before which a “hairpin curve” sign is placed.",
            "When driving on a road section before which a “dangerous descent” sign is placed.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1583. What is the minimum age requirement for carrying another passenger on a motorcycle?",
          options: [
            "17.5 yrs.",
            "18 yrs.",
            "16 yrs.",
            "Age is of no significance.",
          ],
          answer: 1,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0485. What are you required to do according to the following road sign?",
          options: [
            "Slow down, rough road ahead.",
            "Slow down because of road-works.",
            "Slow down and cross the speed bumps With caution.",
            "Slow down and prepare to bypass obstacles on the road.",
          ],
          answer: 2,
          image: "img/quiz/3484.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0589. What are you required to do according to the following traffic sign?",
          options: [
            "Honk and signal drivers coming from the opposite direction that you intend to yield.",
            "Stop and allow vehicles coming from the opposite direction to continue driving.",
            "Reduce speed and check if the vehicle coming from the opposite direction yields as required.",
            "Continue driving, take right of way, and you are not obliged to slow down.",
          ],
          answer: 2,
          image: "img/quiz/3579.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1712. Vehicle registration document shall not be issued or renewed for a taxi:",
          options: [
            "That was manufactured more than 4 years ago.",
            "After more than 12 years from its day of manufacture",
            "That was manufactured more than 8 years ago.",
            "That was manufactured more than 10 years ago.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0773. Four parking spaces for handicapped drivers are marked, one next to the other, in Herzel Street. All four spaces are currently free:",
          options: [
            "Drivers that do not display the handicap card showing the same sign as The road sign, even if they are free at the moment cannot use the parking spaces",
            "Non-handicapped drivers are permitted to use the handicapped parking spaces for a short while, as long as they leave a phone number in which they can be reached at any moment.",
            "Non-handicapped drivers are permitted to use the handicapped parking spaces for a short while, as long as at least half of these spaces are free.",
            "Non-handicapped drivers are permitted to use the handicapped parking spaces for a moment, as long as they stay in the vehicle while it is parked.",
          ],
          answer: 0,
          image: "img/quiz/3216.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0182. Where should you stop according to the following picture:",
          options: [
            "Before and close to the stop line.",
            "You can stop anywhere, as long as you stop before entering the intersection.",
            "Before the signpost.",
            "After the pedestrian crossing, but before the intersection.",
          ],
          answer: 0,
          image: "img/quiz/3182.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0093. In a properly functioning traffic light – how many times does the green light blinks before it turns yellow?",
          options: [
            "Four times.",
            "Three times.",
            "One time.",
            "It varies according to traffic loads.",
          ],
          answer: 1,
          image: "img/quiz/3093.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1030. What is the consequence of tyre pressure that is too low?",
          options: [
            "Low tire air pressure has no effect on driving.",
            "Increased driving noise.",
            "Increased fuel consumption and air pollution.",
            "Easy-to-turn steering wheel.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "A", "В"],
        },
        {
          question:
            "1066. Where would you place the jack stand while changing a wheel?",
          options: [
            "The place is insignificant.",
            "In the place that was marked by the manufacturer, or in another strong and safe place, close to the wheel you want to replace.",
            "As close as possible to the vehicle’s rear.",
            "At the most convenient place for the driver.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0192. What should you do when your driving lane is obstructed?",
          options: [
            "You should use the horn and cross the obstacle in front.",
            "Go! You have “right of way” in the road that is in front of you.",
            "You should pass through the sidewalk, if it is free.",
            "You should give right-of-way to vehicles that are coming from the opposite side.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1358. In a  vehicle, towing a trailer the distance between the rear part of the towing vehicle and the front part of the trailer should not be more than:",
          options: [
            "250 cm.",
            "150 cm.",
            "400 cm.",
            "There is no distance restriction between the towing vehicle and the trailer.",
          ],
          answer: 1,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0476. What are you required to do according to the following road sign?",
          options: [
            "Give right-of-way to vehicles and pedestrians.",
            "Slow down and shift into neutral gear.",
            "Cross this road-section quickly.",
            "Slow down and be very cautious. Prepare for a possible sudden braking.",
          ],
          answer: 3,
          image: "img/quiz/3475.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0854. How can you reduce the effect of being blinded by a vehicle approaching from your opposite direction?",
          options: [
            "By properly adjusting the headlights beam, and flickering to the driver in front.",
            "By not looking into the blinding light, and by slowing down and driving on the right of the road.",
            "By turning on the windshield wipers.",
            'By adjusting the inside mirror to \\"night\\" position.',
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0344. How many number plates should be installed on a private passenger car?",
          options: [
            "One plate only in front,",
            "One plate only at the vehicle’s rear..",
            "Two standard plates, one in front and one at the vehicle’s rear.",
            "As determined by the vehicle’s owner.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0113. The following illustration shows an intersection with traffic signs. What is the correct manner of making a turn from street A to street C?",
          options: [
            "From lane 1 to lane 4.",
            "From lane 1 or from lane 2 to lane 4.",
            "From lane 2 to lane 3.",
            "From lane 2 to lane 4.",
          ],
          answer: 3,
          image: "img/quiz/3113.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1292. What should a driver do when he approaches a road section on which oil was spilled?",
          options: [
            "Drive faster and avoid stepping on the brake pedal.",
            "Slow down as moderately as possible, and avoid heavy l braking.",
            "Move the wheel left and right intermittently while driving on the oil.",
            "Continue driving normally, because the oil helps to increase tire-road friction.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1007. What is the meaning of the signs?",
          options: [
            "Directional, above the lanes before an interchange on an Inter –urban road or a Motorway (Freeway).",
            "Directional, to a central area or town.",
            "Directional, to lanes before a junction, and positioned on the left.",
            "Directional to lanes before a junction and placed on the right.",
          ],
          answer: 0,
          image: "img/quiz/31007.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0007. A police officer may revoke a driver’s license for a period of sixty days:",
          options: [
            "When the driver commits a traffic offence which doesn’t cause a road accident",
            "When a driver involved in an accident is reasonably suspected of being responsible for causing injury or causing damage to property.",
            "When the driver is caught speeding",
            "When the driver commits a traffic offence in which a person is killed",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1055. What should a driver do when he detects a safety failure in the steering wheel or brakes while driving?",
          options: [
            "Continue driving carefully until the nearest garage.",
            "He should stop driving only if the failure is in the ignition or fuel system.",
            "Stop driving immediately.",
            "Identify the source of the problem and try to overcome it.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question:
            "0879. What should a driver do when he intends to turn right?",
          options: [
            "The driver should honk and continue turning without any further note. The cyclist is the one that should be careful.",
            "Take into account the chance that there will be a cyclist to his right during the turn, and take measures to avoid hitting him.",
            "The driver should plan his turn according to the traffic rules, without taking note of the cyclist.",
            "The driver should stop his vehicle before the turn and wait until the cyclist signals that he can pass.",
          ],
          answer: 1,
          image: "img/quiz/3879.jpg",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "0398. A license of a new driver  shall  be extended for two years as a new driver license, if the driver was convicted of the following offence:",
          options: [
            "Not stopping where a “right of way” signpost is placed.",
            "Not placing a “new driver” sign on the vehicle’s rear window.",
            "Driving a motor vehicle without valid insurance.",
            "Not stopping before a level crossing, when stopping is imperative.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0343. What does the law state about the non-use of a vehicle  for a period of more than one month?",
          options: [
            "The vehicle should be placed in a licensed garage.",
            "The Local Authority should be informed.",
            "The Police should be informed.",
            "The Licensing Authority should be informed.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0659. What is the meaning of the following road marking?",
          options: [
            "Parking area for public vehicles.",
            "A marked traffic island in the center of the roadway.",
            "No parking.in this area",
            "A place where parking is permitted for a short time.",
          ],
          answer: 2,
          image: "img/quiz/3659.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0553. What is indicated by the following traffic sign?",
          options: [
            "Roundabout ahead.",
            "Active fountain.",
            "It is also permitted to drive against the direction of traffic.",
            "The driver must make a U-turn.",
          ],
          answer: 0,
          image: "img/quiz/3550.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0785. What is the meaning of the sign?",
          options: [
            "Move to the next lane on the right when traffic conditions allow.",
            "Pass the obstacle on the right side.",
            "Go to the right only.",
            "Special lane for public transport.",
          ],
          answer: 0,
          image: "img/quiz/3785.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1015. What are the side effects of using malfunctioning windshield wipers?",
          options: [
            "Damaging the vehicle’s engine.",
            "Causing noises in the sound system within the driver’s compartment.",
            "Impaired windshield visibility, as well as windshield damage.",
            "Damaging the windshield’s sealing.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0188. What should you do when you are about to start driving after stopping or parking at the side of the roadway?",
          options: [
            "You should signal with your left hand and swiftly merge with traffic. .",
            "You should, before starting to drive, signal and then get on the road quickly.",
            "Make a left-signal and drive. The other vehicle should give you right-of-way.",
            "You should signal appropriately and ensure that you can merge with the traffic without endangering other road users.",
          ],
          answer: 3,
          image: "img/quiz/3188.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1540. “Reasonable speed” is a speed:",
          options: [
            "That is adjusted to the road conditions and circumstances and does not exceed the speed limit.",
            "The speed limit on that road, under all circumstances.",
            "That the vehicle passengers wish to drive at. The passengers are always right.",
            "That is adjusted to the conditions and circumstances, and always exceeds speed limit.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1750. When a traffic light displays a stable (not flashing) yellow light:",
          options: [
            "It indicates that the flashing yellow light is about to appear. You should drive faster and clear the intersection immediately.",
            "It indicates that the flashing green light is about to appear. You should drive faster and clear the intersection immediately.",
            "It indicates that the red light is about to appear. You should prepare to stop or clear the intersection immediately.",
            "It indicates that the stable (not flashing) green light is about to appear and you would be able to continue driving.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0112. The following illustration shows an intersection with traffic signs. What is the correct manner of making a turn from street A to street C?",
          options: [
            "From lane 1 or from lane 2 to lane 6.",
            "From lane 1 or from lane 2 to lane 5.",
            "From lane 2 to lane 5.",
            "From lane 2 to lane 3.",
          ],
          answer: 2,
          image: "img/quiz/3112.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0926. How are you required to conduct yourself according to the following picture?",
          options: [
            "Slow down and continue driving. It seems, from the looks of the pedestrians, that they noticed your intention to continue driving.",
            "The pedestrians almost finished crossing. Continue driving and pass them carefully.",
            "Honk continuously to the pedestrians so that they allow you to continue driving.",
            "Stop, pedestrians are crossing the roadway.",
          ],
          answer: 3,
          image: "img/quiz/3926.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1408. Is a policeman permitted to stop a vehicle that carries freight in an unlawful manner?",
          options: [
            "Yes, a policeman is permitted to stop the vehicle until the freight is unloaded or until the matter is lawfully settled.",
            "No, unless the freight endangers other road users.",
            "Yes, but only if the vehicle’s overall weight is 20% higher than the statutory weight.",
            "No, only a police officer ranked superintendent or higher may stop a vehicle.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1084. Which additional system, absent in other types of vehicles, is found in tractors?",
          options: [
            "A hydraulic system for lifting and operating attachments.",
            "A standard electro-hydraulic steering system.",
            "An Air brake system with a condensed air system.",
            "A hydrostatic differential-lock system.",
          ],
          answer: 0,
          image: "",
          types: ["1"],
        },
        {
          question:
            "1428. What is the maximum permitted height (together with freight) of a vehicle with an all up weight of 15,000 kg or less?",
          options: [
            "3.5 meters, provided that it is in one unit.",
            "Up to 4.80 meters.",
            "4 meters.",
            "Up to 4.40 meters.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0323. To whom should a holder of a driver’s license or vehicle registration report a change of address within 15 days?",
          options: [
            "The Ministry of the Interior.",
            "The Ministry of transportation.",
            "The Israel Police.",
            "The court.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0776. What are the specific risks and problems in crossing intersections?",
          options: [
            "Many and various contact points between the different types of vehicle through the intersection.",
            "Crossing the intersections takes a long time; an upsetting fact for drivers.",
            "The fact that vehicles approach the intersection from different directions causes no problem. The problem is only with pedestrians.",
            "Specific risks and problems are present only in intersections with traffic lights.",
          ],
          answer: 0,
          image: "img/quiz/3776.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1745. When a white unbroken separation line is marked on the roadway, is it permitted to overtake a vehicle that is lawfully driving on the road’s “hard shoulders”?",
          options: [
            "Yes, but provided that the overtaken vehicle is a motorcycle.",
            "No, it is prohibited to overtake.",
            "Yes, but provided that the overtaking vehicle doesn’t cross the unbroken white line with any of its wheels.",
            "Yes, but only if the overtaking vehicle is slow.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0145. When is it permitted to perform an emergency stop?",
          options: [
            "After signaling a distress sign to the vehicle behind you only.",
            "In order to prevent a road accident that cannot be prevented in any other way.",
            "When the driving speed doesn’t fit with the road conditions.",
            "When the road is distorted.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0622. What is the meaning of the following traffic signs?",
          options: [
            "The road is becoming wider.",
            "The number of lanes on the road is increasing.",
            "Left or right turn ahead.",
            "It is permitted to stop on the left side of the road.",
          ],
          answer: 1,
          image: "img/quiz/3622.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0257. When driving in reverse:",
          options: [
            "You must use another person to guide you.",
            "You must use another person to guide you on highways..",
            'You must use another person to guide you during \\"lighting up time\\".',
            "You are permitted to use another person to guide you.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1203. Is it obligatory to install a reverse buzzer on a bus?",
          options: [
            "No.",
            "Yes, it is obligatory to install a buzzer, which will function automatically when shifting to reverse gear.",
            "It is only obligatory for taxis.",
            "It is only obligatory for public minibuses.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0653. A “new driver” that has not yet completed 2years or has not yet turned 21 years will not carry in a private passenger car:",
          options: [
            "More than one passenger besides himself, unless sitting beside him is a qualified escort.",
            "Any passengers for as long as he is a “new driver”.",
            "More than two passengers apart from himself, unless sitting beside him is a qualified escort.",
            "More than four passengers besides himself, unless sitting beside him is a qualified escort.",
          ],
          answer: 2,
          image: "img/quiz/31688.jpg",
          types: ["C1", "В"],
        },
        {
          question: "1462. When you are driving a truck down a steep descent:",
          options: [
            "Drive down on high gear and stop the vehicle every few kilometers.",
            "Drive down on neutral gear and use the brakes according to need.",
            "Drive in an appropriate low gear and make sure that the vehicle’s special deceleration mechanisms are operating.",
            "The use of gears to brake the vehicle is of no significance.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1334. What is the maximum freight height (from the road’s surface) that may be carried by a commercial vehicle with an all up weight of up to 3,500kg?",
          options: ["3.5 meters.", "3.0 meters.", "4.0 meters.", "2.5 meters."],
          answer: 1,
          image: "",
          types: ["C1", "В"],
        },
        {
          question: "1114. Define “private Autobus”:",
          options: [
            "An Autobus not for the general use of the public.",
            "A public minibus.",
            "Any vehicle driving passengers privately for hire.",
            "A private vehicle which is a bus.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1405. Can the details specified in the bill of lading serve as proof of the freight’s weight?",
          options: [
            "Yes, the freight’s weight indication in the bill of lading serves as proof of the correct weight, within a margin of 20% above or below the indicated weight.",
            "Yes.",
            "No, the weight indicated in the bill of lading is not a final, solid and accepted fact.",
            "Yes, but only for freights in a maximum weight of 10,000 kg.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0740. How would you conduct yourself in a vehicle with automatic transmission, when driving down a continuous steep road?",
          options: [
            "Apply the brake pedal intermittently to slow the vehicle down and shift into a lower gear, depending on the slope.",
            "Shift into high gear according to the slope and step continuously on the brake pedal to slow down.",
            "On a steep and continuous downhill road, use the brake system only, without shifting into an adjusted gear.",
            "On a steep and continuous downhill road, shift into an appropriate gear and continue driving.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question: "1728. When you squeeze the motorcycle’s clutch lever:",
          options: [
            "You disconnect the engine from the gearbox.",
            "You connect between the engine and the gearbox.",
            "The motorcycle stops on the place.",
            "The engine’s fuel consumption increases.",
          ],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1509. How can we overcome the problem of tiredness while driving?",
          options: [
            "Have an adequate rest before driving and take rest breaks during the journey.",
            "A short break and a heavy meal will drive the tiredness away for a long time.",
            "Drive slowly until the tiredness passes away.",
            "Physical exercise will drive the tiredness away for many hours.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0853. How can you manage to keep your braking distance within the range of your vehicle’s lights while driving at night?",
          options: [
            "By using an additional spot light to detect road hazards.",
            "By using the rear fog light to reinforce your head lights beam.",
            "By turning on the long-distance high beam and driving at a high speed accordingly.",
            "By adjusting your driving speed to your field of vision at all times.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0961. According to the traffic signs appearing in the picture, the road that turns to the left is:",
          options: [
            "A restricted non-urban road.",
            "A freeway.",
            "A inter-urban road.",
            "Not a main road.",
          ],
          answer: 2,
          image: "img/quiz/3960.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1584. How many passengers, besides the driver, is it permitted to carry on a motorcycle and a motorctcle with sidecar?",
          options: [
            "The number of passengers indicated in the driving license.",
            "The number of passengers indicated in the motorcycle’s insurance policy.",
            "The number of passengers approved by the Licensing Authority noted on the vehicle’s registration document.",
            "As the number of seats suitable for additional riders.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0928. Assuming that the vehicles in the following picture are functioning properly – Which traffic offence was committed according to the picture?",
          options: [
            "Standing and stopping between two lanes.",
            "Not driving on the designated lane for your direction of driving.",
            "Standing in a position that is unparallel to the separation area.",
            "Standing and stopping on a traffic island and preventing the free flow of traffic.",
          ],
          answer: 3,
          image: "img/quiz/3928.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0115. The following illustration shows an intersection with traffic signs. What is the correct manner of making a turn from street A to street B?",
          options: [
            "From lane 2 to lane 4.",
            "From lane 2 to lane 3 or to lane 4.",
            "From lane 1 to lane 4.",
            "From lane 1 to lane 3 or to lane 4.",
          ],
          answer: 2,
          image: "img/quiz/3115.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0808. What problem faces a vehicle driver who is driving at a  high speed?",
          options: [
            "His response time is decreased, and thus his driving becomes more dangerous.",
            "There is no problem at all: Research work has proven that in high speeds driving is more calm and relaxed.",
            "The driver has very little time to properly respond to road events.",
            "The driver's response time is significantly lengthened.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0542. What is the meaning of the following traffic sign?",
          options: [
            "Turn leftt- turn before the sign.",
            "Entry to a one-way road.",
            "Drive left only. U-turn is prohibited.",
            "Turn left behind the sign.",
          ],
          answer: 0,
          image: "img/quiz/3541.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0198. How would you mark a vehicle that was forced to be left standing in the road?",
          options: [
            "Place a blinking flashlight to be seen to a distance of 30 meters.",
            "Turn on the two front winkers.",
            "Turn on the headlights during “light time”.",
            "Place a warning triangle behind the vehicle that can be seen from at least 100mtrs.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "1479. It is obligatory to install on the rear of a commercial vehicle with an all up weight of 12,000 kg or more:",
          options: [
            "Two white light reflecting strips.",
            "Two rectangular tables colored in red/white.",
            "Two triangular yellow-light reflectors colored in red/white.",
            "Two round yellow-light reflectors, colored in red/white.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "1446. What is “overall length”:",
          options: [
            "The general length of the vehicle, from the rear bumper until the center of the front wheel.",
            "The distance between the front and rear edges of the chassis.",
            "The distance between the vehicle’s most frontal point and the vehicle’s rear point.",
            "The distance between the center of the most rear axle and the center of the most frontal axle.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question:
            "0709. How would you (vehicle no. 3) conduct yourself in the following intersection?",
          options: [
            "Give right of way to the motorcycle only (2).",
            "Give right of way to the motorcyclist (2) and to the yellow bus (1).",
            "Give right of way to the yellow bus only (1).",
            "Drive forward carefully. The other drivers (of vehicles 1 and 2) must yield.",
          ],
          answer: 1,
          image: "img/quiz/3709.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1309. What is the maximal excessive freight length permitted from the rear of a vehicle with an all up weight that exceeds 15,000kg?",
          options: [
            "One third the length of the loading platform from the front and also from the back – and only when the freight is in one unit.",
            "One third of the loading platform from the front and also from the back, for freight that is divided to two.",
            "Up to one meter in front and up to one meter behind only.",
            "One third the length of the loading platform – and only when the freight is in one unit.",
          ],
          answer: 3,
          image: "",
          types: ["C"],
        },
        {
          question: "0456. What is the meaning of the following traffic sign?",
          options: [
            "Crossroad ahead.",
            "Level crossing, two tracks.",
            "Level crossing, one track.",
            "Road-Works site.",
          ],
          answer: 2,
          image: "img/quiz/3456.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1124. Which vehicle may be used to transport school children?",
          options: [
            "Every public or private vehicle.",
            "Only a vehicle defined as a public bus.",
            "Every private or commercial vehicle with at least 8 seats.",
            "A vehicle that was authorized to do so, as indicated in its registration documents.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1459. You are driving a heavy truck down a steep and long descent. When should you shift to an appropriate low gear?",
          options: [
            "The gears are insignificant for slowing down, but you must use the speed retarders intelligently.",
            "There is no need to shift to low gear. You should shift to high gear and regulate your speed with the speed retarder, to increase safety.",
            "You should shift to low gear towards the end of the descent.",
            "You should shift to low gear at the beginning of the descent.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0368. Which of the following traffic signs means “pedestrians in the vicinity”?",
          options: ["136", "226", "135", "306"],
          answer: 0,
          image: "img/quiz/3368.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1159. Which types of taxi ride are permitted under a license issued by the Controller of Road Transport?",
          options: [
            "City ride, non-city ride and sightseeing ride.",
            "“Sightseeing” ride, work ride and share ride.",
            "Sharing ride, private ride and sightseeing ride.",
            "“Special” ride, and special children ride.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1594. What is the minimum age requirement for obtaining a Class A2 driving license?",
          options: [
            "18 yrs, subject to written consent of a parent or a guardian.",
            "14 yrs, subject to written consent of a parent or a guardian.",
            "20 yrs, subject to written consent of a parent or a guardian.",
            "16 yrs, subject to written consent of a parent or a guardian.",
          ],
          answer: 3,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0408. What should you do when the traffic sign in the picture appears before you?",
          options: [
            "Drive faster to prevent the car from shaking.",
            "Adjust your speed to the road’s conditions. Don't ignore an uneven road..",
            "You are driving on a mountain road. Drive in low gear.",
            "Obey the instructions of Public Works Department employees.",
          ],
          answer: 1,
          image: "img/quiz/3407.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1053. On the road is sign 405 and a motorcycle overtakes a car?",
          options: [
            "According to the sign both vehicles are prohibited from entering this road.",
            "According to the sign a motor-cycle is prohibited to overtake a car.",
            "According to the sign a car is prohibited to overtake a motor-cycle.",
            "According to the sign overtaking is prohibited.",
          ],
          answer: 0,
          image: "img/quiz/3505.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1131. What is the maximum number of passengers a minibus driver with a taxi driver’s license is permitted to carry",
          options: [
            "Up to eight passengers and two children under 5 years.",
            "Up to sixteen passengers besides the driver.",
            "According to the number of seats in the taxi.",
            "Up to seven passengers and two children under 5 years.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1781. How are you required to overtake a traffic island on a two-way roadway?",
          options: [
            "Always from the side having more traffic.",
            "Always from the side of least traffic.",
            "Always from its left side.",
            "From its right side, unless instructed otherwise by any traffic sign.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1301. The width of a commercial vehicle (except for a vehicle with a refrigeration unit) shall not exceed:",
          options: [
            "2.80 meters.",
            "3.50 meters.",
            "2.55 meters.",
            "2.10 meters.",
          ],
          answer: 2,
          image: "",
          types: ["C"],
        },
        {
          question:
            "1365. Which of the following commercial vehicles must be equipped with a speed “Governor”?",
          options: [
            "Commercial vehicles with an all up weight of up to 10,000 kg.",
            "It is obligatory to install a speed limiter in every commercial vehicle.",
            "Commercial vehicles with an all up weight of up to 8,000 kg.",
            "Vehicles with an all up weight of more than 12,000 kg, truck tractors and work vehicles.",
          ],
          answer: 3,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0454. What are you required to do according to the following road sign?",
          options: [
            "A distance of about 250 meters to an approaching level crossing.",
            "You should always stop at a distance of one meter before the level crossing.",
            "Slow down and stop. Railway tracks 50 meters ahead.",
            "Slow down and prepare to stop before the level crossing, if necessary.",
          ],
          answer: 3,
          image: "img/quiz/3453.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1103. What is forbidden to do after the differential is locked?",
          options: [
            "To turn the steering wheel and the wheels from side to side.",
            "To operate the power transfer (PTO).",
            "To connect between the right and left brakes.",
            "To change gears while driving.",
          ],
          answer: 0,
          image: "",
          types: ["1"],
        },
        {
          question:
            "0325. What action can be taken by a policeman when he finds that a commercial vehicle has an excessive cargo weight?",
          options: [
            "Give the driver a notice that his vehicle is taken off the road for a period of 90 days.",
            "Order the driver to go back immediately to the cargo loading point and unload all the cargo he carries.",
            "Bring the vehicle’s owner to the nearest Licensing Office and revoke his driver’s license for a period of 30 days.",
            "Give the driver a notice that his vehicle is taken off the road for a period of 30 days.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1068. When we want to verify that the vehicle’s tire air pressure is correct:",
          options: [
            "We should take the vehicle to a licensed garage, which is the only place allowed to check tire air pressure.",
            "We check the air pressure with a tire pressure gauge.",
            "It is enough to just look at the tires.",
            "We should take the vehicle to a licensed tire repairman, which is the only one allowed to check tire air pressure.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question:
            "0935. What are you required to do when the brake-check lamp lights up while driving?",
          options: [
            "Continue driving normally until the vehicle’s periodic treatment.",
            "Stop the vehicle and don’t resume driving.",
            "Continue driving slowly until the garage.",
            "Continue driving normally until the garage.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1729. Which road sign would follow road sign 716?",
          options: ["708", "711", "710", "709"],
          answer: 0,
          image: "img/quiz/31729.jpg",
          types: ["D"],
        },
        {
          question: "1180. What is a special taxi ride?",
          options: [
            "A ride under tariff 2 – holidays - plus 25%.",
            "A ride under tariff 1.",
            "Any ride on a route service.",
            "An exclusive taxi ride for the individual who ordered it.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question: "0600. What is the meaning of the following traffic sign?",
          options: [
            "Filling station.",
            "Entrance to a military gas station with a refreshment spot.",
            "Entrance to a gas station and a place for vehicle repair.",
            "Trucks-only filling station.",
          ],
          answer: 0,
          image: "img/quiz/3600.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0126. When another vehicle drives in the turning left only lane:",
          options: [
            "It is permited to pass it from its right side only if it is a slow vehicle",
            "It is permitted to pass it on its rights side.",
            "It is allowed to pass it from its right side with a private passenger car only.",
            "It is prohibited to pass it from its right side.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0352. Which vehicle should be equipped with a fire extinguisher?",
          options: [
            "All vehicles, except for private vehicles, tractors, motorcycles and tri-motorcycles.",
            "Every private vehicle.",
            "A tractor.",
            "Every vehicle with an overall weight of up to 3,500 kg.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "0919. How would you respond to the indications in the following picture?",
          options: [
            "Swerve left and accelerate to avoid hitting passengers that are about to get out of the vehicle.",
            "Continue driving normally.",
            "Slow down and be prepared to stop, in order to avoid hitting the open door.",
            "Honk loudly and continuously and swerve left, to avoid hitting the opening door.",
          ],
          answer: 2,
          image: "img/quiz/3919.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1777. Is it permitted to repair a vehicle on the road?",
          options: [
            "Only if it close to a garage.",
            "Only if the repair is related to the vehicle’s electricity system.",
            "The type of repair is not important, as long as the vehicle is not left standing there for more than 12 hours.",
            "Only a simple repair, provided that no inconvenience is caused to other road users, and the vehicle should not be left standing there for more than 24 hours.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0867. What are the provisions for driving in a straight line, without swerving?",
          options: [
            "Glancing at the separation line and keeping a constant distance from it.",
            "Focusing on the furthest possible object you can see.",
            "Driving while constantly following the roadway’s delineating yellow line.",
            "Keeping to the right of the road, while maintaining a constant distance from the right yellow line.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1161. Is a taxi driver permitted to take an additional passenger during a taxi ride that was ordered by another passenger?",
          options: [
            "Only at night, according to the definition of tariff 2.",
            "Yes, but only with the consent of the passenger that ordered the ride and without extra payment until the destination that was determined.",
            "Yes, but without payment.",
            "Yes, provided that the additional passenger pays half of the fare.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question: "0676. What is the meaning of the following traffic sign?",
          options: [
            "It is permitted to drive in this lane.",
            "Driving in this lane is permitted during the night only.",
            "Driving in this lane is prohibited.",
            "Pass to the adjacent left lane.",
          ],
          answer: 0,
          image: "img/quiz/3676.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1547. Sudden braking is permitted when:",
          options: [
            "It is the only way to prevent an accident.",
            "The driver or any of the passengers suddenly want to get off the vehicle.",
            "The driver wants to make a U-turn.",
            "The driver realizes he is lost.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0726. How should you behave on the road ahead of you?",
          options: [
            "Drive on the right lane only.",
            "After the signposts, you may use all the lanes.",
            "Use one of the two right hand lanes.",
            "Stop under the green arrow.",
          ],
          answer: 2,
          image: "img/quiz/3726.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1548. When you exit a dirt road on to a paved road.",
          options: [
            "There is no obligation to give right of way. It depends on the good will of the driver exiting the dirt road.",
            "Give right of way to vehicle traffic on the roadway only when it is a two-way roadway.",
            "Give right of way to vehicles coming from your right side only and then drive. Incoming traffic from your left side should yield.",
            "Give right of way to vehicle traffic on the paved road from all directions.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1646. Continuous braking on a winding descent can cause:",
          options: [
            "Over heating and weaker braking than normal.",
            "Over heating and stronger braking than normal.",
            "Cooling of the breaks and weak braking.",
            "Continuous braking has no negative affect.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1616. How should we react to the centrifugal force created while the motorcycle turns?",
          options: [
            "Accelerate upon entering the turn and slowing down when coming out of it.",
            "Accelerate strongly before the turn, in order to cancel the centrifugal force.",
            "Lean the motorcycle into the turn.",
            "Brake intermittently.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0654. Braking distance is the distance the vehicle passes?",
          options: [
            "From the moment the driver notices danger until he starts to brake.",
            "During the driver’s response time.",
            "From the moment of applying the brakes until the vehicle reaches a complete stop.",
            "From the moment the driver notices danger until the vehicle reaches a complete stop.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0463. What are you required to do according to the following road sign?",
          options: [
            "Stop before the signpost.",
            "Give right-of-way to vehicle traffic in the roundabout.",
            "Prepare to stop your vehicle at the nearest junction.. Stopping at the junction is obligatory",
            "Prepare to stop the vehicle in about 300 meters.",
          ],
          answer: 2,
          image: "img/quiz/3461.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1126. Is it permitted to park a bus that is designed to transport at least 20 passengers in a parking place designed for private vehicles?",
          options: [
            "Yes, but only with a parking ticket.",
            "Yes, in designated stations for passenger loading and unloading.",
            "No.",
            "Yes, but only if it doesn’t cause any traffic disturbance.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1133. What is a driver required to do when one of his two headlights malfunctions during “light time”?",
          options: [
            "He must stop. It is forbidden to continue driving.",
            "He should keep driving. It is permitted to continue driving slowly until the nearest garage.",
            "It is forbidden to continue driving only if the high beam malfunctions.",
            "It is forbidden to continue driving only if the low beam malfunctions.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "0295. Is it permitted for vehicle drivers to drive under the influence of drugs or alcohol?",
          options: [
            "Yes, when they need to drive in order to receive medical treatment only.",
            "Yes, for short-distance drives back home.",
            "Yes, in accordance with doctor’s orders only.",
            "It is absolutely forbidden.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0051. Is a driver obliged to show a policeman the vehicle’s original documents?",
          options: [
            "Yes, at a time and place to be determined by the policeman",
            "No, a certified photocopy is sufficient",
            "No, a photocopy signed by the vehicle’s owner is sufficient",
            "Yes, at the time determined by the vehicle’s driver",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1467. Which of the following road sign prohibits making a left U-turn?",
          options: ["212", "209", "208", "207"],
          answer: 1,
          image: "img/quiz/31467.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1614. In which direction should a motorcyclist focus his sight when entering into a turn?",
          options: [
            "Far ahead.",
            "In the direction of the turn and the direction of its exit.",
            "As close to the motorcycle as possible.",
            "He should look far ahead and concentrate on the road signs and the surroundings of the turn.",
          ],
          answer: 1,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0080. Is a cyclist allowed to use a public transportation lane?",
          options: [
            "A cyclist may use a public transportation lane when it is the left lane only",
            "Yes, when the lane is on the roadway’s right side",
            "No, only motorcycles are allowed to use the public transportation lane",
            "No, only public vehicles are allowed to use this lane",
          ],
          answer: 1,
          image: "",
          types: ["C1", "D", "В"],
        },
        {
          question: "1768. What is the correct sentence?",
          options: [
            "A driver must conduct himself in a way that doesn’t interfere with the right of any other person to fully use the road.",
            "A vehicle owner is permitted to reserve for himself a parking space next to his place of work.",
            "A driver approaching a pedestrian crossing is not always required to slow down.",
            "The duty of care always requires slow driving.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1476. What might be the effect of sand scattered on the road?",
          options: [
            "Vehicle skidding, due to reduced friction between the tires and the road.",
            "Tire overheating, due to increased friction between the tires and the road.",
            "Better braking force, due to increased friction between the tires and the road.",
            "It might conceal important road signs.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question: "0530. What is indicated by the following traffic sign?",
          options: [
            "End of urban area.",
            "End of vehicle parking and stopping zone.",
            'End of “restriction for driving-instruction\\".',
            "End of freeway zone.",
          ],
          answer: 2,
          image: "img/quiz/3530.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1117. What is a “touring vehicle”?",
          options: [
            "A security vehicle used for border patrolling and properly equipped for this purpose.",
            "A vehicle used as a “security” vehicle for borderline settlements, intended to patrol along a settlement’s fences.",
            "A vehicle used by the different security forces during their regular operations.",
            "A public vehicle designed to carry up to eight passengers besides the driver and is indicated as a touring vehicle in its registration documents.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0732. What is the first thing you need to do after entering the vehicle and before starting to drive?",
          options: [
            "Make sure the hand-brake is applied and adjust the driver’s seat.",
            "Start the engine and adjust the seat for safe driving.",
            "Adjust the seat besides the driver to allow the passenger coming-in from the right an easy entrance.",
            "Make sure you have an extra set of keys (reserve set) for the vehicle.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "0435. What are you required to do according to the following road sign?",
          options: [
            "Continue driving, pay attention to vehicles merging from your left.",
            "Change lane and allow approaching vehicles from the right to merge.",
            "Give right-of-way to vehicles merging from your right.",
            "Slow down and give right-of-way to vehicles merging from your right.",
          ],
          answer: 0,
          image: "img/quiz/3435.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1643. What basic equipment and clothing are required for a safe ride?",
          options: [
            "Full helmet, rigid-cloth pants, high shoes, gloves and a basic riding jacket.",
            "Half helmet, long trousers, shoes, gloves, a basic riding jacket and a back belt.",
            "Full helmet, safety goggles, pants, sandals and a basic riding jacket with body protectors.",
            "Half helmet, safety goggles, pants, shoes and a basic riding jacket with shoulder and elbow protectors.",
          ],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question: "1321. What is the meaning of this sign?",
          options: [
            "Flashing orange light warning of an approaching level crossing.",
            "Flashing orange light at road works.",
            "Constant (not flashing) Orange light when traffic lights are not working.",
            "Constant (not flashing) orange light warning of an approaching forked junction.",
          ],
          answer: 1,
          image: "img/quiz/31321.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1031. The correct vehicle tire size is:",
          options: [
            "The size that was determined by the vehicle’s manufacturer.",
            "The size recommended by the tire professional in the garage (the tire repairman).",
            "The size that was determined by the tire manufacturer.",
            "The size that appears to be most suitable for the vehicle.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question:
            "0498. According to the following road sign, is a motorcycle permitted to overtake a private passenger car?",
          options: [
            "Yes, but only on a one-way road.",
            "No.",
            "Yes, during day-time only.",
            "Yes.",
          ],
          answer: 1,
          image: "img/quiz/3497.jpg",
          types: ["A", "В"],
        },
        {
          question:
            "0826. When you are overtaking another vehicle on a two-lane two-way roadway:",
          options: [
            "Don’t worry: The overtaken vehicle should deviate to the right and allow you to overtake it easily.",
            "You are commiting a traffic violation! This type of overtaking is illegal.",
            "You take a risk of another vehicle appearing in the opposite lane.",
            "This type of overtaking usually last 30 seconds.",
          ],
          answer: 2,
          image: "img/quiz/3826.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1079. What are you required to do when the engine temperature lamp indicates engine overheating?",
          options: [
            "Continue driving in high speed to achieve strong ventilation that would help to cool down the engine.",
            "Continue driving slowly to achieve strong ventilation that would help to cool down the engine.",
            "Shut off the engine, clear passengers away from the place and call for help.",
            "Stop the vehicle and leave the engine working on medium RPM in order to cool down. If it doesn’t cool down, shut-off the engine.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0722. When a vehicle is forced to stop on an inter-urban road and the driver or one of the passengers is about to exit the vehicle:",
          options: [
            "The driver must wear a vest when exiting the vehicle, only if other vehicles are on the road.",
            "The first person to get out of the vehicle must wear a standard fluorescent vest when exiting the vehicle or stepping out of it.",
            "No obligation applies to this situation. Wearing a vest is obligatory on an urban road only.",
            "Only the driver is obliged to wear a vest, and not any other person exiting the vehicle.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1323. When does a vehicle carrying oversize freight require escort?",
          options: [
            "When the vehicle carries hazardous materials.",
            "When the width of its freight is more than 3.40 meters.",
            "When the vehicle was authorized to carry a heavy load.",
            "When the width of its oversize freight is more than 3 meters and its length is more than by 20 meters.",
          ],
          answer: 1,
          image: "",
          types: ["C"],
        },
        {
          question: "1531. When you are driving behind another vehicle:",
          options: [
            "While driving uphill – keep a small distance; while driving downhill – keep double distance.",
            "Keep a safe distance from the vehicle in front of you, to prevent an accident.",
            "Keep a smaller distance from the vehicle in front of you.",
            "Keep a larger distance from the vehicle in front of you only when a “general danger” road sign is placed.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0751. You are approaching a tunnel  with a road sign displayed in front of you and want to overtake a slow vehicle driving ahead of you:",
          options: [
            "It is forbidden to overtake, or to try overtaking, within a distance of 100 meters before the tunnel.",
            "If the tunnel is sufficiently lit, it is also permitted to overtake inside the tunnel.",
            "It is forbidden to overtake,inside the tunnel or to try overtaking, within a distance of 50 meters before or after the tunnell",
            "It is forbidden to overtake, and to try overtaking, within a distance of 250 meters before the tunnel.",
          ],
          answer: 2,
          image: "img/quiz/3616.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0789. You arrived to an intersection that is not clear:(free)",
          options: [
            "Stop before the intersection and wait for traffic to clear.",
            "You are permitted to turn left, even if the intersection is not clear.",
            "If the traffic light is green you may enter the intersection, even when it is not clear.",
            "Enter the intersection carefully and wait there for it to clear.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0869. Being familiar with the different systems in the driver’s compartment whilst driving:",
          options: [
            "Only contributes in a theoretical level, and has no other advantage.",
            "Allows the driver to operate them automatically, without thinking.",
            "Allows the driver to fully concentrate on the traffic and the road.",
            "Allows the driver to blindly operate the vehicle systems.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0104. Which of the following vehicles is allowed to drive against traffic on a one-way road?",
          options: [
            "Only security vehicles",
            "Only private vehicles",
            "Only vehicles with at least four passengers",
            "Only bicycles and motorcycles",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0629. What is the speed limit after this traffic sign?",
          options: ["50 k.p.h.", "20 k.p.h.", "30 k.p.h.", "60 k.p.h."],
          answer: 2,
          image: "img/quiz/3628.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0618. Until where does the following road sign remain valid?",
          options: [
            "Until the next intersection.",
            "Until an “entrance to an urban road” road sign.",
            "Until 150 meters following the place of the sign.",
            "Until the nearest pedestrian crossing.",
          ],
          answer: 0,
          image: "img/quiz/3618.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0125. Are you permitted to pass another vehicle on its right side?",
          options: [
            "Yes, when you drive in a bus lane.",
            "Yes, when your driving speed is faster than that of the other vehicle.",
            "Yes, when the other vehicle driving in front gives a right-turn signal.",
            "Yes, when the other vehicle drives slowly.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1701. The risk of skidding on a wet road is greater:",
          options: [
            "The slower you drive.",
            "The faster you drive.",
            "The risk of skidding on a wet road is identical to the risk of skidding on a dry road.",
            "The newer are the tires.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question:
            "1107. When is it obligatory to install a spark protector on a tractor?",
          options: [
            "When the tractor has an upper exhaust pipe.",
            "When the engine’s mechanical condition is relatively poor.",
            "When the tractor has an open exhaust pipe.",
            "When the tractor has a lower exhaust pipe.",
          ],
          answer: 3,
          image: "",
          types: ["1"],
        },
        {
          question:
            "1649. What is the danger of riding a motorcycle on a wet roadway?",
          options: [
            "Motorcycle skidding and roll-over, the lower the speed is.",
            "The penetration of water into the engine, which creates rust.",
            "Motorcycle skidding and roll-over, the greater the speed is.",
            "Motorcycle roll-over if the driver presses the clutch handle abruptly.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0320. Which vehicle must be equipped with “safety blocks”?",
          options: [
            "Any motor vehicle with an all up weight of more than 5,000kg, except for a bus.",
            "Any motor vehicle, regardless of its weight, except for a bus.",
            "Any vehicle with an all up weight of 3,500kg or less, except for a bus.",
            "Only a vehicle with an all up weight of 4,000kg or less, including a bus.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1363. Which types of vehicles must operate distress lights, or place a flashing yellow lamp, if forced to stand on a non-urban road during “light time”?",
          options: [
            "Commercial vehicles with an all up weight of more than 12,000 kg.",
            "All vehicles, except for private vehicles and passenger transportation vehicles.",
            "Commercial vehicles with an all up weight of more than 3,000 kg and buses only.",
            "All motor vehicles with an all up weight of more than 3,500 kg, buses and working vehicles.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question:
            "0374. Which of the following signs indicate a single track level crossing?",
          options: ["130", "128", "133", "134"],
          answer: 2,
          image: "img/quiz/3371.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0164. Is it obligatory to signal while deviating to the right or to the left from the driving lane?",
          options: [
            "It is not obligatory to signal while deviating from one lane to the other.",
            "It is not obligatory to signal during an insignificant deviation from the lane.",
            "It is obligatory to signal before any deviation from the driving lane, until it is completed.",
            "It is not obligatory to signal if the deviation is significant and noticed by all drivers",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1785. It is prohibited to overtake before a pedestrian crossing:",
          options: [
            "Anywhere, neither in urban roads nor in inter-urban roads.",
            "In any type of road, except in urban two-lane roads.",
            "When a “pedestrian crossing” blue sign is at place.",
            "Only when a “pedestrian crossing ahead” road sign is placed.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0436. What is the meaning of the following traffic signs?",
          options: [
            "You are merging with a roadway from the left. Give right-of-way and don’t turn left.",
            "T-Intersection. It is allowed to turn to the side road.",
            "Dead-end road ahead. Entry to this road is forbidden.",
            "It is always required to make a complete stop and give right-of-way to merging traffic from the right.",
          ],
          answer: 0,
          image: "img/quiz/3436.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0419. What is the meaning of the following traffic sign?",
          options: [
            "Caution, bicycle traffic ahead.",
            "Path for bicycles only.",
            "One-way lane for bicycles.",
            "End of bicycle lane.",
          ],
          answer: 0,
          image: "img/quiz/3419.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1551. In a work vehicle:",
          options: [
            "It is prohibited to transport passengers but it is permitted to carry freight.",
            "It is prohibited to carry freight but it is permitted to carry up to 6 passengers in the vehicle’s trunk.",
            "It is permitted to transport passengers and to carry freight.",
            "It is prohibited to carry freight or transport passengers, except in the driver’s compartment.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1530. When entering a curve and driving through it a driver is required to:",
          options: [
            "Accelerate at the entry to the curve and slow down in the middle of it.",
            "Accelerate to ensure smooth turning.",
            "Slow down and maintain a constant speed",
            "Stay close to the middle of the roadway to ensure vehicle stability.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0609. What is the meaning of red-yellow painted curbstones?",
          options: [
            "A marking to emphasize and indicate sidewalks of different heights,",
            "Absolutely no stopping, except for the vehicle types that are permitted by the sign.",
            "Parking is permitted with parking tickets.",
            "Unrestricted parking for public vehicles is permitted.",
          ],
          answer: 1,
          image: "img/quiz/3609.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0677. What is the meaning of the following traffic sign?",
          options: [
            "It is prohibited to pass to the adjacent left or right lane. You must continue in your curremt lane.",
            "Only two-wheelers are permitted to drive in this lane.",
            "4-way intersection ahead.",
            "Lane closed. No driving in this lane.",
          ],
          answer: 3,
          image: "img/quiz/3677.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1135. What does the law state regarding the operation of a radio on a bus?",
          options: [
            "The driver should adjust the radio station according to the wish of the majority of passengers.",
            "The driver must turn off the passenger loudspeakers and turn down the radio’s volume at the request of any passenger.",
            "The driver must turn off the speakers on an urban road only.",
            "The driver should comply with the request of the majority of passengers.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0954. How would you conduct yourself according to the following picture?",
          options: [
            "Cyclists are of no danger to vehicles. Drive normally when they are nearby.",
            "The cyclist is not stable and is affected by the fast vehicles passing by. Slow down when approaching him.",
            "When passing by cyclists, drive faster to reduce the period of conflict with them.",
            "When passing by cyclists, honk a few times to have their attention.",
          ],
          answer: 1,
          image: "img/quiz/3942.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0866. Is it enough to signal only when you begin to drive?",
          options: [
            "Yes, when starting to drive we can make do with just signaling. Then, when we need to merge with traffic, we will have time to look around.",
            "No, signaling doesn’t give you any right. You need to make sure that you look around correctly in addition to signaling.",
            "Yes, unnecessary actions, besides the required signaling, waste precious time and create unnecessary tensions.",
            "Yes, when we start to drive we are lawfully obliged to signal. It is the responsibility of other road users to be careful.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0898. You are driving a vehicle that approaches the cyclist in the picture from behind. What risk might be posed by the cyclist ?",
          options: [
            "There is no risk. Pass the cyclist quickly.",
            "The cyclist might pull onto the roadway or otherwise trip and fall into it.",
            "The cyclist rides on the sidewalk (pavement) and therefore the bicycles pose no risk.",
            "The cyclist is an adult and poses no risk.",
          ],
          answer: 1,
          image: "img/quiz/3898.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1009. What is the relation between the vehicle’s air bags and safety belts during an accident?",
          options: [
            "If seat belts are not buckled, the passengers will be severely struck by the air bags.",
            "There is no relation between the air bags and the seat belts.",
            "The air bag only operates when the seat belt is not.",
            "It is not obligatory to wear seat belts when air bags are installed in the vehicle.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0892. What risks should be taken into account on the following roadway?",
          options: [
            "Vehicles are parked adjacently to each other and pedestrians cannot cross between them.",
            "There is no risk in the following roadway.",
            "Sudden appearance of pedestrians or children from between the parked cars.",
            "Traffic policeman writes tickets to drivers who parked on the right of the roadway,",
          ],
          answer: 2,
          image: "img/quiz/3892.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1765. How is a driver required to adjust the vehicle’s head rest?",
          options: [
            "The upper edge of the head-rest should be aligned with the bottom line of the driver’s neck.",
            "The lower edge of the head-rest should be aligned with the upper tip of the driver’s head.",
            "The upper edge of the head-rest should be aligned with the upper tip of the driver’s head.",
            "Proper adjustment of the driver’s head-rest is of no significance.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0824. One of the conditions justifying an overtaking of a driving vehicle is:",
          options: [
            "That the overtaking vehicle is delayed as a result of the slow driving of the vehicle in front.",
            "There are no conditions that justify an overtaking.",
            "That the difference in speed between the overtaking and the overtaken vehicles is sufficient to complete the manouver.",
            "That the overtaken vehicle drives slower than the maximum statutory speed.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1568. Is it permitted to make changes in the vehicle’s design or type?",
          options: [
            "The Owner of the vehicle is permitted to make changes in it as he pleases.",
            "Yes, but provided that the owner of the vehicle notifies about it in advance and in writing to the vehicle’s manufacturer or agency.",
            "Yes, but only if the change is made by a licensed garage.",
            "Yes, but only with a written pre-approval of the Licensing Authority.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1207. When is it obligatory to light the inside of the bus?",
          options: [
            "In any hour of the day.",
            "During “light time” – but it is only permitted to turn on the lights next to the rear door.",
            "During “lighting up time”.",
            "It is up to the driver’s discretion whether or not to turn the passageway lights on during “light time”.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0294. What types of vehicles are permitted to park in a bus station, besides the bus itself?",
          options: [
            "Private vehicles, on Sabbaths and holidays only, according to the instructions of the road sign that is placed at the bus station.",
            "Private vehicles, during night time, according to the instructions of the road sign that is placed in the bus station.",
            "All types of vehicles, during night time, according to the instructions of the road sign that is placed in the bus station.",
            "Motorcycles, on Sabbaths and holidays only, according to the instructions of the road sign that is placed in the bus station.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "0273. Where is it permitted to park LPG (Liquid Petroleum Gas) fuelled vehicles?",
          options: [
            "In LPG-Vehicle permitted parking lots, according to the directions of the Ministry of Environmental Protection.",
            "In regular parking lots designated for gasoline vehicles.",
            "In parking lots designated for private passenger cars only.",
            "In LPG-Vehicle permitted parking lots, according to the directions of the Vehicle’s importer.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "0223. What is the maximum permitted distance for standing a vehicle parallel to the kerb?",
          options: [
            "seventy centimeters.",
            "fifty centimeters.",
            "forty centimeters.",
            "sixty centimeters.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "1610. It is not permitted to carry a passenger on a motorcycle unless:",
          options: [
            "The passenger wears a crash helmet as well as knee and elbow protectors.",
            "The driver wears a crash helmet – the passenger doesn’t have to do so.",
            "The driver and the passenger wear crash helmets.",
            "The passenger holds a valid motorcycle driver’s license.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question:
            '1348. Which of the following road signs marks a "freeway"?',
          options: ["Sign 218.", "Sign 216.", "Sign 217.", "Sign 219."],
          answer: 1,
          image: "img/quiz/31348.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0020. “Permitted load” on a commercial vehicle is:",
          options: [
            "The maximum weight of the cargo (in Kg) that is permitted to be loaded on the vehicle",
            "The weight of the cargo plus the vehicle’s own weight",
            "The all up weight of a transport vehicle",
            "The vehicle’s own weight, including: water, oil, fuel and the weight of the driver",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "1723. A road accident is an incident",
          options: [
            "Unintentional, resulting in injury, including the driver, or property damage, or both.",
            "An incident that characterizes young drivers only.",
            "An incident that can always be prevented.",
            "A premeditated incident resulting in property and infrastructure damage only.",
          ],
          answer: 0,
          image: "img/quiz/31723.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0195. Which type of vehicle is allowed to stop or be left standing on the hard shoulder of an inter-urban road which is marked as a main road?",
          options: [
            "A work vehicle or a tractor.",
            "A vehicle engaged in selling fruits and vegetables to passersby.",
            "A vehicle engaged in public works and an I.D.F vehicle - for the purpose of fulfilling their duties.",
            "A towing vehicle and a trailer, a truck tractor and a semi-trailer, with a maximum width of three meters.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question: "0825. Select the correct sentence:",
          options: [
            "Keeping distance is only required to keep distance while driving in high speeds, and when a heavy vehicle or a bus drives in front.",
            "Keeping distance is only required when the driver is very tired and his responses are not quick enough.",
            "Keeping distance prevents traffic congestion which damages road infrastructures.",
            "By keeping proper distance, an overtaking vehicle can enter the space in front.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0263. In a vehicle that has an overall width of 210 centimeters or more and that is lawfully equipped with side lamps:",
          options: [
            "The driver must turn-on the side lamps during “light time”.",
            "The driver must turn-on the side lamps in an inter-urban road only.",
            "The driver doesn’t have to turn-on the side lamps during ‘light time”.",
            "It is allowed to drive at any time, even if the side lamps are out of order.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "1"],
        },
        {
          question:
            "1671. Which of the semi-trailers in the picture has three axles?",
          options: ["17", "15", "16", "14"],
          answer: 2,
          image: "img/quiz/31671.jpg",
          types: ["C"],
        },
        {
          question:
            "0976. According to the following traffic sign, which of the vehicles should give right of way?",
          options: [
            "The first vehicle that arrives at the bridge.",
            "The close vehicle",
            "The vehicle arriving first at the narrow section of the road.",
            "The distant vehicle coming from the opposite direction.",
          ],
          answer: 3,
          image: "img/quiz/3976.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0968. How would you conduct yourself when approaching a vehicle standing on the “hard shoulder”?",
          options: [
            "Honk to the driver of the parked vehicle.",
            "Slow down.",
            "Drive faster.",
            "Signal and move to the left lane.",
          ],
          answer: 1,
          image: "img/quiz/3968.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1659. What are the important emphases as regards to motorcycle riding with a passenger?",
          options: [
            "The passenger should be guided how to properly get on and off a motorcycle, and how to position himself correctly on it.",
            "Codes of communication should be determined between the passenger and the rider. All the rest is secondary.",
            "The passenger should only be guided how to properly get on and off a motorcycle. All the rest is secondary.",
            "It should be verified that the passenger does not weigh more than the rider.",
          ],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question: "1517. Which vehicles can you drive with class B ?",
          options: [
            "Private vehicles and commercial vehicles with an all up weight of up to 4,500 kg.",
            "Private vehicles and commercial vehicles with an all up weight of up to 3,500 kg.",
            "Public vehicles, including minibuses.",
            "Motorcycles with an engine power of up to 14.6 horse-powers.",
          ],
          answer: 1,
          image: "img/quiz/3091.jpg",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "0102. In the illustration you can see a wide road. What number in the picture illustrates a Center reservation (divider)",
          options: ["8", "2", "4", "1"],
          answer: 2,
          image: "img/quiz/3082.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0175. To which of the following should you give right-of-way when making a left turn in an intersection where no road sign is placed?",
          options: [
            "To a vehicle coming from the left only.",
            "To a vehicle coming from in front of you only.",
            "To the fastest vehicle.",
            "To a vehicle coming from the front and from the right.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0437. What are you required to do according to the following road sign?",
          options: [
            "Drive faster. Vehicles in the road you are entering should give you right-of-way.",
            "Give right-of-way to vehicle traffic on the crossing road, from both directions.",
            "Give right of way and carefully merge with the traffic in the road to your left.",
            "You are driving in an acceleration lane. Merge quickly with vehicle traffic in the road you are entering.",
          ],
          answer: 2,
          image: "img/quiz/3436.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1249. What are the terms for operating a taxi at the “Ben-Gurion” airport?",
          options: [
            "The taxi will only operate in coordination with the passenger in need of a ride, including relevant arrival and departure times.",
            "Every taxi driver is permitted to transport passengers from the Ben-Gurion airport.",
            "No permit is required for transporting passengers from the Ben-Gurion airport. Picking up time should be accurately coordinated in advance with passengers.",
            "Only a taxi having a permit from the Licensing Authority can operate in the Ben-Gurion Airport, and only in accordance with the permit’s terms, including taxi stopping and parking.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0717. According to the law, in what order should vehicles enter the following intersection?",
          options: [
            "The motorcycle (2), the red vehicle (1), your vehicle (3).",
            "The red vehicle (1), your vehicle (3), the motorcycle (2).",
            "The motorcycle (2), your vehicle (3), the red vehicle (1).",
            "The red vehicle (1), the motorcycle (2), your vehicle (3).",
          ],
          answer: 3,
          image: "img/quiz/3717.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0220. According to the “point system”:",
          options: [
            "Only the traffic court is entitled to revoke a driver’s license.",
            "Only a police officer is entitled to revoke a driver’s license and to require a driver to undergo examinations.",
            "The Licensing Authority is not authorized to revoke a driver’s license.",
            "The Licensing Authority is entitled to require a driver that incurred demerit points to undergo examinations, and it is also entitled to revoke his license.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1246. When are the driver and conductor allowed to refuse a passenger from getting onto the bus?",
          options: [
            "When the passenger wears a skiing or skating boots on his feet.",
            "When he wants to ride the bus for only one stop.",
            "When the passenger has a 100 Nis note and the bus fare is only 12 Nis.",
            "When he wants to carry a dog or another animal on the bus.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0988. What would you do to achieve safe driving down a continuous steep slope?",
          options: [
            "Use the engine’s braking power by shifting to a lower gear and make only partial use of the brakes",
            "It is not recommended to drive in a sharp descent for a long time.",
            "You should rely solely on the engine’s braking power.",
            "You should step on the brake pedal firmly and continuously to maintain the desirable speed.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0399. Is a driver permitted to send an S.M.S message from his phone while driving?",
          options: [
            "The driver is permitted to send and read S.M.S messages, provided that he does it with care.",
            "He is permitted to send S.M.S messages but not to read ones.",
            "It is absolutely prohibited, and dangerous.",
            "He is permitted to read S.M.S messages but not to send ones.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0506. Are Bicycles permitted to enter a road  when the following traffic sign is placed?",
          options: [
            "Yes.",
            "Yes, entry is prohibited for public vehicles only.",
            "No.",
            "Yes, entry is prohibited for commercial vehicles only.",
          ],
          answer: 0,
          image: "img/quiz/3505.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0518. When you approach an intersection and the a traffic light starts  flashing green.",
          options: [
            "It is a sign that the traffic light is out of order and you should follow the instructions of the road signs that are placed there.",
            "There is no difference between flickering-green and green light; hence, there is no need to pay special attention to it.",
            "It is a sign that the green light is about to change in three seconds.",
            "It is a sign that the light turned green a short time ago and you can continue driving.",
          ],
          answer: 2,
          image: "img/quiz/3093.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0707. How would you (vehicle no. 3) conduct yourself in the following turn?",
          options: [
            "Give right of way to the green vehicle (1).",
            "Drive, you have right of way in the intersection (3).",
            "Give right of way to the red vehicle (2).",
            "Give right of way to both vehicles (1, 2).",
          ],
          answer: 0,
          image: "img/quiz/3707.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0116. The following illustration shows an intersection with traffic signs. What is the correct manner of making a turn from street C to street A?",
          options: [
            "From lane 5 to lane 3.",
            "From lane 5 to lane 4.",
            "From lane 6 to lane 4.",
            "From lane 5 to lane 3 or to lane 4.",
          ],
          answer: 1,
          image: "img/quiz/3116.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0585. What is the meaning of the following traffic sign?",
          options: [
            "Pick-up station. No stopping of commercial vehicles.",
            "Parking for soldier transportation vehicles.",
            "Soldier pick-up station. Only military vehicles are permitted to stop.",
            "Pick-up station for soldiers. Picking up or letting down only (waiting at the station is prohibited).",
          ],
          answer: 3,
          image: "img/quiz/3585.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1473. On an urban road, what is the speed limit (in k.p.h) for a commercial vehicle with an all up weight of more than 12,000 kg?",
          options: [
            "50 k.p.h.- even if there is a road sign permitting to drive at a higher speed.",
            "50 k.p.h.- if there is a road sign permitting to drive at a higher speed, the vehicle is permitted to drive at the speed indicated in the sign.",
            "60 k.p.h.- even if there is a road sign permitting to drive at a higher speed.",
            "60 k.p.h.- if there is a road sign permitting to drive at a higher speed, the vehicle is permitted to drive at the speed indicated in the sign.",
          ],
          answer: 0,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0855. What are the risks of driving continuously for many hours?",
          options: [
            "Engine power decreases, and that reduces the braking capacity.",
            "Severe tiredness and a tendency to fall asleep.",
            "A healthy driver faces no danger in driving continuously for many hours.",
            "Heating and accelerated wear and tear of the front tires.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0140. Under which circumstances should you slow down your driving?",
          options: [
            "When your vehicle or any passerby and property are at risk.",
            "When passing under a bridge.",
            "In a two-way roadway having 4 lanes.",
            "When entering an acceleration lane.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1102. When should the tractor’s differential be locked?",
          options: [
            "When it is required to make a sharp turn on the spot while driving at a high speed.",
            "When the tractor struggles to tow or pull a heavy weight tool.",
            "When it is required to make a sharp turn on the spot while driving at a very slow speed.",
            "When one wheel skids on the ground and the tractor cannot proceed.",
          ],
          answer: 3,
          image: "",
          types: ["1"],
        },
        {
          question:
            "1424. What is the required driving distance between the escorting vehicle and the vehicle carrying oversize freight?",
          options: [
            "Not more than 150 meters.",
            "The escorting vehicle shall keep a minimum distance of 100 meters from the freight carrying vehicle.",
            "A distance in which the driver of the freight carrying vehicle can see the escorting vehicle in his mirrors while driving on a two-way roadway.",
            "A distance in which the two vehicles can maintain eye contact at all times.",
          ],
          answer: 3,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0151. What is the maximum speed for a private passenger vehicle, driven on an inter–urban road with a central divider (if not indicated otherwise by a traffic sign)?",
          options: ["90 KPH.", "110 KPH.", "100 KPH.", "80 KPH."],
          answer: 0,
          image: "img/quiz/3139.jpg",
          types: ["C1", "В"],
        },
        {
          question:
            "0502. Are motorcycles permitted to enter a road-section in which the following traffic sign is placed?",
          options: [
            "Entry is permitted on a two-way road only.",
            "Yes, provided that they have no side-car.",
            "Entry is prohibited on freeways only.",
            "Entry is absolutely prohibited.",
          ],
          answer: 1,
          image: "img/quiz/3501.jpg",
          types: ["A", "В"],
        },
        {
          question: "0129. When is it permitted to overtake another vehicle?",
          options: [
            "Only when the overtaken vehicle is a slow vehicle.",
            "Only if the driver of the overtaken vehicle gives a signal to the driver of the overtaking vehicle.",
            "Only when the overtaken vehicle is a motorcycle.",
            "When the road is clear and you can overtake safely without causing danger to any other road user.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1784. It is prohibited to overtake on a steep hill:",
          options: [
            "Only when there is a “danger” road sign.",
            "When your field of vision is limited or obstructed.",
            "Only when there is a “no overtaking” road sign.",
            "Overtaking is permitted, provided that the weight of your car exceeds 4 tons.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0330. Which trailer can be towed by a class B driver’s license holder using a vehicle that is approved for towing?",
          options: [
            "When the all-up weight is not more than 1,500kilos",
            "A trailer with an overall permissible weight of more than 1,500 kg.",
            "A trailer with an overall permissible weight of no more than 2,000 kg.",
            "None. A holder of such a license is absolutely forbidden to tow a trailer.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "В"],
        },
        {
          question: "1402. Is it permitted to tow more than one trailer?",
          options: [
            "Yes, but only with a permit of the Licensing Authority.",
            "Yes, but only with a permit of the Controller of Road Transport.",
            "Yes, but only with a permit of a policeman.",
            "Yes, when carrying long freights it is permitted to tow more than one trailer, but it requires the approval of the safety officer.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "1", "В"],
        },
        {
          question:
            "0836. What is the first stage in getting back on the road from the “hard shoulder”?",
          options: [
            "Signaling with the indicator.",
            "Getting closer from the hard shoulder to the road's edge.",
            "Accelerating the vehicle up to the speed of the vehicles on the road.",
            "Turning the wheel in order to get on the road in a 45 degree angle.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1510. Can a refreshment break whilst driving lessen the driver’s tiredness?",
          options: [
            "It can drive the tiredness away completely.",
            "Yes, for a long time.",
            "Absolutely yes, and it is even better, on the long run, than taking a rest outside the vehicle.",
            "Yes, for a short time.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1660. Holder of a Class A2 driving license is prohibited to drive  a  passenger on a motorcycle if he is younger than:",
          options: ["17 yrs.", "15 yrs.", "18 yrs.", "16 yrs."],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0057. Which changes in health condition must be reported to the Licensing Authority?",
          options: [
            "The driver doesn’t have to report anything to the Licensing Authority, but only to the Ministry of Health",
            "Any detection of the following conditions: heart disease or any limitation of the nervous system, bones, eye-sight and hearing",
            "The driver doesn’t have to report anything to the Licensing Authority. The report shall be made by the treating physician of the driver",
            "A driver doesn’t have to report about changes in his health, except in case of a neurological problem",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0663. What is the meaning of the following road marking?",
          options: [
            "An area in which parking is prohibited for all vehicles.",
            "Vehicle parking spaces – parking is permitted according to the markings only.",
            "Parking and stopping places for two-wheelers.",
            "Stopping places for non-motor vehicles only.",
          ],
          answer: 1,
          image: "img/quiz/3663.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1752. On a narrow roadway, when two vehicles from opposite directions cannot pass together at the same time:",
          options: [
            "Right of way is given to a vehicle transporting more than 8 passengers.",
            "Whoever arrives first has right of way.",
            "The faster vehicle has right of way.",
            "Both vehicles must keep as much as possible to the right and even pull into the “hard shoulders”.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0111. The following illustration shows an intersection with traffic signs. What is the correct manner of making a turn from street A to street C?",
          options: [
            "From lane 2 to lane 4",
            "From lane 2 to lane 3.",
            "From lane 1 to lane 3.",
            "From lane 2 or from lane 1 to lane 4.",
          ],
          answer: 0,
          image: "img/quiz/3111.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0964. Is the message conveyed in the following yellow traffic sign considered legal?",
          options: [
            "The sign is not a traffic sign; hence the message is not legal.",
            "The message in the traffic sign is limited to an urban road only.",
            "The yellow sign is an advertisement sign of a party having interest.",
            "The traffic sign is legal for all matters.",
          ],
          answer: 3,
          image: "img/quiz/3963.jpg",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question:
            "1152. On an inter-urban route service bus, who is designated to sit in the two front seats either side of the aisle?",
          options: [
            "Security personnel.",
            "Parents riding with children under five years.",
            "Soldiers in uniform only.",
            "Handicapped and elderly people only.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question: "0571. What is the meaning of the following traffic sign?",
          options: [
            "No parking of vehicles, except for the purpose of passenger unloading.",
            "End of cab rank.",
            "No stopping and parking for any other vehicle.",
            "Cabs are prohibited to stop and unload passengers beyond this point.",
          ],
          answer: 1,
          image: "img/quiz/3571.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0575. What is the meaning of the following traffic sign?",
          options: [
            "No entry for vehicle carriers.",
            "No entry for vehicles, including trolleys.",
            "No entry for horse drawn vehicles and non-harnessed animals.",
            "Entry is permitted for horsemen only.",
          ],
          answer: 2,
          image: "img/quiz/3575.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0507. What is the meaning of the following traffic sign?",
          options: [
            "Entry is permitted for commercial motor vehicles with an overall weight which exceeds the weight indicated in the sign.",
            "No entry for commercial motor vehicles with an overall weight which exceeds the weight indicated in the sign.",
            "No entry for any type of vehicle that has a higher all up weight than what is indicated in the sign.",
            "Entry is permitted for all motor vehicles.",
          ],
          answer: 1,
          image: "img/quiz/3507.jpg",
          types: ["C1", "C", "D"],
        },
        {
          question:
            "1573. When driving behind a slow vehicle on a roadway while a continuous centre white line is marked to your left:",
          options: [
            "You are only permitted to overtake if the slow vehicle is a non-motor one.",
            "You are permitted to overtake if the vehicle in front drives slow for a long time",
            "You are permitted to overtake, unless a prohibitory sign is placed.",
            "You are permitted to overtake, providing you do not cross the continuous centre line..",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1289. Regular vehicle maintenance, in accordance with the manufacturer’s manual, will:",
          options: [
            "Annuls the need for an annual test.",
            "Reduce fuel consumption as well as wear and tear of vehicle parts.",
            "Is only required if the vehicle is frequently used.",
            "In a new model vehicle, maintenance is not required at all.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question:
            "0478. Who is responsible for keeping the vehicle roadworthy at all times?",
          options: [
            "The insurance company.",
            "The vehicle’s manufacturer or importer.",
            "The registered owner of the vehicle and the driver.",
            "The Licensing Office.",
          ],
          answer: 2,
          image: "img/quiz/3478.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1041. How can we lower the vehicle’s fuel consumption?",
          options: [
            "Make sure to drive in the lowest gear.",
            "Fill up the vehicle with a lower octane fuel than required.",
            "Make as many strong engine accelerations as possible.",
            "Change gear according to the correct speed and road conditions and avoid high engine revolutions.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "A", "В"],
        },
        {
          question:
            "0594. What are you required to do according to the following traffic sign?",
          options: [
            "Warn pedestrians by honking loudly and turning on the headlights.",
            "Continue driving and make sure that the pedestrians clear away from the roadway.",
            "When you're driving and approaching this traffic sign, always slow down and even stop if necessary to allow pedestrians to safely cross the road.",
            "Honk and warn the crossing pedestrians.",
          ],
          answer: 2,
          image: "img/quiz/3593.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1759. Is it permitted for a bus, which uses a public transportation lane, to overtake an overtaking vehicle?",
          options: [
            "Yes, but only on inter-urban roads.",
            "Yes.",
            "Yes, but only if there are no passengers on the bus.",
            "Yes, but only if it overtakes a commercial vehicle.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1352. For safety reasons, it is required to install light-reflecting plates on the rear wall of a trailer or a semi-trailer. Hence, which of the following sentences is correct?",
          options: [
            "It is obligatory to install one 30cm X 60cm plate plate at the center of the vehicle’s rear wall.",
            "It is obligatory to install 2 plates on a vehicle with a width of more than 150cm. The plates’ outside frame should be red and the rest yellow.",
            "Three red/yellow plates should be installed on vehicles with a width of more than 200cm.",
            "The plates will only be installed according to the requirements of the Licensing Bureau and in accordance with the directions of the chief examiner.",
          ],
          answer: 1,
          image: "",
          types: ["C"],
        },
        {
          question: "0047. What is a “booster seat”?",
          options: [
            "A seat that is designed for transporting small children, restrained by the safety belt of the vehicle.",
            "A standard seat that is designed for transporting children until 18 years of age",
            "A seat that is designed for a driver whose height is less than 160 cm and whose age is more than 18 years",
            "A seat that is designed for transporting children on bicycles only while the children are restrained by a safety belt.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "В"],
        },
        {
          question:
            "1760. Which of the following road signs directs traffic to an interchange leading to a Freeway ( motorwat)",
          options: ["616", "617", "615", "613"],
          answer: 2,
          image: "img/quiz/31760.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1654. What is the connection between the leaning angle and the turning of a motorcycle and the motorcycle’s headlight?",
          options: [
            "The more a motorcycle is leaned or turned, the more its rider suffers from self-blinding.",
            "When you turn or lean the motorcycle, the strength of its lights decrease due to battery fluid level.",
            "The more the motorcycle is leaned or turned, the more its light beam is diverted from the desired direction of driving.",
            "There is no connection, unless motorcycle maintenance is poor and its light adjustment is impaired.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1425. What should be the road positioning of a vehicle that escorts a vehicle carrying oversize freight?",
          options: [
            "The positioning of the escorting vehicle depends on the width of the freight rather than on the type of road.",
            "On a one-way roadway he should drive before the freight carrying vehicle, and on a two-way roadway he should drive behind him.",
            "On a two-way roadway he should drive before the freight carrying vehicle, and on a one-way roadway he should drive behind him.",
            "The escorting vehicle should always drive before the freight carrying vehicle.",
          ],
          answer: 2,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0945. How are you required to conduct yourself according to the following road signs?",
          options: [
            "It is prohibited to drive slower than the speed indicated in the sign.",
            "Slow down and keep to the right before entering the curve.",
            "The last road sign is the determining one; drive 90 k.p.h.",
            "Drive normally. The placement of the road signs is unlawful.",
          ],
          answer: 1,
          image: "img/quiz/3945.jpg",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question:
            "1311. What is a driver required to do when there’s a change in his health condition?",
          options: [
            "Report about it immediately to the Licensing Bureau via registered mail.",
            "Report about it to the Licensing Bureau, only if it is a result of an accident.",
            "Report about it to the Licensing Authority within 15 days.",
            "Report about it immediately to the Ministry of Health.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0373. If traffic sign 618 is placed at an entry to a  road, which traffic sign should be placed at the entry from the opposite side of the same road?",
          options: ["308", "401", "307", "402"],
          answer: 3,
          image: "img/quiz/3373.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1798. From the answers before you, where especially should a driver slow down?",
          options: [
            "On an inter-urban road.",
            "In a congested area.",
            "Whilst driving on a main road.",
            "Whilst driving on an inter city road without a built central divider.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0469. What are you required to do according to the following road sign?",
          options: [
            "Stop and allow children who started to cross a pedestrian crossing to finish crossing.",
            "Keep driving, even if pedestrians are crossing.",
            "Always stop and allow children and elderly people to arrive to the pedestrian crossing and to safely cross it.",
            "Slow down and be aware of pedestrians on the zebra crossing ahead.",
          ],
          answer: 3,
          image: "img/quiz/3468.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1525. Is it permitted to drive under the influence of sedatives?",
          options: [
            "Only if the driver tends to be influenced by a certain medication.",
            "Yes, sedatives have no affect on driving.",
            "It is permitted to take sedation pills half an hour before driving.",
            "Not if it is specifically indicated in the prescription or by the doctor.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0045. Define “built dividing area”:",
          options: [
            "A separation area which divides the road lengthwise by means of an elevated structure above the road way",
            "The area along a roadway, in which road users can stop, park their vehicles or stand in for refreshment",
            "Any area that is marked on the road on which it is forbidden to drive",
            "Any part of the road (pavement, hard shoulder, traffic island) which is elevated above the road way.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0601. According to the law, what is the length limit of the bus in the following picture?",
          options: ["12 meters.", "13.5 meters.", "15 meters.", "18.5 meters."],
          answer: 2,
          image: "img/quiz/3601.jpg",
          types: ["D"],
        },
        {
          question:
            "1190. Is a public vehicle permitted to drive on all the marked lanes?",
          options: [
            "No, it may only drive on bus lanes.",
            "No, it may only drive on the right lane.",
            "No, only on the left side of the road.",
            "Yes, it may also drive on lanes that are not marked as bus lanes.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1273. According to the law, what measures should a bus driver lawfully take before entering the bus?",
          options: [
            "Cleaning the mirrors and the rear windshield.",
            "Cleaning the mirrors and the front and back windshields.",
            "Checking if there are road users nearby only.",
            "Performing a security check and a safety check.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question: "0640. What is the meaning of the following road marking?",
          options: [
            "Paint tests on the roadway.",
            "It is permitted to park on the side of the road with the broken line.",
            "An unbroken separation line is about to start.",
            "It is only permitted to cross the lines if the broken lines are marked on the right side of the continuous line.",
          ],
          answer: 3,
          image: "img/quiz/3640.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0975. How would you conduct yourself when approaching a place, as described in the picture?",
          options: [
            "Hoot to those standing by the vehicle, and make it clear that you intend on continuing driving without interruption.",
            "Slow down and pull into the right “hard shoulder” until the opposite lane is cleared.",
            "Stop at the right side of the road and wait for the rescue forces to arrive.",
            "Slow down and stop if necessary, and give right of way to the traffic coming from the opposite direction.",
          ],
          answer: 3,
          image: "img/quiz/3975.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1166. What is a meter-based tariff?",
          options: [
            "Tariff 2 on mid-holiday periods.",
            "Tariff 1 plus 15% at night.",
            "The tariff from the taxi’s location until the location of the person ordering the ride.",
            "Tariff 1 on mid-holiday periods.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question: "0013. A utility tractor is a tractor type that:",
          options: [
            "Has at least two wheels, an all up weight of not more than 1,500 kg, and is steered with a handlebar",
            "Has four wheels, an unlimited all up weight, and is steered with a handlebar",
            "Has at least three wheels and an all up weight of more than 12,000 kg",
            "Has at least four wheels, an all up weight of not more than 1,500 kg, and is steered with a wheel",
          ],
          answer: 3,
          image: "",
          types: ["C1", "1", "В"],
        },
        {
          question:
            "1652. What is the statutory length limit of the vehicle in the picture?",
          options: [
            "14.10 meters.",
            "13.75 meters.",
            "18.75 meters.",
            "15.50 meters.",
          ],
          answer: 2,
          image: "img/quiz/31652.jpg",
          types: ["C1", "C"],
        },
        {
          question:
            "0650. What traffic sign will appear following traffic sign 708?",
          options: ["710", "709", "716", "712"],
          answer: 1,
          image: "img/quiz/3650.jpg",
          types: ["D"],
        },
        {
          question:
            "1293. What should a driver do when the road has bumps and cracks?",
          options: [
            "Slow down and adjust his drving speed to the road’s conditions.",
            "Drive faster and on the “hard shoulder”",
            "Continue driving normally.",
            "Drive as much as possible in the middle of the road.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question: "1628. Is a tractor driver permitted to cross a sidewalk?",
          options: [
            "Yes, to pick up a passenger.",
            "Yes, in order to enter a yard or a garage.",
            "Yes, to drop a passenger.",
            "Yes, to bypass an obstacle on the road.",
          ],
          answer: 1,
          image: "",
          types: ["1"],
        },
        {
          question:
            "1727. Holder of a Class B driver’s license is also permitted to drive:",
          options: [
            "A public minibus with an all up weight of up to 3,500 kg.",
            "Any bus with an all up weight of up to 3,500 kg.",
            "A private minibus with an all up weight of up to 3,500 kg.",
            "Any motor vehicle with an all up weight of up to 6,000 kg.",
          ],
          answer: 2,
          image: "img/quiz/3091.jpg",
          types: ["C1", "В"],
        },
        {
          question:
            "0905. How would you conduct yourself according to the picture?",
          options: [
            "Glance at the mirrors, slow down and stop if necessary.",
            "Drive faster and honk gently.",
            "Honk for warning and continue driving in normal speed.",
            "Honk loudly to warn pedestrians.",
          ],
          answer: 0,
          image: "img/quiz/3905.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1694. Head lights which are not properly adjusted:",
          options: [
            "Make the driver more alert.",
            "Cause self-blinding of the driver.",
            "Have no effect on driving and on vehicle control.",
            "Do not illuminate the road properly.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question: "1803. What sign warns of a traffic accident ahead?",
          options: ["151", "150", "107", "127"],
          answer: 0,
          image: "img/quiz/31803.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1247. Are special taxis permitted to perform share-taxi rides?",
          options: [
            "Absolutely not.",
            "Yes, through a corporation that has a license for that service route and under written approval from that corporation.",
            "Yes, if the taxi ride’s destination is far from its station, in order to prevent an idle ride.",
            "All taxis are permitted to perform share-taxi rides.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1134. If a public bus driver runs out of tickets for a certain route – should he refuse driving a passenger who gets on the bus?",
          options: [
            "It is forbidden to let that passenger on the bus.",
            "The driver must drive the passenger.",
            "The driver must only drive the passenger in road sections he has tickets for.",
            "The driver doesn’t have to drive that passenger.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question: "1776. Is it permitted to drive on a separation area?",
          options: [
            "Yes, but only in order to cross it.",
            "No, unless otherwise marked by a traffic sign.",
            "Yes, always.",
            "Yes, when the separation area is paved.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1629. If a rider feels that the motorcycle foot brake,does not work efficently he should:",
          options: [
            "Stop the motorcycle and immediately repair the fault.",
            "Take some air out of the wheels to overcome the malfunction.",
            "Change between the front and rear wheels.",
            "Stop at the next gas station and increase tire air pressure.",
          ],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0951. Upon parking your vehicle on the right of the road in an urban:",
          options: [
            "you must make sure that alighting from the vehicle will not cause a disturbance to any other road user",
            "you must make sure that your rear seat passenger alights carefully",
            "you must make sure that you distance from the kerb isnot more that 1 mtr.",
            "you must put on your parking lights if there is no street lighting",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "0805. What is the advantage of driving at a low speed during dangerous situations?",
          options: [
            "The driver’s reacting time is longer and he has enough time for making the right decisions.",
            "The driver’s reacting, braking and stopping times are all longer, and therefore safety is improved.",
            "The drrivers quicker reaction, braking and stopping times are all shorter.",
            "The driver’s reacting time is longer and is sufficient for stopping.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1197. Which of the following sentences is correct regarding the duty to carry passengers on a public vehicle?",
          options: [
            "It is not obligatory to drive any passenger and it is prohibited to charge more than the stated priced for that ride.",
            "It is permitted to charge more than the stated priced for that ride – when the driver is tired.",
            "It is obligatory to carry any passenger that is willing to pay for the ride.",
            "It is obligatory to carry every passenger and it is permitted to charge more than the stated price – if the passenger’s consents.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0965. What are the permitted driving directions according to the traffic signs in the following intersection?",
          options: [
            "Straight and right.",
            "Straight and left.",
            "Straight only.",
            "Straight and a left U-turn.",
          ],
          answer: 2,
          image: "img/quiz/3796.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1010. When air bags are installed in a vehicle:",
          options: [
            "It is unnecessary to install seat belts in that vehicle.",
            "The seat belts must be fastened at all times when the vehicle is in motion.",
            "If the airbag system malfunctions, seat belts should not be worn.",
            "The seat belts should only be worn when the air big system malfunctions.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1533. How many vehicles is it permitted to tow by a commercial vehicle with an all up weight exceeding 2,200 kg?",
          options: [
            "Two vehicles.",
            "One vehicle, unless approved otherwise by the Licensing Authority.",
            "Only a tractor is permitted to tow another vehicle. A small commercial vehicle is prohibited to do so.",
            "There is no restriction on the number of vehicles that can be towed.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "В"],
        },
        {
          question:
            "0716. According to the law, in what order should vehicles enter the following intersection?",
          options: [
            "The tractor (2) always enters the intersection last.",
            "The tractor (2), you, as the vehicle making a left turn (3), the blue vehicle (1).",
            "The vehicle turning left (3), the blue vehicle (1), the tractor (2).",
            "You, as the vehicle making a left turn (3), followed by the tractor (2), and then the blue vehicle (1).",
          ],
          answer: 3,
          image: "img/quiz/3716.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1597. What is a reasonable and safe motorcycle riding speed?",
          options: [
            "The speed in which the rider exceeds the motorcycle’s driving limits or the road’s designated speed limit.",
            "The statutory speed limit.",
            "It depends on the motorcycle’s mechanical condition, age and safety systems.",
            "A speed in which the rider can identify all road details and in which he has full control of the motorcycle.",
          ],
          answer: 3,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1130. How many passengers is a bus driver allowed to carry?",
          options: [
            "Up to 50 passengers sitting and 10 standing.",
            "Up to 70 passengers.",
            "According to the number indicated in the bus registration documents (if such a number is indeed indicated).",
            "Up to 70 children.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0018. The following picture shows a pavement. Which of the following sentences is correct?",
          options: [
            "Motorcycles are permitted to use the pavement instead of the road.",
            "The pavement is designed for pedestrians only.",
            "Private passenger vehicles are always permitted to park temporarily on the pavement, when there is no parking space on the road.",
            "Bicyclists are permitted to ride on the pavement as well.",
          ],
          answer: 1,
          image: "img/quiz/3018.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0009. What is the penalty for driving whilst disqualified?",
          options: [
            "A fine of 80,000 NIS",
            "6 yrs in jail and 2 yrs on probation.",
            "None, if the driver presents a valid driver’s license within 7 days",
            "Up to 3 years in jail, plus other penalties.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0588. What is the meaning of the following traffic sign?",
          options: [
            "Narrow bridge. Give right-of-way to vehicles coming from the opposite direction.",
            "You have right-of-way on the narrow road.over oncoming traffic",
            "One-way traffic ahead.",
            "Driving backwards is prohibited.",
          ],
          answer: 1,
          image: "img/quiz/3579.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0833. What is the correct way for pulling onto a “hard shoulder” which is lower than the road?",
          options: [
            "Quickly pulling onto the “hard shoulder” and quickly stopping after a very short distance.",
            "Slowing down to a low speed and gradually moving onto the “hard shoulder”.",
            'There is no \\"correct way\\": It is prohibited to pull onto a lower hard shoulder.',
            "Quickly pulling onto the “hard shoulder” and then slowing down gradually.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0318. Does drinking alcohol cause sleepiness?",
          options: [
            "Drinking alcohol causes sleepiness among young drivers only.",
            "No.",
            "Yes.",
            "Only while driving during daytime.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1082. Which of the following accessories reduces passenger impact severity during an accident?",
          options: [
            "Seat belts, airbags and head rests.",
            "Head rests and the brake and clutch pedals.",
            "Rear windshield wipers and head rests.",
            "Fog lights and orthopedic seats.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "1", "В"],
        },
        {
          question:
            "0299. A policeman has stopped a driver on the road.and demanded of him to take a breathyalyzer tests . Is the driver obliged to perform the test",
          options: [
            "Yes, after coming out of a pub only.",
            "No, he is not obliged to do so.",
            "Yes, after midnight only.",
            "Yes, otherwise he is considered a drunk driver.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1442. What is a “semi-trailer”?",
          options: [
            "A cabin affixed to a trailer.",
            "A vehicle assembled on a chassis as one unit.",
            "A vehicle assembled on a beam chassis.",
            "A vehicle towing a motor vehicle behind it.",
          ],
          answer: 0,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0313. “Drinking alcohol disrupts the coordination between   limbs”: Correct or incorrect?",
          options: [
            "It depends on the driver.",
            "Incorrect.",
            "Correct.",
            "It is sometimes correct.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1600. What is the factor that affects the rider’s ability to brake, swerve or accelerate the motorcycle?",
          options: [
            "The motorcycle’s grip on the road.",
            "The rider’s standard clothing.",
            "The rider’s weight.",
            "The motorcycle seat design.",
          ],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question: "0759. What is the meaning of this sign?",
          options: [
            "End of a mountainous road.",
            "Refreshment area for drivers. It is obligatory to turn on a blinking light, either yellow or red",
            "Traffic cone, giving directions or marking an obstruction at a road works site.",
            "Narrowing road.",
          ],
          answer: 2,
          image: "img/quiz/3759.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1504. How would we attach a freight container to the vehicle’s loading surface?",
          options: [
            "With steel cables – if a screw anchor (twist lock) is absent.",
            "With the walls of the loading surface.",
            "With a screw anchor (twist lock) only.",
            "With two independent chains, each connected to the loading surface.",
          ],
          answer: 2,
          image: "",
          types: ["C"],
        },
        {
          question:
            "1284. When driving near a group of children, the driver is required to:",
          options: [
            "Slow down and, if needed, stop the vehicle.",
            "Make a complete stop for as long as the children are close to the roadway.",
            "Continue driving in normal speed, provided that he uses the horn to warn them.",
            "Continue driving in normal speed, if the group of children is accompanied by a teacher or a guide.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0429. Is it specified by the following road sign which rules for giving right-of-way should be applied?",
          options: [
            "Yes. You have right-of-way since you are driving in the main road.",
            "No. Right-of-way is determined according to lawful right-of-way rules or according to the traffic signs that are placed in the intersection.",
            "Yes. According to this sign, right-of-way should be given to vehicles that come from the right only.",
            "Yes. Right-of-way should be given to vehicles that come from the right and from the left.",
          ],
          answer: 1,
          image: "img/quiz/3428.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1360. Which vehicles must be equipped with a fire extinguisher?",
          options: [
            "Only private passenger cars.",
            "All motor vehicles, except for private passenger cars, tractors and motorcycles.",
            "All motor vehicles.",
            "Only two-wheelers.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question:
            "0695. Until where does the following road sign remain valid?",
          options: [
            "Until the next intersection.",
            "Until a traffic sign that prohibits entry of vehicles with an all up weight of less than 10,000 kg.",
            "Until the next traffic light.",
            "Until a road sign that cancels it.",
          ],
          answer: 3,
          image: "img/quiz/3694.jpg",
          types: ["C1", "C"],
        },
        {
          question:
            "1406. When a policeman instructs a driver to bring his vehicle for weighing – who is required to pay the weighing expenses if an irregular weight is found?",
          options: [
            "The State of Israel.",
            "The policeman.",
            "The driver.",
            "The driver, but the Ministry of Industry, Trade and Labor gives the driver a 50% refund if the weight is found to be legal.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "1709. Driving at night with the high beam can cause:",
          options: [
            "It doesn’t disturb other drivers.",
            "The dazzling ( blinding )of other drivers.",
            "Damage to the vehicle’s lighting system, particularly to the headlights.",
            "There is no statutory restriction on the use of the high beam while driving.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0859. What should be the initial actions taken by a driver after entering the vehicle and before starting to drive?",
          options: [
            "Checking the position of the gear handle.",
            "Buckling the seatbelt.",
            "Starting-up and warming the vehicle.",
            "Adjusting the seat, steering wheel, mirrors and head rests.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "0835. What are the preliminary actions a driver must perform while getting back up to the road from the “hard shoulder”?",
          options: [
            "Observing all traffic on the road, signaling appropriately and gradually moving back on the road.",
            "Signaling appropriately and getting back to the road as quickly as possible.",
            "Getting back to the road diagonally, as quickly as possible..",
            "Putting the vehicle in a diagonal position and turning on the vehicle’s distress lights.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0858. From which side should a driver approach his vehicle before entering it?",
          options: [
            "From the vehicle’s front and he should face the traffic.",
            "The direction from which he approaches the vehicle is meaningless, because other drivers are forbidden to endanger a driver that enters his vehicle.",
            "From behind the vehicle while his back is turned to the traffic, and from the right door, where there is no danger from traffic.",
            "From behind the vehicle while his back is turned to the traffic.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "1481. How is it possible to reduce the centrifugal force applied to the vehicle whilst driving?",
          options: [
            "The effect of these force cannot be reduced – it depends on the vehicle’s size only.",
            "By driving faster.",
            "By driving lawfully and always giving right of way.",
            "By reducing speed.on corners and curves",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0426. What is the meaning of the following traffic sign?",
          options: [
            "Dead-end street ahead.",
            "Give right-of-way to crossing vehicles.",
            "Instruction road sign: Drive right only.",
            "T-junction ahead.",
          ],
          answer: 3,
          image: "img/quiz/3426.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1189. Is a bus driver required to hold in the vehicle an extra pair of glasses / contact lenses?",
          options: [
            "Yes.",
            "Yes, even if he is not the acting driver at that time.",
            "Yes, every driver of a commercial vehicle must hold an extra pair, even when he doesn’t carry any passengers.",
            "Yes, every acting driver of a commercial vehicle that carries up to 6 passengers must hold an extra pair.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0565. Is a “restricted vehicle” permitted to use a road in which the following traffic sign is placed?",
          options: [
            "Yes, driving at a minimum speed of 55 k.p.h.",
            "Yes, driving at a maximum speed of 55 k.p.h.",
            "Yes, on a freeway only.",
            "No.",
          ],
          answer: 3,
          image: "img/quiz/3564.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1290. One of the differences between manual and automatic transmission vehicles is that:",
          options: [
            "Fuel consumption is usually lower for manual transmission vehicles.",
            "Manual transmission vehicles are noisier.",
            "Acceleration capacity is lower for manual transmission vehicles.",
            "Manual transmission vehicles have no clutch pedal.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question: "1693. The ability to control a vehicle:",
          options: [
            "Does not depend on driving speed.",
            "Depends on road conditions only, and not on the vehicle or the driver.",
            "Improves the faster you drive.",
            "Increases when driving at a suitable speed for the varying road conditions.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1692. In the event of a bus driving outside the traffic transportation lane – Is it allowed to overtake another overtaking vehicle?",
          options: [
            "It is permitted, provided that there are no passengers on the bus.",
            "It is prohibited on all types of roads.",
            "It is permitted in all types of roads.",
            "It is permitted, provided that the all up weight of the bus does not exceed 10,000kg.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question: "1022. What is the meaning of this traffic sign?",
          options: [
            "It is a warning, instruction or prohibitory sign, for public transport only.",
            "It is a square information sign.",
            "It is a warning, instruction or prohibitory sign, which conveys a message that is not covered by the provisions of the road sign table.",
            "It is an unofficial road sign, which conveys various optional guidances.",
          ],
          answer: 2,
          image: "img/quiz/31022.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0816. What effect does keeping distance have on the driver’s field of vision?",
          options: [
            "Keeping distance has no connection to the driver’s field of vision.",
            "Keeping distance affects the driver’s field of vision while driving in high speeds and during stormy weather conditions only.",
            "The greater is the distance we keep from the vehicle in front, the better is our field of vision.",
            "Keeping distance affects the driver’s field of vision during traffic congestion only (“traffic jams”).",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0775. How does pedestrian behavior in the area of the intersection affect drivers?",
          options: [
            "Pedestrians have no significant effect on drivers.",
            "The main complication in an intersection is created when pedestrians are crowded on the sidewalk.",
            "Driver should anticipate in advance possible dangerous behavior from pedestrians in the area of the intersection.",
            "Pedestrians occupy pedestrian crossings for significant time periods, thus causing traffic delays.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0430. What is the meaning of the following traffic sign?",
          options: [
            "Cross the roundabout from its left.",
            "The drivers inside the roundabout should yield when you enter it.",
            "Roundabout ahead.",
            "No right-of-way in the next square.",
          ],
          answer: 2,
          image: "img/quiz/3430.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1259. Is a bus without passengers permitted to enter a road on which the following road sign is placed?",
          options: [
            "Yes, because all vehicles are permitted to drive on that road.",
            "Yes, according to the provisions of the sign.",
            "No, because it doesn’t carry the number of passengers required by the sign.",
            "No, because a bus without passengers does not serve as a bus.",
          ],
          answer: 1,
          image: "img/quiz/3531.jpg",
          types: ["D"],
        },
        {
          question:
            "1251. Under what condition would a bus driver permit a passenger to carry luggage on the bus?",
          options: [
            "That it is one unit, not longer than 3 meters.",
            "That the freight is packed in a safety wrapping.",
            "That the freight is related to the passenger’s livelihood.",
            "That it doesn’t disturb or harm the passengers.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1085. What is the purpose of the level (balanced) movement of the tractor’s front axle?",
          options: [
            "To improve the tractor’s stability while driving on a surface that is not level.",
            "To improve the tractor’s stability while driving at an extreme side slope.",
            "To improve the tractor’s stability while driving on fast roadways and paths.",
            "To improve driving comfort on roadways and leveled paths.",
          ],
          answer: 0,
          image: "",
          types: ["1"],
        },
        {
          question:
            "1503. The wind resistance that a driving vehicle faces affects:",
          options: [
            "The vehicle’s speed and tire wear.",
            "The wear and tear of the steering wheel system,",
            "The capacity of the vehicle’s brakes.",
            "The vehicle’s stability and speed.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0627. What is the meaning of the following traffic sign?",
          options: [
            "Parking on the sidewalk is permitted, provided that the sidewalk is wider than six meters.",
            "Parking on the sidewalk is permitted for all vehicles, according to the sidewalk’s marking.",
            "No parking on the sidewalk.",
            "Parking on the sidewalk is permitted, according to the sidewalk’s marking, for private and commercial vehicles with an all up weight of up to 2,200 kg.",
          ],
          answer: 3,
          image: "img/quiz/3627.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0493. What is the meaning of the following traffic sign?",
          options: [
            "End of overtaking restriction.",
            "End of overtaking section.",
            "End of urban road.",
            "No overtaking for public vehicles only.",
          ],
          answer: 0,
          image: "img/quiz/3493.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0028. When is it permitted to overtake, in one go, two vehicle that are driving before you on your lane",
          options: [
            "Only on highways.",
            "When overtaking is not prohibited, and when the overtaking can be safely completed.",
            "This type of overtaking is absolutely prohibited by law.",
            "Only when overtaking two private passenger cars.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0261. When is it permitted to use the “white reversing light”?",
          options: [
            "While driving in reverse, combined with the reverse gear only.",
            "While parking in a roofed parking lot.",
            "When visibility is poor for any given reason.",
            "During fog or pouring rain.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "1333. A registration document shall not be provided or renewed for a rescue and towing vehicle with an all up weight of up to 16,000 kg:",
          options: [
            "The law doesn’t restrict the renewal of the registration document of such a vehicle.",
            "More than 10 years after its manufacture date.",
            "More than 8 years after its manufacture date.",
            "More than 19 years after its manufacture date.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0984. How should you behave when approaching the road section described in the following picture?",
          options: [
            "Keep to the right of the road and enter the curve with cautions.",
            "Keep to the right without changing your driving speed.",
            "Drive in high gear.",
            "Slow down considerably and keep driving on the left side of the roadway.",
          ],
          answer: 0,
          image: "img/quiz/3984.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1486. What is the speed limit on an urban road for a private passenger car (if not indicated otherwise by any road sign)?",
          options: ["40 k.p.h.", "70 k.p.h.", "50 k.p.h.", "60 k.p.h."],
          answer: 2,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0489. What are you required to do according to the following road sign?",
          options: [
            "Stay away from the right edge of the road.",
            "Slow down as you may encounter cyclists crossingn the road ahead.",
            "Drive on the left of the road to avoid endangering anybody.",
            "You have right-of-way. You can continue driving fast.",
          ],
          answer: 1,
          image: "img/quiz/3419.jpg",
          types: ["C1", "C", "A", "1", "В"],
        },
        {
          question:
            "1236. Are you obliged to vacate your bus seat for a handicapped person, when bus seats have been marked and reserved in advance?",
          options: [
            "Yes, a handicapped person’s spot on a bus is always assured.",
            "Yes.",
            "No.",
            "Yes - on special rides only.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question: "1466. The higher is the vehicle’s center of gravity:",
          options: [
            "The higher is the vehicle’s stability on wet roads.",
            "The lesser is the vehicle’s stability in turns and curves.",
            "The easier it is for the vehicle to make turns",
            "The higher is the vehicle’s stability in curves.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question:
            "0664. What is the meaning of the light appearing in the following traffic light?",
          options: [
            "If the intersection is clear, you may carefully cross it without stopping.",
            "Red light: Stop.",
            "Red light: Stop, unless you cannot stop safely.",
            "Stop for a moment, give right-of-way and carefully continue.",
          ],
          answer: 1,
          image: "img/quiz/3664.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0540. What are you required to do according to the following traffic signs?",
          options: [
            "Turn leftt. Turn after the sign.",
            "Drive left. Turn after the sign. A yellow arrow shows the direction of driving for restricted vehicles only.",
            "Drive left. Turn after the sign.. A yellow arrow shows the direction of driving for private passenger cars only.",
            "Drive right. Turn before the sign.",
          ],
          answer: 0,
          image: "img/quiz/3540.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1083. On which axle do the tractor’s service brakes act?",
          options: [
            "The rear axle.",
            "There are no service brakes in a tractor.",
            "The front axle.",
            "On all axles.",
          ],
          answer: 0,
          image: "",
          types: ["1"],
        },
        {
          question: "0950. When there is heavy rain,",
          options: [
            "You are entitled to put on the emergency yellow indicators..",
            "You are permitted to turn on the fog lights.",
            "You should drive at a speed half the speed limit",
            "You are permitted to turn on the main(high) beam",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0316. “Drinking alcohol has an adverse effect on eyesight”: Correct or incorrect?",
          options: [
            "Correct.",
            "Incorrect.",
            "It is only correct for drivers wearing eyeglasses.",
            "It is only correct during the night.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1148. Can a bus driver prevent a passenger from entering the bus if he is wearing clothes that might contaminate the bus seats?",
          options: [
            "No. Only the company manager shall determine who is prohibited to enter the bus.",
            "No. Only the dispatcher is permitted to do so.",
            "No. Only the police can authorize to prevent a person from entering the bus.",
            "Yes.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1566. What is the normal pressure in a vehicle’s pneumatic (compressed air) braking system?",
          options: [
            "The maximum pressure the vehicle can generate.",
            "As determined by the vehicle’s manufacturer.",
            "The pressure in which the vehicle brakes efficiently.",
            "As determined by local laws.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question:
            "1056. Who is permitted to repair vehicle safety part failures?",
          options: [
            "Every driver having know-how and experience in the field of vehicle mechanics.",
            "Every professional in any vehicle repair garage.",
            "Every driver, as long as he follows the manufacturer’s manual.",
            "A qualified mechanic at a licensed garage only.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question: "0579. What is the meaning of the following traffic sign?",
          options: [
            "Follow the direction of the white arrow. Traffic in the opposite direction is prohibited.",
            "You have right of way over on-coming traffic",
            "Driving backwards is prohibited.",
            "Give right-of-way in a narrow road to incoming traffic from the opposite direction.",
          ],
          answer: 1,
          image: "img/quiz/3579.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1563. When a driver is blinded by the sun, he should:",
          options: [
            "Drive faster until reaching a shaded place.",
            "Slow down and adjust the driving speed to his current range of sight.",
            "Look as low as possible to the roadway.",
            "Overtake the vehicle in front because it is better to drive on an open roadway.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1544. The all up width of a refrigeration unit commercial vehicle should not exceed:",
          options: [
            "2.60 meters.",
            "2.50 meters.",
            "3.40 meters.",
            "2.55 meters.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0103. In the illustration you see a wide road. What number illustrates a hard shoulder. 103",
          options: ["3", "7", "1", "6"],
          answer: 1,
          image: "img/quiz/3082.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0019. What is an “Interchange”?",
          options: [
            "The area that is formed by the meeting of at least three roadways, at the same level.",
            "A meeting of at least two roadways at the same level in a freeway.",
            "A meeting point of roads at different levels, in which it is possible to drive and pass from one road to the other",
            "A junction of roads at the same level in an urban area.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1021. Which type of bulb would you use when required to change a bulb in the vehicle?",
          options: [
            "It is permitted to change a bulb in a certified licensing facility only.",
            "A bulb, stipulated in the manufacturer’s manual.",
            "It is prohibited to change a bulb without the permission of the treating garage.",
            "The type of the bulb is unimportant, as long as it works.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question:
            "1686. Upon completion of the two year “new driver” period, a regular driver’s license will not be given to a “new driver” who was convicted of:",
          options: [
            "Driving a motor vehicle under the influence of alcohol or intoxicating drugs.",
            "Not stopping next to a “stop” sign before an intersection.",
            "Not placing a warning triangle while his malfunctioning vehicle was left standing on the road.",
            "Driving a motor vehicle without valid insurance.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question:
            "0769. What is the main problem a driver faces when he approaches an intersection?",
          options: [
            "Many tasks he needs to perform simultaneously, in a short period of time.",
            "The speed limit changes in an intersection.",
            "Part of the intersection is always designated for public transportation.",
            "The driver’s main problem is how to cross the intersection quickly.",
          ],
          answer: 0,
          image: "img/quiz/3769.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0083. A “central divider” is:",
          options: [
            "An area in which parking is prohibited, unless a traffic sign only directs otherwise",
            "An area allowing escape at a time of danger, marked by a specific traffic sign",
            "An area intended for two-wheeler parking on an urban way, without requiring any specific traffic sign",
            "An area intended for parking on an urban way",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1075. How do dirty mirrors affect vehicle safety?",
          options: [
            "Dirt might damage the safety systems.",
            "A clean vehicle helps to improve the driver’s concentration and general feeling.",
            "A dirty vehicle endangers and pollutes its close environment.",
            "They impair the driver’s vision.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question: "1554. When spending time in a club (pub):",
          options: [
            "It should be planned to wait one hour after leaving the pub, and then there should be no danger to drive.",
            "It should be agreed that the person drinking the least amount of alcohol would drive after leaving the pub.",
            "A designated driver, who abstains from drinking alcohol, should be determined in advance.",
            "It should be agreed that the most experienced driver would drive after leaving the pub.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1625. Does an agricultural trailer require a warning triangle?",
          options: [
            "Only trailers with an own weight of up to 4,000 kg.",
            "No.",
            "Only small trailers with an own weight of up to 500 kg.",
            "Yes.",
          ],
          answer: 3,
          image: "",
          types: ["1"],
        },
        {
          question: "0247. What is a “security vehicle”?",
          options: [
            "A fire-fighting vehicle only.",
            "Any ambulance, police vehicle, fire fighting vehicle, or any other security vehicle that gives a red or blue light and makes a siren sound.",
            "A Red Magen David Ambulance only.",
            "Any Israel Police vehicle.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0033. Define “stop line”:",
          options: [
            "A line drawn on either full or half width across the road marking a stopping point for vehicles.",
            "A line along the road, marking the point in which a vehicle must stop, in junctions with traffic lights only.",
            "A line along the road, marking the point in which a vehicle must stop, before a railway crossing",
            "A junction-line along the road, either full or partial, marking the point in which a vehicle must stop near a pavement",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0943. How are you required to conduct yourself according to the following picture?",
          options: [
            "Keep driving routinely when the road is clear.",
            "Pass quickly next to the road sign.",
            "Slow down and keep to the right; winding road ahead.",
            "Honk before entering the curve.",
          ],
          answer: 2,
          image: "img/quiz/3943.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1112. How would a tractor and its trailer maintain low speed during a long and steep descent?",
          options: [
            "By driving in low gear and relying on the engine’s compression braking .",
            "By regulating the speed with the brakes.",
            "By using the trailer’s brakes and locking the differential.",
            "By operating the engine brake and driving in low gear.",
          ],
          answer: 0,
          image: "",
          types: ["1"],
        },
        {
          question:
            "0953. How should you behave in the situation described in the following picture?",
          options: [
            "Turn on all four winkers to alert the driver coming from the opposite direction.",
            "Turn on the high beam to extend the range of your field of vision.",
            "Turn on the high beam and stay at the center of the roadway, so that the vehicle coming in your direction can notice you.",
            "Dip the lights, keep to the right and slow down to enable stopping if necessary.",
          ],
          answer: 3,
          image: "img/quiz/3953.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1773. A freeway is designated for:",
          options: [
            "Only Vehicles which are capable and permitted to drive at a speed of 55 k.p.h .",
            "Motor vehicles which are able to drive at a speed of at least 90 k.p.h.",
            "Private passenger cars, taxis and buses only.",
            "Motor vehicles and bicycles.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0739. In order to start driving, what is the action the driver has to take before releasing the hand-brake in a vehicle with automatic transmission?",
          options: [
            "Apply the foot brake pedal, to prevent the vehicle from moving.",
            "Shifting the gear handle from parking (P) to neutral (N).",
            "Pushing the gearbox’s lock button while in parking (P).",
            "Shifting the gear handle to neutral (N).",
          ],
          answer: 0,
          image: "",
          types: ["C1", "D", "В"],
        },
        {
          question:
            "1206. How would you mark a bus that needs to be left standing at night on an Inter-urban road?",
          options: [
            "Same as any other vehicle.",
            "Marking is not obligatory, but it must be immediately towed away from the place.",
            "By placing a warning triangle seen by approaching vehicles from a distance of at least 100 meters, as well as by operating the distress flashers (double indicators).",
            "By placing a warning triangle seen by approaching vehicles from a distance of at least 50 meters, accompanied by a flickering yellow light seen from a distance of 100 meters.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question: "0503. What is the meaning of the following traffic sign?",
          options: [
            "No entry for all motor vehicles.",
            "Entry of motorcycles is permitted. .",
            "No entry for bicycles.",
            "No entry for motorcycles.",
          ],
          answer: 3,
          image: "img/quiz/3503.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1737. Mark the correct sentence:",
          options: [
            "It is prohibited to park a vehicle on a pedestrian crossing or within a distance of 12 meters before it.",
            "It is permitted to park vehicles on a pedestrian crossing on a non-urban road only.",
            "It is prohibited to park vehicles within a distance of 20 meters before and after a pedestrian crossing.",
            "It is prohibited to park vehicles on a pedestrian crossing and within a distance of 20 meters before it.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1651. Is it permitted for a motorcycle owner to replace the motorcycle’s original-size tires with tires of a different size?",
          options: [
            "Yes, but only on motorcycles with a sidecar.",
            "No, unless he obtains a pre-approval to do so from the Licensing Authority.",
            "Yes, provided that the new size deviates within 10% of the size indicated in the vehicle’s registration document.",
            "Yes, he may save fuel that way.",
          ],
          answer: 1,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1733. What should a motorcycle rider do when he steps on the foot brake, but the foot brake doesn’t function?",
          options: [
            "He should try to brake by strongly pressing the clutch handle.",
            "He should try to brake by using the hand brake(front brake) and by shifting into low gear.",
            "He should brake by shifting to lower gears only.",
            "He should turn the handlebar to the right in order to brake through the change of the front wheel’s direction.",
          ],
          answer: 1,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0301. Does a-can of beer have a lesser effect on a driver compared to a whisky shot?",
          options: [
            "No. They both have a more or less equal effect on the driver’s ability to drive safely.",
            "No, beer is not regarded as a drink which affects one’s driving.",
            "Yes, beer has more alcohol than whisky.",
            "No, the quantity of beer is greater and therefore its effect is greater.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0291. What law is applied to a driver of a hand propelled vehicle?",
          options: [
            "Only two-wheelers are allowed to be powered by persons who don’t have a driver’s license.",
            "Same as that applying to a vehicle driver.",
            "Only private passenger vehicles are allowed to be powered by persons who don’t have a driver’s license.",
            "Only all-terrain vehicles are allowed to be powered by persons who don’t have a driver’s license.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0336. Is the Licensing Authority permitted to summon a holder of a driver’s license to attend a driving education course?",
          options: [
            "Yes it may, but only within a period of two years from the date in which the driver’s license was obtained.",
            "No, it is not permitted to do so.",
            "Yes, it is Legally permitted to do so.",
            "No, only the Israel Police can instruct to summon a holder of a driver’s license to attend a driving education course",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0074. Is it permitted to drive a vehicle when its front field of vision is restricted?",
          options: [
            "Yes, but during the day time.",
            "No, unless the restriction is only negligible.",
            "Yes, provided that the field of vision is restricted from the front side only.",
            "It is forbidden during rainy weather and permitted at any other time.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "1217. To whom should you hand lost-and-found objects found in public vehicles?",
          options: [
            "Nobody. You should locate the owner and return the lost object to him.",
            "To the dispatcher at the taxi station of the same route service.",
            "To the police, or according to any pre-determined arrangements with the owner of the route service.",
            "To the dispatcher at the bus station of the same route service.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1639. What risk do riders of two-wheelers face while passing between two rows of cars that stand before an intersection?",
          options: [
            "There is no risk, as there is always enough space for two-wheelers to pass comfortably and safely.",
            "The risk is that at the beginning of the row, next to the intersection, there is no more space left because there are many two-wheelers standing there.",
            "They might hit pedestrians who are not using a regulated pedestrian crossing.",
            "They might be hit by cigarette butts thrown by vehicle drivers.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0021. The boundary of the intersection / junction is determined by:",
          options: [
            "The stop lines on each of the roads that meet in the intersection",
            "The place in which traffic signs are posted before the intersection",
            "The imaginary line drawn between the pavements or kerbs of each road meeting the junction.",
            "The meeting of two or more pedestrian crossings in the intersection",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0832. What are the most important conditions in order to safely pull onto the “hard shoulder”?",
          options: [
            "The only important condition is that it is permitted to stop on the “hard shoulder”.",
            "That the hard shoulder and the road are in the same height and that the hard shoulder is wide enough.",
            "That the hard shoulder is paved with asphalt or concrete.",
            "That there are no color markings on the hard shoulder.",
          ],
          answer: 1,
          image: "img/quiz/3832.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1070. A bus route permit regulates:",
          options: [
            "Only the route in which the bus is listed.",
            "Only the fare of the route service.",
            "The service on that route.",
            "The details and size of the bus.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0513. What is the meaning of the following road sign (the yellow colored left arrow)?",
          options: [
            "It is permitted to drive to the right of the yellow line on the road.",
            "Follow the direction of the yellow arrow only.",
            "Public transportation vehicles are prohibited to pass the signpost on its left.",
            "Only public transportation vehicles are permitted to pass the signpost on its left.",
          ],
          answer: 3,
          image: "img/quiz/3513.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1280. Picking up or dropping off pupils on student transport is permitted:.",
          options: [
            "From the rear door only.",
            "From the front right door only.",
            "From the front door and from the rear door.",
            "Picking up - from the front door. Dropping off – from the rear door.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0280. What is required from a vehicle driver that passes  the scene of a road accident with casualties?",
          options: [
            "He should stop at the side of the road and mark the site of the accident with a warning triangle to be seen to a distance of fifty meters.",
            "If he was not involved in the accident, he should continue driving without stopping by.",
            "He should stop at the side of the road, help to clear the vehicles away from the road and allow the traffic to flow freely and uninterruptedly. .",
            "He should stop at the site of the accident, provide casualties with first-aid, to the best of his ability and training, and call the rescue forces.",
          ],
          answer: 3,
          image: "img/quiz/3280.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1051. The required air pressure in the vehicle’s tires:",
          options: [
            "Is written on a sticker inside the vehicle.",
            "Is written in the insurance policy.",
            "Is written in the vehicle’s registration documents.",
            "Is written in the insurance certificate.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1553. It is permitted to park, stop or stand a vehicle when the distance between the vehicle’s wheels and the road’s edge doesn’t exceed:",
          options: ["80 cm.", "50 cm.", "60 cm.", "40 cm."],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "0982. Which of the following sentences is the most correct, as regards to the following picture?",
          options: [
            "The driver is not keeping to the extreme right of the road.",
            "The driver is keeping away from the “hard shoulders” and through this prevents unnecessary risk while in distress.",
            "The driver of the white vehicle is driving correctly on the middle of the roadway.",
            "The marking on the roadway is not clear enough.",
          ],
          answer: 0,
          image: "img/quiz/3982.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1210. A bus lane may also be used by vehicles of:",
          options: [
            "Holders of a handicap tag issued by the vehicle’s manufacturer.",
            "Handicapped people, even when they are not inside the vehicle.",
            "Paraplegic (wheel chair) tag holders.",
            "Any handicapped person having a regular handicap tag.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "D", "В"],
        },
        {
          question:
            "1034. What is the proper way of checking engine oil level?",
          options: [
            "By using the engine’s dipstick, when the engine is cold and turned off.",
            "There is no need to check the engine’s oil, but only to change it in accordance with the manufacturer’s requirements.",
            "It can only be checked in a licensed garage.",
            "Only before the vehicle’s annual test.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "1", "В"],
        },
        {
          question:
            "1062. Which vehicle component (part) engages or disengages the rotational movement from the engine to the gear box?",
          options: ["The battery.", "The wheels.", "The fan.", "The clutch."],
          answer: 3,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question:
            "0424. What are you required to do when the following road sign is placed?",
          options: [
            "Whoever drives straight shall continue driving and make sure that vehicles approaching the straight roadway give him right-of-way.",
            "Always stop before the intersection and give right-of-way to vehicles which come from the right and intend to turn left.",
            "Give right-of-way according to the accepted right-of-way rules and to the traffic signs that are placed at the junction.",
            "Slow down and give right-of-way to vehicles approaching the intersection from your front.",
          ],
          answer: 2,
          image: "img/quiz/3423.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0410. What are you required to do according to the following road sign?",
          options: [
            "Enter curve in high speed and exit it slowly.",
            "Drive normally. The speed at the curve’s entry and exit is insignificant.",
            "Reduce speed before entering the curve and gradually increase speed coming out of it.",
            "Honk before entering the curve.",
          ],
          answer: 2,
          image: "img/quiz/3409.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0765. “Keeping a safe distance from the vehicle in front affects fuel consumption and vehicle wear and tear”. Correct or incorrect?",
          options: [
            "Incorrect: The distance has no influence on vehicle wear or fuel consumption.",
            "Correct: Keeping distance prevents brake wear and excessive fuel consumption due to frequent acceleration and braking.",
            "Incorrect: Using the breaks and the accelerator in order to regulate wind flow is important for increasing the driver’s alertness.",
            "Incorrect: Keeping proper distance from the vehicle in front of you causes brake wear.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1788. Is the Licensing Authority (Licensing Bureau) permitted to delay the renewal of a driving license for a person who has a pending fine?",
          options: [
            "The Licensing Bureau is only permitted to delay renewal if the fine was accompanied by the issuance of demerit points, based on the traffic point system.",
            "Yes. It is permitted to delay renewal due to fines imposed by any governmental body.",
            "No. The Licensing Bureau is not permitted to delay license renewal without the consent of the driving license holder.",
            "Yes, but only if the fine was imposed due to a traffic offense.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0504. Which road sign informs about a change in the number of road lanes?",
          options: ["145", "111", "109", "622"],
          answer: 3,
          image: "img/quiz/3504.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1361. Which vehicles must be equipped with chains or cables and lamps during lighting up time?",
          options: [
            "Every vehicle which carries oversize freight and which requires the approval of a police officer.",
            "A more than 120cm wide trailer or semi-trailer, without floor and walls, which is designed to carry long freights.",
            "Vehicles in which the freight sticks out of the loading platform.",
            "Vehicles with an overall length of more than 20 meters and a width of more than 3 meters.",
          ],
          answer: 1,
          image: "",
          types: ["C"],
        },
        {
          question: "0671. The danger of skidding on a wet road is greater:",
          options: [
            "The deeper are the grooves in the tires.",
            "The more worn-out are the tires.",
            "The higher is the tire air pressure.",
            "The heavier is the vehicle.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question:
            "0560. Who is expected to approach from your opposite direction when the color of the arrow in the sign is yellow?",
          options: [
            "Only tour buses.",
            "Vehicles transporting more than 2 passengers.",
            "Only trains.",
            "Public transportation vehicle or any other vehicle that is permitted according to the sign.",
          ],
          answer: 3,
          image: "img/quiz/3560.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1172. Is a taxi driver permitted to load passengers within the limits of signs that prohibit stopping or parking?",
          options: [
            "Yes, but only on an urban road.",
            "He is permitted to stop only if instructed to do so by a policeman.",
            "A taxi driver is permitted to stop on any type of road.",
            "Only share-taxi drivers.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0172. The following illustration shows a wide road. Which number in it marks a road?",
          options: ["2", "8", "1", "3"],
          answer: 0,
          image: "img/quiz/3082.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1380. Is the Licensing Authority authorized to permit the use of a vehicle with size and weight which exceed regulations?",
          options: [
            "No, only a police officer ranked superintendent or higher is allowed to provide such permission.",
            "Yes, under special conditions.",
            "Yes, following consultation with the Public Works Department and with the Police.",
            "No, the Licensing Authority is prohibited to provide any permit against regulations.",
          ],
          answer: 1,
          image: "",
          types: ["C", "1"],
        },
        {
          question: "0414. What is the meaning of the following traffic sign?",
          options: [
            "Narrow bridge ahead.",
            "Traffic congestion ahead.",
            "Road narrows from the right.",
            "Number of lanes is decreasing.",
          ],
          answer: 2,
          image: "img/quiz/3414.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0698. Are you required to give right-of-way to the cyclist?",
          options: [
            "No, because a non-motor vehicle should give right-of-way to a motor vehicle.",
            "No, because bicycles are not given right-of-way.",
            "No, because right-of-way is given to the vehicle that continues driving straight.",
            "Yes, because the cyclist approaches from the right (1).",
          ],
          answer: 3,
          image: "img/quiz/3698.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0100. Is it obligatory to wear safety belts in a utility vehicle?",
          options: [
            "Yes, it is obligatory on freeways only, for both the driver and the passengers",
            "Yes, it is obligatory on any type of road, for both the driver and all the passengers",
            "Yes, but it is only obligatory on dirt roads, for both the driver and the passengers",
            "No, it is not obligatory to wear safety belts",
          ],
          answer: 1,
          image: "",
          types: ["C1", "1", "В"],
        },
        {
          question: "1494. Who is permitted to transport hazardous materials?",
          options: [
            "Anybody who was trained to do it and obtained a permit from the body that trained him.",
            "Anybody who successfully completed a heavy vehicle driving course.",
            "Anybody who is permitted to drive a vehicle with an all up weight of more than 12,000 kg..",
            "Only someone who was trained to do so and obtained a permit from the Ministry of Transportation.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "0558. What is the meaning of the following traffic sign?",
          options: [
            "Two-way traffic ahead.",
            "You have right-of-way over vehicles approaching from the opposite direction.",
            "The number of lanes is increasing.",
            "You are permitted to use all lanes.",
          ],
          answer: 0,
          image: "img/quiz/3473.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1617. Is a motorcyclist permitted to cross a sidewalk?",
          options: [
            "Yes, but only to bypass an obstacle on the road.",
            "Yes, but only to drop a passenger.",
            "Yes, in order to enter a yard or a garage.",
            "Yes, but only to pick up a passenger.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0339. What is the validity period of a “new driver” driver’s license?",
          options: [
            "Ten years.",
            "Five years.",
            "Only one year.",
            "Two years.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1543. Is it permitted to drive on the sidewalk in order to pass a road obstacle?",
          options: [
            "Yes. It is permitted to bypass the obstacle only through the sidewalk, if it is simpler.",
            "Yes. It is permitted to drive on the sidewalk very carefully and in first gear.",
            "No. It is prohibited to drive on the sidewalk in order to pass an obstacle.",
            "Yes. It is permitted to drive on the sidewalk, but only if the roadway is in a bad condition.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0024. Define level crossing:",
          options: [
            "A place where a road and a railroad meet on a freeway.",
            "A place where a road and a railroad meet at different levels.",
            "A place where a road and a railroad meet or cross at the same level.",
            "Any place where a railroad can be crossed",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0877. What are you always obliged to do while driving in reverse?",
          options: [
            "Drive in low gear, according to the road’s conditions, in a steep descent only.",
            "Operate an external reverse-drive buzzer in all types of vehicles, including private cars.",
            "Place a person to guide the driver, in a spot where the driver can see him.",
            "Take care as well as appropriate measures to prevent danger, impact, nuisance or disturbance.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "0179. How would you conduct yourself when approaching the intersection that is depicted in the following picture?",
          options: [
            "Stop anyway.",
            "Always accelerate when entering the intersection, in order to avoid traffic disturbance.",
            "Cross it safely.",
            "Slow down and even stop if necessary in order to give right-of-way.",
          ],
          answer: 3,
          image: "img/quiz/3179.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0656. What is the meaning of the following road marking?",
          options: [
            "Two-way traffic ahead.",
            "One-way traffic ahead.",
            "Traffic islands (in different shapes), marked on the road in white.",
            "Arrows for driving through the intersection – you may cross the intersection in the direction of the arrow only.",
          ],
          answer: 2,
          image: "img/quiz/3656.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0309. “You are allowed to drive under the influence of alcohol, as long as you do not mix different types of drinks”: Correct or incorrect?",
          options: [
            "Incorrect. The determining factor is the level of alcohol in the blood in relation to the legally allowed level.",
            "Correct. You can drink quite a lot of one type of intoxicating drink without becoming drunk.",
            "Correct. Alcohol affects driving only when different drinks are mixed.",
            "Correct. You should not mix different types of drinks before driving.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0303. “The law doesn’t prohibit driving under the influence of (prohibited) intoxicating drugs”:",
          options: [
            "Correct, since the law doesn’t prohibit the use of intoxicating drugs.",
            "Incorrect. The law explicitly mentions that it is prohibited to drive under the influence of a certain quantity of intoxicating drugs in the driver’s blood.",
            "Incorrect. It is explicitly prohibited by the law to drive under the influence of intoxicating drugs.",
            "Correct. The law only prohibits driving under the influence of alcohol.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0055. For how long should a driver place a “new driver” sign on his rear window?",
          options: [
            "For a period of two years or for as long as he is a new driver",
            "For a period of nine months",
            "For a period of six months",
            "For a period of three months",
          ],
          answer: 0,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0279. When is it not obligatory to inform the police station about the hitting of another vehicle or property in the absence of its owners?",
          options: [
            "When the accident’s impact is mild and the damage caused to the vehicle or to the property is not serious.",
            "When a car assessor, which is recognized for this purpose by the police, is called.",
            "When the incident is informed to the insurance company within the lawfully stated date and time.",
            "When a policeman happens to arrive to the scene and is informed about the incident’s details.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0133. When crossing an intersection with one lane in each driving direction:",
          options: [
            "You are allowed to overtake, but you must finish the overtaking after the intersection.",
            "You are allowed to overtake carefully vehicles with more than two wheels.",
            "You are only allowed to overtake a slow vehicle.",
            "It is prohibited to overtake and it is prohibited to try to overtake within the intersection’s boundaries, except if you are overtaking a two-wheeler.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0646. How should you make a left turn according to the following marking?",
          options: [
            "From each of the marked lanes.",
            "Absolutely no turning left.",
            "From the right lane only.",
            "From the left lane in your direction of driving.",
          ],
          answer: 3,
          image: "img/quiz/3644.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0392. When a “stop” sign is placed before a level crossing, the driver should:",
          options: [
            "Turn-off the radio, turn-on the headlights and listen and look along the tracks.",
            "Stop the vehicle, turn-off the radio, open the window besides him and listen and look along the tracks.",
            "Stop the vehicle, turn-off the engine, turn-off the radio and turn-on the headlights.",
            "Shift into neutral gear, turn-off the radio and listen and look along the tracks.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question: "0455. What is the meaning of the following traffic sign?",
          options: [
            "If you see the train’s lights, stop 20 meters before the crossing and don’t try to overtake.",
            "Overtaking is prohibited, from the signpost until after the level crossing.",
            "Stop at a distance of one meter at least before the tracks and check whether another vehicle is trying to overtake you.",
            "Drive faster and cross the railway tracks as quickly as possible. Complete the overtaking maneuver after the crossing.",
          ],
          answer: 1,
          image: "img/quiz/3453.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1143. Is the driver of a route service bus obliged to announce the stations along the route?",
          options: [
            "No. The driver is only obliged to announce about the arrival to the last station.",
            "Yes, but only when entering secluded settlements.",
            "Yes, but only on city rides.",
            "Yes, if the bus doesn’t have a map of its route.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0938. According to the picture, how are you required to conduct yourself when driving on the roadway?",
          options: [
            "Ignore the vehicle coming out of the dirt road and take right of way.",
            "Pay attention to the vehicle coming out of the dirt road.",
            "Give right of way to the vehicle coming out of the dirt road.",
            "Continue driving without doing anything special.",
          ],
          answer: 1,
          image: "img/quiz/3938.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1173. What is the maximum number of passengers a taxi driver is permitted to carry?",
          options: [
            "The number of passengers he may drive is unlimited.",
            "12 besides the driver.",
            "As the number of passengers that is indicated in the vehicle’s registration documents.",
            "15 besides the driver.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1528. What should you do when driving on a narrow roadway and you are suddenly approached by a vehicle from the opposite direction?",
          options: [
            "Honk and signal with your hands for the driver to return to his lane.",
            "Slow down and swerve as far to the right as possible, but don’t pull off the road.",
            "Slow down, move as far to the right as possible and, if necessary, pull off the road.",
            "Turn on your high beam and signal the other driver to return to his lane.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1214. Where should a panoramic mirror  be installed in a bus?",
          options: [
            "In the back of the bus, so that the driver can see all the passengers.",
            "In the front of the bus, so that the driver can see all the passengers.",
            "In the center of the bus, so that the driver can see all the passengers.",
            "There is no need for a panoramic mirror, as the bus already has enough mirrors.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0619. What type of driving license is a driver of a “tour vehicle” required to have?",
          options: ["C or B.", "B or C1.", "B or A.", "D or D1."],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1142. What is the maximum permitted speed of a bus other than a minibus (unless otherwise indicated in a sign)?",
          options: [
            "In an urban road – 50 k.p.h, in a non-urban road – 80 k.p.h, in an inter-urban road with a built dividing area – 80 k.p.h, and in a freeway – 100.",
            "on an urban road - 50 k.p.h, on a non-urban road - 80 k.p.h, on an inter-urban road with a built dividing area - 90 k.p.h, and on a freeway - 100.",
            "In an urban road – 40 k.p.h, in a non-urban road – 80 k.p.h, in an inter-urban road with a built dividing area – 90 k.p.h, and in a freeway – 110.",
            "In an urban road – 50 k.p.h, in a non-urban road – 70 k.p.h, in an inter-urban road with a built dividing area – 90 k.p.h, and in a freeway – 90.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0446. What are you required to do according to the following road sign?",
          options: [
            "Slow down and follow the right-of-way rules at the crossing area.",
            "Stop and give right-of-way to a light rail.",
            "Continue driving normally. You have right-of-way in the crossing area.",
            "Drive carefully and do not cross any light-rail track.",
          ],
          answer: 0,
          image: "img/quiz/3445.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0794. What are the risks in making a U-turn?",
          options: [
            "Delaying vehicles that are coming from the opposite direction or from behind",
            "Colliding with vehicles coming from the opposite direction or from behind.",
            "All the answers are correct.",
            "Causing an approaching vehicle to make a sudden brake.",
          ],
          answer: 2,
          image: "img/quiz/3794.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1153. Where is a route service bus driver required to stop in order to load and unload passengers?",
          options: [
            "On roads that are at least 12 meters wide.",
            "Only at the stations that are indicated in the route license.",
            "On roads wider than 12 meters.",
            "At a place that can be seen by other road users from all directions.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1261. Is a minibus permitted to enter a road on which the following road sign is placed?",
          options: [
            "Yes, under its definition as a bus and according to the provisions of the sign.",
            "Yes, because all vehicles are permitted to drive on that road.",
            "No, because it doesn’t carry the number of passengers required by the sign.",
            "No, because a minibus without passengers does not serve as a bus.",
          ],
          answer: 0,
          image: "img/quiz/3531.jpg",
          types: ["D"],
        },
        {
          question:
            "0068. Can a temporary sign, which is posted under the direction of a police officer, be regarded as a legitimate traffic sign?",
          options: [
            "No, it is not a legitimate traffic sign",
            "Yes, except in the case of a “no-entry” sign",
            "Yes, it is regarded as a legitimate traffic sign",
            "Yes, except in the case of a “stop” sign",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0447. When you are driving in a roadway and there is an unbroken separation line marked to your left accompanied by a broken line to its left:",
          options: [
            "You are forbidden to cross the separation line.",
            "You are allowed to cross the lines, but vehicle traffic from the opposite side is forbidden to cross.",
            "You are allowed to cross the lines with two wheels only.",
            "You are allowed to cross the lines carefully.",
          ],
          answer: 0,
          image: "img/quiz/3447.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1258. Is a bus used for driving instruction purposes permitted to enter where the following road sign is placed?",
          options: [
            "No, except if it belongs to a bus company that won permission.",
            "Yes.",
            "No.",
            "Yes, except for vehicles with an all up weight that exceeds 10,000 kg.",
          ],
          answer: 2,
          image: "img/quiz/3528.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0561. When you see the following traffic sign before you:",
          options: [
            "Trucks are permitted to drive from your opposite direction.",
            "You are entering a road with two lanes to each direction.",
            "You are on a one-way road, which serves as a two-way road for vehicles with an overall weight of up to 3,500 kg.",
            "You are entering a road section in which the traffic from the opposite direction is public transport only or any other vehicle permitted according to the sign.",
          ],
          answer: 3,
          image: "img/quiz/3560.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1447. Which type of vehicle is prohibited to overtake another overtaking vehicle?",
          options: [
            "Motorcycles.",
            "Buses,(not including minibus), commercial vehicles and work vehicles with an all-up weight of more than 3,500 kg.",
            "Motor vehicles with an all-up weight of more than 4,000 kg.",
            "Commercial vehicles and work vehicles with an all-up weight of 3,500 kg or less.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0353. Is it obligatory to install wastebaskets in a private passenger car?",
          options: [
            "Yes, it is obligatory to install a wastebasket type that was approved by the Licensing Authority.",
            "Yes, it is obligatory in a commercial vehicle only.",
            "No, it is obligatory in a public vehicle only.",
            "There is no such obligation.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0067. Is it obligatory to obey the directions and signals of an authorized municipal inspector, even when a certain direction or signal is opposed to the traffic signs?",
          options: [
            "Yes, except in the case of a “stop” sign",
            "No, it is not obligatory to obey",
            "Yes, it is obligatory to obey, with full caution",
            "Yes, except in the case of a “no-entry” sign",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0587. What is the meaning of the following traffic sign?",
          options: [
            "No through road (cul-de sac) to your right.",
            "Vehicle exit from a parking lot on the right.",
            "Give right-of-way to incoming vehicles and to vehicles turning left.",
            "Give right-of-way while making a right turn.",
          ],
          answer: 0,
          image: "img/quiz/3556.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0939. What is required from a driver that approaches the road signs appearing in the following picture?",
          options: [
            "To adjust his driving speed to 90 k.p.h, in accordance with the traffic sign.",
            "To adjust to urban driving speed, give right of way to the vehicle on his right on the road and be careful of pedestrians.",
            "Only to pay special attention to the presence of children on the road.",
            "The driver should continue driving normally.",
          ],
          answer: 1,
          image: "img/quiz/3939.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0616. When you are driving inside a tunnel which is marked with traffic sign 633:",
          options: [
            "You should turn on the front lights.",
            "You should turn on the fog lights.",
            "You are permitted to change lanes.",
            "You are permitted to overtake other vehicles.",
          ],
          answer: 0,
          image: "img/quiz/3616.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1770. A cement mixer is:",
          options: [
            "There is no proper definition for cement mixers.",
            "An off-road vehicle.",
            "a commercial truck.",
            "A special-purpose vehicle.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1572. Which sign denotes a lane for Cyclists only. Cycle in the direction of the arrow?",
          options: ["804.", "821.", "720.", "802."],
          answer: 0,
          image: "img/quiz/31572.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0076. How can a cellular phone be operated whilst driving?",
          options: [
            "Only by means of a “hands free device” that is installed in the vehicle and can be used without being held.",
            "When the driver holds the phone with the right hand",
            "Only by using earphones that are connected to both ears",
            "It is absolutely forbidden to use a phone while driving",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1488. What is the meaning of a “vehicle non-use notice” (taking a vehicle off the road)?",
          options: [
            "It is permitted to drive the vehicle up to one week after it is taken off the road.",
            "It is prohibited to drive the vehicle without an authorization from the insurance company.",
            "The vehicle is prohibited from driving, unless it is permitted, according to the non-use form, to drive to a nearby garage.",
            "It is permitted to drive the vehicle unlimitedly (if it doesn’t cause any traffic safety risk) but not on road ways.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0821. When are we required to keep a more-than-customary distance from the vehicle driving in front of us?",
          options: [
            "When driving in low speed on an urban road.",
            "When trucks or other heavy vehicles are in front of us.",
            "Never: A distance of two seconds is always sufficient.",
            "When visibility is poor, when driving on a slippery road or when we are tired.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0983. How would you conduct yourself according to the content of the following traffic sign?",
          options: [
            "The road is narrow and has no “hard shoulders” used for escaping in case of danger; drive in the middle of the road.",
            "The road is narrow and has no “hard shoulders” used for escaping in case of danger; drive on the left side of your lane.",
            "The road is narrow and has no “hard shoulders” used for escape in case of danger; slow down and stay alert to what occurs on the road.",
            "You are required to drive as fast as possible to help the vehicle stay stable.",
          ],
          answer: 2,
          image: "img/quiz/3983.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0971. How are you required to conduct yourself in the following road conditions?",
          options: [
            "Stay in low gear while keeping to the right.",
            "Turn on the high beam, because of the shadow on the roadway.",
            "Enter the curve carefully and increase speed when going downhill.",
            "Honk, because of the obstructed field of vision in the curve.",
          ],
          answer: 0,
          image: "img/quiz/3971.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0367. Which of the following traffic signs warn  about an intersection with traffic lights ahead?",
          options: ["122", "114", "702", "705"],
          answer: 0,
          image: "img/quiz/3367.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0167. Is it permitted to use any other means for signaling when the electric signaling system is not functioning?",
          options: [
            "It is required to give signal by hand, but only in good weather conditions.",
            "It is required under these circumstances to give signal by hand.",
            "No. You must use the direction indicator for signaling.",
            "It is required to give signal by hand, but only at night.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0099. What is the maximum allowed speed for a utility vehicle?",
          options: [
            "40 Km/h",
            "70 Km/h",
            "50 Km/h on an urban road",
            "60 Km/h on a freeway",
          ],
          answer: 0,
          image: "",
          types: ["C1", "1", "В"],
        },
        {
          question:
            "0190. How should one refer to a pedestrian crossing that is divided by a traffic island?",
          options: [
            "The pedestrian crossing should be considered as two separate crossings only when a garden separates between them.",
            "A pedestrian crossing that is divided by a separation area in regarded as one long crossing.",
            "There is no difference between a divided and a non-divided pedestrian crossing. They are both regarded as one crossing.",
            "A pedestrian crossing that is divided by a traffic island shall be regarded as two separate pedestrian crossings.",
          ],
          answer: 3,
          image: "img/quiz/3190.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1368. What is the legal definition of a “public service vehicle”?",
          options: [
            "A private passenger car that was fitted to transport passengers for hire.",
            "All public transportation vehicles.",
            "A vehicle used or designed to be used for transporting passengers for hire.",
            "A publicly owned vehicle.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question: "1715. Whilst driving on a rough road, you should:",
          options: [
            "Slow down and increase your alertness.",
            "Hold your foot on the clutch pedal continuously to ensure smooth driving.",
            "Hold your foot on the brake pedal, to be instantly prepared for any occurrence.",
            "Drive faster to avoid shakings and pits.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0251. Who is required to hold the vehicle’s registration papers?",
          options: [
            "The driver while driving, even if he’s not the vehicle’s owner.",
            "The garage that conducts regular treatments of the vehicle.",
            "The vehicle’s owner, even when he’s not driving it.",
            "The vehicle’s insurance company.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0563. Are cyclists permitted to enter a road, when the following traffic sign is placed at the entrance to the road?",
          options: [
            "No.",
            "Yes, in inter-urban roads.",
            "Yes, in an urban road only.",
            "Yes, always.",
          ],
          answer: 0,
          image: "img/quiz/3562.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1141. Who is responsible to perform a security check on a bus?",
          options: [
            "The bus driver should do it before and during every ride.",
            "A security check is performed by a qualified security.",
            "The bus driver must perform a security check at the end of the ride only.",
            "The bus driver must perform a security check before the beginning of the ride only.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0470. Which traffic sign should be placed following traffic sign 135 shown in the picture?",
          options: ["901", "306", "413", "226"],
          answer: 1,
          image: "img/quiz/3470.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0602. What is the meaning of the following traffic sign?",
          options: [
            "Nature reserve.",
            "Licensed night parking lot.",
            "Rural hotel ahead.",
            "Youth hostel.",
          ],
          answer: 3,
          image: "img/quiz/3602.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1704. Accidents that are a result of driver fatigue might occur:",
          options: [
            "On narrow and winding roads only, and not in straight, wide and comfortable road sections.",
            "On roads with long and steep descents only.",
            "When the driver’s level of alertness decreases.",
            "On roads with long and steep uphill sections only.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1020. For what reason might the indicator warning light on the dashboard flash too quickly?",
          options: [
            "When one of the indicator bulbs is not working or malfunctioning.",
            "Malfunction of the dashboard.",
            "Low battery voltage.",
            "When the signal stick is not positioned properly.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "1", "В"],
        },
        {
          question: "0604. What is the meaning of the following traffic sign?",
          options: [
            "Careful! You are entering a section with a speed limit.",
            "Closed to all vehicles from both directions.",
            "On-way street ahead.",
            "Slow down, hazardous place ahead without designated marking.",
          ],
          answer: 1,
          image: "img/quiz/3490.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1191. Do the driver and passengers of a public minibus need to wear seatbelts whilst driving?",
          options: [
            "Yes, but on interurban roads only.",
            "Yes, but only on freeways.",
            "Yes, but only on urban roads.",
            "Yes, on every type of road.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1718. When is it permitted to make a left U-turn in an intersection without traffic lights?",
          options: [
            "Only vehicles driving on more than two wheels are permitted to make the U-turn.",
            "Only when the turn can be made from the right side of the road, and can be completed without stopping.",
            "Only when the vehicle is directed by another person.",
            "When no obstruction to traffic is caused, field of vision is clear and there is no prohibiting road sign.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1444. When driving on a two-way road and visibility is poor:",
          options: [
            "You should horn while overtaking to warn the overtaken vehicle.",
            "It is permitted to overtake, unless a “no overtaking” sign is placed.",
            "You should turn on the parking lights while overtaking.",
            "It is forbidden to overtake.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question:
            "1208. Who is responsible for cleaning the interior of the bus?",
          options: [
            "The bus driver.",
            "The owner of the vehicle.",
            "The bus company’s dispatcher.",
            "The holder of the vehicle.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0059. Do traffic light instructions have priority over “right-of-way giving” signs?",
          options: [
            "No, In legal terms, the instructions of a traffic light and the instructions of traffic signs are of equal effect",
            "No, but a stop sign has priority over a green light for traffic moving in the same direction",
            "Yes, traffic light instructions have priority over any type of traffic sign, except for a flashing yellow light",
            "Sometimes. Traffic light instructions have priority in an urban way only",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0661. What is the meaning of the following road marking?",
          options: [
            "Warning, road works.",
            "Slow down! Rough road ahead.",
            "Parking zone for private passenger cars.",
            "A stopping place for certain types of vehicles where lay-bys are absent.",
          ],
          answer: 3,
          image: "img/quiz/3661.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0248. How should a driver conduct himself when noticing a “security vehicle”?",
          options: [
            "Slow down, divert to the road’s right edge and continue driving normally.",
            "Divert his vehicle to the right, as close as possible to the road’s edge, give right-of-way and stop if necessary a distance from an intersection.",
            "Mount the sidewalk and stop there.",
            "Drive closely behind the security vehicle and keep a constant distance of two seconds from it.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1231. What rule applies to the transportation of packages on a bus?",
          options: [
            "It is permitted to transport freight which is relevant to the passenger’s livelihood.",
            "It is permitted to transport packages when they are not disturbing or harmful to the passengers.",
            "It is permitted to transport packages when it is determined by the license holder to use half the space of the bus for freight transportation.",
            "It is permitted to transport packages with hazardous materials, provided that they are well packed and placed in the luggage compartment of the bus.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0546. The following traffic sign is the only sign posted at an intersection. Is it permitted to turn left?",
          options: [
            "Yes, only during the day.",
            "No.",
            "Yes, only in the intersection.",
            "Yes, in an intersection with traffic lights.",
          ],
          answer: 1,
          image: "img/quiz/3545.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1201. Is it permitted to load suitcases onto the passenger deck of a bus?",
          options: [
            "Yes, but only when there is no more space in the luggage compartment of the bus.",
            "Yes, at the driver’s discretion, and only in a place that will not cause disturbance and allow clear passage for passengers.",
            "No.",
            "Yes, it is obligatory to load suitcases onto the passenger deck.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question: "0624. What is the meaning of the following traffic sign?",
          options: [
            "Camping spot.",
            "Hotel ahead.",
            "Restaurant.",
            "Refreshment station.",
          ],
          answer: 2,
          image: "img/quiz/3624.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0533. What is the meaning of the following traffic sign?",
          options: [
            "Give right-of-way to the traffic that crosses ahead.",
            "Non-specific danger.",
            "Give right-of-way to any vehicles that approaches the intersection.",
            "Stop and give right-of-way to approaching vehicles from your opposite direction.",
          ],
          answer: 0,
          image: "img/quiz/3443.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0567. Are cyclists and pedestrians permitted to enter according to the following traffic signs?",
          options: [
            "Yes, provided that they act very carefully.",
            "No.",
            "Yes.",
            "Yes, on the “hard shoulders” only.",
          ],
          answer: 1,
          image: "img/quiz/3567.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0566. What is the meaning of the following traffic sign?",
          options: [
            "Entrance to a freeway. (Motorway)",
            "Suspension bridge over a freeway.",
            "Freeway interchange ahead.",
            "Exit from a freeway.",
          ],
          answer: 0,
          image: "img/quiz/3147.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1391. Which number marks the “wheelbase” of this vehicle?",
          options: ["14", "16", "7", "3"],
          answer: 0,
          image: "img/quiz/31391.jpg",
          types: ["C1", "C"],
        },
        {
          question: "0451. What is the meaning of the following traffic sign?",
          options: [
            "A distance of about 250 meters to an approaching level crossing.",
            "It is prohibited to stop within a distance of 120 meters before the railway tracks.",
            "A distance of about 70 meters to an approaching level crossing.",
            "Approach to a level crossing. Approx 200metres before the crossing",
          ],
          answer: 3,
          image: "img/quiz/3451.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0253. Is it permitted to drive a motor vehicle on an urban road while its high-beam headlights are on?",
          options: [
            "Yes.",
            "No, unless the road is not properly lit and provided that other drivers are not blinded.",
            "No.",
            "Only during day time.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0118. It is prohibited to make a U-turn:",
          options: [
            "When he drives a vehicle longer than eight meters.",
            "When the turn cannot be seen by a driver from any other direction.",
            "When he is in a two-way roadway.",
            "If a road sign prohibiting left turn is placed.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1497. The distance covered by a vehicle from the moment we step on the brake pedal until the vehicle stops becomes bigger:",
          options: [
            "The faster we drive.",
            "The longer is the response time.",
            "The slower we drive.",
            "The newer is the vehicle.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1170. Can a minibus be regarded as a taxi?",
          options: [
            "A minibus is regarded as a taxi only when there is room for two passengers next to the driver.",
            "A minibus is a taxi with all it implies.",
            "A minibus is regarded as a taxi only when it is equipped with a meter.",
            "A minibus is not regarded as a taxi.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0003. What is the duty of a person who owns or has control of a vehicle?",
          options: [
            "Not to permit to anyone to drive, who doesn’t have a valid license for the type of vehicle.",
            "To register any person who drives his vehicle in the vehicle travel log",
            "To allow any person who asks him to drive his vehicle.",
            "To make sure that photocopies of the driver’s license and insurance policy only are carried in the vehicle",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0524. The following road sign remains valid until:",
          options: [
            "A sign that indicates a non-urban road. .",
            "Only until the nearest intersection with traffic lights.",
            "A sign that permits driving at a restricted speed.",
            "The nearest intersection or until a road sign that cancels it or until a “vehicle parking area” sign.",
          ],
          answer: 3,
          image: "img/quiz/3524.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0804. What are the risks of driving in high speeds?",
          options: [
            "Serious damage to the vehicle's front tires and steering system.",
            "Fast wear and tear of the engine.",
            "There is risk in driving in high speeds.",
            "Reduced ability of stopping the vehicle in time in order to prevent an accident, and even loss of control over the vehicle.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question:
            "0442. What are you required to do according to the following road sign?",
          options: [
            "When you approach railway tracks – honk, open a window and listen to the train’s noise.",
            "Always stop before railway tracks, look to the right and to the left and continue driving.",
            "Slow down and prepare to stop if necessary before the railway tracks.",
            "Cross the railway tracks quickly.",
          ],
          answer: 2,
          image: "img/quiz/3441.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0868. What are the advantages of looking far ahead?",
          options: [
            "Looking far ahead extends the braking distance and enhances safety.",
            "Obtaining all the information required for planning the drive according to the road’s conditions and avoiding surprises.",
            "Forward is the only important direction for drivers, and therefore they should look straight ahead.",
            "It allows you to engage in other activities at the same time, such as searching for an address in the GPS.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1037. When is it required to exchange the vehicle’s fire extinguisher?",
          options: [
            "Every other annual test, meaning once every two years.",
            "When it was fully or partially used, or when its pressure gauge indicates low internal pressure.",
            "Every year, before the vehicle’s annual test.",
            "When you cannot hear the material moving inside the fire extinguisher.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0354. Is it permitted to use any means of visual display, which can be viewed by the driver, other than the vehicle’s G.P.S control and navigation display?",
          options: [
            "Yes, to relax the driver and passengers.",
            "Yes, provided that the driver wears safety goggles.",
            "It is prohibited for as long as the vehicle is moving..",
            "It is prohibited, even if the vehicle is parked.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question: "0031. Define “to dip the lights”:",
          options: [
            "Turning off the high beam and turning on the fog lights",
            "Changing the height of the parking beam",
            "Reverting from low beam to high beam.",
            "Reverting from high beam to low beam.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1237. What is the meaning of this sign?",
          options: [
            "Steep downhill slope about 2,500 meters ahead.",
            "In 2,500 meters a steep uphill climb begins.",
            "Steep downhill slope for 2,500 meters begins here.",
            "The end of a 2,500-meters downhill slope section.",
          ],
          answer: 0,
          image: "img/quiz/31237.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1227. On an urban road, where is a taxi driver permitted to stop in order to board and drop passengers?",
          options: [
            "In any place where parking is permitted, and at the side of red-white curbstones, provided that the taxi is not disturbing traffic.",
            "Only in places designated and marked for that purpose.",
            "Anywhere, including bus stops.",
            "At every taxi station only, along an urban road.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1513. Up to which height is it permitted to load loose materials?",
          options: [
            "Up to the height of the rear door of the freight trunk.",
            "Up to the height of the walls of the truck, including the vehicle’s ladders.",
            "Up to the height of the rear window of the driver’s compartment.",
            "Up to the height of the walls of the freight trunk, excluding the vehicle’s ladders.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "1018. When is it recommended to check tire pressure?",
          options: [
            "After driving, when the tires are hot.",
            "Only before the vehicle’s annual test.",
            "The manner of checking air pressure is of no significance.",
            "Before driving, when the tires are cold.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "В"],
        },
        {
          question: "0473. What is the meaning of the following traffic sign?",
          options: [
            "Two-way traffic on the road ahead.",
            "Entry to the boundaries of an urban road/s.",
            "Give right-of-way to drivers approaching from the opposite direction.",
            "You have right-of-way over traffic from the opposite direction.",
          ],
          answer: 0,
          image: "img/quiz/3473.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0334. What is the minimum age requirement for obtaining a Class 1 license?",
          options: ["19 years.", "16 years.", "18 years.", "17 years."],
          answer: 1,
          image: "",
          types: ["1"],
        },
        {
          question: "0995. What is the function of the vehicle’s starter?",
          options: [
            "To turn the engine and cause it to ignite..",
            "Connect the engine to the wheels.",
            "Control the engine’s operation.",
            "Disconnect the engine from the wheels.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "A", "В"],
        },
        {
          question:
            "0749. How does driving on the right side of the road affect drivers' field of vision?",
          options: [
            "Keeping to the right improves the field of vision of the drivers in the vehicles behind you.",
            "Driving on the right reduces the view angle of those driving behind you.",
            "There is no connection between driving on the right and the field of view.",
            "The field of vision is not affected: A comfortable field of view can only be created by doubling the required distance between vehicles.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1299. What is the general excessive freight width permitted in a commercial vehicle with an all up weight of more than 15,000kg?",
          options: [
            "Up to 2.60 meters, when the freight is in one unit.",
            "Up to 2.10 meters, when the freight is in one unit.",
            "Up to 2.50 meters, when the freight is in one unit.",
            "Up to 3.40 meters, when the freight is in one unit.",
          ],
          answer: 3,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0681. What are you required to do when approaching a railroad barrier in a “down position” (meaning: horizontal)?",
          options: [
            "Continue driving carefully around or under the barrier.",
            "Stop and turn the vehicle’s engine off.",
            "No parking and stopping. Pass the barrier from its right.",
            "Stop before it and do not continue driving.",
          ],
          answer: 3,
          image: "img/quiz/3680.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1678. What is the statutory obligation to slow down which applies to drivers?",
          options: [
            "To slow down when the vehicle is packed with passengers.",
            "To slow down to a reasonable speed, at the driver’s discretion, without stopping.",
            "To slow down before level crossings with a traffic light and with a barrier.",
            "To slow down the vehicle and even stop whenever danger is anticipated.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1793. A minibus is designed to transport up to:",
          options: [
            "16 passengers apart from the driver.",
            "12 passengers besides the driver.",
            "16 passengers including the driver.",
            "8 passengers besides the driver.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1592. The license class that is required for riding a motorcycle with an engine power of up to 33 horse-powers (25 KW) is:",
          options: ["C.", "A1.", "A2.", "D."],
          answer: 1,
          image: "",
          types: ["A"],
        },
        {
          question: "0349. What is the meaning of the following traffic sign?",
          options: [
            "Cab station. Entry to the street in which this sign is posted is prohibited.",
            "Start of cab station designated area.",
            "End of Taxi station (rank) designated area.",
            "Cab station. Stopping or parking of other vehicles is prohibited.",
          ],
          answer: 2,
          image: "img/quiz/3571.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0831. What are the actions you are required to take, before intentionally pulling onto the “hard shoulder” of a non-urban road?",
          options: [
            "Apart from giving a right signal on time, nothing else is required.",
            "Slowing down and turning on the four winkers.",
            "Good observations mainly to the rear, signaling appropriately and slowing down.",
            "Slowig down and turning the steering wheel towards the “hard shoulder” as quickly as possible.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0252. Is it permitted to drive a vehicle with a malfunctioning lighting system?",
          options: [
            "Yes, if one light is not functioning.",
            "Yes, when the parking lights are malfunctioning.",
            "No.",
            "Yes, during day time only.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0215. Is it permitted to stop any vehicle other than a taxi where the following signpost is placed?",
          options: [
            "It is absolutely forbidden.",
            "No, except for stopping in order to unload passengers.",
            "Yes, if there are no taxies parked there.",
            "Yes, for the purpose of loading passengers only.",
          ],
          answer: 1,
          image: "img/quiz/3215.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1357. What is required from the chains that are installed between the towing vehicle and the trailer?",
          options: [
            "They should allow the trailer to make a 90 degree or more turn in relation to the towing vehicle.",
            "They should ground the electric charge (static electricity) that is accumulated in the vehicle while driving.",
            "They should prevent a 90 degrees angle (the letter “L”) between the towing vehicle and the trailer.",
            "Their links should be at least 12 mm thick.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "В"],
        },
        {
          question:
            "1499. What should a driver verify before lifting his car to change a wheel?",
          options: [
            "He must ensure, in all possible ways that the vehicle cannot move.",
            "That the auxiliary (parking/hand) is secured until the wheel is taken off, and then released while the new wheel is installed.",
            "That the auxiliary (parking/hand) brake is released until the wheel is taken off, and then tightened while the new wheel is installed.",
            "That the auxiliary (parking/hand) is free and released, and that the vehicle is in low gear during the entire course of the wheel change.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1329. When does the transport of high freight require the approval of a “police officer”?",
          options: [
            "In a commercial vehicle with an all up weight of up to 15,000 kg, when the height of the freight is more than 4 meters above ground.",
            "In a commercial vehicle weighing more than 4,000 kg.",
            "In a commercial vehicle with an all up weight of more than 15,000 kg, when the height of the freight is more than 4.80 meters above ground.",
            "In a vehicle weighing 1.5 tons, when the height of the freight is more than 2.50 meters.",
          ],
          answer: 2,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0521. What is the speed limit in an area where the following road sign is placed (and no other road sign indicates otherwise)?",
          options: [
            "60 k.p.h.",
            "50 k.p.h",
            "30 k.p.h.",
            "There is no speed limit.",
          ],
          answer: 1,
          image: "img/quiz/3519.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0820. What is the proven and recommended method for keeping proper distance from the vehicle driving in front of us?",
          options: [
            "Drive as similarly as possible to the drivers in the adjacent right and left lanes,.",
            "The “two-second count” method.",
            "Vehicle length and engine volume method.",
            "Estimating a reasonable distance between yourself and the vehicle driving in front.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1606. How would you operate the motorcycle while crossing an oil slick on the road?",
          options: [
            "Lean backwards and improve braking by shifting the weight to the rear wheel.",
            "Accelerate and cross the stain as quickly as possible an in a straight line.",
            "Release the gas handle and the brakes and press the clutch handle.",
            "Bend forward and improve steering by shifting the driver’s weight to the front wheel.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0913. When would the following vehicles be able to continue driving?",
          options: [
            "Only when the barrier is completely opened and a green light is shown by the traffic light.",
            "You should only refer to the barrier and not to the traffic light.",
            "When the light in the traffic light changes to a steady yellow.",
            "Immediately as the barrier starts to open and a flickering red light is shown by the traffic light.",
          ],
          answer: 0,
          image: "img/quiz/3913.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0884. What effect does the distance between vehicles have on the overtaking process?",
          options: [
            "Short distance reduces the field of vision and increases the risk in overtaking.",
            "The closer we are to the vehicle in front of us, the safer is the deviation while overtaking.",
            "The distance between vehicles has no effect on the safety of overtaking.",
            "The closer we are to the vehicle in front of us, the shorter and safer is the overtaking distance.",
          ],
          answer: 0,
          image: "img/quiz/3884.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0689. What is the main function of the auxiliary brake (hand/parking brake)?",
          options: [
            "To assist new drivers in downhill braking.",
            "To make sure that the vehicle doesn’t move while parked.",
            "The auxiliary brake (hand/parking brake) has no function and it shouldn’t be used at all.",
            "To enhance the braking capacity of the service/leg brake.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "1245. What is the penalty of a passenger who was requested by the conductor to show his bus tickets but failed to do so?",
          options: [
            "The conductor may order him to leave the bus.",
            "He should pay the bus fare and a fine, according to the type of bus ride.",
            "The passenger did not commit any offense. The driver is the one that violated the law, because he didn’t charge the bus fare from the passenger.",
            "He should pay double fare.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0741. How would you start the engine of an automatic transmission vehicle?",
          options: [
            "Make sure the gear handle is in (D) and the hand brake is released.",
            "Make sure the hand brake is applied, and the gear selector is in (P) or (N).",
            "The selected gear is of no significance. Just make sure that the hand brake is not released.",
            "Make sure the gear selector is in (R) and the hand brake is released.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "1", "В"],
        },
        {
          question:
            "0966. What are the permitted driving directions according to the traffic signs in the following intersection?",
          options: [
            "Straight and left for busses only.",
            "Straight in the direction of the arrow only for non-public vehicles.",
            "All vehicles approaching will continue in the direction of the arrow.",
            "Straight, for public transport only.",
          ],
          answer: 3,
          image: "img/quiz/3966.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0094. Where is an ATV (All Terrain Vehicle) (Quad bike) allowed to drive?",
          options: [
            "On dirt roads and on roadways within the limits of a Moshav or Kibbutz",
            "Only on a pavement within the limits of a Moshav or Kibbutz",
            "On a freeway only",
            "On on a roadway and only on an urban road.",
          ],
          answer: 0,
          image: "img/quiz/3094.jpg",
          types: ["C1", "1", "В"],
        },
        {
          question:
            "1326. You want to carry a container on a trailer. What should be the all up weight of the trailer in order to carry a container?",
          options: [
            "At least 14,000 kg.",
            "At least 12,000 kg.",
            "At least 15,000 kg.",
            "At least 10,000 kg.",
          ],
          answer: 2,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0365. Which of the following traffic signs alerts about the approach to a T-junction to the right?",
          options: ["205", "208", "115", "119"],
          answer: 2,
          image: "img/quiz/3365.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1498. To disconnect the engine from the wheels, you should:",
          options: [
            "Press the clutch pedal.",
            "Step on the brake pedal and on the accelerator.",
            "Shut-off the engine.",
            "Press on the starter switch.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question:
            "1607. Which number marks the length of the “rear extension” of the bus in the picture?",
          options: ["78", "75", "82", "93"],
          answer: 0,
          image: "img/quiz/31607.jpg",
          types: ["D"],
        },
        {
          question: "0184. What is instructed by the following road sign?",
          options: [
            "Give right-of-way. There is no need to stop.",
            "Slow down and give right-of-way.",
            "Stop for a moment, check the traffic and continue driving.",
            "Stop and give right-of-way.",
          ],
          answer: 3,
          image: "img/quiz/3184.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1118. When is a bus prohibited from crossing a railroad?",
          options: [
            "When there is a “level crossing” warning sign.",
            "When there is no asphalt or cement road that leads to the level crossing, at a distance of at least 20 meters before the nearest track.",
            "When a “stop” sign is placed before the tracks.",
            "When a “right of way” sign is placed before the tracks.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1615. Why do we have to lean the motorcycle while turning?",
          options: [
            "To lesser the effect of the centrifugal force (a force acting on the motorcycle whilst turning).",
            "To improve tire grip on a dry or wet roadway.",
            "To completely annul the effect of the centrifugal force.",
            "To improve our field of vision and our ability to respond to surprises on the road.",
          ],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0162. What should you do when approaching a railway crossing and a warning signal is given before you reached the crossing:",
          options: [
            "Stop and don’t continue driving.",
            "Slow down only when a signaler turns on a warning signal.",
            "Slow down only if you see an approaching train.",
            "Ignore the signal and continue driving. You were there before the signal.",
          ],
          answer: 0,
          image: "img/quiz/3160.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0955. Traffic changes are taking place ahead, how would you prepare for these changes?",
          options: [
            "You are driving on the right lane, pay attention to the vehicles merging into your lane from the right only.",
            "The number of lanes is decreasing; move to the left lane without interrupting the vehicles in that lane.",
            "The number of lanes is increasing; stay on the right lane and keep to your right.",
            "You are driving in the right lane, pay attention to the vehicles merging into your lane, from left and right.",
          ],
          answer: 3,
          image: "img/quiz/3955.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0105. Under which conditions is it allowed to deviate from a driving lane?",
          options: [
            "At a slow speed and by deviating sharply.",
            "Provided that no risk or disturbance is caused to any other road user.",
            "Only after signaling and looking at the mirrors.",
            "It is absolutely prohibited to deviate from the driving lane.",
          ],
          answer: 1,
          image: "img/quiz/3105.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0159. How should you conduct yourself when a “stop” sign is placed before a railway crossing?",
          options: [
            "You must stop when an approaching train is seen or heard.",
            "You have to stop during the night. There is no obligation to stop during the day.",
            "Stop the vehicle, turn off the radio, television or tape set, and open the window next to you.",
            "Stop, as in any other place in which a “stop” sign is placed, and then continue driving.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "A", "В"],
        },
        {
          question:
            "1449. A driver of a commercial vehicle that transports more then eight passengers and who is obliged to wear glasses or contact lenses must:",
          options: [
            "Hold an extra pair of the optical instrument, besides the pair he’s wearing.",
            "Hold in the vehicle a spare pair of glasses or lenses apart from the pair he’s wearing.",
            "Verify before driving the regularity and cleanliness of the optical instrument.",
            "Perform a visual acuity test once every six months, according to requirements.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0840. What risks are expected whilst driving on a mountain road?",
          options: [
            "Engine heating due to excessive use of the brakes.",
            "Loss of braking ability and head-on collision. Difficulty yo pull off the road.",
            "Tire overheating.",
            "Loosening of a wheel due to the road's steep slope.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0603. What does the following traffic sign symbolize?",
          options: [
            "Rest point for drivers.",
            "KKL (Jewish National Fund) memorial",
            "Camping site or night parking lot.",
            "Archeological excavation site.",
          ],
          answer: 2,
          image: "img/quiz/3603.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1485. What are the vehicle's “emergency lights”?",
          options: [
            "The simultaneous flashing of all the vehicle’s indicators.",
            "Warning light placed on the vehicle’s roof.",
            "Warning triangle with lights on it.",
            "Additional brake light at the height of the window.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1222. When should the taxi roof-sign give an “Available” signal?",
          options: [
            "Only during the winter and when there are passengers in the taxi.",
            "Only during the day and when there are passengers in the taxi.",
            "Only on holidays",
            "Whenever there are no passengers in the taxi.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question: "0574. What is the meaning of the following traffic sign?",
          options: [
            "No entry for non-motor vehicles only.",
            "Entry is permitted for tractors and work vehicles.",
            "Careful! road-works.",
            "No entry for tractors and work vehicles.",
          ],
          answer: 3,
          image: "img/quiz/3574.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1443. Define “connected truck”:",
          options: [
            "A motor vehicle towing another motor vehicle.",
            "A rigid motor vehicle connected to a trailer .",
            "A vehicle for carrying long freights.",
            "A truck tractor connected with a semi-trailer.",
          ],
          answer: 1,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0396. Which of the following road signs does not terminate at the nearest intersection?",
          options: ["432", "424", "427", "504"],
          answer: 1,
          image: "img/quiz/3396.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1181. When is it prohibited to refuel a bus?",
          options: [
            "In private gas stations.",
            "When the bus is operated on a route service.",
            "In stations using benzine fuel.",
            "When it carries passengers.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question: "0170. When you use the vehicle’s horn for signaling:",
          options: [
            "The signal shall be given at a fast and unchanging pace.",
            "You are lawfully required to honk at least three times.",
            "The signal should not be longer than needed.",
            "The signal shall be given in a long and continuous manner.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0119. From which lane is it permitted to perform a U-turn to the left?",
          options: [
            "From a left-hand lane or from any other lane, provided that there is no road marking or road sign that prohibit turning from this lane.",
            "Only from the left lane.",
            "Only from a lane in which a straight (forward) arrow is marked on the roadway.",
            "From the lane in which a straight (forward) arrow is marked, that is closest to the separation area.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0649. What is the meaning of the following road marking?",
          options: [
            "Parking lot and bicycle parking arrangements.",
            "Authorized parking lot for public vehicles ahead.",
            "Pedestrian crossing.",
            "Crossing for cyclists.",
          ],
          answer: 2,
          image: "img/quiz/3649.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1221. Where are wastebaskets required to be installed in a taxi?",
          options: [
            "On both sides of the back seats.",
            "Besides the driver’s seat and besides the back seat.",
            "Besides all taxi windows.",
            "Besides the left doors.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0062. Is it obligatory to obey the directions and signals of a municipal inspector in uniform?",
          options: [
            "No. It is only obligatory to obey as regards to parking directions within city limits",
            "No. It is only obligatory to obey as regards to the presentation of a driver’s license",
            "Usually not",
            "Yes, if the directions are related to traffic control.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0864. How would you avoid danger when starting to drive?",
          options: [
            "By observing correctly the road and the traffic on it, before starting to drive.",
            "By giving a signal immediately as you start to drive.",
            "By starting to drive in high speed and quickly merging into traffic.",
            "By driving fast in reverse.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0206. Is it permitted to stop or park a vehicle after a pedestrian crossing?",
          options: [
            "It is only allowed at night.",
            "No.",
            "Yes.",
            "It is forbidden within a distance of twelve meters after the pedestrian crossing.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0039. What is the definition of an “obsolete vehicle” in regard to private passenger cars?",
          options: [
            "A private passenger car is not included in the definition of an “obsolete vehicle”.",
            "A private passenger car that was manufactured more than 19 years ago.",
            "A private passenger car that was manufactured more than 15 years ago.",
            "A vehicle that does not meet the compelling Israeli standard.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "В"],
        },
        {
          question: "1786. According to the law an accordian style bus:",
          options: [
            "Is obliged to display rectangular stickers on rear wall of the bus .",
            "Is obliged to display two stripes on the rear wall of the bus 4X100 cm.",
            "Is obliged to display Stickers and stripes.",
            'Is obliged to display two reflecting stripes and writen on them \\"keep distance\\".',
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question: "0549. What is the meaning of the following traffic sign?",
          options: [
            "Winding road in both directions at the next intersection.",
            "Turn right or left.",
            "No U-turn to the left.",
            "Pass the place that is marked with a signpost from its right only.",
          ],
          answer: 1,
          image: "img/quiz/3549.jpg",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "0086. Who is allowed to drive against traffic in a one-way street?",
          options: [
            "It is prohibited, except for a security vehicle on duty, or in accordance with a policeman’s directions",
            "It is allowed for cyclists only",
            "It is unconditionally prohibited, even when a policeman directs otherwise",
            "It is allowed on holidays, when traffic is light and no disturbance is caused",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0002. Under what conditions can a person drive a motor vehicle?",
          options: [
            "The driver must have a valid license for the same class of vehicle.",
            "According to the conditions, laid down in the annual test.",
            "The driver should be the registered owner or have legal control.",
            "According to the conditions laid down in the Manufacturer’s manual.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0405. Which traffic sign should appear after traffic sign 705?",
          options: ["701", "122", "703", "702"],
          answer: 0,
          image: "img/quiz/3405.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0519. What is the meaning of the following traffic sign?",
          options: [
            "Internal road in a Kibbutz or Moshav only.",
            "End of urban area.",
            "Entrance to an urban area.",
            "Careful! public institutions.",
          ],
          answer: 2,
          image: "img/quiz/3519.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1179. What is a taxi sightseeing ride?",
          options: [
            "A taxi ride in service of the Ministry of Defense.",
            "Only a route service ride.",
            "An exclusive taxi ride for the person who ordered it, for the purpose of touring, traveling or sightseeing.",
            "A taxi ride in service of the Ministries of Tourism and Sports together.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question: "1395. What is the correct way of driving downhill?",
          options: [
            "By shifting to neutral gear, to save on fuel.",
            "By shifting to one of the gears and continuously stepping on the clutch pedal.",
            "By shifting to high gear and continuously stepping on the brake pedal.",
            "By driving in one of the gears.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0843. What causes a vehicle to skid on the roadway?",
          options: [
            "Quick wear of the clutch and of the gearbox.",
            "When the braking forces or the vehicle's inertia exceed the tire’s ability to grip of the roadway.",
            "Excessive change of gears.",
            "When the engine is worn out and cannot stabilize the vehicle.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0715. How would you (vehicle no. 2) conduct yourself in the following intersection?",
          options: [
            "Continue driving carefully, while keeping eye contact with the yellow vehicle (1) which should yield.",
            "Wait and allow the yellow vehicle (1) to cross before you.",
            "Stop (2) before entering the intersection and observe the condition of the traffic and of the traffic signs.",
            "Give right of way to the yellow vehicle (1), because it is coming from your right.",
          ],
          answer: 0,
          image: "img/quiz/3715.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0780. A paraplegic is driving it the city and is in a hurry to a scheduled medical examination :",
          options: [
            "He is prohibited to use the bus lane as well as to exceed the speed limit.",
            "He is permitted to exceed the speed limit.",
            "He is permitted to exceed the speed limit while driving in a bus lane only.",
            "He is permitted to use the bus lane, but is prohibited from exceeing the speed limit.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1726. The minimum age to obtain a driver’s license for a Public Service Vehicle is:",
          options: ["23 yrs.", "21 yrs.", "22 yrs.", "20 yrs."],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0397. When traffic sign 401 is placed at an entry to a road, which traffic sign should be placed at the entry from the opposite side of the same road?",
          options: ["618", "307", "401", "402"],
          answer: 2,
          image: "img/quiz/3397.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0358. Which of the following traffic signs informs about a change in the number of lanes?",
          options: ["109", "145", "622", "All four signs."],
          answer: 2,
          image: "img/quiz/3358.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0529. The following road sign remains valid until:",
          options: [
            "Until the nearest intersection or until an urban area entry sign.",
            "An “end of urban road” sign.",
            "A road sign that cancels it.",
            "Until the nearest intersection with traffic lights.",
          ],
          answer: 2,
          image: "img/quiz/3528.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1154. Who is responsible for placing route  signs on the service bus?",
          options: [
            "The service workers.",
            "The route license holder.",
            "The bus driver and the route license holder.",
            "The bus driver.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0347. Under what condition is it permissible to install additional lamps to those already installed in a vehicle?",
          options: [
            "With the approval of the Licensing Authority only.",
            "It is allowed to install additional symmetric lamps.",
            "Provided that they comply with the new European standard.",
            "Provided that they are installed by a certified electrician.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0632. What is the meaning of the following traffic sign?",
          options: [
            "End of 30 k.p.h zone.",
            "Recommended speed of at least 50 k.p.h.",
            "Beginning of 30 k.p.h zone.",
            "An area of at least 30 k.p.h.",
          ],
          answer: 0,
          image: "img/quiz/3632.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1478. A person shall not, and shall not permit anybody, to transport a freight container on his vehicle, unless:",
          options: [
            "The vehicle has an engine of at least 150 horse-powers.",
            "The vehicle’s all up weight is at least 14,000 kg.",
            "The vehicle has two spare tires.",
            "It is indicated in the vehicle’s registration document that the vehicle is permitted to transport freight containers.",
          ],
          answer: 3,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0815. What is the distance we are required to keep from the vehicle in front of us while driving?",
          options: [
            "Any distance that allows us to see the back wheels of the vehicle in front of us while driving.",
            "Any distance that always allows us to see well what’s happening ahead of the vehicle that is driving in front of us.",
            "The required statutory distance in which accident may always be prevented, while taking all the relevant elements into consideration.",
            "A distance which prevents entry of other vehicles in it.",
          ],
          answer: 2,
          image: "img/quiz/3815.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0559. It is permitted to make a left U-turn:",
          options: [
            "Only from the farthest left lane in which you are permitted to drive.",
            "From a left-turn lane or from any other lane which doesn’t have a prohibiting sign.",
            "From every lane that is marked with left or right-turn arrows.",
            "From a left-turn lane only.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1552. Is it permitted to drive under the influence of an intoxicating alcoholic drink?",
          options: [
            "Yes, provided that the driver doesn’t mix drinks.",
            "Absolutely not.",
            "Yes, but only after family gatherings and for short distances.",
            "Yes, it is permitted.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1302. A freight transporting vehicle shall be marked with a “Caution, wide load” sign when:",
          options: [
            "The freight width is more than 1.80 meters.",
            "The freight width is more than 2.50 meters.",
            "The freight width is more than 2.10 meters.",
            "The freight width is less than 2.40 meters.",
          ],
          answer: 1,
          image: "",
          types: ["C"],
        },
        {
          question:
            "1139. When is a bus driver obliged to display an identifying document?",
          options: [
            "At the request of a policeman only.",
            "Only when a municipal inspector files a complaint against him.",
            "When an income tax clerk requests to see his stock of tickets.",
            "Whenever required to do so by any bus passenger.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1637. Which number marks the length of the “rear extension” of this vehicle?",
          options: ["22", "7", "27", "23"],
          answer: 1,
          image: "img/quiz/31637.jpg",
          types: ["C1", "C"],
        },
        {
          question:
            "1069. Which of the following warning indicators does not require the driver to stop driving when it lights-up?",
          options: [
            "Engine temperature.",
            "The fuel level indicator.",
            "Brakes failure.",
            "Engine oil pressure.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question:
            "1092. For what reason are weights installed on the tractor’s front?",
          options: [
            "To improve the front wheels’ surface grip.",
            "To reduce some of the stress on the tractor’s rear axle while lifting loads.",
            "To improve driving quality and comfort on dirt roads.",
            "To prevent the tractor’s rear part from jumping while driving at high speed.",
          ],
          answer: 0,
          image: "",
          types: ["1"],
        },
        {
          question: "0501. What is the meaning of the following traffic sign?",
          options: [
            "No entry for private vehicles.",
            "No entry for all motor vehicles, except for motorcycles without a side-car.",
            "No parking for all vehicles.",
            "No entry for all vehicles, except for private passenger vehicles.",
          ],
          answer: 1,
          image: "img/quiz/3501.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0737. How do you secure a parked automatic-transmission vehicle (except for a vehicle with a robotic gearbox)?",
          options: [
            "Shift into reverse gear (R) and secure the parking brake.",
            "Shift into parking gear (P) and secure the parking brake.",
            "Shift into neutral gear (N) and secure the parking brake.",
            "Shift into first gear (1) and secure the parking brake.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0004. Apart from a driving instructor, who may escort a “new driver”?",
          options: [
            "Any holder of a valid driving license for the same class of vehicle, and aged at least 24 yrs old.",
            "A holder of a driving license for the same class of vehicle for at least 3 years and aged at least 30 yrs old, or a holder of a license for at least 2 yrs and aged at least 35 yrs old.",
            "Any person who has not been convicted for any traffic offence during the past two years.",
            "A holder of a driving license for the same class of vehicle for at least 5 years and aged at least 24 yrs old, or a holder of a license for at least 3 yrs and aged at least 30 yrs old.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "0233. How should you drive a child less than one year of age inside a vehicle?",
          options: [
            "In the front seat only, accompanied by an adult passenger.",
            "In a safety seat for babies, and in the back seat only..",
            "In a safety seat, with the back of the seat facing the front of the car.",
            "On the knees of a passenger over twenty one years of age.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "В"],
        },
        {
          question:
            "0887. What are you required to do when you see that the overtaking silver vehicle intends to return to the right lane?",
          options: [
            "Deviate to the left lane and overtake the vehicle in front.",
            "Allow it to return safely and without disturbance.",
            "Drive faster in the right lane and overtake the driver before he deviates to the right.",
            "Honk to warn and deter the driver who deviates to the right.",
          ],
          answer: 1,
          image: "img/quiz/3887.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0035. What is a “rigid veicle”?",
          options: [
            "A vehicle body which exceeds the size of a normal vehicle.",
            "A vehicle body in which the driver’s compartment is separated from the trunk.",
            "A vehicle body with a partition between the driver’s seat and the passengers.",
            "A vehicle body in which the driver’s compartment and the trunk comprise one unit.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0747. When you are standing with your vehicle before an intersection while the red light is on, and you notice a security vehicle behind you, with its siren on and its lights flashing:",
          options: [
            "You should get on the sidewalk (pavement) in order to clear the way for the security vehicle.",
            "You are permitted to enter the intersection while the red light is on as well.",
            "You are not allowed to enter the intersection while the red light is on.",
            "You should enter the intersection while the red light is on.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1330. Who is authorized to give permission for carrying oversize freight on a vehicle with an all up weight of more than 15,000 kg?",
          options: [
            "A police officer only.",
            "The Licensing Bureau.",
            "The General Director of the Public Works Department only.",
            "A traffic safety officer.",
          ],
          answer: 0,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0377. Which warning sign is sometimes placed before traffic sign 302?",
          options: ["135", "301", "139", "150"],
          answer: 2,
          image: "img/quiz/3377.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1318. You are driving in the left lane of a one-way road. The driver in the right lane before you slowed down due to a pedestrian crossing the road from right to left. You are close to the pedestrian crossing, and therefore:",
          options: [
            "You should accelerate and quickly pass the crossing before the pedestrian arrives.",
            "Use the horn to warn the passenger and quickly pass before him.",
            "Continue driving normally; the pedestrian should make sure not to do anything risky and not to disturb you.",
            "You should slow down and allow the pedestrian to finish crossing.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0417. What are you required to do according to the following road sign?",
          options: [
            "Slow down and obey the instructions of the workers and of all the road signs in the area.",
            "Drive carefully, rough road ahead.",
            "Cross the place quickly without disturbing passersby.",
            "Driving permit should be obtained from the operating contractor.",
          ],
          answer: 0,
          image: "img/quiz/3416.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0790. A policeman wrote you a traffic report due to “deviation from your lane”, and there are no lanes marked on the roadway. Is it justified?",
          options: [
            "No, if lanes are not marked you may drive in any part of the road.",
            "No, you cannot “deviate” from an unmarked lane.",
            "No, you are permitted by law to deviate from your lane without any restriction.",
            "Yes, a lane can be either marked or unmarked.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1283. A warning triangle must be carried by:",
          options: [
            "Commercial vehicles only.",
            "All motor vehicles, including trailers and semi-trailers, but excluding motorcycles.",
            "Buses only.",
            "Two-wheelers only.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "1758. How is a driver required to conduct himself when approaching schools or playgrounds?",
          options: [
            "Avoid entering the street, except at night time.",
            "Continue driving normally and pay attention to the pedestrian crossings only.",
            "Honk continuously to warn children not to jump to the road.",
            "Slow down and become more alert.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1229. Which seats are reserved for security personnel on a route service bus?",
          options: [
            "When driving on a non urban road - the two front seats on either side of the passageway.",
            "The double seat behind the driver partition.",
            "The seat after the front door and the seat after the rear door.",
            "In a city bus, across from the rear door.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question: "1722. Driving downhill in neutral gear:",
          options: [
            "Is permitted and not dangerous.",
            "Is permitted but dangerous.",
            "Is forbidden and very dangerous.",
            "There is no legal reference to it.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1585. How should an additional passenger sit on a motorcycle?",
          options: [
            "The passenger should sit with both legs on the right side of the motorcycle.",
            "The passenger should sit with both legs on the left side of the motorcycle.",
            "The passenger should sit astride the motorcycle with his feet supported by the foot pedals..",
            "The passenger can sit on the motorcycle however he chooses to.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1255. What is the additional charge applied on share taxi rides from 23:31 until 04:59?",
          options: [
            "50 percent.",
            "25 percent.",
            "18 percent.",
            "12.5 percent.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question: "0286. How should we behave whilst driving downhill?",
          options: [
            "Shift into a high gear and keep the clutch pedal pressed for greater safety.",
            "Shift into neutral gear, in order to save fuel.",
            "Shift into one of the drive gears and keep the clutch pedal pressed.",
            "Whilst driving down hill we must make sure that one of the gears is engaged.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0380. Which of the following traffic signs prohibit a left U-turn?",
          options: [
            "All of the following signs prohibit a U-turn.",
            "205",
            "203",
            "202",
          ],
          answer: 2,
          image: "img/quiz/3380.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0474. What are you required to do according to the following road sign?",
          options: [
            "Drive normally. The drivers from the opposite direction should give you right-of-way.",
            "Stay on the right of road and pay attention to incoming traffic from the opposite direction.",
            "Stay on the right lane because overtaking is prohibited.",
            "Drive faster in the narrow road section.",
          ],
          answer: 1,
          image: "img/quiz/3473.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1500. What type of insurance covers the damage that is caused to other vehicles?",
          options: [
            "Directors insurance.",
            "Third party insurance.",
            "Fire insurance.",
            "Obligatory insurance.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0615. In a school bus, it is prohibited to:",
          options: [
            "Carry students without an escorting adult.",
            "Carry students without at least one escorting adult.",
            "Carry adults standing up.",
            "Carry children standing up.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0071. When is it forbidden for a person to drive any type of vehicle?",
          options: [
            "When he did not rest at least 8 hours before driving",
            "When his physical or mental state might endanger other road-users",
            "Before taking a medication and during the following 24 consecutive hours",
            "As long as the driver holds a valid driver’s license, there is no law that prohibits him from driving",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0911. What impossible situation is depicted in the following picture?",
          options: [
            "the road signs contradict each other.",
            "A “one-way road” sign should be placed on both sides of the roadway.",
            "A “right of way” sign must be placed on both sides of the roadway.",
            "A “one-way road” sign should be placed on the left side of the roadway.",
          ],
          answer: 0,
          image: "img/quiz/3911.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0026. What is “own weight”?",
          options: [
            "The vehicle’s weight with cargo but without the driver.",
            "The vehicle’s weight without cargo and without the driver.",
            "The vehicle’s weight with cargo and with a driver",
            "The vehicle’s volume without cargo but without the driver",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "1"],
        },
        {
          question: "1195. For how long should a bus stop in a bus stop?",
          options: [
            "It is permitted to stop for an unrestricted period of time.",
            "For as long as it takes to load passengers – this always takes more time than unloading.",
            "For as llong as it takes for passengers to get on the bus with their luggage.",
            "For as long as it takes to load and unload passengers.",
          ],
          answer: 3,
          image: "",
          types: ["D"],
        },
        {
          question: "1390. What is the difference between the following signs?",
          options: [
            "Sign 505 indicates a stop for private buses. Sign 506 indicates a Tramway stop.",
            "Sign 505 indicates a Tramway Stop. Sign 506 indicates a stop for Public bus transport.",
            "Sign 505 indicates a stop for Public Transport (Buses). Sign 506 indicates a stop for a Public Tramway.",
            "Sign 505 indicates a public transport stop on a Motorway (Freeway). Sign 506 indicates a public transport stop.",
          ],
          answer: 2,
          image: "img/quiz/31390.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1492. You are driving on a two-way road and in front of you drives a vehicle that signals with its left indicator:",
          options: [
            "You may immediately overtake it from its right, by pulling into the “hard shoulder”.",
            "You should take into consideration that the vehicle before you might deviate to the left and you should allow it to do so.",
            "You shouldn’t overtake it on a narrow roadway, but you may overtake it from its left side on a wide road.",
            "You may overtake after using the horn to warn him. The signal he gives doesn’t oblige you.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1570. ?What road sign would be placed following sign 135 in the picture>",
          options: ["136", "220", "149", "306"],
          answer: 3,
          image: "img/quiz/31570.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0440. What are you required to do according to the following road sign?",
          options: [
            "Prepare to slow down, if necessary. Pay attention to traffic congestion and adjust your driving to the traffic and to the road’s conditions.",
            "Continue driving and be alert about the conduct of other vehicles from your right.",
            "Obey the instructions of road workers and proceed according to the markings.",
            "Drive faster and overtake slow vehicles.",
          ],
          answer: 0,
          image: "img/quiz/3439.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0366. The correctional measures which may be imposed by the Licensing Authority, based on the point system:",
          options: [
            "Are merely recommendations. Only a court of law is authorized to impose correctional measures, based on the point system.",
            "Come to replace traffic offence related convictions..",
            "Do not include driver’s license revocation (Only a police officer is permitted to revoke a driver’s license).",
            "Are additional to any other sanction that is imposed on a driver by a court of law due to traffic offenses.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0986. How does the braking system of a vehicle work?",
          options: [
            "Through the braking of the front wheels only.",
            "Through the pressure created between the engine and the clutch.",
            "By turning the engine opposite to its normal direction of turning.",
            "Through the friction between the brake disc and the brake pads.",
          ],
          answer: 3,
          image: "img/quiz/3986.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0416. What is the meaning of the following traffic sign?",
          options: [
            "Careful, speed bumps ahead.",
            "Careful, falling rocks.",
            "Road works ahead.",
            "Archeological works ahead.",
          ],
          answer: 2,
          image: "img/quiz/3416.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0174. How should you conduct yourself at an exit from a premises or from an access road to a house, when you are about to enter or cross the road?",
          options: [
            "Give way to the vehicles that are driving away from you in the road you are about to enter.",
            "The rules for giving “right-of-way” in such an exit are identical to those that apply in the case of an intersection.",
            "You must give way to vehicles coming from the right only.",
            "Give-way to the approaching vehicles in the road you are about to enter.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1696. When approaching a narrow bridge, you are required to:",
          options: [
            "Slow down while approaching and crossing the bridge.",
            "Always cross the bridge at maximum speed to clear the road for vehicles coming from the opposite direction.",
            "Always stop before the bridge, for safety reasons, and then cross it quickly.",
            "Accelerate when you approach the bridge only.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0464. What is the meaning of the following traffic sign?",
          options: [
            "Dangerous ascent.",
            "Steep ascent.",
            "Dead-end roadway.",
            "Steep descent.",
          ],
          answer: 3,
          image: "img/quiz/3464.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1381. A driver wishing to park his vehicle downhill shall:",
          options: [
            "Turn the front wheels towards the sidewalk and shift to reverse gear (in automatic vehicles – P).",
            "Turn the front wheels towards the sidewalk and shift to first gear.",
            "Turn the front wheels towards the sidewalk and shift to neutral gear (in automatic vehicles – N).",
            "Turn the back wheels towards the sidewalk and shift to neutral gear.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "0177. How should you behave when leaving a private yard a petrol station or an entrance to a properity or a house ?",
          options: [
            "The rule “first to come, first to go” should be exercised at an exit from a premises or from a gas station. The pedestrians will wait.",
            "Slow down and even stop if required, in order to allow the pedestrians to cross safely.",
            "Stop and give right-of-way to the pedestrians that are crossing the intersection.",
            "Stop and give right-of-way to the vehicles that are coming from the left and approaching the exit.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0577. What is the meaning of the following traffic sign?",
          options: [
            "Path for bicycles only.",
            "Bicycle crossing.",
            "Separate bicycle paths on a freeway.",
            "Joint path for cyclists and pedestrians.",
          ],
          answer: 0,
          image: "img/quiz/3577.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0356. Which of the following traffic signs requires driving according to the road’s condition?",
          options: ["147", "101", "114", "139"],
          answer: 1,
          image: "img/quiz/3356.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1754. When a rear camera is installed in a vehicle:",
          options: [
            "It displays the dead spots behind the vehicle.",
            "It displays the dead spots around the vehicle.",
            "It displays the dead spots in the vehicle’s front side.",
            "It displays the dead spots behind the vehicle, while driving forward only.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "1149. Is it permitted to carry carbon gas tanks on a certificated tour bus?",
          options: [
            "No, it is prohibited to carry any type of gas on a bus.",
            "Yes. On an excursion only it is permitted to carry - on the bus’ roof or in the back side of the trunk - 2 cooking gas tanks in a maximum weight of 12 kg per tank.",
            "Yes, it is permitted to carry fuel in small tanks that comply with the ASR-23 Standard.",
            "Yes, it is permitted to carry gas tanks in any bus that has an all up weight of more than 12,000 kg only.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1590. What is the minimum time requirement of having a driving license in order to drive a tractor with an own weight exceeding 3,200 kg, or that is joined with a trailer that has an all up weight exceeding 4,000 kg?",
          options: [
            "Two years from the date of obtaining a tractor driving license.",
            "6 months from the date of obtaining a tractor driving license.",
            "3 months from the date of obtaining a tractor driving license.",
            "One year from the date of obtaining a tractor driving license.",
          ],
          answer: 1,
          image: "",
          types: ["1"],
        },
        {
          question:
            "0371. Which traffic sign should be placed about 300 meters before a level crossing?",
          options: ["128", "130", "133", "131"],
          answer: 1,
          image: "img/quiz/3371.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1168. Who is required to certify that the meter is properly functioning if its seal is disconnected?",
          options: [
            "Only a qualified service provider.",
            "The taxi owner, who is required to sign the certification.",
            "The Licensing Office near the driver’s place of residence.",
            "The Controller of Road Traffic.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question: "1307. What is the meaning of this traffic sign?",
          options: [
            "Authorized parking space for public vehicles.",
            "Beware, authorized shooting range location.",
            "You are approaching a local train station.",
            "City / Settlement / Region center.",
          ],
          answer: 3,
          image: "img/quiz/31307.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0207. In the following picture – where is it forbidden to stop or park a vehicle?",
          options: [
            "Within twelve meters before the stop line.",
            "Within twenty meters before the stop line.",
            "Within fifteen meters after the stop line.",
            "Within twenty meters after the stop line.",
          ],
          answer: 0,
          image: "img/quiz/3207.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0797. What should a driver always do before making left a U-turn?",
          options: [
            "Make sure that the proper gear is shifted and that the headlights are turned on.",
            "Make sure that the turn is permitted and that it doesn’t cause any traffic disturbance.",
            "Alert about his intention by turning on the four direction indicators.",
            "Position the vehicle on the right of the road from which he intends to turn.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1795. Is it permitted to drive a vehicle (other then an electric vehicle) on the sidewalk?",
          options: [
            "It is prohibited to clean the sidewalk with a motorcycle.",
            "Yes, but only for the purpose of cleaning the sidewalk.",
            "Yes, but only vehicles towing a trailer.",
            "Yes, but only non-motor vehicles.",
          ],
          answer: 1,
          image: "",
          types: ["1", "В"],
        },
        {
          question: "1392. What is a “commercial vehicle”?",
          options: [
            "A motor vehicle used or designed to transport goods.",
            "A vehicle that can only be used for self-transportation.",
            "A vehicle in which passenger transportation, besides the driver, is prohibited.",
            "A vehicle designed to carry bulk freight only.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0909. How would you respond to the situation depicted in the following picture?",
          options: [
            "Drive faster and pass the children before they start crossing the roadway.",
            "Slow down, follow the children movement and prepare to stop.",
            "Continue driving. The children would not cross the road when noticing an approaching vehicle.",
            "Honk to the children and warn them that you are approaching and that they should not get on the roadway.",
          ],
          answer: 1,
          image: "img/quiz/3909.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1605. How should a motorcyclist cross an oil slick on the road?",
          options: [
            "Continue driving fast.",
            "Brake strongly with both brakes.",
            "Drive straight without braking.",
            "Drive straight, accelerate and cross the stain quickly.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question: "0886. In a hybrid vehicle:",
          options: [
            "Tire wear is greater than that of regular vehicles because of the second engine.",
            "Braking is harder because of the second engine.",
            "Fuel consumption is higher than that of regular vehicles, because of the second engine.",
            "Fuel consumption is lower than that of regular vehicles.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question:
            "0856. How can we overcome the problem of tiredness whilst driving?",
          options: [
            "By driving faster to shorten your journey and arrive home early.",
            "Black coffee is the solution for all problems.",
            "A short break and a heavy meal will drive the tiredness away for a long time.",
            "By having adequate rest before driving and making rest and refreshment breaks during your journey.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0512. What is instructed by the following traffic signs?",
          options: [
            "Weight restriction – 60 tons.",
            "Special speed limit: It is prohibited to drive faster that the speed limit that is indicated on the sign (in k.p.h).",
            "Special speed: It is prohibited to drive faster than the speed that is indicated in the sign under foggy or hazy conditions.",
            "It is prohibited to drive faster than 60 k.p.h during the night. During the day, however, it is permitted.",
          ],
          answer: 1,
          image: "img/quiz/3512.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1169. Whom should a taxi driver approach when his taxi meter malfunctions?",
          options: [
            "The Licensing Office.",
            "An appropriate garage.",
            "A certified taxi-meter repair service provider.",
            "The taxi owner.",
          ],
          answer: 2,
          image: "",
          types: ["D"],
        },
        {
          question: "0244. Parking at an angle on a roadway:",
          options: [
            "Is allowed when there are designated markings on the road.",
            "Is forbidden, except for motor scooters and motorcycles.",
            "Is forbidden, except for vehicles in a length of 3.5 meters or less.",
            "Is only allowed in a wide roadway.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question:
            "0728. Which of the following three vehicles is not in the correct lane, according to the traffic sign?",
          options: [
            "All three are driving according to the law.",
            "The light (private) vehicle (1).",
            "The fire-engine (2).",
            "The purple pickup truck (3).",
          ],
          answer: 1,
          image: "img/quiz/3728.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0578. What is the meaning of the following traffic sign?",
          options: [
            "Entry is permitted for pedestrians only.",
            "Children are not allowed to play here.",
            "No entry for pedestrians, including the “hard shoulders”.",
            "Careful! Pedestrians nearby.",
          ],
          answer: 2,
          image: "img/quiz/3578.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0360. If traffic sign 118 is placed at an intersection, which sign should be placed about 150 meters before the intersection?",
          options: ["117", "205", "208", "207"],
          answer: 0,
          image: "img/quiz/3360.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0505. Are motorcycles permitted to enter a road before which the following traffic sign is placed?",
          options: [
            "Yes.",
            "Yes, entry is permitted for all motor vehicles.",
            "No.",
            "Yes, except for tri-motorcycles.",
          ],
          answer: 2,
          image: "img/quiz/3505.jpg",
          types: ["A", "В"],
        },
        {
          question:
            "1269. Is a taxi without passengers permitted to use a designated bus lane?",
          options: [
            "No.",
            "Yes, but only on Saturdays and holidays.",
            "Yes, on an urban road, provided that no traffic disturbance is caused.",
            "Yes.",
          ],
          answer: 3,
          image: "img/quiz/31269.jpg",
          types: ["D"],
        },
        {
          question:
            "0829. When is it permitted to overtake another vehicle on its rights side?",
          options: [
            "When there are few lanes in your direction of driving .",
            "When the right lane is clear and you are in a hurry, and the overtaken vehicle is driving slowly.",
            "When you can complete the overtaking maneuver quickly..",
            "When the other vehicle is driving in a left-turn lane and giving a left signal.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1420. Is it permitted to transport a freight container that sticks out from the vehicle’s rear?",
          options: [
            "No, the freight container should never stick out from the vehicle’s rear.",
            "Yes, in vehicles with a short rear extension.",
            "Yes, but only on vehicles having no floor and walls.",
            "Yes, but only on vehicles with a short front extension.",
          ],
          answer: 0,
          image: "",
          types: ["C"],
        },
        {
          question:
            "1025. What is the required air pressure of the vehicle’s tires?",
          options: [
            "The tire air pressure is not so important.",
            "According to the instructions of the garage or licensing facility.",
            "A pressure that provides for the most comfortable driving.",
            "The pressure should be according to the manufacturer’s manual.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "A", "В"],
        },
        {
          question:
            "1426. Does every tractor have to install a reverse buzzer?",
          options: [
            "Yes.",
            "No.",
            "Only farm tractors.",
            "Only tractors with an all up weight of 1,000 kg or less have to install it.",
          ],
          answer: 0,
          image: "",
          types: ["1"],
        },
        {
          question:
            "1344. The maximum speed limit on a freeway is 110 Kph What vehicles are forbidden to drive this speed?",
          options: [
            'A commercial vehicle with an all up weight that exceeds 12,000 kg, a work vehicle, a touring vehicle and a \\"slow moving\\" vehicle.',
            "A vehicle with an all up weight that exceeds 8,000 kg.",
            "A commercial vehicle with an all up weight that exceeds 10,000 kg.",
            "Every vehicle with an all up weight that exceeds 12,000 kg.",
          ],
          answer: 0,
          image: "",
          types: ["C"],
        },
        {
          question:
            "1657. How can you improve your vision when driving a motorcycle in the rain?",
          options: [
            "By driving carefully and turning on the fog lights.",
            "By using an unscratched helmet visor and wipe it with vapor and moisture repellents.",
            "By adding and installing on the motorcycle strong lights and using the high beam continuously.",
            "By riding with a special winter helmet without a visor and using safety goggles intensively.",
          ],
          answer: 1,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0203. Is it permitted to stand a vehicle less than twelve meters from a junction?",
          options: [
            "Yes, provided that the vehicle stands opposite to the direction of traffic.",
            "Yes, in a two-lane road.",
            "No, unless it is permitted by the Local Traffic Sign Authority, by means of an appropriate signpost or marking.",
            "Yes, unless otherwise marked.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1586. Is a two-wheeler permitted to drive at the side of another two-wheeler?",
          options: [
            "Absolutely not.",
            "Yes, always.",
            "No, except for the purpose of overtaking or bypassing another vehicle only.",
            "Yes, but only on an urban road.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0335. Is the Licensing Authority allowed to revoke or suspend an existing driver’s license?",
          options: [
            "No, only the Israel Police is allowed to do so.",
            "Yes, the Authority is allowed to do so, on the grounds of driving competence and medical fitness.",
            "No, only a court of law is allowed to do so.",
            "Yes, the Authority is allowed to revoke a driver’s license at its own discretion and for any reason.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0679. What are you required to do when you notice the following traffic sign (in the picture) placed above your driving lane?",
          options: [
            "Slow down before the level crossing, and if a train is approaching - stop.",
            "Do not enter this lane and do not continue driving in it.",
            "Slow down before the level crossing and pass the tracks without delay or interruption.",
            "Stop before the tracks until the train passes.",
          ],
          answer: 1,
          image: "img/quiz/3679.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0382. Which of the following traffic signs instructs to pass a given place  from its left side only?",
          options: ["123", "214", "215", "213"],
          answer: 2,
          image: "img/quiz/3382.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1123. What is the meaning of the following road sign?",
          options: [
            "It is a direction sign to destinations on a freeway (motorway).",
            "It is a warning sign on leaving a freeway.",
            "It is a direction to destinations on an urban road.",
            "It is a direction sign before an interchange on an inter-urban road.",
          ],
          answer: 2,
          image: "img/quiz/31123.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0081. In which type of road is it obligatory to use the road’s farthest right side?",
          options: [
            "In a freeway",
            "In a two-way road only",
            "In any type of road",
            "In a one-way road only",
          ],
          answer: 2,
          image: "img/quiz/3079.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1501. The depth of the grooves in a tire (pneumatic-air pressure) which comes in contact with the road should not be less than:",
          options: [
            "2mm, excluding the spare tire.",
            "2cm, including the spare tire.",
            "2cm, excluding the spare tire.",
            "2mm, including the spare tire.",
          ],
          answer: 3,
          image: "img/quiz/31501.jpg",
          types: ["C1", "C", "D", "A", "В"],
        },
        {
          question:
            "1470. What risk is a rigid vehicle with trailer or a semi-trailer truck expected to face while driving on a slippery roadway?",
          options: [
            "On a slippery road all vehicles are at risk, and a connected vehicle or a semi-trailer truck is not special in this sense.",
            "The vehicle can skid into a “jack-knife” position, in which a large angle is formed between the trailer and the towing vehicle,",
            "Loss of engine power due to the slippage of a long vehicle.",
            "That the deceleration brake stops functioning.",
          ],
          answer: 1,
          image: "",
          types: ["C"],
        },
        {
          question:
            "1300. When is it obligatory to hold a police authorization for transporting a long freight?",
          options: [
            "When the length of the freight exceeds 15 meters.",
            "Holding an authorization of a police officer is not obligatory for this type of transport.",
            "An authorization is only obligatory in the transportation of hazardous materials.",
            "When the length of the vehicle plus the freight it carries exceeds 20 meters.",
          ],
          answer: 3,
          image: "",
          types: ["C"],
        },
        {
          question: "1121. Which vehicles are obliged to have “safety shoes”?",
          options: [
            "Every motor vehicle with an all up weight of at least 5,000 kg, except for buses.",
            "All commercial vehicles regardless of weigh, except for buses.",
            "Every vehicle with an all up weight of up to 3,500 kg, except for buses.",
            "Every vehicle with an all up weight of up to 4,000 kg, including buses.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question: "1468. The manufacure date of a vehicle’s tire is:",
          options: [
            "Noted in the vehicle book.",
            "Marked on the tire.",
            "Noted in the vehicle’s registration document.",
            "Noted in the vehicle’s insurance policy.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0673. What is the meaning of the following traffic light (intermittently flashing red light)?",
          options: [
            "Prepare to stop before the intersection with the traffic lights.",
            "It is a special traffic light before a level crossing. Stop before the crossing and don’t proceed for as long as the light is flashing.",
            "Stop before the intersection with the traffic lights.",
            "Drive faster before the crossing.",
          ],
          answer: 1,
          image: "img/quiz/3673.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0931. How are you required to conduct yourself according to the occurrence that is depicted in the following picture?",
          options: [
            "The fruits on the road are of no significance to you.",
            "The vehicle might slip while braking, as a result of the fruits on the road, only when the roadway is wet from rain; hence, you don’t need to slow down.",
            "The fruits on the roadway cannot cause the vehicle to lose its grip of the roadway; hence, you don’t need to slow down.",
            "Slow down and drive carefully. The road’s conditions, e.g. the fruits on the road, prevent a good grip on the road.",
          ],
          answer: 3,
          image: "img/quiz/3931.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            '1285. Which type of "city policing" vehicle is permitted to emit a blue light with a flashing yellow light?',
          options: [
            "Rescue vehicles.",
            "Ambulances.",
            "Integrated policing vehicles.",
            "Fire fighting vehicles.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1630. How is a motorcyclist required to overtake another vehicle?",
          options: [
            "As fast as possible, even if it means to exceed speed limit for a brief moment.",
            "From the right side of the overtaken vehicle, and as close as possible to it.",
            "From the left side of the overtaken vehicle, while keeping a proper side distance from it.",
            "In a manner that allows him to always see the other vehicle in both mirrors.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1265. Is a minibus permitted to park within the limits of the following road sign?",
          options: [
            "No.",
            "Yes.",
            "Yes, but only on weekdays from 21:00 until 05:30.",
            "Yes, but only on weekends.",
          ],
          answer: 0,
          image: "img/quiz/3627.jpg",
          types: ["D"],
        },
        {
          question:
            "0862. What are we required to ensure before starting to drive?",
          options: [
            "That the fuel and oil types are those required for the proper operation of the vehicle.",
            "That we can operate and use the vehicle proficiently.",
            "That the vehicle has spare bulbs and fuses.",
            "That the vehicle received all the treatment necessary for its current maintenance.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0994. What is “wheel locking”?",
          options: [
            "Preventing the wheel from turning while releasing the screw nuts that hold it.",
            "It is a device attached to one of the vehicle’s wheels, to prevent movement while parking on a very steep slope.",
            "”Booting” one of the vehicle’s wheels by a certified authority.",
            "The vehicle’s wheels stop turning as a result of strong and continuous braking.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "A", "В"],
        },
        {
          question:
            "0290. What is the responsibility of a vehicle owner as regards to an offence committed by his vehicle?",
          options: [
            "Absolute responsibility, unless he can prove that another person drove the vehicle at that time.",
            "If he doesn’t drive the vehicle, he bears no responsibility.",
            "The vehicle owner is only held responsible if he is present in the vehicle while another person is driving it.",
            "The vehicle owner is responsible for it even if the vehicle is stolen.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1286. Where in a vehicle should a fire extinguisher be placed?",
          options: [
            "Next to the battery, in a closed and locked place.",
            "On the vehicle’s roof, having easy access, to enable quick operation",
            "In a visible place with easy access to enable quick operation.",
            "Next to the fuel tank, in a closed and locked place.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "0713. Who of the following is entitled to enter the intersection first?",
          options: [
            "The motor-cyclist (1). You (2) should give him right-of-way.",
            "In this event there is no generalisation and there is no importance in the order of entry into the junction.",
            "You (2). The motor-cyclist (1) will always go in last.",
            "The vehicle continuing to drive straight (you)",
          ],
          answer: 0,
          image: "img/quiz/3713.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1312. The transportation of which freight width requires a permit from a police officer?",
          options: [
            "A freight width of up to 3.40 meters.",
            "A freight width of more than 3.40 meters.",
            "A freight width of up to 2.10 meters.",
            "A freight width of up to 3.00 meters.",
          ],
          answer: 1,
          image: "",
          types: ["C"],
        },
        {
          question:
            "1440. When is a driver required to drive on the “hard shoulder” - where the “shoulder” is asphalt-paved and free of traffic - in order to allow another vehicle to overtake him?",
          options: [
            "When the vehicle drives very slowely for any reason.",
            "Only when the vehicle is forced to drive slowly on a steep uphill climb, and disturbs the free traffic.",
            "When a driver of a motor vehicle is forced to drive very slowly due to the freight he’s carrying or to the steepness of the road, and he delays the traffic behind him.",
            "Never. It is prohibited to drive on the “hard shoulders”; hence, a vehicle that delays the traffic behind it should pull into the “hard shoulder” and stop, to allow the traffic congestion behind him to open up.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0137. What gap should be maintained from the vehicle that is driving in front of us?",
          options: [
            "A gap of thirty meters, in order to allow safe stoppage.",
            "A gap of forty meters, in order to prevent an accident.",
            "A gap of at least three seconds.",
            "A gap which always allows us to stop and prevent a collision..",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0117. Under which conditions is it permitted to make a U-turn?",
          options: [
            "Making a U-turn is only allowed in a roadway that has at least three lanes.",
            "It is permitted everywhere, but the turn must be made without any maneuvering.",
            "When no traffic disturbance is caused and no passerby is at risk, provided that there is no road sign that prohibits making a U-turn.",
            "A U-turn is permitted in an intersection only.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1343. What is the maximum speed permitted for work vehicles and commercial vehicles with an all up weight that exceeds 12,000 kg?",
          options: [
            "Same as any other vehicle on the roadway.",
            "50 k.p.h on an urban road, and 90 k.p.h on a road with a built diving area;",
            "40 k.p.h on an urban road, 80 k.p.h on a non-urban road, and 90 k.p.h on a road with a built diving area;",
            "50 k.p.h on an urban road, and 80 k.p.h on any other type of road;",
          ],
          answer: 3,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0065. Is it obligatory to obey the directions and signals of a policeman, even when a certain direction or signal is opposed to the traffic signs?",
          options: [
            "Yes, except for directions which contradict a stop sign.",
            "Yes, it is obligatory to obey with full caution.",
            "No, it is not obligatory to obey",
            "Yes, except for directions which contradict a no-entry sign.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1396. When is it obligatory to operate the speed retarder while driving downhill?",
          options: [
            "Only when the slope rate is indicated in the “steep descent” sign.",
            "In every downhill road section.",
            "When the slope rate of the descent exceeds 6% and the length of the descent exceeds one kilometer.",
            "When a “dangerous descent” sign is posted.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D"],
        },
        {
          question:
            "0389. Is it permitted to park a vehicle besides a pedestrian safety guardrail?",
          options: [
            "Yes, in order to load or unload passengers only.",
            "Yes, for a short time only.",
            "Yes, provided that there are no pedestrians besides the safety guardrail.",
            "No, unless there is a signpost indicating otherwise.",
          ],
          answer: 3,
          image: "img/quiz/3389.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0150. What is the maximum permitted speed for driving a private passenger vehicle on a freeway(motorway) (unless otherwise indicted)",
          options: ["110 KPH.", "90 KPH.", "80 KPH.", "100 KPH."],
          answer: 0,
          image: "img/quiz/3139.jpg",
          types: ["C1", "C", "D", "В"],
        },
        {
          question:
            "0720. How should you behave at the following intersection?",
          options: [
            "Honk to warn the motor-cyclist and continue driving.",
            "Keep driving. The motor-cyclist should yield.",
            "Give right of way to the motorcyclist",
            "Keep driving straight and blink with the high beam headlights to warn the motorcyclist.",
          ],
          answer: 2,
          image: "img/quiz/3720.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1295. A policeman is permitted to issue a “vehicle non-use notice” (taking a vehicle off the road) when:",
          options: [
            "The vehicle’s fuel consumption exceeds the level that was determined by the vehicle’s manufacturer.",
            "The vehicle’s oil consumption is excessive.",
            "The vehicle’s tire air pressure is less than half of the required level.",
            "The vehicle causes air pollution beyond permitted levels.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0481. Who are obliged to act cautiously?",
          options: [
            "All road users.",
            "Driving students only.",
            "Only drivers.",
            "Only pedestrians.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0936. What is the driver obliged to do according to the following picture?",
          options: [
            "Pull into the “hard shoulder” and slow down.",
            "Slow down and keep to the right.",
            "He is not obliged to do anything special.",
            "Honk and flash his lights.",
          ],
          answer: 1,
          image: "img/quiz/3936.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1386. Explain the term “axle” in a vehicle:",
          options: [
            "Shaft or system of drive shafts running the width of the vehicle, on which the wheels are installed.",
            "The drive shaft that transmits the rotary motion from the gearbox to the differential.",
            "A line along the vehicle, from bumper to bumper, which runs parallel to the vehicle's longitudinal axis.",
            "The back beam of the chassis, to which the towing pin of the truck tractor is connected.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0991. How would you conduct yourself in case of brake overheating as a result of continuous braking?",
          options: [
            "Continue driving normally and don’t use the brakes much.",
            "Use the parking brake temporarily.",
            "Continue driving fast to ventilate the brakes.",
            "Stop! Wait for the brakes to cool down and continue driving on very low gear.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "A", "1", "В"],
        },
        {
          question:
            "1611. Why is it that the control of a motorcycle is decreased while entering a turn?",
          options: [
            "Because the motorcycle is decelerating during the entire course of the turn.",
            "Because the rider’s position is lower than usual.",
            "Because less surface of the tyre maintains contact on the road when the driver leans the motorcycle over.",
            "Because it needs to accelerate during the entire course of the turn.",
          ],
          answer: 2,
          image: "",
          types: ["A"],
        },
        {
          question: "0509. What is the meaning of the following traffic sign?",
          options: [
            "No entry for vehicles the width of which is less than the width indicated in the sign.",
            "No entry for freight-carrying vehicles the width of which (vehicle and freight) exceeds the width indicated in the sign.",
            "No entry only for commercial vehicles the width of which exceeds the width indicated in the sign.",
            "No entry for non-motor vehicles only.",
          ],
          answer: 1,
          image: "img/quiz/3509.jpg",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "0932. According to the picture, what limitation is faced by the green (commercial) pick-up truck that wishes to turn right?",
          options: [
            "The motor scooter intends to drive straight; hence there is no danger in turning right.",
            "The pick-up truck is permitted to turn right even though the motorcycle is on his right because the law does not forbid it",
            "The driver of the pick-up truck should be careful, because there is another vehicle driving in a “blind spot” to his right.",
            "The pick-up truck is statutory prohibited to turn right.",
          ],
          answer: 2,
          image: "img/quiz/3932.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0387. Which of the following traffic signs requires giving right of way in a roundabout?",
          options: ["301", "307", "303", "121"],
          answer: 2,
          image: "img/quiz/3387.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0914. How are you required to conduct yourself according to the occurrence depicted in the picture?",
          options: [
            "Honk loudly to warn that you are approaching.",
            "Slow down carefully and stop if needed, because a child might be chasing the ball.",
            "Continue driving and make short and rhythmic honks.",
            "Accelerate and pass quickly before any child gets to the roadway.",
          ],
          answer: 1,
          image: "img/quiz/3914.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0899. What are you required to do when the truck in the picture enters your driving lane?",
          options: [
            "Allow it to enter the lane without disturbance.",
            "You should stay on the left lane and drive faster.",
            "You should drive faster and prevent it from entering.",
            "You should move to the right lane in order to continue driving.",
          ],
          answer: 0,
          image: "img/quiz/3899.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1689. When the motorcycle’s high beam malfunctions during a day drive:",
          options: [
            "It is permitted to drive until nightfall only.",
            "It is prohibited by law to continue riding.",
            "It is only permitted to drive on a properly lit urban road and at a slow speed.",
            "It is only permitted to drive behind vehicles with properly functioning lights.",
          ],
          answer: 1,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1636. Driver of a commercial vehicle with an all up weight exceeding 6,000 kg, shall have a rest without working of at least 25 consecutive hours during every period of:",
          options: ["6 days.", "12 days.", "8 days.", "10 days."],
          answer: 2,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1038. When is it recommended to check the tire air pressure?",
          options: [
            "When the tires are hot.",
            "When the tires are cold.",
            "Once a year.",
            "Before every periodic treatment.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "A", "В"],
        },
        {
          question:
            "1305. With is the maximum permitted length of a three-axle commercial vehicle?",
          options: [
            "11.00 meters.",
            "15.30 meters.",
            "12.00 meters.",
            "11.30 meters.",
          ],
          answer: 2,
          image: "",
          types: ["C"],
        },
        {
          question:
            "1434. During the night, where is it prohibited to park a commercial vehicle with an all up weight of more than 10,000 kg?",
          options: [
            "Wherever the local traffic sign authority placed a sign prohibiting this type of vehicle from parking or waiting.",
            "In every road of less than 10 meter width.",
            "All the answers are correct.",
            "In two-way roads.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C"],
        },
        {
          question: "0087. When is it permitted to drive on a pavement?",
          options: [
            "It is permitted to drive on the sidewalk when one wants to cross it in order to enter/exit a yard or building.",
            "It is absolutely prohibited to drive on the sidewalk.",
            "It is permitted to drive on the sidewalk when it is free of pedestrians.",
            "It is permitted to drive on the sidewalk when the road is blocked or obstructed",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0638. What is the meaning of the following traffic sign?",
          options: [
            "Path for bicycles and motorcycles.",
            "Path for bicycles only.",
            "One-way lane for bicycle.",
            "No entrance for bicycles.",
          ],
          answer: 2,
          image: "img/quiz/3618.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0490. What is the meaning of the following traffic sign?",
          options: [
            "Road closed in both directions for all vehicles.",
            "No entry for motor vehicles (one-way street ahead).",
            "No entry for non-motor vehicles (one-way street ahead).",
            "No entry to the lane beneath the sign.",
          ],
          answer: 0,
          image: "img/quiz/3490.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0941. What is the speed limit for private passenger cars after crossing the following road signs?",
          options: ["90 k.p.h.", "70 k.p.h.", "50 k.p.h.", "110 k.p.h."],
          answer: 0,
          image: "img/quiz/3941.jpg",
          types: ["C1", "В"],
        },
        {
          question:
            "0084. Where should vehicles drive in a road divided by a Central divider?",
          options: [
            "In case of a three-lane roadway – on the left lane",
            "In case of a two-way roadway – on the roadway’s left side",
            "On the right of the Central divider - unless otherwise marked",
            "On the left of the Central Divider.",
          ],
          answer: 2,
          image: "img/quiz/3084.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0310. For what reason do the following road signs have a different shape than other signs?",
          options: [
            "To enable pedestrians to notice road signs that are posted opposite their direction of walking.",
            "Because these two road signs are less important than other signs.",
            "To make them more recognisable to drivers, and so that they can also be seen and interpreted correctly even under poor visibility conditions.",
            "Because these two road signs are more important than other signs.",
          ],
          answer: 2,
          image: "img/quiz/3310.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1505. Is it permitted to transport a freight container while few of its twist locks are not secured?",
          options: [
            "Absolutely not.",
            "Yes, but only when transporting short containers.",
            "Yes, but only with the approval of a police officer.",
            "Yes, when transporting a 40 feet container.",
          ],
          answer: 0,
          image: "",
          types: ["C"],
        },
        {
          question:
            "1409. Is it obligatory to mark oversize freight on a vehicle?",
          options: [
            "No. A vehicle with oversize freight should only be marked if the freight sticks out from the vehicle’s rear side.",
            "Yes, it is obligatory to mark the oversize freight, but only during “light time”.",
            "No. When a vehicle is standing on the road, its oversize freight doesn’t cause any risk; hence, it is not obligatory to mark it.",
            "Yes, it is always obligatory to mark oversize freight.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0822. When is it permitted to pass more than one vehicle during one overtaking maneuver?",
          options: [
            "It is permitted in a straight and level roadway only, and prohibited in any other type of road.",
            "Never: You are lawfully obliged to overtake only one vehicle during an overtaking maneuver, and to return to the right lane.",
            "When overtaking is not restricted, and there is enough space in the overtaking lane for safe completion of the overtaking maneuver.",
            "It is permitted when you overtake small vehicles only, and prohibited when you overtake big vehicles.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0127. The Points system of the Licensing Authority:",
          options: [
            "Is additional to any other punishment that is imposed by a court of law on a driver that committed a traffic offense",
            "Allows a police officer to disqualify a driver’s license following any traffic offense that is committed by the driver.",
            "Can only be exercised by a court of law.",
            "Is exercised only if no punishment was imposed on the driver by a court of law/",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1631. When are you (a motorcycle rider) required to return to your driving lane at the end of overtaking?",
          options: [
            "After first glancing in the mirrors and then making another check by looking briefly backwards over your right shoulder.",
            "A reasonable time after passing the overtaken vehicle.",
            "After seeing the overtaken vehicle in both motorcycle mirrors.",
            "When riding a motorcycle, you are not required to return to your former driving lane.",
          ],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1741. Who should you give right of way to at an intersection without traffic signs?",
          options: [
            "To vehicles turning right or left.",
            "To vehicles coming from your left.",
            "To vehicles coming from your right.",
            "To public vehicles.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0288. Is it permitted to re- fuel a vehicle while its motor is running?",
          options: [
            "Absolutely not.",
            "Yes, in case of self-fueling.",
            "Yes, if the vehicle is equipped with a fire extinguisher.",
            "Yes, when the vehicle stands next to a fire extinguishing station.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0842. How can you avoid frontal collision while driving on a narrow and winding mountain road?",
          options: [
            "Drive close to the middle line.",
            "Slow down and honk continuously before every curve.",
            "Drive on the far right of the roadway, turn-on the lights, and adjust your speed to the road’s conditions.",
            "Drive in high gear and don’t use the brakes much, to avoid loss of braking ability.",
          ],
          answer: 2,
          image: "img/quiz/3839.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1174. What is the maximum number of passengers a minibus driver is permitted to carry?",
          options: [
            "6 adults and two children.",
            "As stated in the vehicle’s registration documents and not more than ten.",
            "8 passengers besides the driver.",
            "As the number of seats.",
          ],
          answer: 1,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0817. What effect does driving whilst keeping proper distance from the vehicle in front has on the vehicle’s fuel consumption and wear?",
          options: [
            "It is required to keep a proper distance to prevent accidents only, and it has no effect on the vehicle’s wear or fuel consumption.",
            "The shorter is the distance kept from the vehicle in front, the lower are both brake wear and fuel consumption.",
            "In order to keep proper distance from the vehicle in front you are required to constantly use the brakes, and therefore it causes quick brake wear.",
            "When proper distance is kept – brake wear and engine fuel consumption are reduced,",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0180. How should you behave when approaching the sign in the picture?",
          options: [
            "Cross the place quickly and merge with traffic.",
            "Stop and give right-of-way.",
            "Give right-of-way to the traffic on the roadway that is to your left.",
            "Slow down and give right-of-way to vehicles coming from your right.",
          ],
          answer: 2,
          image: "img/quiz/3180.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1730. Is it permitted to drive a motorcycle in which a pressure exhaust is installed?",
          options: [
            "Yes.",
            "It is only prohibited in private passenger cars.",
            "Yes, but only if the exhaust was installed by a licensed mechanic.",
            "Absolutely not.",
          ],
          answer: 3,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0635. What does the following marking, along the width of the road, indicate?",
          options: [
            "Stop line before an intersection.",
            "White broken line: \\r\\nA one-way road.",
            "Crossing for cyclists.",
            "Bus lane.",
          ],
          answer: 2,
          image: "img/quiz/3635.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1353. How would you mark a commercial vehicle with an all up weight of more than 12,500 kg?",
          options: [
            "With two light-reflecting stripes on the rear bumper.",
            "It is not obligatory to mark a vehicle that doesn’t have a rear wall with light reflectors.",
            "With two light-reflecting plates in the rear.",
            "With 4 light-reflecting stripes, 2 vertical and 2 horizontal.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "1319. What is the obligatory number of rest days per year for a driver of a commercial vehicle with an all up weight of more than 6,000 kg?",
          options: [
            "At least two days per month.",
            "At least 52 days.",
            "At least 31 days.",
            "In accordance with the collective labor agreement.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0212. Within what distance of a bus stop is it forbidden to stop a vehicle?",
          options: [
            "Within a distance of twelve meters before the station’s signpost and twelve meters after the signpost, unless otherwise marked.",
            "Within a distance of twenty meters after the bus station’s signpost only.",
            "Within twenty meters before and twenty meters after the bus stop’s sign, unless otherwise marked.",
            "Within a distance of twenty meters before the bus station’s signpost only.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0345. Who is permitted to summon a vehicle to undergo a technical examination?",
          options: [
            "A Municipal Inspector, an employee of the Public Works Department, and a policeman carrying a policeman certificate.",
            "An examiner of the Licensing Authority as well as a policeman carrying a traffic examiner certificate.",
            "Any manager of a licensed garage.",
            "The Director of the Standardization Department in the Israel Bureau of Standards.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0388. “restricted (Calm) zone” is a zone:",
          options: [
            "With a sign posted speed limit of 25 k.p.h.",
            "With a sign posted speed limit of 40 k.p.h.",
            "Designed for slow vehicles only.",
            "With a sign posted speed limit of 30 k.p.h.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1350. What is a “work vehicle”:",
          options: [
            "A vehicle that is installed with work equipment and that is not designed to carry freights or transport passengers.",
            "A vehicle with a driving speed that doesn’t exceed 40 k.p.h, and that is used for road works.",
            "A vehicle that is designed to transport passengers and is permitted to perform road works.",
            "A vehicle used for carrying goods for hire.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0141. In which cases are you legally obliged to slow down?",
          options: [
            "When the road is obstructed, or having poor visibility or sharp bends.",
            "Before and during overtaking, for increased safety.",
            "In a place that is shaded by a boulevard of high trees.",
            "When you approach a place in which delinquent activity might take place.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0934. What is the imminent danger in the following road section?",
          options: [
            "Limited field of vision while overtaking vehicles in front of you.",
            "Engine overheating due to the steep climb.",
            "The appearance of a vehicle coming from your opposite direction in your driving lane.",
            "The crossing of a separation line and inability to return to the roadway’s right.",
          ],
          answer: 2,
          image: "img/quiz/3933.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1645. What considerations are taken into account in choosing a motorcycle helmet?",
          options: [
            "The helmet strength is of no significance. The most important is that it fits the size and shape of the rider’s head.",
            "The helmet doesn’t have to be standard, but it should have an aerodynamic design, to reduce fuel consumption.",
            "The helmet should have a good ventilation system and as many fastening straps as possible.",
            "The helmet should be standard, provide full protection (also to the chin) and fit the size and shape of the rider’s head.",
          ],
          answer: 3,
          image: "",
          types: ["A"],
        },
        {
          question:
            "1521. What is the all up length of a commercial vehicle towing a trailer?",
          options: [
            "12.75 meters.",
            "16 meters.",
            "18.75 meters.",
            "14 meters.",
          ],
          answer: 2,
          image: "",
          types: ["C"],
        },
        {
          question:
            "1705. How would you avoid colliding with a vehicle that drives in the same direction  you?",
          options: [
            "These collisions cannot be avoided.",
            "Always turn on the distress lights (the four winkers).",
            "Keep a sufficient distance from the vehicle in the front. and at the side",
            "Always drive with your headlight turned on.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0628. What does the following traffic sign indicate?",
          options: [
            "Play street.",
            "No parking zone.",
            "Area in which children are not allowed to play.",
            "End of play street.",
          ],
          answer: 0,
          image: "img/quiz/3628.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0254. When is a driver of a motor vehicle required to dip the vehicle’s lights?",
          options: [
            "When driving towards another vehicle that is driving on the same road or in an adjacent road.",
            "When driving in a steep slope only.",
            "When the vehicle in front of him turns right.",
            "When the vehicle behind him turns right.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1315. What is the maximum freight height permitted on a commercial vehicle with an all up weight of between 8000 kg to 15,000kg?",
          options: [
            "3.50 meters.",
            "4.00 meters.",
            "3.80 meters.",
            "3.00 meters.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C"],
        },
        {
          question:
            "0296. Is an escort of a new driver allowed to be under the influence of alcohol?",
          options: [
            "Yes, provided that one hour passes from the time of drinking.",
            "Yes, provided that he is over 26 years of age.",
            "Yes. An escort of a new driver does not drive the vehicle and it is therefore not important whether he is drunk or not.",
            "No. A person under the influence of alcohol is not allowed to escort a new driver.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "В"],
        },
        {
          question:
            "1669. What is the correct way to adjust the motorcycle’s mirrors?",
          options: [
            "Only in a licensing facility using special instruments.",
            "In a standing position, when the motorcycle and its handlebar are aligned in one line.",
            "By trying different positions while driving slowly.",
            "By measuring the mirror’s angle and spatial positioning.",
          ],
          answer: 1,
          image: "",
          types: ["A"],
        },
        {
          question: "0528. What is the meaning of the following traffic sign?",
          options: [
            "Entry is permitted for driving-instruction vehicles during the course of a driving lesson..",
            "No entry for driving-instruction vehicles during the course of a driving lesson.",
            "No entry for new drivers.",
            "Entry is only permitted for two-wheelers used for driving-instruction purposes.",
          ],
          answer: 1,
          image: "img/quiz/3528.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1792. The law allows driving in summer:",
          options: [
            "All the answers are incorrect.",
            "When the lights dont work.",
            "With no windscreen wipers.",
            "When the treads of the tires are less than 1 mm deep.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0077. When is a driver obliged to wear eyeglasses or contact lenses?",
          options: [
            "When his driver’s license states that he must wear them while driving.",
            "Only when he is driving during lighting up time, and provided that this requirement is stated on his driver’s license",
            "Only when he is driving during night time, provided that this requirement is stated on his driver’s license",
            "While driving in limited visibility and when he is dazzeled.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1539. How is it required to mark freight that sticks out from the vehicle’s rear side?",
          options: [
            "With a white flag tied to the tip of the oversize freight.",
            "With a red light-reflecting triangle. The length of each triangle side should be 60cm.",
            "With a white light-reflecting triangle. The length of each triangle side should be 60cm.",
            "With a red flag tied to the tip of the oversize freight.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "В"],
        },
        {
          question:
            "0985. What is the correct sentence according to the following picture?",
          options: [
            "The road tanker driver doesn’t keep to the right of the roadway, even though he can not see the vehicles coming from the opposite direction.",
            "The road tanker driver is driving far from the “soft shoulders” and in this way avoids risks.",
            "The marking on the roadway is not clear enough.",
            "The road tanker driver is driving correctly in the middle of the road, so that he can see better the vehicles coming from the opposite direction.",
          ],
          answer: 0,
          image: "img/quiz/3985.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0662. What is the meaning of the following road marking (where there is a lay-by and curbstones)?",
          options: [
            "A parking space for two-wheelers.",
            "Stopping is permitted for certain types of vehicles, as indicated in the signpost in place.",
            "Stopping in the marked area is permitted for all types of vehicles.",
            "A place where stopping is permitted for non-motor vehicles only.",
          ],
          answer: 1,
          image: "img/quiz/3662.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1111. Which action should be taken after the tool is harnessed to the hydraulic lift arms?",
          options: [
            "Lifting the tool a few times to maximum height in order to ensure that the tool is properly harnessed.",
            "Preventing vertical freedom of movement of the arms and of the tool.",
            "Preventing horizontal freedom of movement of the arms and of the tool.",
            "Securing the harnessed tool with special security chains.",
          ],
          answer: 2,
          image: "",
          types: ["1"],
        },
        {
          question:
            "0120. Under which conditions is it permitted to drive in reverse",
          options: [
            "Only with the condition that the driver is guided by a person that stands behind the car and is visible to him.",
            "Only if the vehicle is close to the sidewalk.",
            "Provided it is necessary and that no risk, interruption or disturbance is caused to any other road-user.",
            "Driving in reverse is absolutely forbidden.",
          ],
          answer: 2,
          image: "img/quiz/3120.jpg",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question: "1356. The vehicle’s headlights light the road with:",
          options: [
            "A uniform white or bright-yellow color.",
            "Bright white or yellow color, not necessarily in a uniform manner.",
            "A uniform blue or bright-yellow color.",
            "A uniform red or bright-yellow color.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1602. How does road-marking paint affect the motorcycle?",
          options: [
            "The paint might be smoother than the roadway itself and might cause skidding.",
            "The motorcycle tires spoil the roadway-marking paint.",
            "The white color on the roadway might blind and mislead the motorcyclist.",
            "The paint significantly impairs the life span of the motorcycle’s tires.",
          ],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0069. When an intersection is posted with both traffic signs and traffic lights, the instructions of which has the priority over the other?",
          options: [
            "Traffic signs always have priority (a traffic light might malfunction)",
            "A stop sign has priority over a non-flickering traffic light",
            "A “right-of-way giving” sign has priority over a non-flickering traffic light",
            "Traffic light signals (except for a flashing yellow light) have priority over a right-of-way sign",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0217. What should a driver do after parking his vehicle?",
          options: [
            "Turn on all the winkers.",
            "Ensure that the vehicle remains immobile and cannot move",
            "Place a warning triangle.",
            "Place a “We’ll be back soon” sign.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0438. Is there any reference in the following road sign to the giving of right-of-way?",
          options: [
            "No. Only instruction signs refer to the giving of right-of-way.",
            "No. The sign belongs to the instruction signs group and is not, as far as right-of-way rules are concerned, obligatory.",
            "Yes. You should give right-of-way to vehicle traffic on the road ahead.",
            "No. This is a warning sign which doesn’t come to replace any right-of-way sign.",
          ],
          answer: 2,
          image: "img/quiz/3436.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1666. What is the most important thing a motorcyclist should check before entering an intersection?",
          options: [
            "That no vehicle is driving in parallel to him at the same speed.",
            "He should calculate the time needed to cross the intersection at his current speed.",
            "In an intersection with traffic lights there is no need to check anything.",
            "That the intersection is clear of traffic that might endanger him.",
          ],
          answer: 3,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0121. How should drivers conduct themselves while driving opposite one another in a narrow and flat road?",
          options: [
            "The driver of the bigger vehicle shall increase his speed and pass the narrow part first.",
            "According to the accepted conduct in that road.",
            "The driver of the bigger vehicle shall stop and allow the other vehicle to continue driving through the narrow part.",
            "Slow down and divert their vehicle to the roadway’s edge, , to avoid collision.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0480. How is a driver required to drive near a group of children?",
          options: [
            "Stop completely for as long as the children are near the road.",
            "Continue driving in normal speed, provided that the pavements are wide.",
            "Slow down, be prepared to stop and even stop if necessary..",
            "Continue driving in normal speed, provided that he uses the horn to warn the children.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0863. What are we required to check and verify before starting-up the vehicle’s engine?",
          options: [
            "That the foot brake is pressed, the parking brake secured, the gear handle is in Neutral (N) and the clutch is pressed (in automatic vehicles – Parking (P))",
            "The parking brake is released, the gear handle is in first gear and the clutch is pressed.",
            "The parking brake is released, the gear handle is on Neutral (in automatic vehicles – N) and the clutch is pressed.",
            "The parking brake is secured, the gear handle is on first gear (in automatic vehicles – D) and the clutch is pressed.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "0753. When are we not obligated to drive on the far right side of the right lane?",
          options: [
            "When turning right.",
            "When approaching an intersection.",
            "When turning left from a single-lane, two-way road.",
            "Before a roundabout.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1212. When riding a bus, is it obligatory to obey the instructions of the bus service employees?",
          options: [
            "Yes, you must obey the orders of the service employees.",
            "No, you don’t have to obey the instructions of the service employees.",
            "No, you should only obey the instructions of policemen in uniform.",
            "No, you must only obey the instructions of policemen and municipal inspectors.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "1472. It is permitted to park a service route bus (not for the purpose of passenger loading / unloading):",
          options: [
            "According to the provisions of the route license, if such was determined by the Authority.",
            "In every bus station.",
            "In any place, according to the passengers’ request.",
            "In every public parking lot.",
          ],
          answer: 0,
          image: "",
          types: ["D"],
        },
        {
          question:
            "0711. You are driving vehicle no.3. to whom will you give the right of way?",
          options: [
            "To the bus (2) only.",
            "To the Motor-cyclist (1) and the Bus (2).",
            "To none. You (3) have right of way.",
            "To the vehicle that is closest to the intersection.",
          ],
          answer: 1,
          image: "img/quiz/3711.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1514. Towing without a designated safety pin and without safety chains would cause:",
          options: [
            "Disengagement of the trailer from the towing vehicle.",
            "Fracture of the towing pin.",
            "Excessive wear of the towing shaft.",
            "Faster and safer harnessing of the trailer.",
          ],
          answer: 0,
          image: "",
          types: ["C"],
        },
        {
          question:
            "0591. How should you turn left from a road on which the following sign is placed?",
          options: [
            "You should start turning from the central lane.",
            "From the left side, unless otherwise signposted.",
            "You should turn from the far right lane.",
            "It is forbidden to turn left. You are only allowed to drive forward.",
          ],
          answer: 1,
          image: "img/quiz/3590.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0890. What is the purpose of the “hard shoulder” on freeways?",
          options: [
            "It is an escape place in case of danger.",
            "It is a place to stop and talk on the cellular phone,",
            "It is a place for parking and stopping for the purpose of driver refreshment.",
            "It is a lane used for overtaking restricted vehicles, which illegally drive in the right lane",
          ],
          answer: 0,
          image: "img/quiz/3079.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0499. What is the meaning of the following traffic sign?",
          options: [
            "No overtaking permitted for commercial vehicles with an all up weight of more than 4,000 kg but may overtake a Motorcycle",
            "No overtaking or bypassing of motor vehicles for any vehicle with an all up weight of more than eight tons.",
            "A commercial vehicle with an all up weight of at least 14 tons is permitted to overtake or bypass a motor vehicle with more than 2 wheels.",
            "No overtaking for vehicles with an all up weight of more than 12 tons.",
          ],
          answer: 0,
          image: "img/quiz/3499.jpg",
          types: ["C1", "C"],
        },
        {
          question:
            "0989. What might happen as a result of brake over- heating?",
          options: [
            "The engine will overheat.",
            "The vehicle will lose its steering properties.",
            "The tires will heat and wear-out more.",
            "The brakes efficiency is significantly reduced.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0001. Who is under obligation to be familiar with the traffic laws and obey them?",
          options: [
            "Only a pedestrian",
            "Every road user.",
            "Only a professional driver.",
            "Only a driver.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1417. What is a driver required to do after crossing a flooded road section?",
          options: [
            "Stop at the side of the road and wait until the braking system dries up completely.",
            "Make a strong and sudden brake and check if the brakes are responding.",
            "Drive slowly and press gently and intermittently on the brake pedal, to ensure that the brakes function properly.",
            "Continue driving normally, because there is no connection between water and the braking system’s efficiency.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0238. What is the maximum number of passengers allowed in an combined commercial vehicle with an overall permissible weight of up to 3,500 kg?",
          options: [
            "As stated in the driver’s license, if at all.",
            "One passenger next to the driver and three passengers in the back.",
            "As stated on the vehicle license but not more than eight passengers, apart from the driver.",
            "Two passengers next to the driver and four passengers in the back.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "В"],
        },
        {
          question: "0511. What is the meaning of the following traffic sign?",
          options: [
            "It is obligatory to stop for customs inspection",
            "Slow down for passport control.",
            "It is obligatory to stop if you have somrthing to declare only.",
            "No need to stop for custom controls (This is a duty-free facility).",
          ],
          answer: 0,
          image: "img/quiz/3511.jpg",
          types: ["C1", "C", "D"],
        },
        {
          question: "1560. When is it permitted to drive in reverse?",
          options: [
            "When it is required from a traffic-related point of view.",
            "When it is required for personal reasons, even if driving in reverse is not essential.",
            "Only when the roadway is wider than 4 meters.",
            "It is always permitted, as long as we are always seen by other drivers.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "0460. How should you conduct yourself from the place of the following sign until after the railway tracks?",
          options: [
            "Cross the railway tracks quickly.",
            "Slow down and stop before the railway tracks, until the train passes.",
            "Slow down, check and verify that you can cross the railway tracks in a constant and uninterrupted drive. Don’t stop on the tracks.",
            "Stop and wait for the instructions of a signaler/flagman.",
          ],
          answer: 2,
          image: "img/quiz/3459.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0043. When driving in reverse:",
          options: [
            "You must use another person to guide you.",
            "You must use another person to guide you on highways..",
            'You must use another person to guide you during \\"lighting up time\\".',
            "You are permitted to use another person to guide you.",
          ],
          answer: 3,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0385. What is the meaning of the following traffic sign?",
          options: [
            "You have reached a section where fast vehicles should drive on the right lane and slow vehicles should drive on the left lane.",
            "You have reached the end of a special speed section.",
            "You have reached a section where parking is permitted on both sides of the road.",
            "You have reached a section where speed limit is temporarily changed from 50 k.p.h to 30 k.p.h.",
          ],
          answer: 1,
          image: "img/quiz/3385.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1094. What is the function of the tractor’s differential lock?",
          options: [
            "To assist when one of the tractor’s wheels sinks or skids.",
            "To assist in maintaining driving direction while driving on a straight road.",
            "To prevent the tractor from slipping in sharp turns.",
            "To assist during emergency braking.",
          ],
          answer: 0,
          image: "",
          types: ["1"],
        },
        {
          question:
            "0066. Is it obligatory to obey the directions and signals of a military policeman, even when a certain direction or signal is opposed to the traffic signs?",
          options: [
            "Yes, it is obligatory to obey with full caution.",
            "Yes, except for directions which contradict a no-entry sign.",
            "No, it is not obligatory to obey",
            "Yes, except for directions which contradict a stop sign.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "0973. The white vehicle (1) and the silver vehicle (2) at the intersection, intend to enter the same road. Which of them is obliged to give right of way?",
          options: [
            "The first one to turn.",
            "The white vehicle.",
            "The first one to enter the road.",
            "The silver vehicle (2) at the intersection.",
          ],
          answer: 3,
          image: "img/quiz/3973.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1635. Is a motorcyclist permitted to drive on the sidewalk?",
          options: [
            "Yes, as long as pedestrians are not disturbed by it.",
            "Yes, in order to cross it and enter premises.",
            "Never.",
            "Yes, when road traffic is congested.",
          ],
          answer: 1,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0725. How should you (vehicle no. 3) behave while you turn right in the following intersection?",
          options: [
            "Enter (3) the center of the intersection in order to prevent the blue vehicle from hitting the cyclist..",
            "Allow the cyclist to continue riding and turn right behind him.",
            "Overtake the cyclist, quickly and carefully, while he is waiting.",
            "Honk loudly to the cyclist (1), so that he will allow you to complete the right turn.",
          ],
          answer: 1,
          image: "img/quiz/3725.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "1707. When driving behind a big truck:",
          options: [
            "When a “general danger” sign is placed, and due to the size of the truck, you should keep from it a distance that is thrice bigger than the statutory distance. .",
            "Keep a safe distance for safe braking – The law requires a gap of not less than one second.",
            "Keep a small as possible distance from the vehicle in front.",
            "Due to the vehicle’s size - Keep a distance that doubles the statutory gap.",
          ],
          answer: 1,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1109. Is a tractor required to have direction indicators from front and back?",
          options: [
            "Not all tractors. A tractor that mostly works in the field has an exemption.",
            "Yes, every tractor that has a driver’s compartment.",
            "Not all tractors. A tractor to which a hydraulic work tool is harnessed has an exemption..",
            "It is only obligatory in a tractor more than 2 meters wide.",
          ],
          answer: 1,
          image: "",
          types: ["1"],
        },
        {
          question:
            "1647. How would you choose appropriate shoes for riding a motorcycle?",
          options: [
            "You should choose high shoes made from a friction-resistant fiber material.",
            "You should choose light and comfortable shoes enabling flexible riding.",
            "You should choose high insulated shoes.",
            "There are no rules to it: Any shoe or sandal is proper for the motorcycle’s safe operation.",
          ],
          answer: 0,
          image: "",
          types: ["A"],
        },
        {
          question:
            "0535. Where are you required to stop when the following traffic sign is placed?",
          options: [
            "At the center of the intersection, where the best field of view is located.",
            "If a stop line is marked – stop immediately after the line.",
            "Stop wherever you want before the intersection.",
            "If a stop line is marked – stop before and close to the line. If a stop line is not marked – stop at the line of the junction.",
          ],
          answer: 3,
          image: "img/quiz/3184.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question:
            "1257. Is a taxi permitted to stop and load or unload passengers within the limits of the following traffic sign?",
          options: [
            "Yes, but on an urban road only, and provided that no traffic disturbance is caused.",
            "No.",
            "No, except during the night.",
            "Yes, when a “bus station” sign is placed.",
          ],
          answer: 0,
          image: "img/quiz/3015.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0870. Proper adjustment of the driver’s seat:",
          options: [
            "Proper adjustment of the driver’s seat prevents the occurrence of “blind spots” while driving in reverse.",
            "Is performed by the vehicle’s manufacturer during its manufacturing process, and additional adjustment of the seat is not needed.",
            "Allows you to comfortably see the road and to have an easy and non-tiring drive.",
            "Is an important action that should be applied during every periodic treatment of the vehicle.",
          ],
          answer: 2,
          image: "",
          types: ["C1", "C", "D", "1", "В"],
        },
        {
          question:
            "0284. What should you do when your vehicle makes unreasonable noise?",
          options: [
            "A vehicle making unreasonable noise should not be driven.",
            "It is permitted to drive a vehicle making unreasonable noise under the approval of the local authority.",
            "It is permitted to drive a vehicle making unreasonable noise in a freeway.",
            "It is permitted to drive a vehicle making unreasonable noise unrestrictedly.",
          ],
          answer: 0,
          image: "",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
        {
          question: "0605. What is the meaning of the following traffic sign?",
          options: [
            "The number of a regional road.",
            "The distance to the nearest roadway (in k.p.h).",
            "The number of a freeway. (Motorway)",
            "The distance to the nearest intersection (in k.p.h).",
          ],
          answer: 2,
          image: "img/quiz/3605.jpg",
          types: ["C1", "C", "D", "A", "1", "В"],
        },
      ],
    };
  },
};
</script>

<style lang="scss">
// @import url("https://fonts.googleapis.com/css2?family=Fuggles&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
// :root {
//   --vs-theme-bg: #18191c;
//   --vs-theme-color: #fff;
//   --vs-theme-layout: #1e2023;
//   --vs-theme-bg2: #141417;;
//   --vs-theme-code: #141417;
//   --vs-theme-code2: #161619;
// }
body {
  background: #18191c;
  color: white;
}
#app {
  font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
}
// radio button border
.vs-radio-content:not(.active) {
  .vs-radio__effect:after {
    border: 2px solid rgba(255, 255, 255, 0.4) !important;
  }
}
// radio button border
.vs-pagination__progressF {
  background: rgba(15, 16, 19, 1) !important;
}
</style>
