<template>
  <div class="timer">{{ minute }} : {{ second === 60 ? '00' : second }} </div>
</template>

<script>
export default {
  name: "Main",
  props: ["questions"],
  data: function () {
    return { minute: 44, second: 60, first: false };
  },
  mounted: function () {
    this.interval = setInterval(() => {
      this.second--;
      if (this.second === 0) {
        this.minute --;
        this.second = 60;
      }
    }, 1000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 7px;
}
</style>
