<template>
  <div class="main">
    <Quiz :questions="questions" />
  </div>
</template>

<script>
import Quiz from "@/components/Quiz.vue";

export default {
  name: "Main",
  components: { Quiz },
  methods: {},
  props: ["questions"],
  data: function () {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 7px;
}
</style>
